import TableContainer from 'Components/Common/TableContainer';
import { getNonpanelVisithistory } from 'pages/Workers/_requests';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';

const NonpanelClaims = () => {
  const { id } = useParams();
    const [worker, setWorker] = useState<any>([]);
    useEffect(() => {
      
      getNonpanelVisithistory(id).then((response: any) => { 
          setWorker(response);
        });
      }, []);

      const renderCreatedAt = (cell: any) => {
        const formattedDate = new Date(cell.row.original.visitDate).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      
        return <>{formattedDate}</>;
      };
    const columns = useMemo(
        () => [
          {
            header: "S.No",
            id: "sno",
            enableColumnFilter: false,
            cell: (cellProps: any) => {
              return cellProps.row.index + 1;
            },
          },
       
          {
            header: "Clinic name",
            accessorKey: "clinicName",
            enableColumnFilter: false,
          },
          {
            header: "Agency name",
            accessorKey: "agencyName",
            enableColumnFilter: false,
          },
          {
            header: "Visit Date",
            accessorKey: "visitDate",
            enableColumnFilter: false,
            cell: renderCreatedAt
          },
          {
            header: "Visit Reason",
            accessorKey: "visitReason",
            enableColumnFilter: false,
          },
       
          {
            header: "Amount ( RM )",
            accessorKey: "amount",
            enableColumnFilter: false,
          },
 
          {
            header: "Approval Status",
            accessorKey: "approvalStatus",
            enableColumnFilter: false,
            cell: (cell: any) => {
         
              switch (cell.getValue()) {
                case 1:
                  return (
                    <span className="badge border border-success text-success">
                      {" "}
                      Approved
                    </span>
                  );
                case 2:
                  return (
                    <span className="badge border border-danger text-danger">
                      {" "}
                      Rejected
                    </span>
                  );
                default:
                  return (
                    <span className="badge border border-secondary text-secondary">
                      {" "}
                      Pending{" "}
                    </span>
                  );
              }
            }
          },
      
          {
            header: "Action",
            cell: (cellProps: any) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to={`/nonpanel-claims-form/view/${cellProps.row.original.primaryId}`}
                      className="text-primary d-inline-block edit-item-btn"
                    >
    
                 <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
               
                </ul>
              );
            },
          },
        ],
        []
      );

    return (
        <React.Fragment>
         
                <Row>
            <Col lg={12}>
              <Card id="WorkresList">
              
          
                <div className="card-body pt-0">
                  <div>
                    
                    <TableContainer
                    columns={columns}
                    data={(worker || [])}
                    isGlobalFilter={true}
                    customPageSize={10}
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap mb-0"
                    SearchPlaceholder="Search nonpanel claims"
                />
                
                   
                  </div>
                  <div>
               
                  </div>

                </div>
              </Card>
            </Col>
          </Row>
             
        </React.Fragment>
    );
};

export default NonpanelClaims;