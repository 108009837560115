import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import {  useNavigate } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "Components/Common/TableContainer";
import { deleteAgencyById, getAgency } from "./_requests";
import AgencyFilter from "./Filter";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
const AgencyList = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [agency, setAgency] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [roleToDeleteId, setRoleToDeleteId] = useState("");
  const [roleToDeleteName, setRoleToDeleteName] = useState("");
  const [Filter, setFilter] = useState<boolean>(false);
 
  const [agencyaccess, setagencycaccess] = useState<any>([]);


  const toggleFilter = () => {
    setFilter(!Filter);
  };

  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchAgency();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters?: any , Reset?: any) => {
    fetchAgency(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

 

  useEffect(() => {
    getCRUD(userProfile?.role, 10).then((res: any) => {
      setagencycaccess(res.Data[0]);
    });
  }, []);

  const addAgency = () => {
    if (agencyaccess?.add === 1) {
      navigate("/agency/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };


  useEffect(() => {
    fetchAgency(); 
  }, []);
  const fetchAgency = async(filters?:any) => {
    try {
  
     const response= await getAgency(filters)
      setAgency(response);
      
    } catch (error) {
      console.error("Error fetching agency:", error);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const removeAgency = useCallback(() => {

    if(agencyaccess.remove == 1){
      toggleDeleteModal();
    }
    else{
      toast.error("You don't have permission to access this page", {
              position: "top-right",
              style: {
                backgroundColor: "#9b2313",
                color: "#c7c7d7",
                fontSize: "14px",
              },
              closeButton: false,
            });
    }
  },[agencyaccess])
  const prepareDelete = (agency: any) => {
    setRoleToDeleteId(agency.primaryId);
    setRoleToDeleteName(agency.name);
    removeAgency();
  };

  const viewAgency = (cellProps: any) => {
    setagencycaccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.view === 1) {
        navigate(`/agency/view/${cellProps.row.original.primaryId}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const confirmDelete = (departmentId: any) => {
    deleteAgencyById(departmentId)
      .then(() => {
        toast.success("Agency deleted Successfully", { autoClose: 3000 });
        fetchAgency(); // Update the agency list after deletion
        toggleDeleteModal();
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },

      {
        header: "AGENCY NAME",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "USER ID",
        accessorKey: "agencyUniqueId",
        enableColumnFilter: false,
      },
      {
        header: "CONTACT",
        accessorKey: "telephoneNumber",
        enableColumnFilter: false,
      },
      {
        header: "POC NAME",
        accessorKey: "primaryContact",
        enableColumnFilter: false,
      },
      {
        header: "POC CONTACT",
        accessorKey: "primaryTelephone",
        enableColumnFilter: false,
      },
      {
        header: "CITY",
        accessorKey: "city",
        enableColumnFilter: false,
      },
      {
        header: "STATE",
        accessorKey: "state",
        enableColumnFilter: false,
      },
      {
        header: "COUNTRY",
        accessorKey: "country",
        enableColumnFilter: false,
      },

      {
        header: "STATUS",
        accessorKey: "suspenseStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  Active
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  Suspended
                </span>
              );
          }
        },
      },
      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewAgency(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <div
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const agency = cellProps.row.original;
                    prepareDelete(agency);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    [removeAgency]
  );

  document.title = "Agency | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Agency" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="AgencyList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Agency List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleFilter}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliter
                        </button>

                        <div
                          onClick={addAgency}
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Agency
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={agency}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search for agency..."
                    />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the agency{" "}
              <strong>{roleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(roleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>
      <AgencyFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default AgencyList;
