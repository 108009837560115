import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MAIN_URL = `${API_URL}/agencyInvoice`



const getAgencyInvoice = (AgencyId:any,filters?: any) => {
  return axios
    .post(`${MAIN_URL}/agencylist/${AgencyId}`, filters)
    .then((d: any) => {
     
      return d.Data
    })
}
const getAgencyInvoiceById = (id:any) => {
  return axios
    .get(`${MAIN_URL}/viewAgencyInvoice/${id}`,) 
    .then((response) =>{
    
      return response.data;
    }
   )
}
const addAgencyInvoice = (data: FormData,clinicID:any) => {
 // 
  return axios
  .post(`${MAIN_URL}/addAgencyInvoice/${clinicID}`, data,)
  .then((response) => {
   //
    return response.data
   })
   
}
const updateAgencyFile=(data: FormData,id:any)=>{
  
  
  return axios
  .put(`${MAIN_URL}/UpdateAgencyPaid/${id}`, data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then((response) => {
   
    return response.data
   })
}
export {
  getAgencyInvoice,addAgencyInvoice,getAgencyInvoiceById,updateAgencyFile
}
