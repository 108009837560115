import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Plan_URL = `${API_URL}/plan`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
const GET_PLAN_LIST = `${API_URL}/plan`

//
const getPlan = ()=> {
  return axios
    .get(`${Plan_URL}/list`)
    .then((d: any) => {
      return d.Data;
    })
}

const getClientByPlanId = (id: any) => {
   
  return axios
    .get(`${Plan_URL}/viewassignPlan/${id}`)
   .then((response) => {
   
    return response});
}

const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${Plan_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      return response.data
     })
   
}
const getPlanById = (id:any) => {
  return axios
    .get(`${Plan_URL}/view/${id}`) 
    .then((response: any) =>{
      
      return response.Data;
    }
   )
}


const updatePlanById = (Plan:any,ID:any) => {
  //
  return axios
  .put(`${Plan_URL}/edit/${ID}`, Plan)
  .then((response) => {
  //  
    return response.data
   })
   
}
const updateFamilyPlan = (Plan:any,ID:any) => {
 // 
  return axios
  .put(`${Plan_URL}/family/${ID}`, Plan)
  .then((response) => {
  //  
    return response.data
   })
   
}
const getAgency = async ()=> {
  const res = await axios.get(`${Plan_URL}/getagency`);
  //
  return res;
}
const getDepartment = async (clientID:any) => {
  //
  const res = await axios.get(`${Plan_URL}/getDepartment/${clientID}`);
  //
  return res;
}
const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${Plan_URL}/admingetDepartment`);
  //
  return res;
}
const createPlan = (Plan: any) => {
  //
  return axios
  .post(`${Plan_URL}/add`, Plan)
  .then((response) => {
  //  
    return response.data
   })
   
}

const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${EXCEL_URL}`, excel)
    .then((response) => {

    //  
      return response.data;
    // .then((response: AxiosResponse<Response<Plan>>) => response.data)
    // .then((response: Response<Plan>) => response.data)
})}


// const getBenefit = async () => {
//   const res = await axios.get(`${Plan_URL}/`);
//   return res;
// }
// const getPlan= async (id:any) => {
//   const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
//   return res;
// }
const getCountry = async () => {
  const res = await axios.get(`${COUNTRY_URL}`);
  //
  return res;
}

const deletePlanById = (id: any) => {
  return axios
    .post(`${Plan_URL}/delete/${id}`)
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${Plan_URL}/activateDeactivateAccount/${id}/${status}`)
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/${id}`);
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid});
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data);
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data);
  //
   return res.data;
 }
 const cronJob=async()=>{ 
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`);
  //
   return res.data;
 }

 const removeAssignedPlan = (id:any) => {
  return axios
    .get(`${API_URL}/plan/removeAssignedagency/${id}`) 
    .then((response) =>{
   // 
      return response.data;
    }
   )
}
export {
  getPlan,removeAssignedPlan,
  deletePlanById,updatecontrols,getsobdetails,getReportMonthlyCount,
  createPlan,getPlanById,updateFamilyPlan,
  getAgency, getDepartment,getCountry,updatebulkinsert,updatePlanById,
  admingetDepartment,updateActiveDeactive,upgradePlan,renewalPlan,cronJob,getClientByPlanId,
}


