import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { isEmpty } from "lodash";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "Components/Common/TableContainer";
import { deleteClinicsById, getClinics, updatebulkinsert } from "./_requests";
import ClinicFilter from "./Filter";
import Filter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ClinicsList = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [Clinics, setClinics] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<string>("");
  const [clinicaccess, setclinicaccess] = useState<any>([]);
  const [bulkuploadModal, setBulkuploadModal] = useState(false);

  const toggleBulkuploadModal = () => {
    setBulkuploadModal(!bulkuploadModal);
    setIsUploadButtonDisabled(true);
  };

  useEffect(() => {
    getCRUD(userProfile?.role, 39).then((res: any) => {
      setclinicaccess(res.Data[0]);
    });
  }, []);

  const addClinic = () => {
    if (clinicaccess?.add === 1) {
      history("/clinics/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };
  const filter = {};
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (clinics: any) => {
    setRoleToDeleteId(clinics.primaryId);
    setRoleToDeleteName(clinics.name);

 
      if (clinicaccess?.remove === 1) {
        toggleDeleteModal();
        return;
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
   
  };

  const confirmDelete = (id: string) => {
   
    deleteClinicsById(id).then((res: any) => {
      toggleDeleteModal();
      if (res.Code === "1") {
        toast.success("Clinics deleted Successfully", { autoClose: 3000 });
      }
      if (res.Code === "0") {
        toast.success(res.Message, { autoClose: 3000 });
      }
      FetchClinic(filter);
    });
  };

  const downloadFile = () => {
    const fileUrl = "/media/ExcelStatic/ClinicSampleData.xlsx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "ClinicSampleData.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    FetchClinic(filter);
  }, []);


  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    FetchClinic();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any, Reset?:any) => {
    FetchClinic(selectedFilters);
    if(Reset){
     setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  const FetchClinic = (filters?: any) => {
    getClinics(filters).then((response: any) => {
      setClinics(response);
    });
  };

  useEffect(() => {
    if (!isEmpty(Clinics)) {
      setClinics(Clinics);
    }
  }, [Clinics]);

  const [Filter, setFilter] = useState<boolean>(false);

  const viewClinic = (cellProps: any) => {
  
    if (clinicaccess?.view === 1) {
      history(`/Clinics/View/${cellProps.row.original.primaryId}`);
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  
  };

  const toggleFilter = () => {
    setFilter(!Filter);
  };

  const inputFileRef = React.createRef<HTMLInputElement>()
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
  const handleFileChange = (e: any) => {
    if (e.target.files.length > 0) {
      setIsUploadButtonDisabled(false);
    } else {
      setIsUploadButtonDisabled(true);
    }
  };

  const imageHandler = (e: any) => {
    toggleBulkuploadModal()
    if (inputFileRef && inputFileRef.current && inputFileRef.current.files) {
      const formData = new FormData()
      formData.append('excel', inputFileRef.current.files[0])


      updatebulkinsert(formData).then((res: any) => {
        history("/clinics", { replace: true });      
        if (res?.Code == "1") {
          toast.success(res?.Message);
        }
        if (res?.Code == "0") {
          toast.error(res?.Message);
        }
        FetchClinic(filter);
      });
    }
  };
  const imageHandlerclick = () => {
    if (clinicaccess?.add === 1) {
        toggleBulkuploadModal()
      } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "CLINIC NAME",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "USER ID",
        accessorKey: "clinicUniqueId",
        enableColumnFilter: false,
      },
      {
        header: "EMAIL",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "PHONE",
        accessorKey: "telephoneNumber",
        enableColumnFilter: false,
      },
      {
        header: "CITY",
        accessorKey: "city",
        enableColumnFilter: false,
      },
      {
        header: "STATE",
        accessorKey: "state",
        enableColumnFilter: false,
      },
      {
        header: "COUNTRY",
        accessorKey: "country",
        enableColumnFilter: false,
      },

      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewClinic(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const clinics = cellProps.row.original;
                    prepareDelete(clinics);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [prepareDelete,viewClinic]
  );

  document.title = "Clinic | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Clinics" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="ClinicsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center ">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Clinics List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex flex-wrap align-items-center">
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1 mb-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                        <button
                          type="button"
                          className="btn text-white add-btn me-1 mb-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleFilter}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliters
                        </button>

                        <div
                          onClick={addClinic}
                          className="btn text-white add-btn me-1 mb-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1 mb-1"></i>
                          Add Clinics
                        </div>

                        <button
                          type="button"
                          className="btn text-white add-btn me-1 mb-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={imageHandlerclick}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        
                          Bulk Upload
                        </button>

                        <button
                          type="button" 
                          className="btn text-white add-btn me-1 mb-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={downloadFile}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Download Sample
                        </button>
                        <Link
                          to="/Clinic/ExportData"
                          className="btn text-white btn text-white add-btn me-1 mb-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Clinics || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      isTicketsListFilter={false}
                      SearchPlaceholder="Search clinic"
                    />
                    <ToastContainer limit={1} />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the clinic{" "}
              <strong>{RoleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(RoleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>


          <Modal isOpen={bulkuploadModal} toggle={toggleBulkuploadModal} centered>
  <ModalHeader toggle={toggleBulkuploadModal}>
    Bulk upload
  </ModalHeader>
  <ModalBody>
  <div className='mb-3'>
              <label
                htmlFor='fileInput'
                className='col-form-label required fw-bold fs-6 form-label'
              >
                Upload File
              </label>
              <input type='file' className='form-control' id='fileInput' 
              ref={inputFileRef} 
              onChange={handleFileChange}
              />
            </div>
  </ModalBody>
  <ModalFooter>
    <Button color="secondary" onClick={toggleBulkuploadModal}>
      Cancel
    </Button>
    <Button
      color="success"
      disabled={isUploadButtonDisabled}
      onClick={(e) => { imageHandler(e) }}
    >
      Upload
    </Button>
  </ModalFooter>
</Modal>
        </Container>
      </div>
      <ClinicFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default ClinicsList;
