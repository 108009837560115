import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";
import DocumentsTab from "./ClaimsTab";
import ActivitiesTab from "./PaymentsTab";
import TeamTab from "./RolesTab";
import Support from "./Support";
import Controls from "./Controls";
import PlanUpgrade from "./PlanUpgrade";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getClinicsById } from "pages/Clinics/_requests";
import TimeConversionComponent from "./TimeConversionComponent";
import Avatar from "react-avatar";
import clsx from "clsx";
import ClaimsTab from "./ClaimsTab";
import PaymentsTab from "./PaymentsTab";
import RolesTab from "./RolesTab";
import { useProfile } from "Components/Hooks/UserHooks";
import BreadCrumb from "Components/Common/BreadCrumb";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Section = () => {
  const history = useNavigate();
  const { id } = useParams();
  const { userProfile } = useProfile();
  const [clinic, setclinic] = useState<any>([]);
  const location = useLocation();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;

  const [satOpenTime, setSatOpenTime] = useState<any>([]);
  const [satCloseTime, setSatCloseTime] = useState<any>([]);
  const [com, setCom] = useState<any>([]);
  const [ProfileAccess, setProfileAccess] = useState<any>([]);



  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 39;
    } else {
      Data = 29;
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setProfileAccess(res.Data[0]);
    });
  }, []);

  const EditProfile = (Data: any) => {
    if (ProfileAccess?.edit === 1) {
      history("/clinics/Edit/" + Data);
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  var date = clinic.dob;
  var dates = new Date(date);
  var formattedDate = dates.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const Data = Type === 1 ? id : Id;


  useEffect(() => {
    getClinicsById(Data).then((res: any) => {
      setclinic(res);

    });
  }, []);

  const sendEmail = () => {
    var Getname = com?.name;
    var GetuserId = com?.userId;
    var Getemail = com?.email;
    var GetPincode = com?.pincode;
    var data = { name: Getname, email: Getemail, pincode: GetPincode };
  };

  function goBack() {
    history(-1);
  }

  document.title = " Clinic Overview | Al khairi care ";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <BreadCrumb title="Clinic Overview" pageTitle="Clinic" />
      {Type === 1 && (
        <Row>
          <Col>
            {" "}
            <button
              onClick={goBack}
              className="btn btn-primary px-2 text-center mb-3"
            >
              <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
            </button>{" "}
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={12}>
          {/* <Card className="mt-n4 mx-n4"> */}
          <Card className="">
            <div className="bg-primary-subtle">
              <CardBody className="pb-0 px-4">
                <Row className="mb-3">
                  <div className="col-md">
                    <Row className="align-items-center g-3">
                     
                      <div className="col-md">
                        <div>
                          <h4 className="fw-bold">{clinic?.name}</h4>
                          <br></br>

                          <div className="hstack flex-wrap">
                            {/* <div><i className="ri-building-line align-bottom me-1"></i> {clinic?.department?.name}</div> */}
                            <div className="vr"></div>
                            <div className="border border-dashed border-success rounded mb-1 me-2 p-2">
                            <i className="ri-phone-line me-1"></i>
                              Mobile Number:{" "}
                              <span className="fw-medium">
                                {clinic?.telephoneNumber}
                              </span>
                            </div>
                            <div className="vr"></div>
                            <div className="border border-dashed border-success rounded mb-1 p-2">
                            <i className="ri-mail-line me-1"></i> 
                              Email:{" "}
                              <span className="fw-medium">{clinic?.email}</span>
                            </div>
                            <div className="vr"></div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                  {/* <div className="col-md-auto">
                                        <div className="hstack">
                                            <button type="button" className="btn btn-ghost-secondary  btn btn-primary"><i className="ri-attachment-line fs-16"></i> Deactivate</button>
                                        </div>
                                    </div> */}
                </Row>
                <Row className="my-3"></Row>

                {Type === 1 && (
                  <Nav
                    className="nav-tabs-custom border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                        href="#"
                      >
                        Overview
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2");
                        }}
                        href="#"
                      >
                        Claim Forms
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3");
                        }}
                        href="#"
                      >
                        Payments
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4");
                        }}
                        href="#"
                      >
                        Roles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("5");
                        }}
                        href="#"
                      >
                        Support
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "6" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("6");
                        }}
                        href="#"
                      >
                        Controls
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "7" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("7");
                        }}
                        href="#"
                      >
                        Bank Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row>
                <Col xxl={3}>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xl={6}>
                          <h5 className="card-title mb-0">Timing Settings</h5>
                        </Col>
                        <Col xl={6} className="d-flex justify-content-end">
                          <div
                            onClick={() => {
                              EditProfile(Data);
                            }}
                            className="btn btn-primary me-1"
                          >
                            Edit Clinic
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <div className="table-responsive ">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium table-card col-4">
                                Monday Timing
                              </td>
                              {clinic?.monday_time === 2 && (
                                <td style={{ paddingLeft: "16px" }}>Holiday</td>
                              )}
                              {clinic?.monday_time === 1 && <td>24Hrs</td>}
                              {clinic?.monday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.monday_open,
                                    close: clinic?.monday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Tuesday Timing</td>
                              {clinic?.tuesday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.tuesday_time === 1 && <td>24Hrs</td>}
                              {clinic?.tuesday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.tuesday_open,
                                    close: clinic?.tuesday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Wednesday Timing</td>
                              {clinic?.wednesday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.wednesday_time === 1 && <td>24Hrs</td>}
                              {clinic?.wednesday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.wednesday_open,
                                    close: clinic?.wednesday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Thursday Timing</td>
                              {clinic?.thursday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.thursday_time === 1 && <td>24Hrs</td>}
                              {clinic?.thursday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.thursday_open,
                                    close: clinic?.thursday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Friday Timing</td>
                              {clinic?.friday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.friday_time === 1 && <td>24Hrs</td>}
                              {clinic?.friday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.friday_open,
                                    close: clinic?.friday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Saturday Timing</td>
                              {clinic?.saturday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.saturday_time === 1 && <td>24Hrs</td>}
                              {clinic?.saturday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.saturday_open,
                                    close: clinic?.saturday_close,
                                  }}
                                />
                              )}
                            </tr>
                            <tr>
                              <td className="fw-medium">Sunday Timing</td>
                              {clinic?.sunday_time === 2 && (
                                <td id="t-client">Holiday</td>
                              )}
                              {clinic?.sunday_time === 1 && <td>24Hrs</td>}
                              {clinic?.sunday_time === 3 && (
                                <TimeConversionComponent
                                  time={{
                                    open: clinic?.sunday_open,
                                    close: clinic?.sunday_close,
                                  }}
                                />
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Address Settings</h5>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-4">Address</td>
                              <td>{clinic?.address}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">City</td>
                              <td id="t-client">{clinic?.city}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">State</td>
                              <td>{clinic?.state}</td>
                            </tr>

                            <tr>
                              <td className="fw-medium">Country</td>
                              <td>
                                {clinic?.country} - {clinic?.pincode}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Location Settings</h5>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-4">Location</td>
                              <td>{clinic?.location}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            {Type === 1 && (
              <>
                <TabPane tabId="2">
                  <ClaimsTab />
                </TabPane>
                <TabPane tabId="3">
                  <PaymentsTab />
                </TabPane>
                <TabPane tabId="4">
                  <RolesTab />
                </TabPane>
                <TabPane tabId="5">
                  <Support />
                </TabPane>
                <TabPane tabId="6">
                  <Controls />
                </TabPane>
                <TabPane tabId="7">
                  <PlanUpgrade />
                </TabPane>
              </>
            )}
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
