import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getClinic, getEmployee, getReportforClinic} from './_requests'
import Select from 'react-select'; // Make sure to remove the unnecessary import statement
import { Card, Container, Row } from 'reactstrap';
import React from 'react';
import { log } from 'util';

const WorkerReport = () => {
  const tableRef = useRef(null)
  const [cli, setCli] = useState<any>([])
  const [employee, setEmployee] = useState<any>([])
  const [report, setReport] = useState<any>([])
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState([null, null])
  const [cliId, setcliId] = useState(0)
  const [OCcli, setOCcli] = useState('')
  const [OCDate, setOCDate] = useState('')

  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [selectedEmp, setSelectedEmp] = useState('')
  const [selectedid, setSelectedid] = useState('')

  const EmployeeChange = (event: any) => {
    const id = event.target.value
    const selectedDataId = event.target.selectedOptions[0].getAttribute('data-id')
    setSelectedEmp(selectedDataId)
    setSelectedid(id)
  }
  const [btndisable, setBtnDisable]=useState(true);
  const [selectedOption, setSelectedOption] = useState(null)
  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
 setBtnDisable(false)
    setSelectedid(selectedOption?.value)
    setSelectedEmp(selectedOption?.emp_id)

    /*  var text = selectedOption?.id
    setOCcli(text)
    setcliId(selectedOption?.value) */
    //
  }
  const clinicChange = (event: any) => {
    var index = event.nativeEvent.target.selectedIndex
    setOCcli(event.nativeEvent.target[index].text)
    setcliId(event.target.value)
  }
  useEffect(() => {
    getClinic().then((res: any) => {
      //setCli(res.data.Data)
      const mappedOptions = res.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setCli(optionsWithAll)
    })
  }, [])
  useEffect(() => {
    getEmployee().then((res: any) => {
      
      const mappedOptionsemp = res.Data.map((com: {id: any; name: any; workerId: any}) => ({
        value: com.id,
        label: com.name,
        emp_id: com.workerId,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptionsemp]
      setEmployee(optionsWithAll)
    })
  }, [])

 

  const clinicReport = () => {
    const empDetails = {id: selectedid, empId: selectedEmp}

    navigate('/workerReport/NewReport/' + selectedid + '/' + selectedEmp, {state: empDetails})
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()
  const clearReport = () => {
    setReport([])
    getClinic().then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setCli(optionsWithAll)
    })
    setSelectedOption(null)
    setDateRange([null, null])
    setOCDate('')
    setOCcli('')
    setcliId(0)
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }

 

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
      <Row>
      <Card>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-500 m-0'>Individual Worker Report</h3>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Worker</label>

              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={employee}
                isSearchable
                // onInputChange={handleInputChange}
              />
            </div>

            <div className='col-lg-2 pt-12 mt-3'>
            <br></br>
              <button onClick={clinicReport}  className="btn text-white add-btn me-1 col-lg-12"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              disabled={btndisable}
                              >
                Search
              </button>
            </div>
       
          </div>
        </div>
        {/* begin::Body */}
      </Card>
      </Row>
      </Container>
      </div>
      </React.Fragment>
  )
}

export {WorkerReport}
