import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
} from "reactstrap";
import Select from "react-select";
import { admingetDepartment, getAgency, getDepartment, getPlan, getSubSob } from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";



interface CrmFilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any , Reset :any) => void; // Define onFilterApply in props interface
}

const WorkerFilter: React.FC<CrmFilterProps> = ({ show, onCloseClick, onFilterApply }) => {
  const { userProfile } = useProfile();
  const TypeId = userProfile?.primaryID;
  const Type = userProfile?.userType;
  const [agencyId, setAgencyId] = useState<{value: string | number, label: string  } | null>({ value: 0, label: 'All' });
  const [departmentId, setDepartmentId] = useState<{ value: string | number, label: string } | null>({ value: 0, label: 'All' });
  const [planId, setPlanId] = useState<{value: string | number, label: string } | null>({ value: 0, label: 'All' });
  const [suspenseStatusId, setSuspenseStatusId] = useState<{ value: string | number, label: string  } | null>({ value: 0, label: 'All' });
  const [agency, setAgency] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);
  const [plan, setPlan] = useState<any>([]);

 
  useEffect(() => {
    if(Type === 1){
    const fetchAgency = async () => {
      try {
        const response = await getAgency();
        const agencyOptions = response.data.map((agency: any) => ({
          value: agency.id,
          label: agency.name,
        }));
        const allOption = { value: 0, label: 'All' };
        setAgency([allOption, ...agencyOptions]);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchAgency();
  }
  else{
    getDepartment(TypeId).then((res: any) => {
      const departmentOptions = res.data.map((department: any) => ({
        value: department.id,
        label: department.name,
      }));
      
      const allOption = { value: 0, label: 'All' };
      setDepartment([allOption, ...departmentOptions]);  
    })
    getPlan(TypeId).then((res: any) => {
      const planOptions = res.Data.map((plan: any) => ({
        value: plan.id,
        label: plan.name,
      }));
      const allOption = { value: 0, label: 'All' };
      setPlan([allOption, ...planOptions]);
    })
  }
  }, []);

  const handleAgencyChange=(com:any)=>{ 
    getDepartment(com).then((res: any) => {
      const departmentOptions = res.data.map((department: any) => ({
        value: department.id,
        label: department.name,
      }));
      
      const allOption = { value: 0, label: 'All' };
      setDepartment([allOption, ...departmentOptions]);  
    })
    getPlan(com).then((res: any) => {
      const planOptions = res.Data.map((plan: any) => ({
        value: plan.id,
        label: plan.name,
      }));
      const allOption = { value: 0, label: 'All' };
      setPlan([allOption, ...planOptions]);
    })
  }

  useEffect(() => {
    if(Type === 1){
    const fetchDepartment = async () => {
      try {       
        const response = await admingetDepartment();
        const departmentOptions = response.data.map((department: any) => ({
          value: department.id,
          label: department.name,
        }));
        
        const allOption = { value: 0, label: 'All' };
        setDepartment([allOption, ...departmentOptions]);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchDepartment();
  }
  }, []);

  useEffect(() => {
    if(Type === 1){
    const fetchSub = async () => {
      try { 
        const response = await getSubSob();
        const planOptions = response.data.map((plan: any) => ({
          value: plan.id,
          label: plan.name,
        }));
        const allOption = { value: 0, label: 'All' };
        setPlan([allOption, ...planOptions]);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchSub();
  }
  }, []);

  const applyFilters = () => {
    const filters = {
      agencyId:  Type === 1 ?  agencyId?.value : TypeId,
      departmentId: departmentId?.value || "",
      planId:planId?.value || "",
      suspenseStatus: suspenseStatusId?.value || "",
    };
    const Reset =true;
    onFilterApply(filters, Reset); // Use onFilterApply from props
    onCloseClick();
  };

  const clearFilters = () => {
    const filters={}
    const Reset =false;
    onFilterApply(filters, Reset); 
     setAgencyId({ value: 0, label: 'All' });
     setDepartmentId({ value: 0, label: 'All' }); 
     setPlanId({ value: 0, label: 'All' });
     setSuspenseStatusId({ value: 0, label: 'All' }); 
     onCloseClick(); // Close the filter panel
   };
  
  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Worker Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>

          {Type === 1 && (<div className="mb-4">
            <Label
              htmlFor="agency-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Agency
            </Label>
            <Select
              id="agency-select"
              className="mb-0"
              value={agencyId}
              onChange={(selectedOption: any) => { 
                setAgencyId(selectedOption);             
                handleAgencyChange(selectedOption.value)
              }}
              options={agency}
            />
          </div>
          )}
          <div className="mb-4">
            <Label
              htmlFor="agency-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Department
            </Label>
            <Select
              id="department-select"
              className="mb-0"
              value={departmentId}
              onChange={(selectedOption: any) => setDepartmentId(selectedOption)}
              options={department}
            />
          </div>
          <div className="mb-4">
            <Label
              htmlFor="agency-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Plan
            </Label>
            <Select
              id="plan-select"
              className="mb-0"
              value={planId}
              onChange={(selectedOption: any) => setPlanId(selectedOption)}
              options={plan}
            />
          </div>
          <div className="mb-4">
            <Label
              htmlFor="agency-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Suspended Status
            </Label>
            <Select
              id="Suspended Status-select"
              className="mb-0"
              value={suspenseStatusId}
              onChange={(selectedOption: any) => setSuspenseStatusId(selectedOption)}
              options={[
                { value: '0', label: 'All' },
                { value: '1', label: 'Active' },
                { value: '2', label: 'Suspended' },
              ]}
            />
             
          </div>
          {/* Add other filter options as needed */}
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
          type="button"
            className="btn btn-light w-100"
            onClick={clearFilters}
          >
           Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Apply Filters
          </button>
        </div>
      </form>
      {/* <WorkersList />  */}
    </Offcanvas>
  );
};

export default WorkerFilter;
