import TableContainer from 'Components/Common/TableContainer'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'

type Props = {
  data?: Record<string, any>
}

const HistoryVisitNonPanel: React.FC<Props> = ({ data}) => {
  const [List, setList] = useState([])

  
  useEffect(()=>{
    if (data?.recentVisitsNonPanel?.length){

      setList(data?.recentVisitsNonPanel)
    }
    
  },[data])
 
  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(cell?.row?.original?.visitDate).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  
    return <>{formattedDate}</>;
  };
  
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: 'sno',
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Clinic",
        accessorKey: "clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Visit Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>
                {new Date(cell.getValue()).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </>
            )}
          </>
        ),
      },
      {
        header: "Visit Reason",
        accessorKey: "visitReason",
        enableColumnFilter: false,
      },
      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  {" "}
                  Approved
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  {" "}
                  Rejected
                </span>
              );
            default:
              return (
                <span className="badge border border-secondary text-secondary">
                  {" "}
                  Pending{" "}
                </span>
              );
          }
        }
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/nonpanel-claims-form/view/${cellProps.row.original.primaryId}`}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  // onClick={() => { prepareDelete(cellProps.row.original) }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
  <Card>

<div className="card-body pt-3">
                  {data?.recentVisitsNonPanel  ? (
                    <TableContainer
                      columns={columns}
                      data={List? List : []}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}

</div>
              </Card>
   
      </React.Fragment>
  )
}

export {HistoryVisitNonPanel}
