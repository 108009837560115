import axios from 'axios'
import getToken from 'helpers/jwt-token-access/auth-token-header'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/nonPanelClaimform` 
 


 
 
const getClaimform = (filter:any) => {
  return axios
    .post(`${CLAIM_URL}/list`,filter, getToken())
    .then((d: any) => {
     
      return d.Data
    })
} 
const FetchNonclaimholders = (id: any) => {
  return axios
    .get(`${API_URL}/worker/view/${id}`, getToken())
    .then((d) => {
     //
      return d
    })
} 
const FetchNonclaimholdersByid = (id: any) => {
  return axios
    .get(`${API_URL}/nonPanelClaimform/${id}`, getToken())
    .then((d:any) => {
     //
      return d.Data
    })
} 
const approvenonpanelclaim = (id: any,workerId:any,amount:any) => {
  return axios
    .post(`${API_URL}/nonPanelClaimform/approve/${id}`,{workerId,amount}, getToken())
    .then((d) => {
     //
      return d
    })
} 
const getNonPanelReport = (date: any) => {
  return axios
    .post(`${API_URL}/report/NonpanelclaimformsReport`,{date}, getToken())
    .then((d) => {
     //
      return d
    })
} 
const deleteClaimFormById = (id: any,type:any) => {
  return axios.get(`${API_URL}/nonPanelClaimform/deleteClaimForm/${id}/${type}`, getToken()).then((response:any) => {
    return response
  })
}
export {
  getClaimform,
  FetchNonclaimholders,
  FetchNonclaimholdersByid,
  deleteClaimFormById,
  approvenonpanelclaim,
  getNonPanelReport
  
}
