import React from "react";

import Dashboard from "pages/Admin Dashboard/Dashboard";
import SupportOverview from "pages/Admin Dashboard/Support-overview";
import AgencyOverview from "pages/Admin Dashboard/Agency-overview";
import ClinicOverview from "pages/Admin Dashboard/Clinic-overview";
import Category from "pages/Category";
import User from "pages/Users";
import Department from "pages/Department";
import WorkersEdit from "pages/Workers/EditWorker";
import WorkersList from "pages/Workers";
import WorkersAdd from "pages/Workers/AddWorker";
import WorkersView from "pages/Workers/WorkersOverview";
import AgencyList from "pages/Agency";
import AgencyAdd from "pages/Agency/AddAgency";
import AgencyEdit from "pages/Agency/EditAgency";
import AgencyView from "pages/Agency/AgencyOverview";
import Login from "pages/Authentication/Login";
import ClinicsList from "pages/Clinics";
import ClinicsAdd from "pages/Clinics/AddClinics";
import ClinicsEdit from "pages/Clinics/EditClinics";
import ClinicsView from "pages/Clinics/ClinicsOverview";
import ClaimFormList from "pages/ClaimForm";
import AddClaimForm from "pages/ClaimForm/AddClaimForm";
import VerifyWorkers from "pages/Verify Workers";
import Logout from "pages/Authentication/Logout";
import ViewClaimForm from "pages/ClaimForm/viewClaimForm";
import NonPanelClaimFormList from "pages/NonPanelClaimForm";
import ViewNonPanelClaimForm from "pages/NonPanelClaimForm/viewNonPanelClaimForm";
import AssignPlan from "pages/Assign Plan";
import InvoiceCreate from "pages/Admin Invoices/Admin-Agency -Invoice/InvoiceCreate";
import Billing from "pages/Billing";
import Diagnosiss from "pages/Diagnosis";
import PlanList from "pages/Plan";
import PlanAdd from "pages/Plan/AddPlan";
import { ViewPlan } from "pages/Plan/ViewPlan";
import PlanEdit from "pages/Plan/EditPlan";
import BenefitList from "pages/Benefits";
import AddBenefit from "pages/Benefits/AddBenefit";
import EditBenefit from "pages/Benefits/EditBenefit";
import PlanOverview from "pages/Plan-overview";
import SupportAgencyList from "pages/Support/Agency-Support";
import ClinicSupportList from "pages/Support/Clinic-Support";
import WorkerSupportList from "pages/Support/Worker-Support";
import WorkerView from "pages/Support/Worker-Support/WorkerOverview";
import SupportAgencyView from "pages/Support/Agency-Support/AgencyOverview";
import ClinicView from "pages/Support/Clinic-Support/ClinicOverview";
import PortalAgencyOverview from "pages/Agency-Support/Agency-Support/PortalAencyOverview";
import PortalWorkerOverview from "pages/Agency-Support/Worker-Support/PortalWorkerOverview";
import SaAgencyList from "pages/Agency-Support/Agency-Support";
import SaWorkerList from "pages/Agency-Support/Worker-Support";
import InvoiceList from "pages/Admin Invoices/Admin-Agency -Invoice/InvoiceList";
import InvoiceAdd from "pages/Admin Invoices/Admin-Agency -Invoice/InvoiceCreate";
import InvoiceView from "pages/Admin Invoices/Admin-Agency -Invoice/InvoiceDetails";
import PaymentDetails from "pages/Admin Invoices/Admin-Clinic -Payment/InvoiceDetails";
import PaymentList from "pages/Admin Invoices/Admin-Clinic -Payment/InvoiceList";
import PortalPaymentList from "pages/Admin Invoices/Portal-Clinic -Payment/InvoiceList";
import PaymentAdd from "pages/Admin Invoices/Portal-Clinic -Payment/InvoiceCreate";
import PortalPaymentDetails from "pages/Admin Invoices/Portal-Clinic -Payment/InvoiceDetails";
import PortalInvoiceList from "pages/Admin Invoices/Portal-Agency -Invoice/InvoiceList";
import PortalInvoiceDetails from "pages/Admin Invoices/Portal-Agency -Invoice/InvoiceDetails";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import {  WorkerReport } from "pages/Report/Worker Report/Report-list";
import CreditReportContent from "pages/Report/Credit Report";
import { HistoryVisitPage } from "pages/Report/Worker Report/HistoryVisitPage";
import { HistoryVisitNonPanel } from "pages/Report/Worker Report/HistoryVisitNonPanel";
import WorkerReportContent from "pages/Report/Worker Report";
import { AgencyReportContent } from "pages/Report/Agency individual Reports/AgencyReportContent";
import AgencyReportList from "pages/Report/Agency individual Reports";
import { AgencyReportContents } from "pages/Report/Agency Report/AgencyReport";
import { ClinicReportContents } from "pages/Report/Clinic Report/ClinicReport";
import Role from "pages/Roles";
import { NonpanelClaimReport } from "pages/Report/Nonpanel Report/Nonpanel Report";
import AgencyDashboard from "pages/Agency Dashboard";
import ClinicDashboard from "pages/Clinic Dashboard";
import { PortalAgencyReportContent } from "pages/Report/Portal Agency Report/Agency Report";
import { PortalClinicReportContent } from "pages/Report/Portal Clinic Report/Clinic Report";
import { CliniExportContents } from "pages/Report/Clinic List Report/ClinicReport";
import { AddOtherCharges } from "pages/ClaimForm/AddOtherCharges";
import PortalClinicList from "pages/Clinic-Support";
import AddWorkers from "pages/Workers/WorkersOverview/Addworker";
import { WorkerCountContents } from "pages/Report/Worker Count Report/workerReport";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/overview", component: <Dashboard /> },
  { path: "/agency-portal", component: <AgencyDashboard /> },
  { path: "/clinic-portal", component: <ClinicDashboard /> },
  { path: "/clinic-overview", component: <ClinicOverview /> },
  { path: "/agency-overview", component: <AgencyOverview /> },
  { path: "/support-overview", component: <SupportOverview /> },
  { path: "/role", component: <Role /> },
  { path: "/category", component: <Category /> },
  { path: "/users", component: <User /> },
  { path: "/department", component: <Department /> },
  { path: "/billing", component: <Billing /> },
  { path: "/diagnosis", component: <Diagnosiss /> },
  { path: "/workers", component: <WorkersList /> },
  { path: "/workers/add", component: <WorkersAdd /> },
  { path: "/workeradd/:id", component: <AddWorkers /> },
  { path: "/workers/view/:id/:workerId", component: <WorkersView /> },
  { path: "/workers/Edit/:id", component: <WorkersEdit /> },
  { path: "/agency", component: <AgencyList /> },
  { path: "/Agency/add", component: <AgencyAdd /> },
  { path: "/Agency/Edit/:id", component: <AgencyEdit /> },
  { path: "/Agency/View/:id", component: <AgencyView /> },
  { path: "/agency-profile", component: <AgencyView /> },
  { path: "/clinics", component: <ClinicsList /> },
  { path: "/Clinics/add", component: <ClinicsAdd /> },
  { path: "/Clinics/Edit/:id", component: <ClinicsEdit /> },
  { path: "/Clinics/View/:id", component: <ClinicsView /> },
  { path: "/clinic-profile", component: <ClinicsView /> },
  { path: "/claim-form", component: <ClaimFormList /> },
  { path: "/claim-form/add", component: <AddClaimForm /> },
  { path: "/claim-form/view/:id", component: <ViewClaimForm /> },
  { path: "/nonpanel-claims-form", component: <NonPanelClaimFormList /> },
  { path: "/nonpanel-claims-form/Report", component: <NonpanelClaimReport /> },
  { path: "/nonpanel-claims-form/view/:id",component: <ViewNonPanelClaimForm />},
  { path: "/assign-plan", component: <AssignPlan /> },
  { path: "/verify-workers", component: <VerifyWorkers /> },
  { path: "/agency-invoice/list", component: <InvoiceList /> },
  { path: "/agency-invoice/add", component: <InvoiceCreate /> },
  { path: "/plan", component: <PlanList /> },
  { path: "/plan/add", component: <PlanAdd /> },
  { path: "/plan/edit/:id", component: <PlanEdit /> },
  { path: "/plan/view/:id", component: <ViewPlan /> },
  { path: "/benefit", component: <BenefitList /> },
  { path: "/benefit/add", component: <AddBenefit /> },
  { path: "/benefit/edit/:id", component: <EditBenefit /> },
  { path: "/plan-overview", component: <PlanOverview /> },
  { path: "/admin/worker-support", component: <WorkerSupportList /> },
  { path: "/admin/clinic-support", component: <ClinicSupportList /> },
  { path: "/admin/agency-support", component: <SupportAgencyList /> },
  { path: "/Agencyview/:id", component: <SupportAgencyView /> },
  { path: "/clinicview/:id", component: <ClinicView /> },
  { path: "/workerview/:id", component: <WorkerView /> },
  { path: "/agency/agency-support", component: <SaAgencyList /> },
  { path: "/agency/worker-support", component: <SaWorkerList /> },
  { path: "/PortalAgencyOverview/view/:id",component: <PortalAgencyOverview />},
  { path: "/PortalWorkerOverview/view/:id",component: <PortalWorkerOverview />},
  { path: "/clinic/clinic-support", component: <PortalClinicList /> },
  { path: "/invoice", component: <InvoiceList /> },
  { path: "/invoice-add", component: <InvoiceAdd /> },
  { path: "/invoice/view/:id", component: <InvoiceView /> },
  { path: "/clinic-payment", component: <PaymentList /> },
  { path: "/clinic-payment/view/:id", component: <PaymentDetails /> },
  { path: "/portal-Agency-invoice", component: <PortalInvoiceList /> },
  { path: "/portal-Agency-invoice/view/:id", component: <PortalInvoiceDetails />},
  { path: "/portal-clinic-payment", component: <PortalPaymentList /> },
  { path: "/portal-clinic-payment/add", component: <PaymentAdd /> },
  { path: "/portal-clinic-payment/view/:id",component: <PortalPaymentDetails />},
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/workerReport", component: <WorkerReport/>  },
  { path: "/creditReport", component: <CreditReportContent /> },
  { path: "/agency-report", component: <AgencyReportContents /> },
  { path: "/workercount-report", component: <WorkerCountContents /> },
  { path: "/portal-agency-report", component: <PortalAgencyReportContent /> },
  { path: "/clinic-report", component: <ClinicReportContents /> },
  { path: "/portal-clinic-report", component: <PortalClinicReportContent /> },
  { path: "/AgencyIndividualReport", component:    <AgencyReportContent />},
  { path: "/AgencyIndividualReport/NewReport/view/:id", component:    <AgencyReportList />},
  { path: "/Clinic/ExportData", component:    <CliniExportContents />},
  { path: "/workerReport/NewReport/:id/:WorkerId", component:<WorkerReportContent />},
  { path: "/workerReport/NonPanelVisitHistoryPage/:id/:WorkerId",  component: <HistoryVisitPage />},
  { path: "/workerReport/PanelVisitHistoryPage/:id/:WorkerId", component: <HistoryVisitNonPanel />},
  { path: "/claimform-charges",component: <AddOtherCharges />}, 
];

const publicRoutes: any = [
 
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
