import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { recentPlan } from "./_requests";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PlanData = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [plan, setPlan] = useState<any>([]);
  const [PlanAccess, setplanaccess] = useState<any>([]);

  useEffect(() => {
    getCRUD(userProfile?.role, 17).then((res: any) => {
      var crudres = res.Data[0];
      setplanaccess(crudres);
    });
  }, []);

  useEffect(() => {
    recentPlan().then((res: any) => {
      setPlan(res.Data);
    });
  }, []);

  const listPlan = () => {
    if (PlanAccess?.list === 1) {
      history("/plan");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const viewPlan = (cellProps: any) => {
    setplanaccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.view === 1) {
        history(`/plan/view/${cellProps.row.original.id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(
      cell.row.original.createdAt
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  const limit = () => {
    return <>Per Employee</>;
  };

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Plan Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Limits",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: limit,
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: renderCreatedAt,
      },

      {
        header: "Actions",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <a
                  onClick={() => {
                    viewPlan(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </a>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Recent Plan</h5>

                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <div onClick={listPlan}  className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                      <i className=" ri-equalizer-line me-2 "></i> View All
                    </div>
                  </div>
                </div>
              </div>
              <p>List of recently created Plan</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={plan || []}
                customPageSize={10}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
                isTicketsListFilter={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PlanData;
