import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
} from "reactstrap";
import Select from "react-select";
import {getCity, getState } from "./_requests";

interface CrmFilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any , Reset :any) => void; // Define onFilterApply in props interface
}

const AgencyFilter: React.FC<CrmFilterProps> = ({ show, onCloseClick, onFilterApply }) => {
  const [CityId, setCityId] = useState<{value: string | number, label: string  } | null>({ value: 0, label: 'All' });
  const [StateId, setStateId] = useState<{value: string | number, label: string  } | null>({ value: 0, label: 'All' });
  const [City, setCity] = useState<any>(null);
  const [State, setState] = useState<any>(null);
  const [AgencyTpe, setAgencyTpe] = useState<any>({ value: 0, label: 'All' });

  useEffect(() => {
    getCity()
      .then((res: any) => {
          const mappedOptions = res.Data.map((agency: { city: any }) => ({
            value: agency.city,
            label: agency.city,
          }));
          const allOption = { value: 0, label: "All" };
          const optionsWithAll = [allOption, ...mappedOptions];
          setCity(optionsWithAll);
      
      })
      .catch((error: any) => {
        console.error("Error fetching cities:", error);
      });
  }, []);
  

  const handleSelectChange = (selectedOption: any) => {  
    setCityId(selectedOption)
    getState(selectedOption.value).then((res: any) => {
      const mappedOptions = res.Data.map((agency: { state: any }) => ({
        value: agency.state,
        label: agency.state,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAlll = [allOption, ...mappedOptions];
      setState(optionsWithAlll);
    });
  };


  const applyFilters = () => {
    const filters = {
      city: CityId?.value || "",
      state:StateId?.value || "",
      agencyType: AgencyTpe?.value || "",
    };
    const Reset=true;
    onFilterApply(filters,Reset); 
    onCloseClick();
  };

  const clearFilters = () => {
    const filters={}
    const Reset=false;
     onFilterApply(filters,Reset); 
     setCityId({ value: 0, label: 'All' });
     setStateId({ value: 0, label: 'All' }); 
     setAgencyTpe({ value: 0, label: 'All' });
     onCloseClick(); // Close the filter panel
   };


  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Agency Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
        <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              City 
            </Label>
       
             <Select
              className="mb-0"
              value={CityId}
              onChange={handleSelectChange}
              options={City}
              isSearchable
              id="country-select"
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              State 
            </Label>
            <Select
              className="mb-0"
              value={StateId}
              onChange={(selectedOption: any) => setStateId(selectedOption)}
              options={State || []}
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Agency Type 
            </Label>
            <Select
              className="mb-0"
              value={AgencyTpe}
              onChange={(selectedOption: any) => setAgencyTpe(selectedOption)}
              options={[
                { value:  0, label: 'All' },
                { value: 'Sole proprietorship', label: 'Sole proprietorship' },
                { value: 'Private limited company (Sdn. Bhd.)', label: 'Private limited company (Sdn. Bhd.)' },
                { value: 'Partnership', label: 'Partnership' },
                { value: 'Private limited company (Berhad)', label: 'Private limited company (Berhad)' },
                { value: 'Foreign company', label: 'Foreign company' },
                { value: 'Limited liability partnership (LLP)', label: 'Limited liability partnership (LLP)' },
              ]}
           
            ></Select>
          </div>
          
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
           type="button"
            className="btn btn-light w-100"
            onClick={clearFilters}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default AgencyFilter;
