import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { getWorkerById, getsobdetails } from 'pages/Workers/_requests';

interface DateDifferenceProps {
    startDate: Date | string | number;
    endDate: Date | string | number;
}



const PlanDetails = () => {
const {id}=useParams()
    const [Worker, setWorker] = useState<any>([]);
    const [Data, setData] = useState<any>([]);

    useEffect(() => {
        getWorkerById(id).then((res: any) => {
            setData(res)
           if(res) {
            getsobdetails(res.planId).then((res: any) => {
                
                setWorker(res.Data.benefit)
              })
           }
       
        })
      }, [])


    
    const getDateDifference = ({ startDate, endDate }: DateDifferenceProps): number => {
        // Convert startDate and endDate to Date objects if they are not already
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
    
        // Calculate the difference in milliseconds
        const timeDifference = endDateObj.getTime() - startDateObj.getTime();
    
        // Convert milliseconds to days
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    
        return daysDifference;
    };
    
    


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1">Plan Details</h5>
                    </div>
                    </Row>
                    
                            
                        
                            
                             
                                <Col xl={12}>
                                <Card>
                                <CardBody className="p-4">
                    <Row className="g-3">
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Plan name
                        </p>
                        <h5 className="fs-14 mb-0"><span id="invoice-no">{Data?.plan?.name}</span></h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Validity From
                        </p>
                        <h5 className="fs-14 mb-0">
                        <span className="badge border border-success text-success fs-11" id="payment-status">{Data?.validityFrom}</span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Validity To
                        </p>
                        <span className="badge border border-success text-success fs-11" id="payment-status">{Data?.validityTo}</span>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Days
                        </p>
                        <h5 className="fs-14 mb-0"><span id="total-amount"> {getDateDifference({ startDate: Data?.validityFrom, endDate: Data?.validityTo })}</span></h5>
                      </Col>
                    </Row>
                  </CardBody>
                                </Card>
                                </Col>
                                <Row>
                            
                                <Card>
    <CardHeader>
        <div className="d-flex">
            <div className="flex-grow-1">
                <h5 className="card-title mb-0">Credit balance details</h5>
            </div>
        </div>
    </CardHeader>
    <CardBody>
    <Row>
    {/* First Card */}
    <Col xl={6} xxl={6}>
        <Card>
            <CardBody>
                <table className="table table-borderless align-middle mb-0">
                    <thead>
                        <tr>
                            <th className="fw-bold" colSpan={2}>
                                Available Current Balance:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data?.workerbalancedetails?.map((item: any, i: any) => (
                            <tr key={i}>
                                <td>{item?.cliniccategory?.name}</td>
                                <td>{item?.unlimited === true ? 'Unlimited' : item?.currentBalance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    </Col>
    
    {/* Second Card */}
    <Col xl={6} xxl={6}>
        <Card>
            <CardBody>
                <table className="table table-borderless align-middle mb-0">
                    <thead>
                        <tr>
                            <th className="fw-bold" colSpan={2}>
                                Actual Plan Limits:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data?.plan?.plandetails?.map((item: any, i: any) => (
                            <tr key={i}>
                                <td>{item?.cliniccategory?.name}</td>
                                <td>{Number(item?.unlimited) === 1 ? 'Unlimited' : item?.limit}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    </Col>
</Row>

    </CardBody>
</Card>





                              
                                </Row>
             
          
                    <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1 me-2">Plan Info :  {Worker?.map((item: any, i: any) => {
            return (
                <div  className="badge border border-success text-success me-2"  key={i}>
                {item?.name}
              </div>
            )
          })}</h5>
                        
                    </div>
                    </Row>
                        <div className="d-flex align-items-center border border-dashed p-2 rounded">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-zip-line fs-20 text-primary"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Plan Covers</Link></h6>
                               <small className="text-muted">{Data?.plan?.planCovers}</small>
                            </div>
                        </div>
                        <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-ppt-2-line fs-20 text-danger"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Non Plan Covers</Link></h6>
                                <small className="text-muted">{Data?.plan?.nonPlanCovers}</small>
                            </div>
                        </div>
                        <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-ppt-2-line fs-20 text-danger"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Benefits</Link></h6>
                                <small className="text-muted">{Data?.plan?.benifits}</small>
                            </div>
                        </div>
                    </CardBody>
                </Card>
        </React.Fragment>
    );
};

export default PlanDetails;