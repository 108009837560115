import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Worker_URL = `${API_URL}/worker`
const SOB_URL = `${API_URL}/plan/getplan`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
const GET_PLAN_LIST = `${API_URL}/plan`

//
const getWorker = (filters:any)=> {
  return axios
    .post(`${Worker_URL}/list`,filters)
    .then((d: any) => {
      return d.Data;
    })
}
const getAgencyWorker = (filters:any,id:any)=> {
  return axios
    .post(`${Worker_URL}/getdata/list/${id}`,filters)
    .then((d: any) => {
      return d.data;
    })
}
const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${Worker_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      
      return response.data
     })
   
}
const getWorkerById = (id:any) => {
  return axios
    .get(`${Worker_URL}/view/${id}`) 
    .then((response: any) =>{
      
      return response.Data;
    }
   )
}
const updateWorkerById = (Worker:any,ID:any) => {
  //
  return axios
  .put(`${Worker_URL}/${ID}`, Worker)
  .then((response:any) => {
  //  
    return response
   })
   
}
const updateFamilyWorker = (Worker:any,ID:any) => {
 // 
  return axios
  .put(`${Worker_URL}/family/${ID}`, Worker)
  .then((response) => {
  //  
    return response.data
   })
   
}
const getAgency = async ()=> {
  const res = await axios.get(`${Worker_URL}/getagency`);
  //
  return res;
}
const getDepartment = async (clientID:any) => {
  //
  const res = await axios.get(`${Worker_URL}/getDepartment/${clientID}`);
  //
  return res;
}
const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${Worker_URL}/admingetDepartment`);
  //
  return res;
}
const createWorker = (formData:FormData) => {
  return axios.post(`${Worker_URL}/addWorker`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set Content-Type header to multipart/form-data
    }
  })
  .then((response:any) => {
    return response;
  })
  .catch((error) => {
    throw error; // Throw error to be caught in the calling function
  });
};


const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${EXCEL_URL}`, excel,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => { 
      return response;
})}

const getSubSob = async () => {
  const res = await axios.get(`${SOB_URL}`);
  return res;
}
const getCompany = async () => {
  const res = await axios.get(`${Worker_URL}/getagency`, );
  //
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
  return res;
}
const getCountry = async () => {
  const res = await axios.get(`${COUNTRY_URL}`);
  //
  return res;
}

const deleteWorkerById = (id: any) => {
  return axios
    .post(`${Worker_URL}/${id}`, {})
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${Worker_URL}/activateDeactivateAccount/${id}/${status}`)
    .then((response:any) => {

  //  
    return response;

    }
    )
}

const getVisithistory = (id:any)=> {
  return axios
    .post(`${Worker_URL}/list/visitHistory/${id}`,)
    .then((d:any) => {
    
      return d.Data
    })
} 
const getNonpanelVisithistory = (id:any)=> {
  return axios
    .post(`${API_URL}/nonPanelClaimform/nonPanel/list/${id}`)
    .then((d:any) => {
    
      return d.Data
    })
} 

const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/view/${id}`);
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid});
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data);
  //
   return res.data;
 }
 const WorkerSupport=async(id:any)=>{
  const res = await axios.post(`${API_URL}/support/WorkerView/SupportIndex/${id}`);
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data);
  //
   return res.data;
 }
 const cronJob=async()=>{ 
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`);
  //
   return res.data;
 }


 const checkDuplicateEmail = (email:any) => {
  return axios
    .post(`${Worker_URL}/find-duplicate`, {email}, )
    .then((response) => {return response; }
    )
}

export {
  getWorker,
  getCompany,checkDuplicateEmail,
  deleteWorkerById,
  WorkerSupport,
  getSubSob,updatecontrols,getsobdetails,getReportMonthlyCount,
  createWorker,getWorkerById,updateFamilyWorker,getAgencyWorker,
  getAgency, getDepartment,getCountry,updatebulkinsert,updateWorkerById,
  admingetDepartment,updateActiveDeactive,getPlan,upgradePlan,renewalPlan,cronJob,getVisithistory,getNonpanelVisithistory
}
