import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from "yup";
import { ChangePasswordAPI } from './_request';
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row,  } from 'reactstrap';
import { toast,ToastContainer } from 'react-toastify';

const ChangePassword = () => {
    const authUser: any = sessionStorage.getItem("authUser");
    const obj: any = JSON.parse(authUser);
    const userId = obj?.Id;
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [NewpasswordShow, setNewPasswordShow] = useState<boolean>(false);
    const [ConfirmpasswordShow, setConfirmPasswordShow] = useState<boolean>(false);
    const Formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            currentPassword: '',
            newPassword: "",
            passwordConfirmation: "",
        },

        validationSchema: Yup.object({
            currentPassword: Yup.string().required("Please Enter Your Current Password"),
            newPassword: Yup.string().required("Please Enter Your New Password"),
            passwordConfirmation: Yup.string()
                .required("Please Enter Your Confirm Password")
                .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'), // Check if passwords match
        }),

        onSubmit: async (values) => {
            ChangePasswordAPI(userId, values)
                .then((res:any) => {
                    if(res.Code == 1){
                        toast.success(res.Message, { autoClose: 3000 });
                    }
                    else{
                        toast.error(res.Message, { autoClose: 3000 });

                    }
                })
                .catch((error) => {
                    console.error("Error Updating Password:", error);
                });
        }
    });

    document.title = "Change Password | Alkhairi Care";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={9}>
                            <ToastContainer closeButton={false} limit={1} />
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <h5>Change Password</h5>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        Formik.handleSubmit();
                                        return false;
                                    }}>
                                        <Row className="g-2">
                                            <Col lg={4}>
                                                <div>
                                                    <Label htmlFor="oldpasswordInput" className="form-label">Old
                                                        Password*</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input name="currentPassword" type={passwordShow ? "text" : "password"} className="form-control"
                                                        placeholder="Enter current password"
                                                        onChange={Formik.handleChange}
                                                        onBlur={Formik.handleBlur}
                                                        value={Formik.values.currentPassword || ""}
                                                        invalid={
                                                            Formik.touched.currentPassword && Formik.errors.currentPassword ? true : false
                                                        }
                                                    />
                                                    {Formik.touched.currentPassword && Formik.errors.currentPassword ? (
                                                        <FormFeedback type="invalid">{Formik.errors.currentPassword}</FormFeedback>
                                                    ) : null}
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <Label htmlFor="newpasswordInput" className="form-label">New
                                                        Password*</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="newPassword"
                                                        type={NewpasswordShow ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder="Enter new password"
                                                        onChange={Formik.handleChange}
                                                        onBlur={Formik.handleBlur}
                                                        value={Formik.values.newPassword || ""}
                                                        invalid={
                                                            Formik.touched.newPassword && Formik.errors.newPassword ? true : false
                                                        }
                                                    />
                                                    {Formik.touched.newPassword && Formik.errors.newPassword ? (
                                                        <FormFeedback type="invalid">{Formik.errors.newPassword}</FormFeedback>
                                                    ) : null}
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setNewPasswordShow(!NewpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div>
                                                    <Label htmlFor="confirmpasswordInput" className="form-label">Confirm
                                                        Password*</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input name="passwordConfirmation" type={ConfirmpasswordShow ? "text" : "password"} className="form-control"
                                                        onChange={Formik.handleChange}
                                                        onBlur={Formik.handleBlur}
                                                        value={Formik.values.passwordConfirmation || ""}
                                                        invalid={
                                                            Formik.touched.passwordConfirmation && Formik.errors.passwordConfirmation ? true : false
                                                        }
                                                        placeholder="Confirm password" />
                                                    {Formik.touched.passwordConfirmation && Formik.errors.passwordConfirmation ? (
                                                        <FormFeedback type="invalid">{Formik.errors.passwordConfirmation}</FormFeedback>
                                                    ) : null}
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setConfirmPasswordShow(!ConfirmpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="text-end">
                                                    <button type="submit" className="btn btn-secondary">Change
                                                        Password</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ChangePassword;
