import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Button,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteInvoiceById,
} from "../Admin-Agency -Invoice/_requests";
import { getAgencyInvoice } from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import InvoiceFilter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

const PortalInvoiceList = () => {
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const history = useNavigate();
  const [invoice, setInvoice] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [DeleteName, setDeleteName] = useState("");
  const [Filter, setFilter] = useState<boolean>(false);
  const [agencyInvAccess, setagenInvaccess] = useState<any>([]);

  useEffect(() => {
    let Data;
    if (Type === 2) {
      Data = 33;
    } else {
      Data = "";
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setagenInvaccess(res.Data[0]);
    });
  }, []);

  const viewWorkers = (cellProps: any) => {

    setagenInvaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.view === 1) {
        history(
          `/portal-Agency-invoice/view/${cellProps.row.original.primaryId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess;
    });
  };

  const filter = {};
  const toggleFilter = () => {
    setFilter(!Filter);
  };


  const [ResetButton,setResetButton]=useState<Boolean>(false)
const handleResetButton=()=>{
  fetchInvoice();
  setResetButton(false)
}

  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchInvoice(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  const fetchInvoice = (filters?: any) => {
    getAgencyInvoice(Id, filters).then((res: any) => {
      setInvoice(res);
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (invoice: any) => {
    setDeleteId(invoice.primaryId);
    setDeleteName(invoice.invoiceNumber);

    setagenInvaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.remove === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess;
    });
  };

  const confirmDelete = (Id: any) => {

    deleteInvoiceById(Id).then(() => {
      history("/portal-Agency-invoice");
      toast.success("Invoice deleted Successfully", { autoClose: 3000 });
      fetchInvoice();
      toggleDeleteModal();
    });
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  //Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "DATE",
        accessorKey: "invoiceDueDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "REFERENCE",
        accessorKey: "invoiceNumber",
        enableColumnFilter: false,
      },
      {
        header: "AMOUNT ( RM )",
        accessorKey: "total",
        enableColumnFilter: false,
      },

      {
        header: "STATUS",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
            case 1:
              return (
                <span className="badge border border-success text-success">
                  Payment Submitted
                </span>
              );
            default:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
          }
        },
      },
      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewWorkers(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const worker = cellProps.row.original;
                    prepareDelete(worker);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Invoice List | Al khairi care";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Invoices" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Invoice List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <div className="flex-shrink-0">
                          <div className="d-flex gap-2 flex-wrap">
                          { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                            <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={toggleFilter}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Fliter
                            </button>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={invoice || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      SearchPlaceholder="serach invoice"
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
          <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete the invoice{" "}
            <strong>{DeleteName}</strong>?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() => {
                confirmDelete(DeleteId);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <InvoiceFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default PortalInvoiceList;
