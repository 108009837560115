import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import { clientportalEmpCount } from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";


const Widgets = () => {
  const [count, setCount] = useState<any>([]);
  const { userProfile } = useProfile();

  useEffect(() => {
    clientportalEmpCount(userProfile?.primaryID).then((res: any) => {
      setCount(res.data);
    });
  }, []);



  const AgencyWidgets = [
    {
      id: 1,
      title: "Total Workers",
      subtitle: "Total number of Workers",
      counter: count?.totalEmployeeCountPortal,
      arrowIcon: "ri-arrow-up-line",
      percentage: "17.32 %",
      percentageClass: "success",
      icon: "ri-ticket-2-line",
      decimals: 0,
      prefix: "",
      suffix: "",
      color: "primary",
    },
    {
      id: 2,
      title: "Total Invoice Received",
      subtitle: "Estimated total invoice received",
      counter: count?.paymentCount,
      arrowIcon: "ri-arrow-down-line",
      percentage: "0.96 %",
      percentageClass: "danger",
      icon: "ri-ticket-2-line",
      decimals: 0,
      prefix: "",
      suffix: "",
      color: "warning",
    },
  ];
  return (
    <React.Fragment>
      {(AgencyWidgets || []).map((item, key) => (
        <Col sm={12} xl={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-bold text-muted mb-2">{item.title}</p>
                  <p className="fw-medium text-muted mb-3">{item.subtitle}</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="547">
                      <CountUp
                        start={0}
                        end={item.counter}
                        duration={3}
                        suffix={item.suffix}
                        prefix={item.prefix}
                        decimals={item.decimals}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span
                      className={
                        "badge bg-" +
                        item.percentageClass +
                        "-subtle text-" +
                        item.percentageClass +
                        " mb-0"
                      }
                    >
                      {/* <i className={item.arrowIcon + " align-middle"}></i> {item.percentage} */}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                      <i className={item.icon}></i>
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
