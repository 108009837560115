import React, { useState, useEffect } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
} from "reactstrap";
import Select from "react-select";
import { getCity, getState } from "./_requests";


interface CrmFilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any) => void; 
}

const ClinicFilter: React.FC<CrmFilterProps>  = ({ show, onCloseClick, onFilterApply}: any) => {
  const [selectCity, setselectCity] = useState<any>({ value: 0, label: 'All' });
  const [selectState, setselectState] = useState<any>({ value: 0, label: 'All' });
  const [city, setCity] = useState<any>(null);
  const [state, setState] = useState<any>(null);

  useEffect(() => {
    getCity().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; city: any }) => ({
        value: com.city,
        label: com.city,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];

      setCity(optionsWithAll);
    });
  }, []);

  
  useEffect(() => {
    getState().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; state: any }) => ({
        value: com.state,
        label: com.state,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];

      setState(optionsWithAll);
    });
  }, []);

  const handleselectCity = (selectCity: any) => {
    setselectCity(selectCity);
  };

  const handleselectState = (selectState: any) => {
    setselectState(selectState);
  };



  const applyFilters = () => {
    const filters = {
      city: selectCity?.value ,
      state: selectState?.value ,
    };
    let Reset=true;
    onFilterApply(filters,Reset); 
    onCloseClick();
  };
  const clearFilters = () => {
    const filters={}
    let Reset=false;
     onFilterApply(filters,Reset); 
     setselectCity({ value: 0, label: 'All' });
     setselectState({ value: 0, label: 'All' }); 
     onCloseClick(); 
   };
  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Clinic Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              City
            </Label>

            <Select
              className="mb-0"
              value={selectCity}
              onChange={handleselectCity}
              options={city}
              id="country-select"
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              State
            </Label>
            <Select
              className="mb-0"
              value={selectState}
              onChange={(selectState: any) => {
                handleselectState(selectState);
              }}
              options={state}
              id="country-select"
            ></Select>
          </div>
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button 
           type="button" 
           className="btn btn-light w-100" 
           onClick={clearFilters}
           >
           Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default ClinicFilter;
