import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import { recentClinics } from "pages/Admin Dashboard/Dashboard/_request";
import { Link, useNavigate } from "react-router-dom";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "Components/Hooks/UserHooks";

const ClinicData = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [clinics, setClinics] = useState<any>([]);
  const [clinicaccess, setclinicaccess] = useState<any>([]);
  useEffect(() => {
    recentClinics().then((res: any) => {
      setClinics(res.Data);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 39).then((res: any) => {
      setclinicaccess(res?.Data[0]);
    });
  }, []);

  const viewClinic = (cell: any) => {
    setclinicaccess((prevDepAccess: any) => {
      if (prevDepAccess?.view === 1) {
        navigate(`/Clinics/view/${cell.row.original.id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  const listClinics = () => {
    if (clinicaccess?.list === 1) {
      navigate("/clinics");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };



  const columns = useMemo(
    () => [
      {
        header: "Clinic Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Clinic Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Clinic Phone",
        accessorKey: "telephoneNumber",
        enableColumnFilter: false,
      },
      {
        header: "Clinic State",
        accessorKey: "state",
        enableColumnFilter: false,
      },
      {
        header: "Clinic Country",
        accessorKey: "country",
        enableColumnFilter: false,
      },
      {
        header: "Actions",
        cell: (cell: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewClinic(cell);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Recent Clinics</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <div
                      onClick={listClinics}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="fa-solid fa-bars"></i> View All
                    </div>
                  </div>
                </div>
              </div>
              <p>List of recently created clinics</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={clinics || []}
                customPageSize={5}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClinicData;
