import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  getReport,
} from './_requests'
import Flatpickr from "react-flatpickr";
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import { Button, Card, Container, Row } from 'reactstrap'
import TableContainer from './TableContainer';
import * as XLSX from 'xlsx';
import Select from "react-select";
import { getCompany } from '../Agency Report/_requests';
import { getReportMonthlyCount } from 'pages/Agency/_requests';
interface ReportRow {
  [key: string]: string | number | undefined;
}
interface Column {
  header: string;
  accessorKey?: string;
}
const WorkerCountContents = () => {
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const tableRef = useRef(null)
  const [Total, setTotal] = useState<any>('')
  const [OCDate, setOCDate] = useState('')
  const [report, setReport] = useState<any>([])
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [Agency, setAgency] = useState<any>([]);
  const [AgencyId, setAgencyId] = useState<{value: string | number, label: string  } | null>({ value: 0, label: 'All' });

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Worker ID",
        accessorKey: "workerId",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agency.name",
        enableColumnFilter: false,
      },
      {
        header: "Department",
        accessorKey: "department.name",
        enableColumnFilter: false,
       
      },
      {
        header: "Plan",
        accessorKey: "plan.name",
        enableColumnFilter: false,
     
      },
      {
        header: "Nric/Passport",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "Worker code",
        accessorKey: "wbsCode",
        enableColumnFilter: false,
      },
      {
        header: "Created On",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>
                {new Date(cell.getValue()).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </>
            )}
          </>
        ),
      },
  
      {
        header: "Status",
        accessorKey: "location",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.suspenseStatus ===2 ? "Suspended":"Active";
        },
      },
    ],
    []
  );

  useEffect(() => {
    getCompany().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setAgency(optionsWithAll);
    });
  }, []);
  var Datefilename = new Date()
  var fileName =
    Datefilename.getFullYear() + '-' + (Datefilename.getMonth() + 1) + '-' + Datefilename.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Clinic_Report_${fileName}`,
    sheet: 'Report',
  })

 

 
  const Report = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate =selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange =(startDate && endDate) ? `${startDate} - ${endDate}` : "";


    setOCDate(Daterange);
    getReportMonthlyCount( Daterange , AgencyId?.value).then((resRep:any) => {
      setReport(resRep.Data)  
      setTotal(resRep.empCount)
        
        if (resRep.Data.length > 0 ) {
          setbtnExcelDisable(false)
          setbtnClearDisable(false)
        } else {
          setbtnExcelDisable(true)
          setbtnClearDisable(true)
        }
      
    })
  }
  const clearReport = () => {
    setTotal(null)
    setReport([])
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setOCDate('')
    setAgencyId({ value: 0, label: 'All' })
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  



  
  const formatDataForExport = async () => {
    const exportData: { [key: string]: string | number }[] = [];

  
    await Promise.all(
      report.map(async (row:any, i:any) => {
        const dataRow = {
          sno: i + 1,
          name: row.name || "",
          workerId: row.workerId || "",
          agency: row.agency?.name || "",
          department: row.department?.name || "",
          plan: row.plan?.name || "",
          nricPassport: row.nricPassport || "",
          wbsCode: row.wbsCode || "",
          createdAt: row.createdAt ? new Date(row.createdAt).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }) : "",
          status: row.suspenseStatus === 2 ? "Suspended" : "Active",
        };
        exportData.push(dataRow);
      })
    );
  
    return exportData;
  };
  
  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };






const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveExcelFile(excelBuffer);
};

  var DatefileName = new Date();
  var DateFileName  =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();
  
  const saveExcelFile = (buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `WorkerCount_Report${DateFileName}.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  
  document.title = " Worker Count Report  | Al khairi care ";
  return (
    
       <div className="page-content">
        <Container fluid>
          <Card>
        {/* begin::Header */}
        <div className='cursor-pointer'>
          <Row>
          <div className='col-sm'>
            <h5 className='card-title m-3'>Monthly Worker Report</h5>
          </div>
          <div className='col-sm-auto m-3'>
            <button
       className="btn text-white add-btn me-1 col-lg-12"
       style={{
         backgroundColor: "#28a8df",
         borderColor: "#28a8df",
       }}
              disabled={btnExcelDisable}
              onClick={handleExport}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
          <div className="col-lg-3">
                <label className=" col-form-label required fw-bold fs-6">
                  Filter By Agency
                </label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={AgencyId}
                  onChange={(selected:any)=>{setAgencyId({ value: selected.value, label: selected.label });  
                  }}
                  options={Agency}
                  isSearchable
                />
              </div>
           
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
            </div>

<div className='col-lg-2'>
  <br/>
                <Button onClick={Report}     className="btn mt-3 text-white add-btn me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }} >
                  Search
                </Button>
                </div>
                <div className='col-lg-2'>
                <br/>
                <Button
                  onClick={clearReport}
                  className="btn text-white add-btn me-1 mt-3 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  disabled={btnClearDisable}
                >
                  Clear
                </Button>
                </div>
          

          </div>
<Row className='mt-4'>
          <TableContainer       
                   
                      columns={columns}
                      data={report || []}
                      customPageSize={10}
                      divClass="table-responsive"
                      tableClass="align-middle table-nowrap mb-0"
                      agency={AgencyId?.label}
                      date={OCDate}
                      total={Total}
                      onDownload={onDownload}
                    />
                    </Row>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        </Card>
        </Container>
      </div>
   
  )
}

export {WorkerCountContents}





