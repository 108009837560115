import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

export const BasicLineCharts = ({ dataColors ,data,categories,name } : any) => {
  
    
    var linechartBasicColors = getChartColorsArray(dataColors);
    const series = [{
        name: name,
        data: data
    }];
    var options : any = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: linechartBasicColors,
        title: {
            text: '',
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },

        xaxis: {
            categories: categories,
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="line"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

