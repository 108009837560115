import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AgencyData from "./PlanData";
import CountUp from "react-countup";
import { widgetCountPlan } from "./_requests";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PlanOverview = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [plan, setPlan] = useState<any>([]);
  const [benefitaccess, setbenefitaccess] = useState<any>([]);
  const [PlanAccess, setplanaccess] = useState<any>([]);

// 15
  useEffect(() => {
    getCRUD(userProfile?.role, 16).then((res: any) => {
      var crudres = res.Data[0];
      setbenefitaccess(crudres);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 17).then((res: any) => {
      var crudres = res.Data[0];
      setplanaccess(crudres);
    });
  }, []);

  const listBenefit = () => {
    if (benefitaccess?.list === 1) {
      history("/benefit");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const addPlan = () => {
    if (PlanAccess?.add === 1) {
      history("/plan/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const listPlan = () => {
    if (PlanAccess?.list === 1) {
      history("/plan");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  useEffect(() => {
    widgetCountPlan().then((res: any) => {
      setPlan(res.data);
    });
  }, []);

  document.title = "Agency Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Plan Overview</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <div
                    onClick={listBenefit}
                    className="btn text-white add-btn me-1"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                    >
                      <i className=" ri-equalizer-line me-2 "></i> Manage
                      Benefits
                    </div>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={3} sm={12} >
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-bold text-muted mb-2">Total Plans</p>
                      <p className="fw-medium text-muted mb-3">
                        Total number of Plans
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold mb-4">
                        <span className="counter-value " data-target="547">
                          <CountUp
                            start={0}
                            end={plan.planCount}
                            // end={item.counter}
                            duration={3}
                            decimals={0}
                          />
                        </span>
                      </h2>
                      {/* <p className="mb-0 text-muted"><span className={"badge bg-" + item.percentageClass + "-subtle text-" + item.percentageClass + " mb-0"}> */}
                      {/* <i className={item.arrowIcon + " align-middle"}></i> {item.percentage} */}
                      {/* </span></p> */}
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="ri-ticket-2-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} sm={12}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-bold text-muted mb-2">Total Benefits</p>
                      <p className="fw-medium text-muted mb-3">
                        Total number of Benefits
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold mb-4">
                        <span className="counter-value" data-target="547">
                          <CountUp
                            start={0}
                            end={plan.benefitCount}
                            duration={3}
                            decimals={0}
                          />
                        </span>
                      </h2>
                      {/* <p className="mb-0 text-muted"><span className={"badge bg-" + item.percentageClass + "-subtle text-" + item.percentageClass + " mb-0"}> */}
                      {/* <i className={item.arrowIcon + " align-middle"}></i> {item.percentage} */}
                      {/* </span></p> */}
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="ri-ticket-2-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="justify-items-center">
                    <div className="">
                      <p className="fw-bold  text-hover-primary fs-4">
                        Configure and Manage
                      </p>
                    </div>
                    <p className="  text-dark-75 fw-bold fs-5">
                      Plans & Benefits.
                    </p>
                    <div className="mt-1  fw-semibold fs-5">
                      <div
                        onClick={addPlan}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Add Plan
                      </div>
                      <div
                        onClick={listPlan}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        List of Plan
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <AgencyData />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanOverview;
