import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  ListDepartment,
  createDepartment,
  deleteDepartmentById,
  updateDepartment,
} from "./_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "Components/Hooks/UserHooks";
import { Link, useNavigate } from "react-router-dom";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

interface Departments {
  id: string;
  name: string;
}

const Department = () => {
  const history = useNavigate();
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [department, setDepartment] = useState<Departments[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [departmentToDeleteId, setDepartmentToDeleteId] = useState<string>("");
  const [departmentToDeleteName, setDepartmentToDeleteName] = useState<string>(
    ""
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [departmentAccess, setdepartmentaccess] = useState<any>([]);

  useEffect(() => {
    let Data;
    if (Type === 1) {
      Data = 2;
    } else if (Type === 2) {
      Data = 32;
    } else {
      Data = "";
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setdepartmentaccess(res.Data[0]);
    });
  }, []);

  const addDepartment = () => {
    if (departmentAccess?.add === 1) {
      toggle();
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const editDepartment = () => {
    if (departmentAccess?.edit === 1) {
      toggle();
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (departmentId: string, departmentName: string) => {
    setDepartmentToDeleteId(departmentId);
    setDepartmentToDeleteName(departmentName);
    setdepartmentaccess((prevDepAccess: any) => {
      if (departmentAccess?.edit === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  const confirmDelete = (departmentId: string) => {
    deleteDepartmentById(departmentId)
      .then(() => {
        FetchData();
        toggleDeleteModal();
        toast.success("Department deleted Successfully", { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);
  const FetchData = () => {
    ListDepartment(Id).then((res: any) => {
      setDepartment(res);
    });
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Department is required"),
    }),
    onSubmit: (values: any) => {
      const id =
        values.id && typeof values.id === "object" ? values.id.id : values.id;
      if (isEdit) {
        const updateDepartments = {
          name: values.name,
        };
        updateDepartment(id, updateDepartments).then(() => {
          FetchData();
          toast.success("Department updated successfully", { autoClose: 3000 });
          toggle();
          validation.resetForm();
        });
      } else {
        const newDepartment = {
          name: values.name,
          agencyId: Id,
        };
        createDepartment(newDepartment)
          .then(() => {
            FetchData();
            toast.success("Department created Successfully", {
              autoClose: 3000,
            });
            toggle();
            validation.resetForm();
          })
          .catch((error) => {
            console.error("Error creating department:", error);
          });
      }
    },
  });

  document.title = "Department | Alkhairi care";

  const filteredDepartment = department.filter((department) =>
    department.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="g-3">
                <div className="col-lg-3 col-auto">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      id="search-task-options"
                      placeholder="Search Department..."
                      value={searchQuery}
                      onChange={(e) =>
                        setSearchQuery((e.target as HTMLInputElement).value)
                      }
                    />

                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <button
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        addDepartment();
                        validation.setValues({ name: "" });
                      }}
                    >
                      <i className="ri-add-fill align-bottom me-1"></i>
                      Add Department
                    </button>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>
          <ToastContainer closeButton={false} limit={1} />
          <Row className="mt-4">
            {filteredDepartment.length === 0 ? (
              <div className="text-center">No search data found</div>
            ) : (
              filteredDepartment.map((department: Departments, key: number) => (
                <React.Fragment key={key}>
                  <Col xl={3} lg={6}>
                    <Card className="card card-height-100 shadow-none bg-opacity-10">
                      <CardBody className="p-4">
                        <UncontrolledDropdown className="float-end">
                          <DropdownToggle
                            tag="a"
                            className="text-reset dropdown-btn"
                            href="#"
                          >
                            <span className="text-muted fs-18">
                              <i className="mdi mdi-dots-vertical"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end" end>
                            <DropdownItem
                              onClick={() => {
                                setIsEdit(true);
                                editDepartment();
                                validation.setValues({
                                  name: department.name,
                                  id: department.id,
                                }); // Set initial values for form fields
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                prepareDelete(department.id, department.name)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <h5 className="m-2 text start">
                          <i className="las la-university me-1"></i>{" "}
                          {department.name}
                        </h5>
                        {/* <Row className="mt-2">
                        <p className="text-muted m-2 ms-4 text start">Total {1} agencys</p>
                      </Row> */}
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))
            )}
          </Row>
        </Container>
      </div>

      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {!!isEdit ? "Edit Department" : "Create Department"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Department Name
              </label>
              <Input
                type="text"
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter department name"
                name="name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setIsEdit(false);
                setModal(false);
                validation.resetForm();
              }}
            >
              Close
            </Button>
            <Button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
              <i className="ri-save-line align-bottom me-1"></i>{" "}
              {!!isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the department{" "}
          <strong>{departmentToDeleteName}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            className="btn text-white add-btn me-1"
            style={{
              backgroundColor: "#28a8df",
              borderColor: "#28a8df",
            }}
            onClick={() => {
              confirmDelete(departmentToDeleteId);
              setModal(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Department;
