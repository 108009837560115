import TableContainer from 'Components/Common/TableContainer';
import { getAgencyInvoice } from 'pages/Admin Invoices/Portal-Agency -Invoice/_requests';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
  } from "reactstrap";




const InvoicesTab = () => {
 const {id}=useParams()
 const [Invoice,SetInvoice]=useState('')
    useEffect(()=>{
      getAgencyInvoice(id).then((res:any)=>{
                 SetInvoice(res)
        })
    },[])
    const columns = useMemo(
        () => [
          {
            header: "S.No",
            id: "sno",
            enableColumnFilter: false,
            cell: (cellProps: any) => {
              return cellProps.row.index + 1;
            },
          },
    
          {
            header: "Date",
            accessorKey: "paymentDate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <>
                  {cell.getValue() && (
                    <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
                  )}
                </>
              ),
          },
          {
            header: "Refrenece",
            accessorKey: "invoiceNumber",
            enableColumnFilter: false,
          },
          {
            header: "Invoice Description",
            accessorKey: "description",
            enableColumnFilter: false,
          },
          {
            header: "Amount",
            accessorKey: "total",
            enableColumnFilter: false,
          },
    
          {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            cell: (cell:any) => {
              switch (cell.getValue()) {
                case 2:
                  return (
                    <span className="badge border border-danger text-danger">
                      Invoice Created
                    </span>
                  );
                case 1:
                  return (
                    <span className="badge border border-success text-success">
                      Payment Submitted
                    </span>
                  );
                default:
                  return (
                    <span className="badge border border-danger text-danger">
                      Invoice Created
                    </span>
                  );
              }
            },
        },
          {
            header: "ACTION",
            cell: (cellProps: any) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0 ms-3">
                  <li className="list-inline-item edit" title="View">
                    <Link
                      to={`/invoice/view/${cellProps.row.original.primaryId}`}
                      className="text-primary d-inline-block edit-item-btn"
                    >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
                
                </ul>
              );
            },
          },
        ],
        []
      );
    return (
        <React.Fragment>
              <Card >
                <div className="card-body pt-0 m-0">
                  <div>
                 
                      <TableContainer
                        columns={columns}
                        data={Invoice || []}
                        isGlobalFilter={true}
                        customPageSize={10}
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap mb-0"
                        SearchPlaceholder="Search for invoice"
                      />
                  
                    {/* <ToastContainer closeButton={false} limit={1} /> */}
                  </div>
             
                </div>
              </Card>
           
       
        </React.Fragment>
    );
};

export default InvoicesTab;