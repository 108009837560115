import TableContainer from 'Components/Common/TableContainer'
import React, { useEffect, useMemo, useState  } from 'react'
import { Link, } from 'react-router-dom'
import { Card } from 'reactstrap'
interface Item {
  // Define the properties of each item in the list
  // For example:
  id: number;
  name: string;
  // Add more properties as needed
}

type Props = {
  data?: Item[]; // Specify the type of data prop
};
export const SupportPage: React.FC<Props> = ({data}) => {
 
  const [List, setList] = useState<Item[]>([])

  useEffect(()=>{
    if (data?.length){
      setList(data)
    } 
  },[data])

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: 'sno',
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "fromName",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="flex-shrink-0 avatar-xs me-2">
                  <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                    {cell.row.original.fromName.charAt(0)}
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-2 name">{cell.getValue()}</div>
            </div>
          </>
        ),
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "Priority",
        accessorKey: "supportpriority.priorityName",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "supportstatus.statusName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const statusValue = cell.getValue();
          let badgeClass = "";
          switch (statusValue) {
            case "Open":
              badgeClass = "border border-danger text-danger";
              break;
            case "Inprogress":
              badgeClass = "border border-secondary text-secondary";
              break;
            case "Resolved":
              badgeClass = "border border-success-subtle text-success";
              break;
          }
      
          return <span className={`badge text-uppercase ${badgeClass}`}>{statusValue}</span>;
        }
      },
      {
        header: "Action",
        cell: (cellProps: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" title="View">
              <Link to={`/clinicview/${cellProps.row.original.primaryId}`}>
                <i className="ri-eye-fill align-bottom text-muted"></i>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    []
  );
  
  document.title = "Products | Al khairi care";
  return (
    <React.Fragment>
  <Card>

<div className="card-body pt-3">
                  {List  ? (
                    <TableContainer
                      columns={columns}
                      data={List ? List : []}
                      // isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      // isProductsFilter={true}
                      SearchPlaceholder="Search Products..."
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}

</div>
              </Card>
   
      </React.Fragment>
  )
}


