import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { getClinic } from "pages/Report/Agency Report/_requests";
import { getAgency } from "pages/Agency/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
// import { getSupportStatus, getpriority } from "../_requests";

interface FilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any , Reset :any) => void; // Define onFilterApply in props interface
}

const ClaimFilter: React.FC<FilterProps> = ({ show, onCloseClick, onFilterApply}) => {
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const [AgencyId, setAgencyId] = useState<any>({ value: 0, label: 'All' });
  const [ClinicId, setClinicId] = useState<any>({ value: 0, label: 'All' });
  const [Status, setStatus] = useState<any>({ value: 0, label: 'All' });
   const [AmountFrom, setAmountFrom] = useState<any>('');
  const [AmountTo, setAmountTo] = useState<any>([]);
  const [Clinic, setClinic] = useState<any>(null);
  const [Agency, setAgency] = useState<any>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
 
  
  
  useEffect(() => {
    if(Type === 1){
    getClinic()
      .then((res: any) => {
          const mappedOptions = res.Data.map((agency: { id: any ,name:any }) => ({
            value: agency.id,
            label: agency.name,
          }));
          const allOption = { value: 0, label: "All" };
          const optionsWithAll = [allOption, ...mappedOptions];
          setClinic(optionsWithAll);
      
      })
      .catch((error: any) => {
        console.error("Error fetching clinics:", error);
      });
    }
  }, []);
  useEffect(() => {
    const filter={}
    getAgency(filter).then((res: any) => {
          const mappedOptions = res?.map((agency: { primaryId: any ,name:any }) => ({
            value: agency.primaryId,
            label: agency.name,
          }));
          const allOption = { value: 0, label: "All" };
          const optionsWithAll = [allOption, ...mappedOptions];
          setAgency(optionsWithAll);
      
      })
      .catch((error: any) => {
        console.error("Error fetching cities:", error);
      });
  }, []);
 



  const applyFilters = () => {
    const filters = {
      agencyId: AgencyId?.value || '',
      clinicId: ClinicId?.value || '',
      amountfrom: AmountFrom || '',
      amountTo: AmountTo || '',
      status: Status.value || '',
      fromDate: startDate ? startDate.toISOString() : "",
      toDate: endDate ? endDate.toISOString() : "",
    };
  
    const Reset=true;
    onFilterApply(filters,Reset);  // Use onFilterApply from props to pass filters to parent component or API call
    onCloseClick();
  };

const clearFilters = () => {
  const filters = {};
  const Reset=false;
  onFilterApply(filters,Reset); 
  setAgencyId({ value: 0, label: 'All' }); 
  setClinicId({ value: 0, label: 'All' });
  setStatus({ value: 0, label: 'All' });
  setStartDate(null); 
  setEndDate(null); 
  setAmountFrom(null);
  setAmountTo(null);
  onCloseClick(); 
};





  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Support Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
     
     { Type === 1 && (    <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-2"
            >
              Clinic
            </Label>
            <Select
              className="mb-0"
              value={ClinicId}
              onChange={(selectedOption: any) => setClinicId(selectedOption)}
              options={Clinic || []}
            ></Select>
          </div>

  )}
          <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-2"
            >
              Agency
            </Label>

            <Select
              className="mb-0"
              value={AgencyId}
              onChange={(selectedOption: any) => setAgencyId(selectedOption)}
              options={Agency || []}
            ></Select>
          </div>
          <div className="mb-2">
            <Label
              htmlFor="datepicker-range"
              className="form-label text-muted text-uppercase fw-semibold mb-2"
            >
              Date :
            </Label>
            <Flatpickr
                className="form-control"
                id="Daterange"
                placeholder="Select a date range"
                value={[startDate as Date, endDate as Date]}
                onChange={(selectedDates) => {
                  if (Array.isArray(selectedDates) && selectedDates.length === 2) {
                    setStartDate(selectedDates[0] as Date);
                    setEndDate(selectedDates[1] as Date);
                  }
                }}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                mode: "range",
                dateFormat: "d/m/Y",
              }}
            />
     
          </div>
          <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-2"
            >
              Amount Range
            </Label>
            <Input 
              type="number"
              className="mb-2"
              value={AmountFrom}
              placeholder="Enter amount range from"
              onChange={(e) => setAmountFrom(e.target.value)}
           / >
            <Input 
              type="number"
              className="mb-0"
              placeholder="Enter amount range to"
              value={AmountTo}
              onChange={(e) => setAmountTo(e.target.value)}
           / >
          </div>
          <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-2"
            >
              Status
            </Label>
            <Select
              className="mb-0"
              value={Status}
              onChange={(selectedOption: any) => setStatus(selectedOption)}
              options={[
                { value:  'All', label: 'All' },
                { value: '0', label: 'Pending' },
                { value: '1' , label: 'Approved'},
                { value: '2' , label: 'Rejected'},
              ]}
           
            ></Select>
          </div>
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
           type="button"
            className="btn btn-light w-100"
            onClick={clearFilters}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default ClaimFilter;
