import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "Components/Common/TableContainer";
import { deletePlanById, getPlan } from "./_requests";
import { AgencyInfoCell } from "./AgencyInfoCell";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";



const PlanList = () => {
  const history = useNavigate();
  const [PlanAccess, setPlanAccess] = useState<any>([]);
  const { userProfile } = useProfile();

  const [Plan, setPlan] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<string>("");

  useEffect(() => {
    getCRUD(userProfile?.role, 17).then((res: any) => {
      setPlanAccess(res.Data[0]);
    });
  }, []);

  const addPlan = () => {
    if (PlanAccess?.add === 1) {
      history("/plan/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (plan: any) => {
    setRoleToDeleteId(plan.primaryId);
    setRoleToDeleteName(plan.name);

    setPlanAccess((PrevAccess: any) => {
      if (PrevAccess?.remove === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return PrevAccess;
    });
  };

  const confirmDelete = async(departmentId: string) => {
    await deletePlanById(departmentId);
    toggleDeleteModal();
    fetchPlan()
    toast.success("Plan deleted Successfully", { autoClose: 3000 });
  };

 

  useEffect(() => {
  fetchPlan()
  }, []);

const fetchPlan=async()=>{
    const response= await getPlan()
    setPlan(response);
}
  const viewPlan = (cellProps: any) => {
    setPlanAccess((PrevAccess: any) => {
      if (PrevAccess?.view === 1) {
        history(`/plan/view/${cellProps.row.original.primaryId}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return PrevAccess;
    });
  };

 

  // Customers Column
  const columns = useMemo(
    () => [
      {
        header: "Plan Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "company",
        enableColumnFilter: false,
        cell: (cellProps: any) => (
          <AgencyInfoCell plan={cellProps.row.original.primaryId} />
        ),
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <a
                  onClick={() => {
                    viewPlan(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </a>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const Plan = cellProps.row.original;
                    prepareDelete(Plan);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Plan | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Plans" pageTitle="Plan" />
          <Row>
            <Col lg={12}>
              <Card id="PlansList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Plan List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <a
                          onClick={addPlan}
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Plan
                        </a>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Plan || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Plan..."
                    />
                    <ToastContainer closeButton={false} limit={1} />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the plan{" "}
              <strong>{RoleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(RoleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanList;
function deleteCheckbox() {
  throw new Error("Function not implemented.");
}
