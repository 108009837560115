import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TableContainer from "Components/Common/TableContainer";
import { deleteClaimFormById, getClaimform } from "./_requests";
import ClaimFilter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
// import {  cronJob, deleteclaimformById, getclaimform, updatebulkinsert } from "./_requests";

const NonPanelClaimFormList = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [claimform, setClaimform] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<string>("");
  const [Filter, setFilter] = useState<boolean>(false);
  const filter = {};
  const toggleFilter = () => {
    setFilter(!Filter);
  };
  const [nonPanelClaim, setNonpanelClaim] = useState<any>([]);

  useEffect(() => {
    getCRUD(userProfile?.role, 19).then((res: any) => {
      setNonpanelClaim(res.Data[0]);
    });
  }, []);

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (claimform: any) => {
    setRoleToDeleteId(claimform.primaryId);
    setRoleToDeleteName(claimform.Name);

    setNonpanelClaim((prevDepAccess: any) => {
      if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  const confirmDelete = (Id: string) => {
    deleteClaimFormById(Id, 1)
      .then(() => {
        toggleDeleteModal();
        fetchData(filter);
        toast.success("NonPanel Claim Form deleted Successfully", {
          autoClose: 3000,
        });
      })
      .catch((error: any) => {
        console.error("Error deleting role:", error);
      });

    deleteClaimFormById(Id, 1)
      .then(() => {
        toggleDeleteModal();
        fetchData();
        toast.success("NonPanel Claim Form deleted Successfully", {
          autoClose: 3000,
        });
      })
      .catch((error: any) => {
        console.error("Error deleting role:", error);
      });
  };

  useEffect(() => {
    fetchData(filter);
  }, []);
  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchData();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchData(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  const fetchData = (filters?: any) => {
    getClaimform(filters).then((response: any) => {
      setClaimform(response);
    });
  };

  const downloadFile = () => {
    const fileUrl = "/media/ExcelStatic/claimformInfo.xlsx"; // replace with your file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "claimformData.xlsx"; // replace with your file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const imageHandlerclick = () => {
    // 👇️ open file input box on click of other element
    (document.getElementById("fileup") as HTMLElement).click();
  };

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = e.target.files;

    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append("excel", files[0]);
    }
  };

  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    // setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    ele.forEach((element: any) => {
      // dispatch(deleteProducts(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
      del.style.display = "none";
    });
  };

  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".invoiceCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".invoiceCheckBox:checked");
    // ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    // setSelectedCheckBoxDelete(ele);
  };

  const viewNonpanelClaim = (cellProps: any) => {
    setNonpanelClaim((prevDepAccess: any) => {
      if (prevDepAccess?.view === 1) {
        history(
          `/nonpanel-claims-form/view/${cellProps.row.original.primaryId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  const [patientname, setpatientname] = useState("");
  const avatar = (cell: any) => {

    return (
      <div className="d-flex align-items-center">
        {/* begin:: Avatar */}
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          <Avatar
            size="30"
            round="20px"
            maxInitials={2}
            name={
              cell.row.original.familymember !== null
                ? cell.row.original.familymember?.patientName
                : patientname
            }
          ></Avatar>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-800  mb-1">
            {cell.row.original.familymember !== null
              ? cell.row.original.familymember?.patientName.toUpperCase()
              : patientname.toUpperCase()}
          </div>
        </div>
      </div>
    );
  };

  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(
      cell.row.original.visitDate
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Patient",
        accessorKey: "Name",
        enableColumnFilter: false,
      },
      {
        header: "Nric/Passport",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "Wbs Code",
        accessorKey: "wbsCode",
        enableColumnFilter: false,
      },
      {
        header: "Clinic",
        accessorKey: "clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agencyName",
        enableColumnFilter: false,
      },
      {
        header: "Reference No",
        accessorKey: "uniqueId",
        enableColumnFilter: false,
      },
      {
        header: "Visit Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: renderCreatedAt,
      },
      {
        header: "Visit Reason",
        accessorKey: "visitReason",
        enableColumnFilter: false,
      },

      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },

      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  {" "}
                  Approved
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  {" "}
                  Rejected
                </span>
              );
            default:
              return (
                <span className="badge border border-info text-info">
                  {" "}
                  Pending{" "}
                </span>
              );
          }
        },
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <div
                  onClick={() => {
                    viewNonpanelClaim(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const claimform = cellProps.row.original;
                    prepareDelete(claimform);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Nonpanel claimform | Alkhairi care";
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="NonPanel Claim Form" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="WorkresList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          NonPanel Claim Form List
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                        <button
                          type="button"
                          className="btn text-white add-btn mb-1 me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleFilter}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliter
                        </button>{" "}
                        <Link
                          to="/nonpanel-claims-form/Report"
                          className="btn text-white add-btn mb-1 me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          {/* <i className="ri-add-line align-bottom me-1" ></i> */}
                          Export Data
                        </Link>{" "}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={claimform || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Nonpanel Claimform"
                    />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Nonpanel Claim Form{" "}
              <strong>{RoleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(RoleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
      <ClaimFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default NonPanelClaimFormList;
