/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  data?: Record<string, any>;
};

export const OverviewPage: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <div className={`card `}>
        {/* begin::Header */}
        <div>
          {/* begin::Title */}

          {/* end::Title */}

          {/* begin::Toolbar */}

          {/* end::Toolbar */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body">
          {/* begin::Chart */}
          <div />
          {/* end::Chart */}

          <div className="flex-grow-1">
            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-2">
                <div className="d-flex flex-wrap">
                  <div className="border  border-dashed rounded min-w-125px p-2 m-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {data?.counts?.totalVisit}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Total Visits
                    </div>
                  </div>

                  <div className="border border-dashed rounded min-w-125px p-2 m-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {data?.counts?.panelCount}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Total Panel Claims
                    </div>
                  </div>

                  <div className="border border-dashed rounded min-w-125px p-2 m-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {data?.counts?.nonpanelCount}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Total Non Panel Claims
                    </div>
                  </div>

                  <div className="border border-dashed rounded min-w-125px p-2 m-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {data?.counts?.balance?.sumValue}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Available Credit
                    </div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded min-w-125px p-2 m-2">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {data?.counts?.supportCount}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Support Raised
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>

      <div className={`card`} style={{ marginTop: "3%" }}>
        {/* ...other code... */}
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">Recent Panel Visits</h3>
          <div className="card-toolbar">
            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
        </div>
        <div className="card-body pt-2">
          {data &&
          data.recentVisitsPanel &&
          data.recentVisitsPanel.length > 0 ? (
            data.recentVisitsPanel.map((item: any, i: any) => (
              <div key={i}>
                {item?.billingdetails && item.billingdetails.length > 0
                  ? item.billingdetails.map((billing: any, j: any) => {
                      if (billing?.billing?.name != null) {
                        return (
                          <div key={j}>
                            <div
                              className="d-flex  mb-3"
                              key={i}
                              style={{ marginTop: "0%" }}
                            >
                              {/* begin::Avatar */}
                              <div className="col-md-4">
                                {/* <img src={toAbsoluteUrl('/media/icons/duotune/general/gen025.svg')} alt='' /> */}

                                {item?.familymember &&
                                item?.familymember.length > 0 ? (
                                  item?.familymember.map(
                                    (familydata: any, k: any) => {
                                      return (
                                        <div
                                          key={k}
                                          className="text-dark fw-bold fs-6"
                                        >
                                          {familydata?.fromName}
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="text-dark fw-bold fs-6">
                                    {item?.fromName}
                                  </div>
                                )}

                                <div className="text-muted d-block fw-semibold">
                                  {item?.workerId}
                                </div>
                              </div>

                              <div
                                className="timeline-badge"
                                style={{ marginTop: "0%" }}
                              >
                                <i className="fa fa-genderless text-warning fs-1"></i>
                              </div>

                              <div
                                className="flex-grow-1 col-md-6"
                                style={{ marginLeft: "3%", marginTop: "0%" }}
                              >
                                <div className="text-dark fw-bold  fs-6">
                                  {billing.billing.name}
                                </div>
                                <span className="text-muted d-block fw-semibold">
                                  Visited :{" "}
                                  {new Date(item?.visitDate).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    }
                                  )}
                                </span>
                              </div>

                              <div className="d-flex justify-content-center flex-shrink-0 col-md-2">
                                <Link
                                  to={`/claim-form/view/${item?.id}`}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                >
                                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  : null}
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
        {/* ...other code... */}
      </div>

      <div className={`card `} style={{ marginTop: "3%" }}>
        {/* ...other code... */}
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">
            Recent Non Panel Visits
          </h3>
          <div className="card-toolbar">
            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
        </div>
        <div className="card-body pt-2">
          {data &&
          data.recentVisitsNonPanel &&
          data.recentVisitsNonPanel.length > 0 ? (
            data.recentVisitsNonPanel.map((item: any, i: any) => (
              <div key={i}>
                <div
                  className="d-flex  mb-3"
                  key={i}
                  style={{ marginTop: "0%" }}
                >
                  {/* begin::Avatar */}
                  <div className="col-md-4">
                    {/* <img src={toAbsoluteUrl('/media/icons/duotune/general/gen025.svg')} alt='' /> */}

                    {item?.insuranceholder &&
                    item?.insuranceholder.patientname ? (
                      <div className="text-dark fw-bold fs-6">
                        {item.insuranceholder.patientname}
                      </div>
                    ) : (
                      <div className="text-dark fw-bold fs-6">
                        {item?.fromName}
                      </div>
                    )}

                    <div className="text-muted d-block fw-semibold">
                      {item?.uniqueId}
                    </div>
                  </div>

                  <div className="timeline-badge" style={{ marginTop: "0%" }}>
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  </div>

                  <div
                    className="flex-grow-1 col-md-6"
                    style={{ marginLeft: "3%", marginTop: "0%" }}
                  >
                    <div className="text-dark fw-bold  fs-6">
                      {item?.visitReason}
                    </div>
                    <span className="text-muted d-block fw-semibold">
                      Visited :{" "}
                      {new Date(item?.visitDate).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </div>

                  <div className="d-flex justify-content-center flex-shrink-0 col-md-2">
                    <Link
                      to={`/nonpanel-claims-form/view/${item?.primaryId}`}
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
        {/* ...other code... */}
      </div>
    </div>
  );
};
