import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ListCategory, createCategory, deleteCategoryById, updateCategory } from "./_requests";
import { ToastContainer, toast } from "react-toastify";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

interface Categorys {
  id: string;
  name: string;
}

const Category = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [category, setCategory] = useState<Categorys[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [categoryToDeleteId, setCategoryToDeleteId] = useState<string>("");
  const [categoryToDeleteName, setCategoryToDeleteName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [categoryAccess, setCategoryAccess] = useState<any>([]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (category: any) => {
    setCategoryToDeleteId(category.id);
    setCategoryToDeleteName(category.name);
    toggleDeleteModal();
  };

  const confirmDelete = (categoryId: string) => {
    Delete(categoryId);
    toggleDeleteModal();
  };

  const Delete = (categoryId: string) => {
    deleteCategoryById(categoryId)
      .then(() => {
        FetchData()
        toast.success("Category deleted Successfully", { autoClose: 3000 });
        // Handle successful deletion if needed
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
      });
  };

  useEffect(() => {
    FetchData()
  }, []);

  const FetchData=()=>{
    ListCategory().then((res: Categorys[]) => {
      setCategory(res);
    });
  }

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Category is required"),
    }),
    onSubmit: (values: any) => {
      const id = values.id && typeof values.id === 'object' ? values.id.id : values.id;
      if (isEdit) {
        const updateCategoryData = {
          name: values.name,
        };
        updateCategory(id, updateCategoryData)
          .then(() => {
            toast.success("Category updated successfully", { autoClose: 3000 });
            FetchData()
            toggle();
            validation.resetForm();
          })
          .catch((error) => {
            console.error("Error updating category:", error);
          });
      } else {
        const newCategory = {
          name: values.name,
        };
        createCategory(newCategory)
          .then(() => {
            toast.success("Category created successfully", { autoClose: 3000 });
            FetchData()
            toggle();
            validation.resetForm();
          })
          .catch((error) => {
            console.error("Error creating category:", error);
          });
      }
    }
  });

  document.title = "Category | Alkhairi care";

  // Filter categories based on search query
  const filteredCategory = category.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="g-3">
                <div className="col-lg-3 col-auto">
                  <div className="search-box">
                    <input type="text" className="form-control search" id="search-task-options" placeholder="Search Category..."
                      value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <button     className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }} onClick={() => { setIsEdit(false); toggle(); validation.setValues({ name:''});}}>
                      <i className="ri-add-fill align-bottom me-1"></i> 
                      Add Category
                    </button>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Row className="mt-4">
          {filteredCategory.length === 0 ? (
  <div className="text-center">No search data found</div>
) : (
  filteredCategory?.map((category: Categorys, key: number) => (
    <React.Fragment key={key}>
      <Col xl={3} lg={6}>
        <Card className="card card-height-100  shadow-none bg-opacity-10">
          <CardBody className="p-4">  
            <UncontrolledDropdown className="float-end">
              <DropdownToggle tag="a" className="text-reset dropdown-btn" href="#">
                <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical"></i></span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" end>
                <DropdownItem onClick={() => {
                  setIsEdit(true);
                  toggle();
                  validation.setValues({ name: category.name, id: category.id }); // Set initial values for form fields
                }}>Edit</DropdownItem>
                <DropdownItem onClick={() => prepareDelete(category)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> 
            <h5 className="m-2 text start">
              <i className="bx bx-shield-alt-2 me-1"></i> {category.name}
            </h5>
            {/* <Row className="mt-2">
              <p className="text-muted m-2 ms-4 text start">Total {1} Plans</p>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  ))
)}

          </Row>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
      </div>

      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
        {!!isEdit ? "Edit Category" : "Create Category"}
        </ModalHeader>
        <Form onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">Category Name</label>
              <Input type="text" className="form-control" id="contactDescription" rows={3} placeholder="Enter category name"
                name="name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name ? true : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="light" id="close-modal" onClick={toggle}>
              Close
            </Button>
            <Button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
              <i className="ri-save-line align-bottom me-1"></i> {!!isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the category?{categoryToDeleteName}
        </ModalBody>
        <ModalFooter>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={() => confirmDelete(categoryToDeleteId)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Category;
