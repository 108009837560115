import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Clinics_URL = `${API_URL}/clinic`
const RA_URL = `${API_URL}/roleAccess`
const CLIENT_R_ETABLE_URL = `${API_URL}/dashboard/portal`
const C_E_URL = `${API_URL}/dashboard/employeePortalCount`
const CLAIM_URL = `${API_URL}/dashboard/recentClailforms`
const getClinicsById = (id:any) => {
  return axios
    .get(`${Clinics_URL}/view/${id}`) 
    .then((response: any) =>{
      return response.Data;
    }
   )
}

const clientportalEmpCount = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${C_E_URL}/${clientID}`, );
  return result;
}

const clientPortalrecentemployee = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${CLIENT_R_ETABLE_URL}/${clientID}`, );
  return result;
}

const getCRUD = (roleId: any,moduleId:any)=> {
  return axios
    .post(`${RA_URL}/getModuleWise/CRUD`,{roleId,moduleId})
    .then((response) => {return response})
}

const recentClaims = async (clinicId:any) => {
  const result = await axios.get(`${CLAIM_URL}/${clinicId}`);
 
  return result;
 
}
export {
  getClinicsById,
  getCRUD,
  clientportalEmpCount,
  recentClaims,
  clientPortalrecentemployee,
}
