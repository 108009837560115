import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import { Link, useNavigate } from "react-router-dom";
import { recentWorker } from "pages/Admin Dashboard/Dashboard/_request";
import { ToastContainer, toast } from "react-toastify";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";

const WorkerData = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [worker, setWorker] = useState<any>([]);
  const [workeraccess, setworkeraccess] = useState<any>([]);

  useEffect(() => {
    recentWorker().then((res: any) => {
      setWorker(res.data);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 11).then((res: any) => {
      var crudres = res.Data[0];
      setworkeraccess(crudres);
    });
  }, []);

  const viewWorkers = (cellProps: any) => {
    setworkeraccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.view === 1) {
        navigate(
          `/workers/view/${cellProps.row.original.id}/${cellProps.row.original.workerId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const listWorker = () => {
    setworkeraccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.list === 1) {
        navigate("/worker");
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const name = (cell: any) => {
    return (
      <>
        <p>{cell.row.original?.name}</p>
        <p>{cell.row.original?.workerId}</p>
      </>
    );
  };
  const columns = useMemo(
    () => [
      {
        header: "Worker Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: name,
      },
      {
        header: "Agency Name",
        accessorKey: "agency.name",
        enableColumnFilter: false,
      },
      {
        header: "Department",
        accessorKey: "department.name",
        enableColumnFilter: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Contact",
        accessorKey: "mobileNumber",
        enableColumnFilter: false,
      },

      {
        header: "Actions",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewWorkers(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Recent Workers</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <div
                      onClick={listWorker}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="fa-solid fa-bars"></i> View All
                    </div>
                  </div>
                </div>
              </div>
              <p>List of recently created workers</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={worker || []}
                customPageSize={5}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WorkerData;
