import React, { useState, useEffect,  useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Label,
  Input,
  FormFeedback,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCompany, getSubSob } from "pages/Workers/_requests";
import { createAssign } from "./_requests";
import { useNavigate } from "react-router-dom";

const AssignPlan = (data: any) => {
  const history = useNavigate();
  // Delete Employee
  const [modal, setModal] = useState<boolean>(false);
  const [plan, setPlan] = useState<any>([])
  const [agency, setAgency] = useState<any>([])
  const toggle = useCallback(() => {
    setModal(!modal);
  },[modal]);

  useEffect(() => {
    getSubSob().then((res: any) => {
      
      setPlan(res.data)
    })
  }, [])
  useEffect(() => {
    getCompany().then((res: any) => {
      setAgency(res.data)
    })
  }, [])

  

  // validation
  const Formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      planId:'',
      agencyId:''
    },
    validationSchema: Yup.object({
      planId: Yup.string().required("Plan is required"),
      agencyId: Yup.string()
        .required('At least one agency must be selected')
    }),
    onSubmit: async(values: any) => {
   
        const AssignPlan = {
          planId: values.planId,
          agencyId: values.agencyId,
        };
       await createAssign(AssignPlan);
        history("/plan");
        setTimeout(()=>{
              
          toast.success("Plan Assigned Successfully", { autoClose: 3000 });
        },100)
        toggle();
      }
    });








  document.title = "Assign Plan | Al khairi care - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
 
     
      <Container fluid>
  <BreadCrumb title="Assign Plan" pageTitle="Dashboard" />
  <Row>
    <Col xs={12} md={5} className="text-center">
      <Card id="employeeList">
        <CardHeader className="border-0">
          <Row className="g-4 align-items-center">
            <div className="col-sm">
              <div>
                <h5 className="card-title fw-bold mb-3">Plan</h5>
                <p>Please select Plan to assign with agencies</p>
                <Row>
                  <Col>
                    <div className="mb-3 mt-3">
                      <Input
                        name="planId"
                        type="select"
                        className="form-select"
                        id="zipcodeInput"
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        value={Formik.values.planId || ""}
                        invalid={Formik.touched.planId && Formik.errors.planId ? true : false}
                      >
                        <option>Select a Plan...</option>
                        {plan?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      {Formik.touched.planId && Formik.errors.planId ? (
                        <FormFeedback type="invalid" className="fw-300 mt-3">
                          {Formik.errors.planId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <p className="h-10"> </p>
          </Row>
        </CardHeader>
        <ToastContainer closeButton={false} limit={1} />
      </Card>
    </Col>
    <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
      <i className="bx bxs-right-arrow-alt fw-500 text-primary arrow-right" style={{ fontSize: "2.5rem" }}></i>
      <i className="bx bxs-down-arrow-alt fw-500 text-primary arrow-down" style={{ fontSize: "2.5rem" }}></i>
    </Col>
    <Col xs={12} md={5} className="text-center">
      <Card id="employeeList">
        <CardHeader className="border-0">
          <Row className="g-4 align-items-center">
            <div className="col-sm">
              <div>
                <h5 className="card-title mb-3 fw-500">Agency Details</h5>
                <p>Please select agency to assign with Plan</p>
                <Row>
                  <Col>
                    <div className="mb-3 mt-3">
                      <Input
                        name="agencyId"
                        type="select"
                        className="form-select"
                        id="zipcodeInput"
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        value={Formik.values.agencyId || ""}
                        invalid={Formik.touched.agencyId && Formik.errors.agencyId ? true : false}
                      >
                        <option>Select an Agency...</option>
                        {agency?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      {Formik.touched.agencyId && Formik.errors.agencyId ? (
                        <FormFeedback type="invalid" className="fw-300 mt-3">
                          {Formik.errors.agencyId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <p className="h-10"> </p>
          </Row>
        </CardHeader>
      </Card>
    </Col>
  </Row>
  <Row>
    <Col className="text-center">
      <Button
        disabled={Formik.values.planId === "" && Formik.values.agencyId === ""}
        className="btn text-white add-btn me-1 mb-3"
        style={{
          backgroundColor: "#28a8df",
          borderColor: "#28a8df",
        }}
        onClick={toggle}
      >
        Assign Plan
      </Button>
    </Col>
  </Row>
  <Modal isOpen={modal} toggle={toggle} centered>
    <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
    <Form
      className="tablelist-form"
      onSubmit={(e: any) => {
        e.preventDefault();
        Formik.handleSubmit();
        return false;
      }}
    >
      <ModalBody>Are you sure, you want to assign this Plan to this agency?</ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn text-white add-btn me-1"
          style={{
            backgroundColor: "#28a8df",
            borderColor: "#28a8df",
          }}
          onClick={toggle}
        >
          Not now
        </button>
        <button
          type="submit"
          className="btn text-white add-btn me-1"
          style={{
            backgroundColor: "#28a8df",
            borderColor: "#28a8df",
          }}
        >
          Confirm
        </button>
      </ModalFooter>
    </Form>
  </Modal>
</Container>

      </div>
    </React.Fragment>
  );
};

export default AssignPlan;


