/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getClientByPlanId,
  getPlanById,
  removeAssignedPlan,
} from "./_requests";
import { getCRUD } from "pages/ClaimForm/_requests";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Avatar from "react-avatar";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useProfile } from "Components/Hooks/UserHooks";

export function ViewPlan() {
  const { id } = useParams();
  const history = useNavigate();
  const { userProfile } = useProfile();
  const [Plan, setPlan] = useState<any>([]);
  const [Agency, setAgency] = useState<any>([]);
  const [cliniccategory, setClinicCategory] = useState<any>([]);
  const [Model, setModal] = useState<boolean>(false);

  const toggleModal = () => {
    setModal(!Model);
  };

  useEffect(() => {
    getPlanById(id).then((res: any) => {
      setPlan(res);
      setClinicCategory(res.planInfoview);
    });
  }, []);
  useEffect(() => {
    getClientByPlanId(id).then((res: any) => {
      //
      setAgency(res.Data);
    });
  }, []);

  //   const {currentUser} = useAuth()
  // const [roleaccess, setroleaccess] = useState<any>([]);
  //   useEffect(() => {
  //     getCRUD(currentUser?.role, 17).then((res: any) => {
  //       var crudres = res.data?.Data[0]
  //       setroleaccess(crudres)
  //     })
  //   }, [])

  //remove client

  var editsubsob = "/planInfo/edit/" + id;
  const navigate = useNavigate();
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [RCroleaccess, setRCroleaccess] = useState<any>([]);
  const [PlanAccess, setPlanAccess] = useState<any>([]);

  useEffect(() => {
    getCRUD(userProfile?.role, 17).then((res: any) => {
      setPlanAccess(res.Data[0]);
    });
  }, []);

  const [selectedId, setselectedId] = useState("");
  const [selectedIdName, setselectedIdName] = useState("");

  const handleShows = (primaryid: any, clientname: any) => {
    if (RCroleaccess?.remove === 0) {
      toast.error("You do not have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    } else {
      setselectedId(primaryid);
      setselectedIdName(clientname);
      toggleModal();
    }
  };
  const handleSubmitButton = async () => {
    await removeAssignedPlan(selectedId);
    history("/plan");
    setTimeout(()=>{
        toast.success("Agency removed from plan successfully")      
    },100)
    toggleModal();
  };

  //
  document.title = " View Plan | Al khairi care ";
  function goBack() {
    history(-1);
  }

  const editPlan = () => {
    setPlanAccess((PrevAccess: any) => {
      if (PrevAccess?.edit === 1) {
        history(`/plan/edit/${id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return PrevAccess;
    });
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ToastContainer closeButton={false} limit={1} />
            <BreadCrumb title=" View Plan " pageTitle="Plan" />
            <Row>
              <Col>
                {" "}
                <button
                  onClick={goBack}
                  className="btn btn-primary px-2 text-center mb-3"
                >
                  <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
                </button>{" "}
              </Col>
            </Row>
        <Card>

     
        <Row className="mt-4">
  <Col className="fw-bolder fw-800 fs-20"><p className="ms-4">{Plan?.name}</p></Col>
  <Col className="col-auto">
    <div
      onClick={() => {
        editPlan();
      }}
      className="btn text-white add-btn me-2"
      style={{
        backgroundColor: "#28a8df",
        borderColor: "#28a8df",
      }}
    >
      <i className="ri-pencil-fill align-bottom me-2 text-white"></i>
      Edit plan
    </div>
  </Col>
</Row>

<Row className="mb-3 ms-3">
  {Plan?.benefit?.map((item:any, i:any) => (
    <Col key={i} xs={6} sm={4} md={3} lg={2} xl={2} className="col-2">
      <div className="border border-success border-dashed rounded min-w-125px p-2 me-6 mb-2 d-inline-block">
        <i className="ri-equalizer-line me-2"></i>
        {item.name}
      </div>
    </Col>
  ))}
</Row>





            </Card>
            <Card>
              <CardHeader>Plan Details</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Validity</Col>
                  <Col className="col-3">{Plan.validity} </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Panel Clinic Coverage</Col>
                  <Col className="col-3">{Plan.planCovers}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Non Panel Clinic Coverage</Col>
                  <Col className="col-3">{Plan.nonPlanCovers}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Plan Benefits</Col>
                  <Col className="col-3">{Plan.benifits}</Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Clinic Category</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">CLINIC CATEGORY</Col>
                  <Col className="col-4">LIMIT</Col>
                </Row>

                {cliniccategory && cliniccategory.length > 0 ? (
                  cliniccategory.map((item: any, i: any) => (
                    <Row className="mb-3" key={i}>
                      <Col className="col-4">{item?.cliniccategory?.name}</Col>
                      <Col className="col-3">
                        {item?.unlimited === 1 ? (
                          <div className="card-toolbar">Unlimited</div>
                        ) : (
                          <div className="card-toolbar">{item.limit}</div>
                        )}
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Row className="mb-3">
                    <Col className="col-12 text-center">No Data</Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Plan Holding Agencies</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Agency NAME</Col>
                  <Col className="col-4">ACTION</Col>
                </Row>

                {Agency && Agency.length > 0 ? (
                  Agency.map((item: any, i: any) => (
                    <Row className="mb-3" key={i}>
                      <Col className="col-4">{item?.agency?.agencyname}</Col>
                      <Col className="col-3 ">
                        <div
                          className="badge border border-primary text-primary "
                          onClick={() =>
                            handleShows(item.id, item?.agency?.agencyname)
                          }
                        >
                          Remove Agency
                        </div>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Row className="mb-3">
                    <Col className="col-12 text-center">
                      "No agency Assigned"
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>

            <Modal isOpen={Model} toggle={toggleModal} centered>
              <ModalHeader toggle={toggleModal}>Confirmation</ModalHeader>
              <ModalBody>
                Are you sure, you want remove {selectedIdName} from this plan ?
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                  Not Now
                </Button>
                <Button color="danger" onClick={handleSubmitButton}>
                  Yes, Remove
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
