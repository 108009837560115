import axios from 'axios'
import { } from 'pages/Support/Agency-Support/_requests'

const API_URL = process.env.REACT_APP_API_URL
const Support_URL = `${API_URL}/support`
const SOB_URL = `${API_URL}/support/getsupport`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
const GET_PLAN_LIST = `${API_URL}/support`

//
const getClinicSupport = (filterData:any)=> {
  return axios
    .post(`${Support_URL}/cliniclist`,filterData)
    .then((d: any) => {
      return d.Data;
    })
}

const getSupportStatus = () => {
  return axios
    .get(`${Support_URL}/getsupportStatus`)
    .then((response:any) => {

  
      
      return response.Data
     })
}

const getpriority = async ()=> {
  const res = await axios.get(`${Support_URL}/getsupportPriority`);
  return res;
}

const createClientSupport = (data:any) => {
  return axios
    .post(`${Support_URL}/addClientSupport`,data)
    .then((response) => {
      return response.data
     })
}

  const viewclinicSupport = (primaryId:any) => {
    return axios
      .get(`${Support_URL}/viewClinicSupport/${primaryId}`)
      .then((response:any) => {
        return response.Data
      })
  }  

  const updateclinicStatus = (statusId:any,statusNotes:any,id:any) => {
    return axios
      .post(`${Support_URL}/updateClinicStatus/${id}`,{statusId,statusNotes})
      .then((response) => {
        return response.data
       })
     
  }

  const updateReply = (data:any) => {
    return axios
      .post(`${Support_URL}/ReplyClinicSupport`,data)
      .then((response) => {
       //
        return response.data
       })
     
  }

  const getreplyList = (id:any) => {
    return axios
      .get(`${Support_URL}/ListReplyClinicSupport/${id}`)
      .then((response:any) => {
        return response.Data
       })
     
  }


const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${Support_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      
      return response.data
     })
   
}
const getSupportById = (id:any) => {
  return axios
    .get(`${Support_URL}/view/${id}`) 
    .then((response: any) =>{
      return response.Data;
    }
   )
}


const updateClinic = (clinicId:any): Promise< undefined> => {
  return axios
    .put(`${Support_URL}/${clinicId}`, clinicId())
    .then((response) => {
      return response.data
     })
   
}
const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${API_URL}/clinicbulkInsert/upload`,)
    .then((response) => {
   return response.data;
    
})}

const updateSupportById = (Support:any,ID:any) => {
  //
  return axios
  .put(`${Support_URL}/${ID}`, Support)
  .then((response) => {
  //  
    return response.data
   })
   
}
const updateFamilySupport = (Support:any,ID:any) => {
 // 
  return axios
  .put(`${Support_URL}/family/${ID}`, Support)
  .then((response) => {
  //  
    return response.data
   })
   
}
const getAgency = async ()=> {
  const res = await axios.get(`${Support_URL}/getagency`);
  //
  return res;
}
const getDepartment = async (clientID:any) => {
  //
  const res = await axios.get(`${Support_URL}/getDepartment/${clientID}`);
  //
  return res;
}
const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${Support_URL}/admingetDepartment`);
  //
  return res;
}
const createSupport = (Support: any,clientID:any) => {
  //
  return axios
  .post(`${Support_URL}/addSupport/${clientID}`, Support)
  .then((response) => {
  //  
    return response.data
   })
   
}



const getSubSob = async () => {
  const res = await axios.get(`${SOB_URL}`);
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
  return res;
}
const getCountry = async () => {
  const res = await axios.get(`${COUNTRY_URL}`);
  //
  return res;
}

const deleteSupportById = (id: any) => {
  return axios
    .post(`${Support_URL}/${id}`, {})
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${Support_URL}/activateDeactivateAccount/${id}/${status}`)
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/${id}`);
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid});
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data);
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data);
  //
   return res.data;
 }
 const cronJob=async()=>{ 
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`);
  //
   return res.data;
 }
export {
  getClinicSupport,
  deleteSupportById,
  getSupportStatus,
  updateclinicStatus,
  updateReply,
  getSubSob,updatecontrols,getsobdetails, 
  createSupport,getSupportById,
  getAgency, getDepartment,getCountry,updatebulkinsert,updateClinic,updateSupportById,
  admingetDepartment,updateActiveDeactive,getPlan,upgradePlan,renewalPlan,cronJob,getpriority,createClientSupport,viewclinicSupport,
  getreplyList,
}

function getToken(): import("axios").AxiosRequestConfig<any> | undefined {
  throw new Error('Function not implemented.')
}
// 

