import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import progileBg from "../../../src/assets/images/profile-bg.jpg";
import {
  checkDuplicateEmail,
  createWorker,
  getAgency,
  getCountry,
  getDepartment,
  getPlan,
} from "./_requests";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { FormikValues } from "formik";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { useProfile } from "Components/Hooks/UserHooks";
const WorkersAdd = () => {
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;

  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Worker | Alkhairi care";
  const [selectedImage, setSelectedImage] = useState<any>();
  const [department, setDepartment] = useState<any>([]);
  const [country, setapiCountry] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [plan, setPlan] = useState<any>([]);
  const [imgStore, setImgStore] = useState<any>();

  // const handleClick = (item: any) => {
  //   const newData = [...imgStore, item];
  //   setImgStore(newData);
  //   Formik.setFieldValue("img", newData);
  // };

  useEffect(() => {
    if (Type === 1) {
      getAgency().then((res: any) => {

        setCompany(res.data);
      });
    } else {
      getDepartment(Id).then((res: any) => {

        setDepartment(res.data);
      });
    }
  }, []);
  useEffect(() => {
    getCountry().then((res: any) => {
      //
      setapiCountry(res.data);
    });
  }, []);
  useEffect(() => {
    if (Type === 2) {
      getPlan(Id).then((res: any) => {
        setPlan(res.Data);
      });
      Formik.setFieldValue("agencyId", Id);
    }
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    const files = event.target.files;

    if (files) {
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setSelectedImage(e.target.result as string); // Assuming setSelectedImage is a state setter function
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const Formik: any = useFormik<FormikValues>({
    enableReinitialize: true,

    initialValues: {
      name: "",
      agencyId: "",
      departmentId: "",
      wbsCode: "",
      planId: "",
      dob: "",
      mobileNumber: "",
      email: "",
      nricPassport: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      age: "",
      gender: "",
      maritalStatus: "",
      nationality: "",
      jobTitle: "",
      position: "",
      industry: "",
      workPermitNo: "",
      typeOfVisa: "",
      visaNo: "",
      visaExpiryDate: "",
      proposedDateOfArrival: "",
      emergencyContactName: "",
      relationship: "",
      emergencyContactNo: "",
      emergencyContactEmail: "",
      Code: "",
      Message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Worker name is required"),
      agencyId:
        Type === 1 ? Yup.string().required("Agency is required") : Yup.string(),
      departmentId: Yup.string().required("Department is required"),
      wbsCode: Yup.string(),
      planId: Yup.string().required("paln is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      dob: Yup.string().required("Date of Birth is required"),
      mobileNumber: Yup.string()
        .matches(/^\d+$/, "Mobile number should contain only numbers")
        .required("Phone Number is required"),
      nricPassport: Yup.string().required("NRIC / Passport  is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      pincode: Yup.string().required("Pincode is required"),
      age: Yup.string().required("Age is required"),
      gender: Yup.string().required("Gender is required"),
      maritalStatus: Yup.string().required("Marital Status is required"),
      nationality: Yup.string().required("Nationality is required"),
      jobTitle: Yup.string().required("Job Title is required"),
      position: Yup.string().required("Position is required"),
      industry: Yup.string().required("Industry is required"),
      workPermitNo: Yup.string().required("Work Permit No is required"),
      typeOfVisa: Yup.string().required("Type Of Visa is required"),
      visaNo: Yup.string().required("Visa No is required"),
      visaExpiryDate: Yup.string().required("Visa Expiry Date is required"),
      proposedDateOfArrival: Yup.string().required(
        "Proposed Date Of Arrival is required"
      ),
      emergencyContactName: Yup.string().required(
        "Emergency Contact Name is required"
      ),
      relationship: Yup.string().required("Relationship is required"),
      emergencyContactNo: Yup.string()
        .matches(
          /^\d+$/,
          "Emergency contact number should contain only numbers"
        )
        .required("Emergency contact number is required"),
      emergencyContactEmail: Yup.string()
        .email("Invalid email")
        .required("Emergency Contact Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();

      // Assuming selectedImage is accessible here
      // Assuming selectedImage is accessible here
      const base64Image = selectedImage;
      const byteCharacters = atob(base64Image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      // Append the image blob to the form data
      formData.append("addEmpImage", blob, "image.jpg");

      // Append other form values
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Save new worker
      const res = await createWorker(formData);

      // Assuming history is a function to navigate
      if (res.Code === 1) {
        history("/workers");
        setTimeout(() => {
          toast.success(res.Message, { autoClose: 3000 });
        }, 100);
      } else {
        setTimeout(() => {
          toast.error(res.Message, { autoClose: 3000 });
        }, 100);
      }

      // Reset the form
      resetForm();
    },
  });

  const handleChange = (event: any) => {
    getDepartment(event.target.value).then((res: any) => {

      setDepartment(res.data);
    });

    getPlan(event.target.value).then((res: any) => {
      setPlan(res.Data);
    });
    Formik.setFieldValue("agencyId", event.target.value);
  };

  const [Duplicate, setDuplicate] = useState<any>("");
  const DuplicateCheck = (val: any) => {
    try {

      checkDuplicateEmail(val).then((response: any) => {
        if (response?.isDuplicate === true) {
          setDuplicate("Email already exist");
        } else {
          setDuplicate("");
        }

      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              Formik.handleSubmit();
              return false;
            }}
          >
       
            <Row>
              <Col xxl={3}>
                <Card className="card-bg-fill">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div>Worker Profile <span className="text-danger">*</span></div>
                        <div className="position-absolute  bottom-0 end-0">
                          <Label
                            htmlFor="customer-image-input"
                            className="mb-0"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            name="formImage"
                            className="form-control d-none"
                            id="customer-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleImageChange}
                          />
                        </div>
                        <div
                          className="avatar-lg p-1"
                        >
                          <div className="avatar-title bg-light rounded-circle">
                            <img
                              src={selectedImage || dummyImg}
                              alt="dummyImg"
                              id="customer-img"
                              className="avatar-md rounded-circle object-fit-cover"
                            />
                          </div>
                        </div>
                      </div>
                      <div>{Formik.values.name}</div>
                     
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xxl={9}>
                <Card className="mt-xxl-n5 card-bg-fill">
                  <CardHeader>
                    <Nav
                      className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            tabChange("1");
                          }}
                        >
                          <i className="fas fa-home"></i>
                          Personal Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            tabChange("2");
                          }}
                          type="button"
                        >
                          <i className="far fa-user"></i>
                          Employment Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => {
                            tabChange("3");
                          }}
                          type="button"
                        >
                          <i className="far fa-envelope"></i>
                          Emergency Contact Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => {
                            tabChange("4");
                          }}
                          type="button"
                        >
                          <i className="far fa-envelope"></i>
                          Choose a Plan
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody className="p-4">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          {Type === 1 && (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="skillsInput"
                                    className="form-label"
                                  >
                                    Agency <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="agencyId"
                                    type="select"
                                    className="form-select"
                                    id="choices-publish-status-input"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={Formik.handleBlur}
                                    value={Formik.values.agencyId || ""}
                                    invalid={
                                      Formik.touched.agencyId &&
                                      Formik.errors.agencyId
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select a Agency...</option>
                                    {company?.map((item: any, index: any) => (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </Input>
                                  {Formik.touched.agencyId &&
                                  Formik.errors.agencyId ? (
                                    <FormFeedback type="invalid">
                                      {Formik.errors.agencyId}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="skillsInput"
                                    className="form-label"
                                  >
                                    Department <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="departmentId"
                                    type="select"
                                    className="form-select"
                                    id="choices-publish-status-input"
                                    onChange={Formik.handleChange}
                                    onBlur={Formik.handleBlur}
                                    value={Formik.values.departmentId || ""}
                                    invalid={
                                      Formik.touched.departmentId &&
                                      Formik.errors.departmentId
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select a department...</option>
                                    {department?.map(
                                      (item: any, index: any) => (
                                        <option value={item.id} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                  {Formik.touched.departmentId &&
                                  Formik.errors.departmentId ? (
                                    <FormFeedback type="invalid">
                                      {Formik.errors.departmentId}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </>
                          )}

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Workers Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Name"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.name || ""}
                                invalid={
                                  Formik.touched.name && Formik.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.name && Formik.errors.name ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {Type === 2 && (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="skillsInput"
                                    className="form-label"
                                  >
                                    Department <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="departmentId"
                                    type="select"
                                    className="form-select"
                                    id="choices-publish-status-input"
                                    onChange={Formik.handleChange}
                                    onBlur={Formik.handleBlur}
                                    value={Formik.values.departmentId || ""}
                                    invalid={
                                      Formik.touched.departmentId &&
                                      Formik.errors.departmentId
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select a department...</option>
                                    {department?.map(
                                      (item: any, index: any) => (
                                        <option value={item.id} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                  {Formik.touched.departmentId &&
                                  Formik.errors.departmentId ? (
                                    <FormFeedback type="invalid">
                                      {Formik.errors.departmentId}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </>
                          )}
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Gender <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="gender"
                                type="select"
                                className="form-select"
                                id="choices-publish-status-input"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.gender || ""}
                                invalid={
                                  Formik.touched.gender && Formik.errors.gender
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a Gender...</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Transgender">Transgender</option>
                              </Input>
                              {Formik.touched.gender && Formik.errors.gender ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.gender}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="JoiningdatInput"
                                className="form-label"
                              >
                                Date Of Birth <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="dob"
                                type="date"
                                className="form-control"
                                id="JoiningdatInput"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.dob || ""}
                                invalid={
                                  Formik.touched.dob && Formik.errors.dob
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.dob && Formik.errors.dob ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.dob}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="designationInput"
                                className="form-label"
                              >
                                Age <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="age"
                                type="number"
                                className="form-control"
                                placeholder="Enter Your Age"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.age || ""}
                                invalid={
                                  Formik.touched.age && Formik.errors.age
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.age && Formik.errors.age ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.age}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="websiteInput1"
                                className="form-label"
                              >
                                Marital Status <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="maritalStatus"
                                type="text"
                                className="form-control"
                                placeholder="Enter Marital Status"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.maritalStatus || ""}
                                invalid={
                                  Formik.touched.maritalStatus &&
                                  Formik.errors.maritalStatus
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.maritalStatus &&
                              Formik.errors.maritalStatus ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.maritalStatus}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="cityInput" className="form-label">
                                Nationality <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="nationality"
                                type="text"
                                className="form-control"
                                placeholder="Nationality"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.nationality || ""}
                                invalid={
                                  Formik.touched.nationality &&
                                  Formik.errors.nationality
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.nationality &&
                              Formik.errors.nationality ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.nationality}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="countryInput"
                                className="form-label"
                              >
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => {
                                  Formik.handleChange(e);
                                  DuplicateCheck(e.target.value);
                                }}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.email || ""}
                                invalid={
                                  Formik.touched.email && Formik.errors.email
                                    ? true
                                    : false
                                }
                              />
                              <span style={{ color: "red" }}>
                                {Duplicate ? Duplicate : ""}
                              </span>
                              {Formik.touched.email && Formik.errors.email ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Worker Code<span> (Optional)</span>
                              </Label>
                              <Input
                                name="wbsCode"
                                type="text"
                                className="form-control"
                                placeholder="Enter WBS Code"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.wbsCode || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Worker NRIC/Passport <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="nricPassport"
                                type="text"
                                className="form-control"
                                id="zipcodeInput"
                                placeholder="Enter Worker NRIC/Passport"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.nricPassport || ""}
                                invalid={
                                  Formik.touched.nricPassport &&
                                  Formik.errors.nricPassport
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.nricPassport &&
                              Formik.errors.nricPassport ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.nricPassport}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Worker Contact <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                name="mobileNumber"
                                placeholder="Enter Worker Contact"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.mobileNumber || ""}
                                invalid={
                                  Formik.touched.mobileNumber &&
                                  Formik.errors.mobileNumber
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.mobileNumber &&
                              Formik.errors.mobileNumber ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.mobileNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Country <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="country"
                                type="select"
                                className="form-select"
                                id="choices-publish-status-input"
                                placeholder="Select a agency"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.country || ""}
                                invalid={
                                  Formik.touched.country &&
                                  Formik.errors.country
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a country...</option>
                                {country?.map((item: any, index: any) => (
                                  <option value={item.name} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </Input>
                              {Formik.touched.country &&
                              Formik.errors.country ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.country}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Address <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="address"
                                placeholder="Enter Address"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.address || ""}
                                invalid={
                                  Formik.touched.address &&
                                  Formik.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.address &&
                              Formik.errors.address ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                City <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="Enter City"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.city || ""}
                                invalid={
                                  Formik.touched.city && Formik.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.city && Formik.errors.city ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                State <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="Enter State"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.state || ""}
                                invalid={
                                  Formik.touched.state && Formik.errors.state
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.state && Formik.errors.state ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.state}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Postal Code <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                name="pincode"
                                placeholder="Enter Postal Code"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.pincode || ""}
                                invalid={
                                  Formik.touched.pincode &&
                                  Formik.errors.pincode
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.pincode &&
                              Formik.errors.pincode ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.pincode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn btn-primary"
                                onClick={() => {
                                  tabChange("2");
                                }}
                              >
                                Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Job Title <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="jobTitle"
                                placeholder="Enter Job Title"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.jobTitle || ""}
                                invalid={
                                  Formik.touched.jobTitle &&
                                  Formik.errors.jobTitle
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.jobTitle &&
                              Formik.errors.jobTitle ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.jobTitle}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Position <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="position"
                                placeholder="Enter Position"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.position || ""}
                                invalid={
                                  Formik.touched.position &&
                                  Formik.errors.position
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.position &&
                              Formik.errors.position ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.position}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Industry <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="industry"
                                placeholder="Enter Industry"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.industry || ""}
                                invalid={
                                  Formik.touched.industry &&
                                  Formik.errors.industry
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.industry &&
                              Formik.errors.industry ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.industry}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Work Permit No <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="workPermitNo"
                                placeholder="Enter Work Permit No"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.workPermitNo || ""}
                                invalid={
                                  Formik.touched.workPermitNo &&
                                  Formik.errors.workPermitNo
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.workPermitNo &&
                              Formik.errors.workPermitNo ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.workPermitNo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Type Of Visa <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="typeOfVisa"
                                placeholder="Enter Type Of Visa"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.typeOfVisa || ""}
                                invalid={
                                  Formik.touched.typeOfVisa &&
                                  Formik.errors.typeOfVisa
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.typeOfVisa &&
                              Formik.errors.typeOfVisa ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.typeOfVisa}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Visa No <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="visaNo"
                                placeholder="Enter Visa No"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.visaNo || ""}
                                invalid={
                                  Formik.touched.visaNo && Formik.errors.visaNo
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.visaNo && Formik.errors.visaNo ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.visaNo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="JoiningdatInput"
                                className="form-label"
                              >
                                Visa Expiry Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="visaExpiryDate"
                                type="date"
                                className="form-control"
                                id="JoiningdatInput"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.visaExpiryDate || ""}
                                invalid={
                                  Formik.touched.visaExpiryDate &&
                                  Formik.errors.visaExpiryDate
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.visaExpiryDate &&
                              Formik.errors.visaExpiryDate ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.visaExpiryDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="JoiningdatInput"
                                className="form-label"
                              >
                                Proposed Date of Arrival <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="proposedDateOfArrival"
                                type="date"
                                className="form-control"
                                id="JoiningdatInput"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={
                                  Formik.values.proposedDateOfArrival || ""
                                }
                                invalid={
                                  Formik.touched.proposedDateOfArrival &&
                                  Formik.errors.proposedDateOfArrival
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.proposedDateOfArrival &&
                              Formik.errors.proposedDateOfArrival ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.proposedDateOfArrival}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn btn-primary"
                                onClick={() => {
                                  tabChange("3");
                                }}
                              >
                                Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Emergency Contact Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="emergencyContactName"
                                placeholder="Enter Emergency Contact Name"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.emergencyContactName || ""}
                                invalid={
                                  Formik.touched.emergencyContactName &&
                                  Formik.errors.emergencyContactName
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.emergencyContactName &&
                              Formik.errors.emergencyContactName ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.emergencyContactName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Relationship <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="relationship"
                                type="select"
                                className="form-select"
                                id="choices-publish-status-input"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.relationship || ""}
                                invalid={
                                  Formik.touched.relationship &&
                                  Formik.errors.relationship
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a Relationship...</option>
                                <option value="Spouse">Spouse</option>
                                <option value="Child">Child</option>
                                <option value="Self">Self</option>
                                <option value="Parent">Parent</option>
                              </Input>
                              {Formik.touched.relationship &&
                              Formik.errors.relationship ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.relationship}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Emergency Contact No <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                name="emergencyContactNo"
                                placeholder="Enter Emergency Contact No"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.emergencyContactNo || ""}
                                invalid={
                                  Formik.touched.emergencyContactNo &&
                                  Formik.errors.emergencyContactNo
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.emergencyContactNo &&
                              Formik.errors.emergencyContactNo ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.emergencyContactNo}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Emergency Contact Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                name="emergencyContactEmail"
                                placeholder="Enter Emergency Contact Email"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={
                                  Formik.values.emergencyContactEmail || ""
                                }
                                invalid={
                                  Formik.touched.emergencyContactEmail &&
                                  Formik.errors.emergencyContactEmail
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.emergencyContactEmail &&
                              Formik.errors.emergencyContactEmail ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.emergencyContactEmail}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn btn-primary"
                                onClick={() => {
                                  tabChange("4");
                                }}
                              >
                                Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody className="bg-info-subtle">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <FeatherIcon
                                      icon="calendar"
                                      className="text-info icon-dual-info"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <h6 className="fs-6">Plan</h6>
                                    <p className="text-muted mb-0">
                                      Choose a Plan, this will assign the plan
                                      to the Workers, and the benefits will be
                                      automatically applied to the Worker.
                                    </p>
                                    <div className="hstack  justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                      >
                                        Choose a Plan
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Plan <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="planId"
                                type="select"
                                className="form-select"
                                id="choices-publish-status-input"
                                placeholder="Select a Plan"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.planId || ""}
                                invalid={
                                  Formik.touched.planId && Formik.errors.planId
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a Plan...</option>
                                {plan?.map((item: any, index: any) => (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </Input>
                              {Formik.touched.planId && Formik.errors.planId ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.planId}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                             
                              <button
                                type="submit"
                                className="btn text-white add-btn me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                disabled={Duplicate}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WorkersAdd;
