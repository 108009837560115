import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

const GET_EMPLOYEE_LIST = `${API_URL}/employees/list`
const ROLE_URL = `${API_URL}/roleAccess`
const EMPLOYEE_URL = `${API_URL}/employees/add`
const EMPLOYEE_VIEW = `${API_URL}/employees/viewEmployee`
const EMPLOYEE_UPDATE = `${API_URL}/employees/updateEmployee`
const EMPLOYEE_DELETE = `${API_URL}/employees/deleteEmployee`
const EMPLOYEE_Duplicate = `${API_URL}/employees/find-duplicate`
const listEmployee = (Type:any,TypeId:any)=> {
  return axios
    .get(`${GET_EMPLOYEE_LIST}/${Type}/${TypeId}`)
    .then((d) => {
      
      return d;
    })
}

const getRole= async () => {
  const res = await axios.get(`${ROLE_URL}/getRole`)
  
  return res;
} 

const createEmployee = (employee: any) => {
  return axios
    .post(EMPLOYEE_URL, employee)
    .then((response) => {
      return response.data
     })
   
}

const deleteEmployee = (id: any) => {
  return axios
    .delete(`${EMPLOYEE_DELETE}/${id}`)
    .then((response) => {
      
   return response.data;

    }
    )
}

const viewEmployee = (id:any) => {
  return axios
    .get(`${EMPLOYEE_VIEW}/${id}`) 
    .then((response) =>{
   // 
      return response.data;
    }
   )
}

const updateEmployee = (data: any,id:any) => {
  return axios
    .post(`${EMPLOYEE_UPDATE}/${id}`, data)
    .then((response) => {
      
      return response.data;
    })
}


const checkDuplicateEmail = (email:any) => {
  return axios
    .post(`${EMPLOYEE_Duplicate}`, {email}, )
    .then((response) => {return response; }
    )
}

export {
  listEmployee,
  deleteEmployee,
  viewEmployee,
  createEmployee,
  getRole,
  updateEmployee,
  checkDuplicateEmail
}
