import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { fetchDataVerify, getsobdetails } from "./_requests";
import classnames from "classnames";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";


interface DateDifferenceProps {
  startDate: Date | string | number;
  endDate: Date | string | number;
}

const VerifyWorkers = () => {


  const [worker, setWorker] = useState<any>([]);
  const [Worker, setWorkers] = useState<any>([]);
  const [Data, setData] = useState<any>([]);
  const [Employeeid, setEmployeeid] = useState("");
  const [emptyemp, setemptyemp] = useState("");
  const [code, setcode] = useState("");
  const [radio, setRadio] = useState("");
  const [detailpages, setdetailpage] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const handleInput = (event: { target: { value: any } }) => {
    setEmployeeid(event.target.value);
    setemptyemp("");
    setcode("");
    //
  };
  const hadleRadio = (e: any) => {
    setemptyemp(" ");
    setRadio(e.target.value);
  };

  const searchEmployeeInfo = () => {
    if (Employeeid !== "") {
      var RDIOCONT=""
      if (radio !== "") {
        if (radio === "1") {
          RDIOCONT = "NRIC/Passport Number";
        } else {
          RDIOCONT = "Worker ID";
        }
        
        fetchDataVerify(Employeeid, radio).then((res: any) => {
          
          if (res.Code !== "2") {
            setWorker(res.data[0]);
            setdetailpage(res.data)
            getsobdetails(res.data[0].planId).then((ressob: any) => {
              
              setData(ressob.Data);
            });
            setEmployeeid("");
          } else {
            setcode(RDIOCONT + " ( " + Employeeid + " ) does not exist");
            setWorker("");
          }
        });
      } else {
        setemptyemp("Please Select Search By Method");
      }
    } else {
      setemptyemp("Please Give Some Data");
    }
  };

  const clearEmployeeInfo = () => {
    setWorker("");
    setcode("");
    setEmployeeid("");
  };

  useEffect(() => {
    const element = document.getElementById("fetchedDataScrollDiv");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [detailpages]);

  

  document.title = "Clinic Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
useEffect(()=>{
  setWorker('')
},[])

const getDateDifference = ({ startDate, endDate }: DateDifferenceProps): number => {
  // Convert startDate and endDate to Date objects if they are not already
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Calculate the difference in milliseconds
  const timeDifference = endDateObj.getTime() - startDateObj.getTime();

  // Convert milliseconds to days
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className="h-80">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Verify Workers</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xl={12}>
              <Card>
              
                <CardBody className="text-center">
                <Row>
            <Col xs={12} className="h-80">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                
              </div>
            </Col>
          </Row>
                  <div>
                    <div className="mt-3">
                      <p className="fw-bold text-hover-primary fs-4">
                        Verify Worker Details!
                      </p>
                    </div>
                    <Col lg={12}>
                      <Col
                        lg={8}
                        className="m-auto mb-2 bg-light-warning rounded border-success border border-dashed"
                      >
                        <div className="notice d-flex m-3 align-items-center">
                          <Col lg={2}>
                            <i
                              className="bx bx-message-square-check justify-self-center"
                              style={{ fontSize: "2rem" }}
                            ></i>
                          </Col>
                          <Col lg={10}>
                            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                              <div className="mb-3 mb-md-0 fw-bold">
                                <h4
                                  className="text-gray-800 fw-bolder mt-2 me-5"
                                  style={{ textAlign: "left" }}
                                >
                                  Worker Verification
                                </h4>
                                <div className="fs-6 text-gray-600 pe-7 mb-2 me-5">
                                  You can search Worker here and verify that
                                  worker details also
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col>
                    </Col>
                  </div>
                  <Row className="m-auto justify-content-center align-items-center ">
                    <Col xl={3} lg={3} md={3}>
                      <label className="col-form-label fw-bold fs-6">
                        Search By
                      </label>
                    </Col>
                    <Col xl={2} lg={2} md={2}>
                      <label className="form-check form-check-inline form-check-solid">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="searchtype"
                          value="2"
                          onChange={hadleRadio}
                        />
                        <span className="fw-bold ps-2 fs-6">Worker ID</span>
                      </label>
                    </Col>
                    <Col xl={2} lg={2} md={2}>
                      <label className="form-check form-check-inline form-check-solid me-1">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="searchtype"
                          value="1"
                          onChange={hadleRadio}
                        />
                        <span className="fw-bold ps-2 fs-6">NRIC/Passport</span>
                      </label>
                    </Col>
                  </Row>

                  <Row className="m-auto justify-content-center align-items-center mt-2">
                    <Col xl={4} lg={4} md={4}>
                      <Input
                        type="text"
                        id="employeeID"
                        value={Employeeid}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter worker id (as in ID card)"
                        onChange={handleInput}
                        style={{ fontSize: "14px" }}
                      />
                   <span className="text-danger">
                          {emptyemp}
                       </span>
                      <span className="text-danger">
                          {code}
                       </span>
                    </Col>
                    <Col xl={2} lg={2} md={2}>
                      <Button
                        onClick={searchEmployeeInfo}
                        className="btn text-white add-btn me-1  mb-1 col-lg-12"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col xl={2} lg={2} md={2} s>
                      <Button
                        onClick={clearEmployeeInfo}
                        className="btn text-white add-btn me-1 mb-1 col-lg-12"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                  <Row>
            <Col xs={12} className="h-80">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="h-80">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                
              </div>
            </Col>
          </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="h-50">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                
              </div>
            </Col>
          </Row>
          <div id='fetchedDataScrollDiv'></div>
      { worker && (<>
        <Row>
            <Col lg={12}>

              <Card >
                <div className="bg-primary-subtle">
                <CardHeader className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6'>

            <i className=" ri-search-line" style={{fontSize: '2rem'}}></i>
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ms-5'>
                <div className='mb-3 mb-md-0 fw-bold'>
                  <h4 className='text-gray-800 fw-bolder' style={{textAlign: 'left'}}>
                    Worker Found
                  </h4>
                  <div className='fs-6 text-gray-600 pe-7'>
                    There is one worker found for your search result
                  </div>
                </div>
              </div>
       
                    </CardHeader>
                  <CardBody className="pb-0 px-4">
                  <Row className="mb-3">
  <div className="col-md">
    <Row className="align-items-center g-3">
      <Col xxl={2} md={3} sm={4} xs={6} className="text-center text-md-start">
        <div>
          {worker?.profileImage ? (
            <img
              src={worker?.profileImage}
              alt="worker-img"
              className="rounded avatar-xl img-fluid"
            />
          ) : (
            <Avatar
              name={worker?.name}
              maxInitials={2}
              round="50px"
              color="#607458"
              fgColor="#1b1b29"
              className="custom-avatar"
              style={{ fontSize: "1.25rem" }}
            />
          )}
        </div>
      </Col>
      <Col xxl={10} md={9} sm={8} xs={12}>
        <Row className="g-3">
          <Col xs={12}>
            <h4 className="fw-bold text-start">
              {worker?.name} ({worker?.workerId})
            </h4>
          </Col>
          <Col xs={12} className="mb-3 text-start">
            <div className="fs-14">
              <i className="mdi mdi-account-outline fs-20 me-2"></i>
              {worker?.department?.name}
            </div>
          </Col>
          <Col xs={12} className="mb-3 text-start">
            <div className="fs-10 badge bg-success p-1">
              <i className="mdi mdi-account-outline fs-12 me-2"></i>
              {worker?.agency?.name}
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2 text-center text-md-start">
              <div className="d-flex justify-content-start align-items-center">
                <i className="ri-phone-fill fs-20 me-2"></i>
                <span className="fw-medium">{worker?.mobileNumber}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2 text-center text-md-start">
              <div className="d-flex justify-content-start align-items-center">
                <i className="ri-mail-line fs-20 me-2"></i>
                <span className="fw-medium">{worker?.username}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
</Row>




                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Plan Details
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                       <Card>
                       <CardHeader>
                        <h5 className="card-title mb-0">Basic Details</h5>
                    </CardHeader>
                       <CardBody>
                        <div className="table-responsive table-card">
                            <Table className="table-borderless align-middle mb-0">
                                <tbody>
                                <tr>
                                        <td className="fw-medium col-4" >Date of Birth</td>
                                        <td>
                                        {worker?.dob}
                                        </td>
                                    </tr>
                              
                                    <tr>
                                        <td className="fw-medium">NRIC / Passport</td>
                                        <td>{worker?.nricPassport}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Worker Code</td>
                                        <td>
                                            <span className="badge bg-danger" id="t-priority">{worker?.wbsCode}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Address</td>
                                        <td id="c-date">{worker?.address}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"></td>
                                        <td id="d-date">{worker?.city}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"></td>
                                        <td>{worker?.state}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"></td>
                                        <td>{worker?.country} - {worker?.pincode}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
         
                       </Card>
                        </TabPane>
                    <TabPane tabId="3">
                    <Card>
                <CardBody>
                <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1">Plan Details</h5>
                    </div>
                    </Row>
                    
                            
                        
                            
                             
                                <Col xl={12}>
                                <Card>
                                <CardBody className="p-4">
                    <Row className="g-3">
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Plan name
                        </p>
                        <h5 className="fs-14 mb-0"><span id="invoice-no">{worker?.plan?.name}</span></h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Validity From
                        </p>
                        <h5 className="fs-14 mb-0">
                        <span className="badge bg-success-subtle text-success fs-11" id="payment-status">{worker?.validityFrom}</span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Validity To
                        </p>
                        <span className="badge bg-success-subtle text-success fs-11" id="payment-status">{worker?.validityTo}</span>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Days
                        </p>
                        <h5 className="fs-14 mb-0"><span id="total-amount">{getDateDifference({ startDate: worker?.validityFrom, endDate: worker?.validityTo })}</span></h5>
                      </Col>
                    </Row>
                  </CardBody>
                                </Card>
                                </Col>
                                <Row>
                            
                                <Card>
    <CardHeader>
        <div className="d-flex">
            <div className="flex-grow-1">
                <h5 className="card-title mb-0">Credit balance details</h5>
            </div>
        </div>
    </CardHeader>
    <CardBody>
    <Row>
    {/* First Card */}
    <Col xl={6} xxl={6}>
        <Card>
            <CardBody>
                <table className="table table-borderless align-middle mb-0">
                    <thead>
                        <tr>
                            <th className="fw-bold" colSpan={2}>
                                Available Current Balance:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {worker?.workerbalancedetails?.map((item: any, i: any) => (
                            <tr key={i}>
                                <td>{item?.cliniccategory?.name}</td>
                                <td>{item?.unlimited === true ? 'Unlimited' : item?.currentBalance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    </Col>
    
    {/* Second Card */}
    <Col xl={6} xxl={6}>
        <Card>
            <CardBody>
                <table className="table table-borderless align-middle mb-0">
                    <thead>
                        <tr>
                            <th className="fw-bold" colSpan={2}>
                                Actual Plan Limits:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {worker?.plan?.plandetails?.map((item: any, i: any) => (
                            <tr key={i}>
                                <td>{item?.cliniccategory?.name}</td>
                                <td>{Number(item?.unlimited) === 1 ? 'Unlimited' : item?.limit}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    </Col>
</Row>

    </CardBody>
</Card>





                              
                                </Row>
             
          
                    <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1">Plan INFO :  {Worker?.map((item: any, i: any) => {
            return (
                <button type="button" className="btn btn-primary m-1" key={i}>
                {item?.plan?.name}
              </button>
            )
          })}</h5>
                        
                    </div>
                    </Row>
                        <div className="d-flex align-items-center border border-dashed p-2 rounded">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-zip-line fs-20 text-primary"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Plan Covers</Link></h6>
                               <small className="text-muted">{worker?.plan?.planCovers}</small>
                            </div>
                        </div>
                        <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-ppt-2-line fs-20 text-danger"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Non Plan Covers</Link></h6>
                                <small className="text-muted">{worker?.plan?.nonPlanCovers}</small>
                            </div>
                        </div>
                        <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
                            <div className="flex-shrink-0 avatar-sm">
                                <div className="avatar-title bg-light rounded">
                                    <i className="ri-file-ppt-2-line fs-20 text-danger"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1"><Link to="#">Benefits</Link></h6>
                                <small className="text-muted">{worker?.plan?.benifits}</small>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            </>
)}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VerifyWorkers;
