import TableContainer from 'Components/Common/TableContainer'
import React, { useEffect, useMemo, useState  } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'

interface Props  {
  data?: Record<string, any>
}

const HistoryVisitPage: React.FC<Props> = ({  data }) => {
  const [List, setList] = useState([])
  useEffect(()=>{
    if (data?.recentVisitsPanel?.length){

      setList(data?.recentVisitsPanel)
    }
    
  },[data])
  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(cell.row.original.visitDate).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  
    return <>{formattedDate}</>;
  };
  
  const columns = useMemo(
    () => [

      {
        header: "S.No",
        id: 'sno',
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Patient",
        accessorKey: "fromName",
        enableColumnFilter: false,
      },
      {
        header: "Clinic",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agency.clientName",
        enableColumnFilter: false,
      },
      {
        header: "Reference No",
        accessorKey: "uniqueId",
        enableColumnFilter: false,
      },
  
      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
 
      
      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
     
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  {" "}
                  Approved
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  {" "}
                  Rejected
                </span>
              );
            default:
              return (
                <span className="badge border border-secondary text-secondary">
                  {" "}
                  Pending{" "}
                </span>
              );
          }
        }
      },
      {
        header: "Created",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: renderCreatedAt
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <Link
                  to={`/claim-form/view/${cellProps.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                >

             <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    //  const claimform = cellProps.row.original; 
                    // prepareDelete(claimform)
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [] 
    );
  
  document.title = "Products | Al khairi care - React Admin & Dashboard Template";


  return (
    <React.Fragment>
  <Card>

<div className="card-body pt-3">
                  {data?.recentVisitsPanel ? (
                    <TableContainer
                      columns={columns}
                      data={List ? List : []}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      // isProductsFilter={true}
                      SearchPlaceholder="Search Products..."
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}

</div>
              </Card>
   
      </React.Fragment>
  )
}

export { HistoryVisitPage }
