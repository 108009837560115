
import { getAgencyInvoice } from 'pages/Admin Invoices/Portal-Agency -Invoice/_requests';
import { getClientSupport } from 'pages/Agency-Support/Agency-Support/_requests';
import React, { useEffect, useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
  } from "reactstrap";
  import clsx from 'clsx'
import { deleteWorkerById, getAgencyWorker, getWorker } from 'pages/Workers/_requests';
import TableContainer from './TableContainer';
import { useProfile } from 'Components/Hooks/UserHooks';
import { toast } from 'react-toastify';

const WorkerTab = () => {
    const {id}=useParams()
    const history = useNavigate();
    const { userProfile } = useProfile();
    const Type = userProfile?.userType;
    const [worker, setWorker] = useState([]);
    const [Name, DeleteName] = useState('');
    const [Id, DeleteId] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);



    const toggleDeleteModal = () => {
      setDeleteModalOpen(!deleteModalOpen);
    };

       useEffect(()=>{
        fetchWorker()
       },[])

       const prepareDelete = (worker: any) => {
        DeleteId(worker.primaryId);
        DeleteName(worker.name);
        toggleDeleteModal();
      };
    
      const confirmDelete = (departmentId: any) => {
    
        deleteWorkerById(departmentId).then(() => {
          toast.success("Worker deleted Successfully", { autoClose: 3000 });
          fetchWorker();
          toggleDeleteModal();
        });
      };
    
    
    
      const fetchWorker = () => {
        const filter={} 
          getAgencyWorker(filter,id).then((response) => {
            setWorker(response);
          });
        
      };
      
       const workerInfoCell = (cellProps:any) => {
        return (
          <div className='d-flex align-items-center'>
            {/* begin:: Avatar */}
            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
              <div>
                {cellProps.row.original?.profileImage ? (
                  <div className='symbol-label'>
                    <img src={cellProps.row.original?.profileImage} alt="hi" /*clinic?.clinic_name*/  style={{ objectFit: 'contain', width: '40px', height: '40px' ,borderRadius: '50%'}}  className='w-20' />
                  </div>
                ) : (
                  <div className={clsx('symbol-label fs-3', `bg-light-primary`, `text-white`)}>
                    <Avatar size="40" round="20px" maxInitials={2} name={cellProps.row.original?.name} />
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <div className='text-gray-800  mb-1'>
                {/*clinic?.clinic_name*/}
              </div>
              <span>{cellProps.row.original?.name}</span>
            </div>
          </div>
        );
      };

       const columns = useMemo(
           () => [
            {
                header: "S.No",
                id: "sno",
                enableColumnFilter: false,
                cell: (cellProps: any) => {
                  return cellProps.row.index + 1;
                },
              },
              {
                header: "Worker Name",
                accessorKey: "name",
                enableColumnFilter: false,
                cell: workerInfoCell
              },
              {
                header: "Department",
                accessorKey: "department.name",
                enableColumnFilter: false,
              },
              {
                header: "Worker Id",
                accessorKey: "workerId",
                enableColumnFilter: false,
              },
              {
                header: "Contact Number",
                accessorKey: "mobileNumber",
                enableColumnFilter: false,
              },
              {
                header: "Plan",
                accessorKey: "plan.name",
                enableColumnFilter: false,
              },
              {
                header: "Status",
                accessorKey: "suspenseStatus",
                enableColumnFilter: false,
                cell: (cell: any) => {
                  switch (cell.getValue()) {
                    case 1:
                      return (
                        <span className="badge border border-success text-success">
                          Active
                        </span>
                      );
                    case 2:
                      return (
                        <span className="badge border border-danger text-danger">
                          Suspended
                        </span>
                      );
                    default:
                      return null; // or handle the default case as needed
                  }
                },
              },
              {
                header: "Action",
                cell: (cellProps: any) => {
                  return (
                    <ul className="list-inline hstack gap-2 mb-0">
                      <li className="list-inline-item edit" title="View">
                        <Link
                          to={`/workers/view/${cellProps.row.original.primaryId}/${cellProps.row.original.workerId}`}
                          className="text-primary d-inline-block edit-item-btn"
                        >
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item" title="Remove">
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => {
                            const worker = cellProps.row.original;
                            prepareDelete(worker);
                          }}
                        >
                          <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                      </li>
                    </ul>
                  );
                },
              },
           ],
           []
         );
    return (
        <React.Fragment>
         <Card >
           <div className="card-body pt-0 m-0">
             <div>
            
                 <TableContainer
                   columns={columns}
                   data={worker || []}
                   isGlobalFilter={true}
                   customPageSize={10}
                   divClass="table-responsive table-card mb-3"
                   tableClass="align-middle table-nowrap mb-0"
                   SearchPlaceholder="Search for worker"
                 />
             
               {/* <ToastContainer closeButton={false} limit={1} /> */}
             </div>
        
           </div>
         </Card>
      
         <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the worker{" "}
              <strong>{Name}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(Id);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
   </React.Fragment>
    );
};

export default WorkerTab;