import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";

//import images

import Support from "./Support";
import Controls from "./Controls";
import { getWorkerById, updateActiveDeactive } from "pages/Workers/_requests";
import { Link, useNavigate, useParams } from "react-router-dom";
import PlanDetails from "./PlanDetails";
import VisitHistory from "./VisitHistory";
import NonpanelClaims from "./NonpanelClaims";
import PlanUpgrade from "./PlanUpgrade";
import Avatar from "react-avatar";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/ClaimForm/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Section = () => {
  //Tab
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const { id } = useParams();
  const navigate = useNavigate();
  const [worker, setWorker] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [workeraccess, setworkercaccess] = useState<any>([]);
  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const UpdateStatus = async () => {
    let status;
    if (worker?.suspenseStatus !== 2) {
      //2 means deactivate ,1 means activate
      status = 2;
    } else {
      status = 1;
    }
    const res = await updateActiveDeactive(id, status);
    navigate("/workers", { replace: true });
    await setTimeout(() => {
      toast.success(res?.Message, { autoClose: 3000 });
    }, 100);
  };

  useEffect(() => {
    getWorkerById(id).then((res: any) => {
      setWorker(res);
    });
  }, []);
  var date = worker?.dob;
  var dates = new Date(date);
  var formattedDate = dates.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const history = useNavigate();
  function goBack() {
    history(-1);
  }
  useEffect(() => {
    let Data;
    if (Type === 1) {
      Data = 11;
    } else if (Type === 2) {
      Data = 31;
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setworkercaccess(res.Data[0]);
    });
  }, []);

  const EditWorkers = () => {
    if (workeraccess?.edit === 1) {
      history("/workers/Edit/" + id);
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  // const EditWorkers = () => {
  //   setworkercaccess((prevWorkerAccess:any) => {

  //     if (prevWorkerAccess?.edit === 1) {

  //       history("/workers/Edit/" + id);
  //     }
  //     else {
  //     toast.error("You don't have permission to access this page", {
  //       position: "top-right",
  //       style: {
  //         backgroundColor: "#9b2313",
  //         color: "#c7c7d7",
  //         fontSize: "14px",
  //       },
  //       closeButton: false,
  //     });
  //   }
  //   return prevWorkerAccess; // Return the previous state to avoid unintended changes
  // })
  // };
  return (
    <React.Fragment>
      <Row>
        <BreadCrumb title="Worker Overview" pageTitle="Worker" />
        <Row>
          <Col>
            {" "}
            <button
              onClick={goBack}
              className="btn btn-primary px-2 text-center mb-3"
            >
              <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
            </button>{" "}
          </Col>
        </Row>
        <Col lg={12}>
          <Card className="">
            <div className="bg-primary-subtle">
              <CardBody className="pb-0 px-4">
                <Row className="mb-3">
                  <div className="col-md">
                    <Row className="align-items-center g-3">
                      <div className="col-6 col-md-2">
                        {" "}
                        {/* Adjust column width for smaller devices */}
                        <div className="mt-3">
                            {worker?.profileImage ? (
                              <img
                                src={worker?.profileImage}
                                alt=""
                                className="rounded avatar-xl p-2"
                              />
                            ) : (
                              <Avatar
                                name={worker?.name}
                                maxInitials={2}
                                round="50px"
                                color="#607458"
                                fgColor="#1b1b29"
                                className="custom-avatar" // Add a custom class name
                                style={{ fontSize: "1.25rem" }} // Adjust the font size using inline style
                              />
                            )}
                        
                        </div>
                      </div>

                      <div className="col-md">
                        <div>
                          <h4 className="fw-bold mt-2">
                            {worker?.name} ({worker?.workerId})
                          </h4>
                          <br></br>
                          <div className="hstack gap-3 flex-wrap">
                            <div className="badge rounded-pill bg-info fs-12">
                              {worker?.agency?.name}
                            </div>
                          </div>
                          <br></br>
                          <div className="hstack gap-3 flex-wrap">
                            <div>
                              <i className="ri-building-line align-bottom me-1"></i>{" "}
                              {worker?.department?.name}
                            </div>
                            <div className="vr"></div>
                            <div>
                              Mobile Number:{" "}
                              <span className="fw-medium">
                                {worker?.mobileNumber}
                              </span>
                            </div>
                            <div className="mb-2"></div>
                            <div>
                              Email:{" "}
                              <span className="fw-medium">
                                {worker?.username}
                              </span>
                            </div>
                            <div className="vr"></div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                  <div className="col-md-auto mt-1">
                    <div onClick={toggle}>
                      {worker.suspenseStatus !== 2 ? (
                        <div
                          className="badge border border-danger text-danger w-80 h-20"
                          style={{ fontSize: "1rem" }}
                        >
                          {" "}
                          Deactivate
                        </div>
                      ) : (
                        <div
                          className="badge border border-success text-success  w-80 h-20"
                          style={{ fontSize: "1rem" }}
                        >
                          {" "}
                          Activate
                        </div>
                      )}
                    </div>
                  </div>
                </Row>
                <div className='d-flex overflow-auto h-55px'>
                <Nav className="nav-tabs-custom border-bottom-0 " role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "1" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("1");
                      }}
                      href="#"
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "3" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("3");
                      }}
                      href="#"
                    >
                      Plan Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "2" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("2");
                      }}
                      href="#"
                    >
                      Visit History
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "4" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("4");
                      }}
                      href="#"
                    >
                      Non Panel Claims
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "5" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("5");
                      }}
                      href="#"
                    >
                      Support
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "6" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("6");
                      }}
                      href="#"
                    >
                      Controls
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "7" },
                        "fw-semibold"
                      )}
                      onClick={() => {
                        toggleTab("7");
                      }}
                      href="#"
                    >
                      Plan Upgrade
                    </NavLink>
                  </NavItem>
                </Nav>
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row>
                <Col xxl={3}>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xl={6}>
                          <h5 className="card-title mb-0">
                            Personal Information
                          </h5>
                        </Col>
                        <Col xl={6} className="d-flex justify-content-end">
                          <div
                            onClick={EditWorkers}
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                          >
                            Edit Basic
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-4">Age</td>
                              <td>{worker?.age}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Date of Birth</td>
                              <td id="t-client">{formattedDate}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Gender</td>
                              <td>{worker?.gender}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Marital Status</td>
                              <td>{worker?.maritalStatus}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Nationality</td>
                              <td>{worker?.nationality}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">NRIC / Passport</td>
                              <td>{worker?.nricPassport}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">WBS Code</td>
                              <td>
                                <span
                                  className="badge bg-danger"
                                  id="t-priority"
                                >
                                  {worker?.wbsCode}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Address</td>
                              <td id="c-date">{worker?.address}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">City</td>
                              <td id="d-date">{worker?.city}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium"></td>
                              <td>{worker?.state}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium"></td>
                              <td>
                                {worker?.country} - {worker?.pincode}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Employment Details</h5>
                    </CardHeader>
                    <ToastContainer />
                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-4">Job Title</td>
                              <td>{worker?.jobTitle}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Position</td>
                              <td id="t-client">{worker?.position}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Industry</td>
                              <td>{worker?.industry}</td>
                            </tr>

                            <tr>
                              <td className="fw-medium">Work Permit No</td>
                              <td>{worker?.workPermitNo}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Type of Visa</td>
                              <td>{worker?.typeOfVisa}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Visa No</td>
                              <td>{worker?.visaNo}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Visa Expiry Date</td>
                              <td id="c-date">{worker?.visaExpiryDate}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">
                                Proposed Date of Arrival
                              </td>
                              <td id="c-date">
                                {worker?.proposedDateOfArrival}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">
                        Emergency Contact Details
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-4">
                                Emergency Contact Name:
                              </td>
                              <td>{worker?.emergencyContactName}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Relationship</td>
                              <td id="t-client">{worker?.relationship}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">
                                Emergency Contact No
                              </td>
                              <td>{worker?.emergencyContactNo}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">
                                Emergency Contact Email
                              </td>
                              <td>{worker?.emergencyContactEmail}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <VisitHistory />
            </TabPane>
            <TabPane tabId="3">
              <PlanDetails />
            </TabPane>
            <TabPane tabId="4">
              <NonpanelClaims />
            </TabPane>
            <TabPane tabId="5">
              <Support />
            </TabPane>
            <TabPane tabId="6">
              <Controls data={worker} />
            </TabPane>
            <TabPane tabId="7">
              <PlanUpgrade data={worker} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to{" "}
          {worker?.suspenseStatus !== 2 ? "Deactivate" : "Activate"} this Worker{" "}
          <strong>{worker.name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="danger" onClick={UpdateStatus}>
            {worker?.suspenseStatus !== 2 ? "Deactivate" : "Activate"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Section;
