import React, { useEffect, useState, useMemo } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

//redux
import { useSelector } from "react-redux";
import TableContainer from "../../../../Components/Common/TableContainer";

import Loader from "../../../../Components/Common/Loader";
import { createSelector } from "reselect";
import { ToastContainer, toast } from "react-toastify";
import Filter from "./Filter";
import { getAgencySupport } from "../_requests";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { Link, useNavigate } from "react-router-dom";
import { getClientSupport } from "pages/Agency-Support/Agency-Support/_requests";

const SupportAgencyList = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [AgenSupAccess, setAgenSupAccess] = useState<any>([]);

  const selectLayoutState = (state: any) => state.Crm;

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 14;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      var crudres = res.Data[0];
      setAgenSupAccess(crudres);
    });
  }, []);


  const [support, setSupport] = useState<any>([]);
 
  const viewAgencySupport = (cellProps: any) => {
    setAgenSupAccess((prevDepAccess: any) => {
      if (prevDepAccess?.view === 1) {
        history(`/agencyview/${cellProps.row.original.primaryId}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };



  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false);

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchWorker();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any , Reset :any) => {
    fetchWorker(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  useEffect(() => {
    fetchWorker(); // Fetch initial worker data
  }, []);

  const fetchWorker = async (filters?: any) => {
    try {
      const response = await getAgencySupport(filters);
      
      setSupport(response);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Agency Name",
        accessorKey: "fromName",
        enableColumnFilter: false,
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "Priority",
        accessorKey: "supportpriority.priorityName",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "supportstatus.statusName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const statusValue = cell.getValue();
          let badgeClass = "";
          switch (statusValue) {
            case "Open":
              badgeClass = "border border-danger text-danger";
              break;
            case "Inprogress":
              badgeClass = "border border-secondary text-secondary";
              break;
            case "Resolved":
              badgeClass = "border border-success-subtle text-success";
              break;
          }

          return (
            <span className={`badge text-uppercase ${badgeClass}`}>
              {statusValue}
            </span>
          );
        },
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item view" title="View">
                <div
                  onClick={() => {
                    viewAgencySupport(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Agency support | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Agency Support" pageTitle="Support" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                      <h5 className="card-title mb-0">Agency Support</h5>
                    </Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleInfo}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliter
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={support || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      SearchPlaceholder="Search support"
                    />
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        show={isInfoDetails}
        onCloseClick={toggleInfo}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default SupportAgencyList;
