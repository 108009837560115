import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`



const getCompany = async () => {
  const res = await axios.get(`${REPORT_URL}/getagencydropdown`, )
  return res;
}

const getCliniCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`,)
    .then((response) => {return response})
   
}
const getClinic= async () => {
  const res = await axios.get(`${REPORT_URL}/getClinicdropdown`, )
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${REPORT_URL}/clientReport/getAssignedPlans/${id}`, )
  return res;
}
const getEmployee = async () => {
  const res = await axios.get(`${REPORT_URL}/getEmployeedropdown`, )
  return res;
}
const getTreatement = async () => {
  const res = await axios.get(`${CLAIM_URL}/getTreatment`, )
  return res;
}
const claimTreatements = async (id:any) => {
  const res = await axios.get(`${CLAIM_URL}/claimTreatements/${id}`, )
 // 
  return res;
}

const getReportforCompany = async (CID:any,COMID:any,CLINICID:any,date:any) => {
  const res = await axios.post(`${REPORT_URL}/getClaimReport/${CID}/${COMID}/${CLINICID}`,{date}, )
  return res;
}
const getReportforCompanyNotIn = async (CID:any,COMID:any) => {
  const res = await axios.get(`${REPORT_URL}/getNonClaimEmployee/${CID}/${COMID}`, )
  return res;
}
const getCliniCategoryByplan=
async (id:any) => {
  const res = await axios.get(`${API_URL}/clinicCategory/getClinicCategoryByplan/${id}`,)
  return res;
}
export {
  getCompany,getEmployee,getClinic,getReportforCompany,claimTreatements,getPlan,getReportforCompanyNotIn,
  getTreatement,getCliniCategory,getCliniCategoryByplan
}
