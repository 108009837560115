import React, { useEffect, useState } from "react";

import {
  Container,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useParams } from "react-router-dom";
import Avatar from "react-avatar";
import { HistoryVisitPage } from "./HistoryVisitPage";
import { getEmployeeDetails, getworkerSupport } from "./_requests";
import { SupportPage } from "./support";
import { HistoryVisitNonPanel } from "./HistoryVisitNonPanel";
import { OverviewPage } from "./OverviewPage";

const WorkerReportContent = (props: any) => {
  const { id, WorkerId } = useParams();
  const [activeTab, setActiveTab] = useState<any>("1");
  const [worker, setWorker] = useState<any>([]);
  const [isPersonalDetailsOpen, setIsPersonalDetailsOpen] = useState(false);
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(true);
  const [List, setList] = useState([])
  useEffect(() => {
    getEmployeeDetails(id, WorkerId).then((res: any) => {
      setWorker(res.Data);
    });

    getworkerSupport(id).then((res)=>{  
      setList(res)
    })

  }, [id, WorkerId]);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.title = "Worker Report | Alkhairi Care";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Worker Report" pageTitle="Report" />

        <Row>
          <Col xl={3} lg={4}>
            <Card>
              <CardHeader>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <div className="avatar-md">
                    <div className="avatar-title bg-white rounded-circle">
                      {worker?.profileImage ? (
                        <img
                          src={worker?.profileImage}
                          alt=""
                          className="rounded avatar-xl ps-2 " // Set the width of the image to 100%
                        />
                      ) : (
                        <Avatar
                          name={worker?.name}
                          maxInitials={2}
                          round="50px"
                          color="#607458"
                          fgColor="#1b1b29"
                          className="custom-avatar" // Add a custom class name
                          style={{ fontSize: "1.25rem" }} // Adjust the font size using inline style
                        ></Avatar>
                      )}
                    </div>
                  </div>
                </div>
                <h4 className="text-center">{worker.name}</h4>
              </CardHeader>

              <div className="accordion accordion-flush">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button bg-transparent shadow-none ${
                        isPersonalDetailsOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      id="flush-headingRating"
                      onClick={() =>
                        setIsPersonalDetailsOpen(!isPersonalDetailsOpen)
                      }
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Personal Details
                      </span>{" "}
                    </button>
                  </h2>

                  <UncontrolledCollapse toggler="#flush-headingRating">
                    <div
                      id="flush-collapseRating"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingRating"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2">
                      
                            <label
                              className="-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted me-3">
                                ReferenceId :
                              </span>{" "}
                              <span>{worker?.workerId}</span>
                            </label>
                 
                            <label
                              className="-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted me-3">
                                Date of Birth :
                              </span>{" "}
                              <span>
                                {" "}
                                {new Date(worker?.dob).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </span>
                            </label>
                       
                            <label
                              className="-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted me-3">
                                NRIC/Passport :
                              </span>{" "}
                              <span>{worker?.nricPassport}</span>
                            </label>
                        
                            <label
                              className="-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted me-3">
                                WBS Code :
                              </span>{" "}
                              <span>{worker?.wbsCode}</span>
                            </label>
                    
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button bg-transparent shadow-none ${
                        isContactDetailsOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      id="flush-headingBrands"
                      onClick={() =>
                        setIsContactDetailsOpen(!isContactDetailsOpen)
                      }
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Contact Details
                      </span>{" "}
                    </button>
                  </h2>
                  <UncontrolledCollapse
                    toggler="#flush-headingBrands"
                    defaultOpen
                  >
                    <div
                      id="flush-contactdetails"
                      className="accordion-collapse collapse show collapsed"
                      aria-labelledby="flush-contactdetails"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2">
                          <div className="">
                            <span className="text-muted me-3">Address :</span>{" "}
                            <span>{worker?.address}</span>
                          </div>
                          <div className="">
                            <span className="text-muted me-3">City :</span>{" "}
                            <span>{worker?.city}</span>
                          </div>
                          <div className="">
                            <span className="text-muted me-3">Email :</span>{" "}
                            <span>{worker?.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div>
            </Card>
          </Col>

          <Col xl={9} lg={8}>
            <div>
              <Card>
                <div className="card-header border-0">
                  <Row className=" align-items-center">
                    <Col>
                    <div className="d-flex flex-column">
                      <Nav
                        className="nav-tabs-custom card-header-tabs border-bottom-0"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "1" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("1", "all");
                            }}
                            href="#"
                          >
                            Overview{" "}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "2" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("2", "published");
                            }}
                            href="#"
                          >
                            Panel Visit History{" "}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "3" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("3", "draft");
                            }}
                            href="#"
                          >
                            Non Panel Visit History
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "4" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("4", "draft");
                            }}
                            href="#"
                          >
                            Support Raised
                          </NavLink>
                        </NavItem>
                      </Nav>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <OverviewPage data={worker} />
                </TabPane>
                <TabPane tabId="2">
                  <HistoryVisitPage  data={worker} />
                </TabPane>
                <TabPane tabId="3">
                  <HistoryVisitNonPanel data={worker} />
                </TabPane>
                <TabPane tabId="4">
                  <SupportPage  data={List} />
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WorkerReportContent;
