import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`
const SUPPORT_URL = `${API_URL}/support`

const getworkerSupport= async (id:any) => {
  const res = await axios.post(`${SUPPORT_URL}/WorkerView/SupportIndex/${id}`, )
  return res.data;
} 
const getEmployee= async () => {
  const res = await axios.get(`${REPORT_URL}/getEmployeedropdown`, )
  return res;
} 
const getEmployeeDetails= async (id:any,WorkerId:any) => {
  const res = await axios.get(`${REPORT_URL}/worker/individualReport/${id}/${WorkerId}`, )
  return res;
} 
const getClinic= async () => {
  const res = await axios.get(`${REPORT_URL}/getClinicdropdown`, )
  return res;
} 
const claimTreatements = async (id:any) => {
  const res = await axios.get(`${CLAIM_URL}/claimTreatements/${id}`, )
 // 
  return res;
}

const getReportforClinic = async (CID:any,date:any,portal:any) => {
  const res = await axios.post(`${REPORT_URL}/getClinicClaimReport/${CID}`,{date,portal}, )
  return res;
} 
export {
  getworkerSupport, getReportforClinic,getClinic,claimTreatements,getEmployee,getEmployeeDetails
}
