import { getClinicsById } from 'pages/Clinics/_requests';
import { getWorkerById } from 'pages/Workers/_requests';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

const PlanUpgrade = () => {
   const { id } = useParams();
   const navigate = useNavigate()
   const [clinic, setclinic] = useState<any>([]);
   useEffect(() => {
    getClinicsById(id).then((res: any) => { 
        setclinic(res)          
     });
   }, [])
   

    return (
        <React.Fragment>
             
            <Card>
               <CardBody>
               <CardHeader>
               <Row className="align-items-center">
                    <Col xl={6}>
                        <h5 className="card-title mb-0">Bank Details</h5>
                        </Col>
                </Row>
                </CardHeader><br></br>
                        <div className="table-responsive table-card">
                            <Table className="table-borderless align-middle mb-0">
                                <tbody>
                                    <tr>
                                        <td className="fw-medium col-4">Account Number</td>
                                        <td>{clinic?.account_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Account Holder's Name</td>
                                        <td>{clinic?.accountholder_name}</td>
                                        </tr>
                                    <tr>
                                        <td className="fw-medium">Bank Name</td>
                                        <td>{clinic?.bankname}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
          
        </React.Fragment>
    );
};

export default PlanUpgrade;