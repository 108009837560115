import React, { useEffect, useState, useMemo } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Input,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClinicPayment } from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import InvoiceFilter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

const PortalPaymentList = () => {
  const history = useNavigate();
  const [invoice, SetInvoice] = useState<any>([]);
  const { userProfile } = useProfile();
  const clinicId = userProfile?.primaryID;
  const [Filter, setFilter] = useState<boolean>(false);
  const [PortalClinicAccess, setportalclinicAccess] = useState<any>([]);

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 3) {
      Data = 26;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setportalclinicAccess(res.Data[0]);
    });
  }, []);

  const addPortal = () => {
    if (PortalClinicAccess?.add === 1) {
      history("/portal-clinic-payment/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const viewPortal = (cellProps: any) => {
    setportalclinicAccess((prevDepAccess: any) => {
      if (prevDepAccess?.view === 1) {
        history(
          `/portal-clinic-payment/view/${cellProps.row.original.primaryId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return;
    });
  };

  const toggleFilter = () => {
    setFilter(!Filter);
  };

  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchInvoice();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchInvoice(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  const fetchInvoice = (filters?: any) => {
    getClinicPayment(clinicId, filters).then((res: any) => {
      SetInvoice(res);
    });
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  //Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "DATE",
        accessorKey: "invoiceDueDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "REFERENCE",
        accessorKey: "invoiceNumber",
        enableColumnFilter: false,
      },

      {
        header: "AMOUNT ( RM )",
        accessorKey: "total",
        enableColumnFilter: false,
      },

      {
        header: "STATUS",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
            case 1:
              return (
                <span className="badge border border-success text-success">
                  Payment Submitted
                </span>
              );
            default:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
          }
        },
      },
      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewPortal(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              
              {/* <li className="list-inline-item" title="Remove">
              <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  const worker = cellProps.row.original;
                  // prepareDelete(worker);
                }}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
            </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title =
    "Invoice List | Al khairi care - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Clinic Payments" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Invoice List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <div className="flex-shrink-0">
                          <div className="d-flex gap-2 flex-wrap">
                          { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                            <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={toggleFilter}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Fliter
                            </button>{" "}
                            <div
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={() => {
                                addPortal();
                              }}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              Create
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={invoice || []}
                      customPageSize={10}
                      isGlobalFilter={true}
                      SearchPlaceholder={"search invoice"}
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <InvoiceFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default PortalPaymentList;
