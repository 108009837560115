import { widgetCountClient } from 'pages/Admin Dashboard/Dashboard/_request';
import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Card, CardBody, Col } from 'reactstrap';

const Widgets = () => {
    const [count, setCount] = useState<any>([]);
    useEffect(() => {
        widgetCountClient().then((res: any) => {      
          setCount(res.data)   
        });
      }, [])

    const AgencyWidgets = [
        {
            id: 1,
            title: "Total Workers",
            subtitle:"Total number of active  Workers",
            counter: count?.totalEmployeeCount,
            arrowIcon: "ri-arrow-up-line",
            percentage: "17.32 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
            color: "primary"
        },
        {
            id: 2,
            title: "Total Agencys",
            subtitle:"Total number of active Agencys",
            counter: count?.totalagencysCount,
            arrowIcon: "ri-arrow-down-line",
            percentage: "0.96 %",
            percentageClass: "danger",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
            color: "warning"
        },
        {
            id: 3,
            title: "Total Invoices Sent",
            subtitle:"Total number of invoices sent",
            counter: count?.agencysentCount,
            arrowIcon: "ri-arrow-down-line",
            percentage: "3.87 %",
            percentageClass: "",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
            color: "secondary"
        },
        {
            id: 4,
            title: "Total Payments Received",
            subtitle:"Estimated total payments Received",
            counter: count?.clientReceiveCount,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
            color: "danger"
        },
    ];
    return (
        <React.Fragment>
            {(AgencyWidgets || []).map((item, key) => (
                <Col xl={3} sm={12} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                <p className="fw-bold text-muted mb-2">{item.title}</p>
                                    <p className="fw-medium text-muted mb-3">{item.subtitle}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value" data-target="547">
                                            <CountUp
                                                start={0}
                                                end={item.counter}
                                                duration={3}
                                                suffix={item.suffix}
                                                prefix={item.prefix}
                                                decimals={item.decimals}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-" + item.percentageClass + "-subtle text-" + item.percentageClass + " mb-0"}>
                                        {/* <i className={item.arrowIcon + " align-middle"}></i> {item.percentage} */}
                                    </span></p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                                            <i className={item.icon}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default Widgets;