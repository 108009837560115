import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`



const getCompany = async () => {
  const res = await axios.get(`${REPORT_URL}/getCompanydropdown`,  )
  return res;
} 
const getClaimListToAddCharges = async (id:any,year:any,month:any) => {
  const res = await axios.get(`${CLAIM_URL}/getClaimList/${id}/${year}/${month}`, )
  return res;
}
const AddCharges = async (data:any) => {
  const res = await axios.post(`${CLAIM_URL}/mockup/add/OtherCharges`,{data}, )
  return res;
}
export {
  getCompany,getClaimListToAddCharges,AddCharges
}
