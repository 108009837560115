import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Widgets from "./Widgets";
import { Link, useNavigate } from "react-router-dom";
import WorkerData from "./WorkerData";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCompanyById } from "./_requests";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AgencyDashboard = () => {
  const [agency, setCom] = useState<any>([]);
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [workeraccess, setworkeraccess] = useState<any>([]);
  const [departmentaccess, setdepartmentaccess] = useState<any>([]);

  document.title = "Agency Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 11;
    } else if (Type === 2) {
      Data = 31;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setworkeraccess(res.Data[0]);
    });
  }, []);

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 2;
    } else if (Type === 2) {
      Data = 32;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setdepartmentaccess(res.Data[0]);
    });
  }, []);

  useEffect(() => {
    getCompanyById(userProfile?.primaryID).then((res: any) => {
      setCom(res);
    });
  }, []);

 

  const addWorkers = () => {
    if (workeraccess?.add === 1) {
      history("/workers/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const listDepartment = () => {
    if (departmentaccess?.list === 1) {
      history("/department");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const listWorker = () => {
    if (workeraccess?.list === 1) {
      history("/workers");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer closeButton={false} limit={1} />
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Agency Dashboard</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <div
                      onClick={addWorkers}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>
                      New Worker
                    </div>
                    {/* <Link to="#" className="btn btn-primary fw-semibold me-2 ">Worker Count</Link> */}
                    <div
                      onClick={listDepartment}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>
                      Manage Department
                    </div>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody className="p-0">
                  <Row className="align-items-end">
                    <Col>
                      <div className="p-3 ">
                        <div className="mt-3">
                          <p className="fw-bold text-muted text-hover-primary fs-4">
                            {"Welcome to " + agency?.name}
                          </p>
                        </div>
                        <div className="fw-bold text-primary my-6">{time}</div>
                        <div className="mt-3 text-dark-75 fw-semibold fs-5 m-0">
                          <p className="fs-16 lh-base m-0">
                            Manage and configure Agency
                          </p>
                          <p className="fs-16 lh-base m-0">settings here</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="position-relative mb-1">
                    <div className="mt-3">
                      <p className="fw-bold  text-hover-primary fs-4">
                        Add or Check
                        <a
                          onClick={addWorkers}
                          className=" text-underline fw-bold fs-5 mx-2"
                        >
                          new Worker
                        </a>
                        here
                      </p>
                    </div>
                    <p className="  text-dark-75 fw-bold fs-5">
                      This is the master agency datas.
                    </p>
                    <div className="mt-1  fw-semibold fs-5">
                      <div
                        onClick={addWorkers}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Add new Worker
                      </div>
                      <div
                        onClick={listWorker}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        List of Worker
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Widgets />
          </Row>
          <Row>
            <Col xl={12}>
              <WorkerData />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyDashboard;
