import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

const SUPPORT_URL = `${API_URL}/support`

const getClinicSupport = (filters:any,id:any) => {
  return axios
    .post(`${SUPPORT_URL}/portalclinicIndex/${id}`,filters)
    .then((d:any) => {
      return d.Data
    })
} 

const createClinicSupport = (clinic: any) => {
  return axios
    .post(`${SUPPORT_URL}/addClilnicSupport`, clinic)
    .then((response:any) => {
      return response.Data
     })
   
}

const getpriority = () => {
  return axios
    .get(`${SUPPORT_URL}/getsupportPriority`)
    .then((res) => res)
    .then((res) => {
      return res; // Return the data to pass it along in the promise chain
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error; // Re-throw the error to propagate it down the promise chain
    });
};

const createAgencySupport = (clinic: any) => {
  return axios
    .post(`${SUPPORT_URL}/addagencysupport`, clinic)
    .then((response) => {
    
      
      return response.data
     })
   
}
const viewclinicSupport = (primaryId:any) => {
  return axios
    .get(`${SUPPORT_URL}/viewClinicSupport/${primaryId}`)
    .then((response:any) => {
     
      return response.Data
     })
   
}
const updateclinicSupport = (data: any,id:any) => {
  return axios
    .post(`${SUPPORT_URL}/editClinicSupport/${id}`,data)
    .then((response) => {
      return response.data
     })
   
}
const deleteclinicSupport = (id:any) => {

  return axios
    .get(`${SUPPORT_URL}/deleteClinicSupport/${id}`)
    .then((response) => {
      return response.data
     })
   
}


const updateReply = (data:any) => {
  return axios
    .post(`${SUPPORT_URL}/ReplyClinicSupport`,data)
    .then((response) => {
      return response.data
     })
   
}

const getreplyList = (id:any) => {
  return axios
    .get(`${SUPPORT_URL}/ListReplyClinicSupport/${id}`)
    .then((response:any) => {
      return response.Data
     })
   
}

const getSupportStatus = () => {
  return axios
    .get(`${SUPPORT_URL}/getsupportStatus`)
    .then((response:any) => {
      
      return response.Data
     })
}

const updateclinicStatus = (statusId:any,statusNotes:any,id:any) => {
  return axios
    .post(`${SUPPORT_URL}/updateClinicStatus/${id}`,{statusId,statusNotes})
    .then((response) => {
      return response.data
     })
   
}


export {
  getClinicSupport,
  getpriority,
  createAgencySupport,
  viewclinicSupport,
  updateclinicSupport,
  deleteclinicSupport,
  updateReply,
  getreplyList,
  getSupportStatus,
  updateclinicStatus,
  createClinicSupport



}
