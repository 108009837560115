import React, { useEffect, useState } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ListRole, createRole, deleteRoleById, updateRole } from "../../Roles/_requests";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createroleaccess,
  getmodules,
  getroleaccess,
} from "../../Roles/_requests-roleaccess";

interface Roles {
  id: string;
  roleName: string;
}
const RolesTab = () => {
  const history = useNavigate();
  const {id}=useParams();
  const TypeId = id;
  const Type = 2;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [role, setRole] = useState<Roles[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [permissionModalOpen, setPermissionModalOpen] =
    useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<any>("");
  const [btnDisable, setbtnDisable] = useState(true);
  const [roleid, setRoleId] = useState("");
  const [module, setModule] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch: any = useDispatch();




  const handleCheckboxChange = (roleid: any, column: any) => {


    const updatedModules = module.map((module: any) => {


      if (module.id === roleid) {
        return {
          ...module,
          [column]: !module[column],
        };
      }
      return module;
    });

    setModule(updatedModules);
    const anyChecked = updatedModules.some((checkbox: any) => checkbox.add);
    const listChecked = updatedModules.some((checkbox: any) => checkbox.list);
    const viewChecked = updatedModules.some((checkbox: any) => checkbox.view);
    const editChecked = updatedModules.some((checkbox: any) => checkbox.edit);
    const deleteChecked = updatedModules.some(
      (checkbox: any) => checkbox.remove
    );
    var checkeffect = false;
    if (
      anyChecked === true ||
      listChecked === true ||
      viewChecked === true ||
      editChecked === true ||
      deleteChecked === true
    ) {
      checkeffect = true;
    } else {
      checkeffect = false;
    }
    checkvalidation(roleid, checkeffect);

  };
  function checkvalidation(roleid: any, checkeffect: any) {
    //
    if (roleid !== "" && checkeffect !== false) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }
  const handleSelectAll = (column: string) => {
    setModule(
      module.map((module: any) => ({
        ...module,
        [column]: !module[column],
      }))
    );
    callValidator();
  };
  const callValidator = () => {
    const anyChecked = module.some((checkbox: any) => checkbox.add);
    const listChecked = module.some((checkbox: any) => checkbox.list);
    const viewChecked = module.some((checkbox: any) => checkbox.view);
    const editChecked = module.some((checkbox: any) => checkbox.edit);
    const deleteChecked = module.some((checkbox: any) => checkbox.remove);
    let checkeffect;
    if (
      anyChecked === true ||
      listChecked === true ||
      viewChecked === true ||
      editChecked === true ||
      deleteChecked === true
    ) {
       checkeffect = false;
    } else {
       checkeffect = true;
    }
    checkvalidation(roleid, checkeffect);
  };
 
  const handlerole = async (role: any) => {
    const RoleId = role?.id; 
    setRoleId(RoleId);
    
    togglePermissionModal();
    getroleaccess(RoleId, Type).then((res: any) => {
      if (res.Data.length > 0) {
        setModule(res.Data);

      } else {
        getmodules(Type).then((res: any) => {
          const updatedModule = res.Data.map((obj: any) => ({
            ...obj,
            add: false,
            list: false,
            view: false,
            edit: false,
            remove: false,
          }));

          setModule(updatedModule);
        });
      }
    });
  };
  
  const toggle = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };
  const togglePermissionModal = () => {
    setPermissionModalOpen(!permissionModalOpen);
  };

  const prepareDelete = (departmentId: any, departmentName: any) => {
    setRoleToDeleteId(departmentId);
    setRoleToDeleteName(departmentName);
    toggleDeleteModal();
  };

  const confirmDelete = (departmentId: any) => {
    deleteRoleById(departmentId)
      .then(() => {
        toggleDeleteModal();
        FetchData();
        toast.success("Roles deleted Successfully", { autoClose: 3000 });
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    FetchData();
  }, []);

  const FetchData = () => {
    ListRole(Type, TypeId).then((res: Roles[]) => {
      setRole(res);
    });
  };

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Role is required"),
    }),
    onSubmit: (values: any) => {
      const id =
        values.id && typeof values.id === "object" ? values.id.id : values.id;
      if (isEdit) {
        const updateRoles = {
          name: values.name,
        };
     

        updateRole(updateRoles, id).then(() => {
          FetchData();
          toast.success("Role updated successfully", { autoClose: 3000 });
          toggle();
          validation.resetForm();
        });
      } else {
        const newRole = {
          name: values.name,
          type: Type,
          typeId: TypeId,
          createdBy: TypeId,
        };
        createRole(newRole)
          .then(() => {
            FetchData();
            toast.success("Role created Successfully", { autoClose: 3000 });
            toggle();
            validation.resetForm();
          })
          .catch((error) => {
            console.error("Error creating Role:", error);
          });
      }
    },
  });

  const handleSubmit = () => {
    //
    const SubmitupdatedModule = module.map((obj: any) => ({
      ...obj,
      moduleId: obj.id,
      status: "1",
      roleId: roleid,
    }));
    
    function findObjectsWithValue1(SubmitupdatedModule: any) {
      const result = [];
      for (let i = 0; i < SubmitupdatedModule.length; i++) {
        const obj = SubmitupdatedModule[i];
        for (let prop in obj) {
          if (
            obj["add"] === 1 ||
            obj["add"] === true ||
            obj["list"] === 1 ||
            obj["list"] === true ||
            obj["view"] === 1 ||
            obj["view"] === true ||
            obj["edit"] === 1 ||
            obj["edit"] === true ||
            obj["remove"] === 1 ||
            obj["remove"] === true
          ) {
            result.push(obj);
            break;
          }
        }
      }
      return result;
    }
    const objectsToInsert = findObjectsWithValue1(SubmitupdatedModule);
    const updatedResponse = objectsToInsert.map((item: any) => {
      const { name, icon, sbtitle, link, id, ...rest } = item;
      return rest;
    });
    //
    createroleaccess(updatedResponse).then((res) => {
  
      history("/role");
      togglePermissionModal();
    });
  };

  document.title = "Role | Alkhairi care";

  const filteredRole = role.filter((item) =>
    item.roleName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <React.Fragment>

      
          <Card>
            <CardBody>
              <Row className="g-3">
                <div className="col-lg-3 col-auto">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      id="search-task-options"
                      placeholder="Search Role..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    {/* <button
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                        validation.setValues({ name: "" });
                      }}
                    >
                      <i className="ri-add-fill align-bottom me-1"></i> Add
                      Roles
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Row className="mt-4">
            {filteredRole.length === 0 ? (
              <div className="text-center">No search data found</div>
            ) : (
              filteredRole.map((role: any, key: any) => (
                <React.Fragment key={key}>
                
                  <Col xl={3} lg={6}>
                    <Card className="card card-height-100  shadow-none bg-opacity-10">
                      <CardBody className="p-4 ">
                        {/* <UncontrolledDropdown className="float-end">
                          <DropdownToggle
                            tag="a"
                            className="text-reset dropdown-btn"
                            href="#"
                          >
                            <span className="text-muted fs-18">
                              <i className="mdi mdi-dots-vertical mt-2"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end" end>
                            <DropdownItem
                              onClick={() => {
                                setIsEdit(true);
                                toggle();
                                validation.setValues({
                                  name: role.roleName,
                                  id: role.id,
                                }); // Set initial values for form fields
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                prepareDelete(role.id, role.roleName)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                        <h5 className="m-2 text start">
                          <i className=" ri-account-circle-fill me-3"></i>{" "}
                          {role.roleName}
                        </h5>

                  
                      
                    
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))
            )}
          </Row>
      
        <ToastContainer closeButton={false} limit={1} />
   

      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {!!isEdit ? "Edit Role" : "Create Role"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Role Name
              </label>
              <Input
                type="text"
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter role name"
                name="name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setIsEdit(false);
                setModal(false);
                validation.resetForm();
              }}
            >
              Close
            </Button>
            <Button type="submit" color="success">
              <i className="ri-save-line align-bottom me-1"></i>{" "}
              {!!isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the role{" "}
          <strong>{RoleToDeleteName}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              confirmDelete(RoleToDeleteId);
              setModal(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={permissionModalOpen}
        toggle={togglePermissionModal}
        centered
        size="xl"
      >
        <ModalHeader toggle={togglePermissionModal}>
          Roles Permission
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="min-w-50px">NO</th>
                  <th className="min-w-340px">MODULE NAME</th>
                  <th className="min-w-100px">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="selectAllAdd"
                        onChange={() => handleSelectAll("add")}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="selectAllAdd"
                      >
                        ADD
                      </label>
                    </div>
                  </th>
                  <th className="min-w-100px">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="selectAllList"
                        onChange={() => handleSelectAll("list")}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="selectAllList"
                      >
                        LIST
                      </label>
                    </div>
                  </th>
                  <th className="min-w-100px">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="selectAllView"
                        onChange={() => handleSelectAll("view")}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="selectAllView"
                      >
                        VIEW
                      </label>
                    </div>
                  </th>
                  <th className="min-w-100px">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="selectAllEdit"
                        onChange={() => handleSelectAll("edit")}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="selectAllEdit"
                      >
                        EDIT
                      </label>
                    </div>
                  </th>
                  <th className="min-w-100px">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="selectAllDelete"
                        onChange={() => handleSelectAll("remove")}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="selectAllDelete"
                      >
                        DELETE
                      </label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {module.map((item: any, i: any) => (
                  <tr className="fw-bold text-muted" key={item.id}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={item.add}
                          onChange={() => handleCheckboxChange(item.id, "add")}
                          className="form-check-input"
                          id={`checkboxAdd${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkboxAdd${i}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={item.list}
                          onChange={() => handleCheckboxChange(item.id, "list")}
                          className="form-check-input"
                          id={`checkboxList${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkboxList${i}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={item.view}
                          onChange={() => handleCheckboxChange(item.id, "view")}
                          className="form-check-input"
                          id={`checkboxView${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkboxView${i}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={item.edit}
                          onChange={() => handleCheckboxChange(item.id, "edit")}
                          className="form-check-input"
                          id={`checkboxEdit${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkboxEdit${i}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={item.remove}
                          onChange={() =>
                            handleCheckboxChange(item.id, "remove")
                          }
                          className="form-check-input"
                          id={`checkboxRemove${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkboxRemove${i}`}
                        ></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#28a8df", borderColor: "#28a8df" }}
            className="btn text-white w-25"
            onClick={togglePermissionModal}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#28a8df", borderColor: "#28a8df" }}
            className="btn text-white w-25"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default RolesTab;
