import axios from 'axios'
import { } from 'pages/Clinics/_requests'

const API_URL = process.env.REACT_APP_API_URL
const Clinics_URL = `${API_URL}/clinic`
const Clinics_Support_URL = `${API_URL}/support`
const Clinics_Role_URL = `${API_URL}/roles`
const Clinics_Payment_URL = `${API_URL}/clinicPayment`
const Clinics_Claimform_URL = `${API_URL}/claimform/portalClaimlist`
const SOB_URL = `${API_URL}/clinic/getclinic`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const GET_PLAN_LIST = `${API_URL}/clinic`

//
const getClinics = (filter:any)=> {
  return axios
    .post(`${Clinics_URL}/list`,filter)
    .then((d: any) => {
      return d.Data;
    })
}
const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${Clinics_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      return response.data
     })
   
}
const getClinicsById = (id:any) => {
  return axios
    .get(`${Clinics_URL}/view/${id}`) 
    .then((response: any) =>{
      return response.Data;
    }
   )
}
const getSupportClinic = (primaryId:any)=> {
  return axios
    .post(`${Clinics_Support_URL}/clinicsupportlist/${primaryId}`)
    .then((response: any) => {
      return response.Data;
    })
}

const getClaimlistClinic = (primaryId:any)=> {
  return axios
    .post(`${Clinics_Claimform_URL}/${primaryId}`)
    .then((response: any) => {
      return response.Data;
    })
}

const getPaymentClinic = (primaryId:any)=> {
  return axios
    .post(`${Clinics_Payment_URL}/getdata/${primaryId}`)
    .then((res: any) => {
      return res.Data;
    })
}


const getRoleClinic = () => {
  return axios
    .get(`${Clinics_Role_URL}/list`)
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
};




const getCountry = async () => {
  const res = await axios.get(`${COUNTRY_URL}`);
  return res;
}

const updateClinicsById = (data:any,ID:any) => {
  return axios
  .put(`${Clinics_URL}/${ID}`, data)
  .then((response) => {
    return response.data
   })
   
}



const getEditClinicById = (data:any,id:any) => {
  return axios
    .put(`${Clinics_URL}/edit/${id}`, data) 
    .then((response:any) =>{
      return response;
    }
   )
}

const updateClinic = (clinicId:any) => {
  return axios
    .put(`${Clinics_URL}/${clinicId}`)
    .then((response) => {
      return response.data
     })
   
}


const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${API_URL}/clinicbulkInsert/upload`,excel,{
      headers: {
        "Content-Type": "multipart/form-data",
      },})
    .then((response) => {
   return response;
    
})}



const getAgency = async ()=> {
  const res = await axios.get(`${Clinics_URL}/getagency`);
  //
  return res;
}
const getDepartment = async (clientID:any) => {
  //
  const res = await axios.get(`${Clinics_URL}/getDepartment/${clientID}`);
  //
  return res;
}
const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${Clinics_URL}/admingetDepartment`);
  //
  return res;
}
const createClinics = (data: any) => {
  //
  return axios
  .post(`${Clinics_URL}/add`, data)
  .then((response:any) => {
  //  
    return response
   })
}



const getSubSob = async () => {
  const res = await axios.get(`${SOB_URL}`);
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
  return res;
}

const deleteClinicsById = (id: any) => {
  return axios
    .post(`${Clinics_URL}/delete/${id}`,)
    .then((response) => {
    return response;
    }
    )
}

const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${Clinics_URL}/activateDeactivateAccount/${id}/${status}`)
    .then((response) => {

    return response.data;
    }
    )
}
const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/${id}`);
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid});
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data);
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data);
  //
   return res.data;
 }
 const cronJob=async()=>{ 
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`);
  //
   return res.data;
 }

 const getCity = async () => {
  const res = await axios.get(`${Clinics_URL}/fliter/clinicCity`,);
  return res;
}
const getState = async () => {
  const res = await axios.get(`${Clinics_URL}/fliter/clinicState`, );
  return res;
}



export {
  getState,getCity,
  getClinics,getEditClinicById,
  getSupportClinic,
  getClaimlistClinic,
  getPaymentClinic,
  getRoleClinic,
  deleteClinicsById,
  getSubSob,updatecontrols,getsobdetails, 
  createClinics,getClinicsById,
  getAgency, getDepartment,getCountry,updatebulkinsert,updateClinicsById,
  admingetDepartment,updateActiveDeactive,getPlan,upgradePlan,renewalPlan,cronJob,
  updateClinic,getReportMonthlyCount
}
