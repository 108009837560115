import axios from 'axios'
 


const API_URL = process.env.REACT_APP_API_URL
const WIDGET_URL = `${API_URL}/dashboard/widgetCount`
const WIDGET_SOB_URL = `${API_URL}/dashboard/widgetCountSOB`
const TABLE_URL = `${API_URL}/dashboard/recentClinics`
const SUBSOB_URL = `${API_URL}/dashboard/recentSubsob`
const CLIENT_WIDGET_URL = `${API_URL}/dashboard/widgetCountClient`
const CLIENT_TABLE_URL = `${API_URL}/dashboard/recentClient`
const CLIENT_R_ETABLE_URL = `${API_URL}/dashboard/portal`
const IH_TABLE_URL = `${API_URL}/dashboard/recentEmployees`
const CLAIM_URL = `${API_URL}/dashboard/recentClailforms`
const CLINIC_V_URL = `${API_URL}/dashboard/Clientportalvisitors`
const C_E_URL = `${API_URL}/dashboard/employeePortalCount`
const C_COMON_URL = `${API_URL}/dashboard`

const widgetCount = async () => {
  const result = await axios.get(`${WIDGET_URL}`, );
 
  return result;
}
const recentClinics = async () => {
  const result = await axios.get(`${TABLE_URL}`, );

  return result;
 
}

const recentSubsob = async () => {
  const result = await axios.get(`${SUBSOB_URL}`, );

  return result;
}
const widgetCountClient = async (): Promise<any> => {
  //debugger
  const result = await axios.get(`${CLIENT_WIDGET_URL}`, ); 
  
  return result;
}
const widgetCountSOB = async (): Promise<any> => {
  const result = await axios.get(`${WIDGET_SOB_URL}`, );
  
  return result;
}
const recentClient = async (): Promise<any> => {
  const result = await axios.get(`${CLIENT_TABLE_URL}`, );
  
  return result;
 
}
const clientPortalrecentemployee = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${CLIENT_R_ETABLE_URL}/${clientID}`, );

  return result;
 
}
const recentWorker= async (): Promise<any> => {
  const result = await axios.get(`${IH_TABLE_URL}`,);
  return result;
 
}
// clinic portal
const recentClaims = async (clinicId:any): Promise<any> => {
  const result = await axios.get(`${CLAIM_URL}/${clinicId}`, );
 
  return result;
 
}
const clinicportalvisitor = async (clinicId:any): Promise<any> => {
  const result = await axios.get(`${CLINIC_V_URL}/${clinicId}`, );
 
  return result;
}
 
const clientportalEmpCount = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${C_E_URL}/${clientID}`, );
  
  return result;
}
const adminrecentClailforms= async (): Promise<any> => {
  const result = await axios.get(`${C_COMON_URL}/adminrecentClailforms`, );
  
  return result;
}
const getsupportwidget= async () => {
  const result = await axios.get(`${API_URL}/support/widget`, );
  
  return result;
}
const getsupportrecentagencys= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportAgencys`, );
 
  return result;
}
const recentSupportClinic= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportClinic`, );
  
  return result;
}
const recentSupportWorker= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportEmployeee`, );
 
  return result;
}
const vistGraph= async () => {
  const result = await axios.get(`${C_COMON_URL}/visitgraph`, );
  
  return result;
}
const PaymentSentGraphs= async () => {
  const result = await axios.get(`${C_COMON_URL}/paymentsentgraph`, );
 
  return result;
}
const PaymentReceiveGraphs= async () => {
  const result = await axios.get(`${C_COMON_URL}/paymentreceivegraph`, );
  
  return result;
}
const ClaimApprovedGraph= async () => {
  const result = await axios.get(`${C_COMON_URL}/approvedClaimgraph`, );
  
  return result;
}
export { widgetCount, recentClinics,recentSupportClinic,recentSupportWorker,getsupportrecentagencys,
  recentSubsob,getsupportwidget,
  widgetCountClient,vistGraph,ClaimApprovedGraph,PaymentReceiveGraphs,PaymentSentGraphs,
  recentClient,clientPortalrecentemployee,clientportalEmpCount,adminrecentClailforms,
  recentWorker,widgetCountSOB,recentClaims,clinicportalvisitor}