import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { getSupportStatus, getpriority } from "../_requests";

interface CrmFilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any , Reset :any) => void; // Define onFilterApply in props interface
}

const CrmFilter: React.FC<CrmFilterProps> = ({ show, onCloseClick, onFilterApply}) => {
  const [supportpriorityId, setSupportPriorityId] = useState<{ value: string | number, label: string }>({ value: 0, label: 'All' });
  const [SupportStatusId, setSupportStatusId] = useState<{ value: string | number, label: string }>({ value: 0, label: 'All' });
  const [support, setSupport] = useState<any>([]);
  const [Status, setSupportStatus] = useState<any>([]);

 
  
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
 
  
  
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };
  
  const handleEndDateChange = (date: Date | null | [Date, Date]) => {
    if (Array.isArray(date)) {
      // Handle date range selection
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else {
      // Handle single date selection
      setEndDate(date);
    }
  };
 
const listPage=false
  useEffect(() => {
    const fetchAgencySupport = async () => {
      try {
       await getpriority().then((res:any)=>{
          const agencyOptions = res.Data.map((support: any) => ({
            value: support.id,
            label: support.name,
          }));
          const allOption = { value: 0, label: 'All' };
          setSupport([allOption, ...agencyOptions]);
        })
        
       
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchAgencySupport();
  }, []);

  useEffect(() => {
    const fetchAgencySupport = async () => {
      try {
        const response = await getSupportStatus();
        const agencyOptions = response.map((support: any) => ({
          value: support.id,
          label: support.name,
        }));
        const allOption = { value: 0, label: 'All' };
        setSupportStatus([allOption, ...agencyOptions]);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchAgencySupport();
  }, []);


  const applyFilters = () => {
  
    const filters = {
      supportpriorityId: supportpriorityId?.value ,
      supportstatusId: SupportStatusId?.value ,
      fromDate: startDate ? startDate.toISOString() : "",
      toDate: endDate ? endDate.toISOString() : "",
    };
  
    
    const Reset =true;
    onFilterApply(filters, Reset); // Use onFilterApply from props to pass filters to parent component or API call
    onCloseClick();
  };

  const clearFilters = () => {
    const filters={}
    const Reset =false;
    onFilterApply(filters, Reset);  
     setSupportPriorityId({ value: 0, label: 'All' }); // Reset support priority selection
     setSupportStatusId({ value: 0, label: 'All' }); // Reset support status selection
     setStartDate(null); // Reset start date
     setEndDate(null); // Reset end date
     onCloseClick(); // Close the filter panel
   };




  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Support Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
        <div className="mb-4">
            <Label
              htmlFor="datepicker-range"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Date
            </Label>
            <Flatpickr
                className="form-control"
                id="Daterange"
                placeholder="Select a date"
                value={[startDate as Date, endDate as Date]}
                onChange={(selectedDates) => {
                  if (Array.isArray(selectedDates) && selectedDates.length === 2) {
                    setStartDate(selectedDates[0] as Date);
                    setEndDate(selectedDates[1] as Date);
                  }
                }}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                mode: "range",
                dateFormat: "d/m/Y",
              }}
            />
         
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Support Priority
            </Label>
            <Select
              className="mb-0"
              value={supportpriorityId}
              onChange={(selectedOption: any) => setSupportPriorityId(selectedOption)}
              options={support}
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Support Status
            </Label>

            <Select
              className="mb-0"
              value={SupportStatusId}
              onChange={(selectedOption: any) => setSupportStatusId(selectedOption)}
              options={Status}
            ></Select>
          </div>
          
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
             type="button"
            className="btn btn-light w-100"
            onClick={clearFilters}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default CrmFilter;
