import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Billing_URL = `${API_URL}/billing`




const createBilling = (Billing: any) => {
  return axios
    .post(`${Billing_URL}/add`, Billing)
    .then((d) => d.data)
}
const ListBilling = () => {
  return axios
    .get(`${Billing_URL}/list`)
    .then((d:any) => d.Data)
}

const deleteBillingById = (id: any) => {
  return axios
    .post(`${Billing_URL}/delete/${id}`)
    .then((response) => {
    return response.data;
    }
    )
}

const updateBillingById = (updateBilling:any,id: any) => {
  return axios
    .put(`${Billing_URL}/edit/${id}`, updateBilling)
    .then((response) => {
    return response.data;
    }
    )
}


export {createBilling, deleteBillingById,updateBillingById,ListBilling}
