import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Agency_URL = `${API_URL}/agency`
const CLIENT_R_ETABLE_URL = `${API_URL}/dashboard/portal`
const C_E_URL = `${API_URL}/dashboard/employeePortalCount`
const SOB_URL = `${API_URL}/plan/getplan`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
const GET_PLAN_LIST = `${API_URL}/plan`


const getCompanyById = (id:any) => {
  return axios
    .get(`${Agency_URL}/view/${id}`) 
    .then((response:any) =>{
    return response.Data;
    }
   )
}

const clientportalEmpCount = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${C_E_URL}/${clientID}`, );
  return result;
}

const clientPortalrecentemployee = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${CLIENT_R_ETABLE_URL}/${clientID}`, );
  return result;
}


export {
  getCompanyById,
  clientportalEmpCount,
  clientPortalrecentemployee,
}
