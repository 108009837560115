import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Widgets from "./Widgets";
import { Link, useNavigate } from "react-router-dom";
import AgencyData from "./AgencyData";
import WorkerData from "./WorkerData";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { toast } from "react-toastify";

const AgencyOverview = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [workeraccess, setworkeraccess] = useState<any>([]);
  const [agencyaccess, setagencyaccess] = useState<any>([]);

  document.title = "Agency Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    getCRUD(userProfile?.role, 11).then((res: any) => {
      setworkeraccess(res?.Data[0]);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 10).then((res: any) => {
      setagencyaccess(res.Data[0]);
    });
  }, []);

  const addWorker = () => {
    if (workeraccess?.add === 1) {
      navigate("/workers/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const addAgency = () => {
    if (agencyaccess?.add === 1) {
      navigate("/agency/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
};

const listAgency = () => {
    if (agencyaccess?.list === 1) {
        navigate("/agency");
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Agency Overview</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <div
                      onClick={addWorker}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      Add Worker
                    </div>
                    <Link to="/workercount-report"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                      Worker Count
                    </Link>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody className="p-0">
                  <Row className="align-items-end">
                    <Col>
                      <div className="p-3 ">
                        <div className="mt-3">
                          <p className="fw-bold text-muted text-hover-primary fs-4">
                            Welcome to Agencys
                          </p>
                        </div>
                        <div className="fw-bold text-primary my-6">{time}</div>
                        <div className="mt-3 text-dark-75 fw-semibold fs-5 m-0">
                          <p className="fs-16 lh-base m-0">
                            Manage and configure Agencys
                          </p>
                          <p className="fs-16 lh-base m-0">
                            based settings here
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="position-relative">
                    <div className="mt-3">
                      <p className="fw-bold  text-hover-primary fs-4">
                        Add or Check
                        <a
                          onClick={addAgency}
                          className=" text-underline fw-bold fs-5 mx-3 me-3"
                        >
                          new Agency
                        </a>
                        here
                      </p>
                    </div>
                    <p className="  text-dark-75 fw-bold fs-5">
                      This is the master agency datas.
                    </p>
                    <div className="mt-1  fw-semibold fs-5">
                      <div
                        onClick={addAgency}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Add new Agency
                      </div>
                      <div
                        onClick={listAgency}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        List of Agency
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Widgets />
          </Row>
          <Row>
            <Col xl={12}>
              <AgencyData />
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <WorkerData />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyOverview;
