import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const PLAN_URL = `${API_URL}/plan`






const createAssign = (data: any) => {

  return axios
    .post(`${PLAN_URL}/assignPlan/addAssignedPlan`, data)
   .then((response) => response.data)
}
const getClientByPlanId = (id: any) => {
   
  return axios
    .get(`${PLAN_URL}/viewassignPlan/${id}`)
   .then((response) => {
   
    return response.data});
}
export {

  createAssign,getClientByPlanId
 
 
}
