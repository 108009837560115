import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

const WIDGET_URL = `${API_URL}/dashboard/widgetCountbenefit`
const Plan_URL = `${API_URL}/dashboard/recentPlan`

export const widgetCountPlan = async () => {
  const result = await axios.get(`${WIDGET_URL}`);
  
  return result;
}
export const recentPlan = async () => {
  const result = await axios.get(`${Plan_URL}`);

  return result;
} 