import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TableContainer from "Components/Common/TableContainer";
import {
  approveSelectedClaim,
  deleteClaimFormById,
  deleteSelectedClaim,
  listClaimForm,
  listPortalClaimForm,
  rejectSelectedClaim,
} from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import ClaimFilter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
// import {  cronJob, deleteclaimformById, getclaimform, updatebulkinsert } from "./_requests";
interface CheckedItems {
  [key: string]: boolean;
}
interface TableItem {
  id: string;
  // Other properties...
}
const ClaimFormList: React.FC = () => {
  const history = useNavigate();
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;

  const [claimform, setClaimform] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<string>("");
  const [Filter, setFilter] = useState<boolean>(false);
  const [claimformcharges, setClaimformCharges] = useState<any>([]);
  const [claimformaccess, setClaimformAccess] = useState<any>([]);

  useEffect(() => {
    getCRUD(userProfile?.role, 43).then((res: any) => {
      setClaimformCharges(res.Data[0]);
    });
  }, []);

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 6;
    } else if (Type === 3) {
      Data = 24;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setClaimformAccess(res.Data[0]);
    });
  }, []);


  const addClaimCharges = () => {
    if (claimformcharges?.add === 1) {
      history("/claimform-charges");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const addClaimForm = () => {
    if (claimformaccess?.add === 1) {
      history("/claim-form/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const toggleFilter = () => {
    setFilter(!Filter);
  };
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (claimform: any) => {
    setRoleToDeleteId(claimform.id);
    setRoleToDeleteName(claimform.patientName);

    setClaimformAccess((prevDepAccess: any) => {
      if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  const confirmDelete = (val: string) => {
    deleteClaimFormById(val, 2)
      .then(() => {
        toggleDeleteModal();
        fetchClaimForm();
        toast.success("Claim form temporarily deleted Successfully", {
          autoClose: 3000,
        });
      })
      .catch((error: any) => {
        console.error("Error deleting role:", error);
      });
  };
  const confirmPermanentDelete = (val: string) => {
    deleteClaimFormById(val, 1)
      .then(() => {
        toggleDeleteModal();
        fetchClaimForm();
        toast.success("Claim form permanently deleted Successfully", {
          autoClose: 3000,
        });
      })
      .catch((error: any) => {
        console.error("Error deleting role:", error);
      });
  };

  const viewClaim = (cellProps: any) => {
    setClaimformAccess((prevDepAccess: any) => {
      if (prevDepAccess?.view === 1) {
        history(`/claim-form/view/${cellProps.row.original.id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevDepAccess;
    });
  };

  useEffect(() => {
    fetchClaimForm();
  }, []);

  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchClaimForm();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchClaimForm(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  const fetchClaimForm = (filters?: any) => {

    try {
      if (Type === 1) {
        listClaimForm(filters).then((response) => {
          setClaimform(response);
        });
      } else {

        listPortalClaimForm(filters, Id).then((response) => {
          setClaimform(response);
        });
      }
    } catch (error) {
      console.error("Error fetching agency:", error);
    }
  };



  const [selectedCheckBox, setSelectedCheckBox] = useState<any>([]);
  const [isMultiDeleteButton, setIsMultiButton] = useState<any>(false);



  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(
      cell.row.original.createdAt
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };
  const checkedAll = () => {
    const checkall = document.getElementById(
      "checkBoxAll"
    ) as HTMLInputElement | null;
    const ele = document.querySelectorAll(".invoiceCheckBox");

    if (checkall && checkall.checked) {
      ele.forEach((ele: any) => {
        (ele as HTMLInputElement).checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        (ele as HTMLInputElement).checked = false;
      });
    }
    deleteCheckbox();
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".invoiceCheckBox:checked");
    const ids = Array.from(ele).map((checkbox) =>
      checkbox.getAttribute("data-id")
    );
    setSelectedCheckBox(ids);
    setIsMultiButton(ids.length > 0);
  };

  const deleteSelectedItems = (selected: any) => {
    // 💡 response of the mutation is passed to onSuccess

    // deleteSelectedClaim(selected)
    deleteSelectedClaim(selected).then((res: any) => {
      if (res.Code === "1") {
        toast.success(`${res.Message}`);
        history("/claim-form", { replace: true });
        const checkAllCheckbox = document.getElementById("checkBoxAll") as HTMLInputElement | null;
        if (checkAllCheckbox) {
            checkAllCheckbox.checked = false;
        }
        const ele = document.querySelectorAll(".invoiceCheckBox:checked");
        ele.forEach((checkbox: any) => {
            (checkbox as HTMLInputElement).checked = false;
        });
        setSelectedCheckBox([])
        fetchClaimForm();
      } else {
        toast.error(`${res.Message}`);
      }
    });
  };
  const approveSelectedItems = (selected: any) => {
    approveSelectedClaim(selected).then((res: any) => {
      if (res.Code === "1") {
        toast.success(`${res.Message}`);
        history("/claim-form", { replace: true });
        const checkAllCheckbox = document.getElementById("checkBoxAll") as HTMLInputElement | null;
        if (checkAllCheckbox) {
            checkAllCheckbox.checked = false;
        }
        const ele = document.querySelectorAll(".invoiceCheckBox:checked");
        ele.forEach((checkbox: any) => {
          (checkbox as HTMLInputElement).checked = false;
        });
        setSelectedCheckBox([])
        fetchClaimForm();
      } else {
        toast.error(`${res.Message}`);
      }
    });
  };
  const RejectSelectedItems = (selected: any) => {
    rejectSelectedClaim(selected).then((res: any) => {
      if (res.Code === "1") {
        toast.success(`${res.Message}`);
        history("/claim-form", { replace: true });
        const ele = document.querySelectorAll(".invoiceCheckBox:checked");
        ele.forEach((checkbox: any) => {
            (checkbox as HTMLInputElement).checked = false;
        });
        const checkAllCheckbox = document.getElementById("checkBoxAll") as HTMLInputElement | null;
        if (checkAllCheckbox) {
            checkAllCheckbox.checked = false;
        }
        setSelectedCheckBox([])
        fetchClaimForm();
      } else {
        toast.error(`${res.Message}`);
      }
    });
  };


  const columns = useMemo(() => {
    const baseColumns = [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={checkedAll}
            value={isMultiDeleteButton}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="invoiceCheckBox form-check-input"
              value={cell.getValue()}
              onChange={deleteCheckbox}
              data-id={cell.row.original.id}
            />
          );
        },
        id: "#",
        accessorKey: "_id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Patient",
        accessorKey: "patientName",
        enableColumnFilter: false,
      },
      {
        header: "Reference No",
        accessorKey: "uniqueId",
        enableColumnFilter: false,
      },
      {
        header: "Worker Id",
        accessorKey: "workerId",
        enableColumnFilter: false,
      },
      {
        header: "visitDate",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>
                {new Date(cell.getValue()).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </>
            )}
          </>
        ),
      },

      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
   

      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  {" "}
                  Approved
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  {" "}
                  Rejected
                </span>
              );
            default:
              return (
                <span className="badge border border-secondary text-secondary">
                  {" "}
                  Pending{" "}
                </span>
              );
          }
        },
      },
      {
        header: "Created",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: renderCreatedAt,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item edit" title="View">
                  <div
                    onClick={() => {
                      viewClaim(cellProps);
                    }}
                    className="text-primary d-inline-block edit-item-btn"
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                  </div>
                </li>
                <li className="list-inline-item" title="Remove">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      const claimform = cellProps.row.original;
                      prepareDelete(claimform);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </>
          );
        },
      },
    ];

    // Check if user type is 3, if yes, remove checkbox and clinic columns
    if (Type === 3) {
      return baseColumns.filter(
        (column) =>
          column.accessorKey !== "_id" &&
          column.accessorKey !== "clinic.clinicName"
      );
    }

    return baseColumns;
  }, []);

  document.title = "claimform | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Claim Form" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="WorkresList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Claim Form List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {selectedCheckBox.length !== 0 ? (
                          <>
                            <Col>
                              <span className="me-3 fs-15 mb-1">
                                {" "}
                                {selectedCheckBox.length} Selected
                              </span>
                              <Link
                                to="/claim-form"
                                className="btn text-white add-btn mb-1 me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                onClick={() => {
                                  deleteSelectedItems(selectedCheckBox);
                                }}
                              >
                                Delete Selected
                              </Link>
                              <Link
                                to="/claim-form"
                                className="btn text-white add-btn mb-1 me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                onClick={() => {
                                  RejectSelectedItems(selectedCheckBox);
                                }}
                              >
                                Reject Selected
                              </Link>
                              <Link
                                to="/claim-form"
                                className="btn text-white add-btn mb-1 me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                onClick={() => {
                                  approveSelectedItems(selectedCheckBox);
                                }}
                              >
                                Approved Selected
                              </Link>
                            </Col>
                          </>
                        ) : (
                          <>
                              { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                            <button
                              type="button"
                              className="btn text-white add-btn mb-1 me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={toggleFilter}
                            >
                              <i className="ri-filter-3-line align-bottom me-1 mb-1"></i>{" "}
                              Fliter
                            </button>

                            {Type === 1 && (
                              <div
                                onClick={addClaimCharges}
                                className="btn text-white add-btn mb-1 me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                              >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                Add Other Charges
                              </div>
                            )}
                            <div
                              onClick={addClaimForm}
                              className="btn text-white add-btn mb-1 me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add Claim Form
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={claimform || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      // isTicketsListFilter={true}
                      SearchPlaceholder="Search for claim form..."
                    />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Claim form{" "}
              <strong>{RoleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Not now
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(RoleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
          <ClaimFilter show={Filter} onCloseClick={toggleFilter} onFilterApply={handleFilterApply} />
          <ToastContainer closeButton={false} limit={1} />
   
    </React.Fragment>
  );
};

export default ClaimFormList;
