import React, { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getPlan,  renewalPlan, upgradePlan } from "../_requests";
import { toast } from "react-toastify";
interface data {
  data: any;
}
const VerifyWorkers = ({ data }: data) => {
  const { id } = useParams();
  const [showModal, setShow] = useState(false);
  const [showRModal, setRShow] = useState(false);
  const [planId, setplanId] = useState("");
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleRClose = () => setRShow(false);
  const [SubSob, setSubSob] = useState<any>([]);

  useEffect(() => {
    getPlan(data.agencyId).then((res: any) => {
      setSubSob(res.Data);
    });
  }, [data]);
   
  const upgrade = () => {
    
    setShow(true)
  }

  const upgradeItem = () => {
    checkExistPlan(planId, data?.planId);
  };

  const handlePlanChange = (e: any) => {
    setplanId(e.target.value);
  };
  const checkExistPlan = (currentplan: any, oldPlan: any) => {
    if (currentplan === oldPlan) {
      setRShow(true);
      setShow(false);
    } else {
      const Data = {
        oldPlan: data?.planId,
        newPlan: planId,
        eid: id,
      };
      upgradePlan(Data).then((res:any) => {
        navigate("/workers", { replace: true });
        
        setTimeout(() => {
          toast.success( "Your plan was upgraded successfully");
      }, 100);

        setShow(false);
      });
    }
  };
  const renewPlan = () => {
    const Data = {
      oldPlan: data?.planId,
      newPlan: planId,
      eid: id,
    };
    renewalPlan(Data).then((res) => {
      toast.success(res?.Message, res?.Code);
      navigate("/workers", { replace: true });
      setRShow(false);
      setShow(false);
    });
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1">Update Plan</h5>
            </div>
            <Col lg={12}>
              <div className="d-flex align-items-center mb-4">
                <p className="card-title flex-grow-1">
                  Click below button to upgrade the plan for worker ({data.name}
                  ){" "}
                </p>
              </div>
              <div className="hstack gap-3 flex-wrap">
                <div
                  className="btn text-white add-btn me-1"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  onClick={upgrade}
                >
                  Upgrade
                </div>{" "}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={showModal} toggle={handleClose} centered>
        <ModalHeader toggle={handleClose}>Confirmation</ModalHeader>
        <ModalBody>
          <div className="row col-lg-12 mb-7 ">
            <label className="text-center">
              Your current plan is{" "}
              <span style={{ color: "green", fontWeight: "900" }}>
                {data?.plan?.name}
              </span>
            </label>
            <div className="row col-lg-12 mb-7">
              <Label className="col-lg-4 fw-bold text-muted">Plan Info</Label>

              <div className="col-lg-8">
                <Input
                  type="select"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Role"
                  value={planId}
                  onChange={(e) => handlePlanChange(e)}
                >
                  <option value="">Select a Plan</option>
                  {SubSob?.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={handleClose}>
            Cancel
          </Button>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={upgradeItem}>
          Yes, Upgrade
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showRModal} toggle={handleRClose} centered>
        <ModalHeader toggle={handleRClose}>Confirmation</ModalHeader>
        <ModalBody>
          <div className="row col-lg-12 mb-7 ">
            <label className="text-center">
              Your current plan is{" "}
              <span style={{ color: "green", fontWeight: "900" }}>
                {data?.plan?.name}
              </span>
            </label>
            <label className="text-center">
              You are selected old plan so if you want to renewal the plan ?
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={handleRClose}>
            Cancel
          </Button>
          <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={renewPlan}>
          Yes, Renew
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default VerifyWorkers;
