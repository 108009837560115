import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { getAgency } from "pages/Agency/_requests";

interface FilterProps {
  show: boolean;
  onCloseClick: () => void;
  onFilterApply: (filters: any , Reset :any) => void; // Define onFilterApply in props interface
}

const InvoiceFilter: React.FC<FilterProps> = ({ show, onCloseClick, onFilterApply}) => {
  const [Status, setStatus] = useState<any>({ value: '', label: 'All' });
   const [AmountFrom, setAmountFrom] = useState<any>('');
  const [AmountTo, setAmountTo] = useState<any>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [SubmissionstartDate, setSubmissionStartDate] = useState<Date | null>(null);
  const [SubmissionendDate, setSubmissionEndDate] = useState<Date | null>(null);
 

  const applyFilters = () => {
    const filters = {
      amountfrom: AmountFrom || '',
      amountTo: AmountTo || '',
      status: Status.value || '',
      payment_fromDate: startDate ? startDate.toISOString() : "",
      payment_toDate: endDate ? endDate.toISOString() : "",
      Due_fromDate: SubmissionstartDate ? SubmissionstartDate.toISOString() : "",
      Due_toDate: SubmissionendDate ? SubmissionendDate.toISOString() : "",
    };
    
    const Reset=true;
    onFilterApply(filters,Reset); // Use onFilterApply from props to pass filters to parent component or API call
    onCloseClick();
  };

const clearFilters = () => {
  const filters = {};
  const Reset=false;
  onFilterApply(filters,Reset); 
  setStatus({ value: '', label: 'All' });
  setStartDate(null); 
  setEndDate(null); 
  setSubmissionStartDate(null); 
  setSubmissionEndDate(null); 
  setAmountFrom(null);
  setAmountTo(null);
  onCloseClick(); 
};





  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Invoice Filter
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
     
          <div className="mb-2">
            <Label
              htmlFor="datepicker-range"
              className="form-label text-muted fw-semibold mb-2"
            >
             Payment Date :
            </Label>
            <Flatpickr
                className="form-control"
                id="Daterange"
                placeholder="Select a date range"
                value={[startDate as Date, endDate as Date]}
                onChange={(selectedDates) => {
                  if (Array.isArray(selectedDates) && selectedDates.length === 2) {
                    setStartDate(selectedDates[0] as Date);
                    setEndDate(selectedDates[1] as Date);
                  }
                }}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                mode: "range",
                dateFormat: "d/m/Y",
              }}
            />
     
          </div>
          <div className="mb-2">
            <Label
              htmlFor="datepicker-range"
              className="form-label text-muted fw-semibold mb-2"
            >
              Due Date :
            </Label>
            <Flatpickr
                className="form-control"
                id="Daterange"
                placeholder="Select a date range"
                value={[SubmissionstartDate as Date, SubmissionendDate as Date]}
                onChange={(selectedDates) => {
                  if (Array.isArray(selectedDates) && selectedDates.length === 2) {
                    setSubmissionStartDate(selectedDates[0] as Date);
                    setSubmissionEndDate(selectedDates[1] as Date);
                  }
                }}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                mode: "range",
                dateFormat: "d/m/Y",
              }}
            />
     
          </div>
          <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted fw-semibold mb-2"
            >
              Amount Range
            </Label>
            <Input 
              type="number"
              className="mb-2"
              value={AmountFrom}
              placeholder="Enter amount range from"
              onChange={(e) => setAmountFrom(e.target.value)}
           / >
            <Input 
              type="number"
              className="mb-0"
              placeholder="Enter amount range to"
              value={AmountTo}
              onChange={(e) => setAmountTo(e.target.value)}
           / >
          </div>
          <div className="mb-2">
            <Label
              htmlFor="country-select"
              className="form-label text-muted fw-semibold mb-2"
            >
              Status
            </Label>
            <Select
              className="mb-0"
              value={Status}
              onChange={(selectedOption: any) => setStatus(selectedOption)}
              options={[
                { value: '', label: 'All' },
                { value: '2' , label: ' Invoice Created'},
                { value: '1' , label: ' Payment Submitted'},
              ]}
           
            ></Select>
          </div>
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
           type="button"
            className="btn btn-light w-100"
            onClick={clearFilters}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={applyFilters}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default InvoiceFilter;
