import React, {
  Fragment,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import { Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel"; // Import UseExcel type

// Import necessary dependencies from tanstack packages
import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";

// Column Filter
const Filter = ({
  column,
}: {
  column: Column<any>;
  table: ReactTable<any>;
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

// DebouncedInput component
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

interface TableContainerProps {
  columns: Column<any>[];
  data: any[];
  agency?: string;
  date?: string;
  total?: string | number;
  plan?: string;
  clinic?: string;
  customPageSize?: number;
  tableClass?: string;
  theadClass?: string;
  trClass?: string;
  thClass?: string;
  divClass?: string;
  innerRef: React.MutableRefObject<any>;
}

const TableContainer = forwardRef(
  (
    props: TableContainerProps,
    ref: React.Ref<{ getDataForExport: () => any }>
  ) => {
    const {
      columns,
      data,
      agency,
      date,
      total,
      plan,
      customPageSize,
      tableClass,
      theadClass,
      trClass,
      thClass,
      divClass,
    } = props;
    const tableRef = useRef<HTMLTableElement | null>(null);
    const getDataForExport = () => {

      // Construct the data array for export
      const exportData = data.map((item) => {
        const rowData: Record<string, any> = {}; // Specify the type for rowData
        columns.forEach((column: any) => {
          rowData[column.accessorKey] = item[column.accessorKey];
        });
        return rowData;
      });

      return exportData;
    };
    
  
    const memoizedRef = useMemo(() => ({
      getDataForExport
    }), [data, columns]);
  
    useImperativeHandle(ref, () => memoizedRef);
    var DatefileName = new Date();
    var fileName =
      DatefileName.getFullYear() +
      "-" +
      (DatefileName.getMonth() + 1) +
      "-" +
      DatefileName.getDate();
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");

    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
      const itemRank = rankItem(row.getValue(columnId), value);
      addMeta({
        itemRank,
      });
      return itemRank.passed;
    };

    const table = useReactTable({
      columns,
      data,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      state: {
        columnFilters,
        globalFilter,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: fuzzyFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    const {
      getHeaderGroups,
      getRowModel,
      getCanPreviousPage,
      getCanNextPage,
      getPageOptions,
      setPageIndex,
      nextPage,
      previousPage,
      setPageSize,
      getState,
    } = table;

    useEffect(() => {
      if (customPageSize) setPageSize(customPageSize);
    }, [customPageSize, setPageSize]);

    const currentPage = getState().pagination.pageIndex;
    const totalPages = getPageOptions().length;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, startPage + 4);

    // Adjust start and end page if near the beginning or end
    if (endPage - startPage < 4) {
      startPage = Math.max(0, endPage - 4);
    }


 

    return (
      <Fragment>
        <div className={divClass}>
          {/* <button
            className="btn btn-primary"
            style={{ zIndex: "9999", position: "absolute", top: "20px" }}
            onClick={() => onDownload()}
          >
            Download Excel
          </button> */}

          <Table
            hover
            className={tableClass}
            innerRef={(el) => {
              if (el) tableRef.current = el;
            }}
          >
            <thead className={theadClass}>
              <tr>
                <th colSpan={5}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="fw-bold text-muted">Agency:</span>{" "}
                      {agency}
                    </div>
                  </div>
                </th>
                <th colSpan={5}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="fw-bold text-muted">PLAN:</span> {plan}
                    </div>
                  </div>
                </th>
                <th colSpan={4}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="fw-bold text-muted">DATE:</span> {date}
                    </div>
                  </div>
                </th>
              </tr>

              {getHeaderGroups().map((headerGroup: any) => (
                <tr className={trClass} key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th
                      key={header.id}
                      className={thClass}
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ",
                            desc: " ",
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

            { total && ( <tr>
                <td colSpan={12}> </td>
                <th> TOTAL </th>
                <th> {total}</th>
              </tr>)}
            </tbody>
          </Table>
        </div>

        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing{" "}
              <span className="fw-semibold ms-1">
                {getState().pagination.pageSize}
              </span>{" "}
              of <span className="fw-semibold">{data.length}</span> Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => setPageIndex(0)}
                >
                  First
                </Link>
              </li>
              <li
                className={
                  !getCanPreviousPage() ? "page-item disabled" : "page-item"
                }
              >
                <Link to="#" className="page-link" onClick={previousPage}>
                  Previous
                </Link>
              </li>

              {Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((item) => (
                <li key={item} className="page-item">
                  <Link
                    to="#"
                    className={
                      currentPage === item ? "page-link active" : "page-link"
                    }
                    onClick={() => setPageIndex(item)}
                  >
                    {item + 1}
                  </Link>
                </li>
              ))}
              <li
                className={
                  !getCanNextPage() ? "page-item disabled" : "page-item"
                }
              >
                <Link to="#" className="page-link" onClick={nextPage}>
                  Next
                </Link>
              </li>
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => setPageIndex(totalPages - 1)}
                >
                  Last
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </Fragment>
    );
  }
);

export default TableContainer;
