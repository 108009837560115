import React from "react";
import SupportList from "./List/SupportList";


const MainComponent = () => {
  return (
    <div>
      <SupportList />
    </div>
  );
};

export default MainComponent;