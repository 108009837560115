import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useProfile } from "Components/Hooks/UserHooks";
import Avatar from "react-avatar";

const ProfileDropdown = () => {
  const { userProfile } = useProfile();
  const username = userProfile?.username;
  const Type = userProfile?.Type;
  const Id = userProfile?.primaryID;
  const profiledropdownData = createSelector(
    (state: any) => state.Profile,
    (user) => user.user
  );
  // Inside your component
  const user = useSelector(profiledropdownData);

  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    const authUSer: any = sessionStorage.getItem("authUser");
    if (authUSer) {
      const obj: any = JSON.parse(authUSer);
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === undefined
            ? user.first_name
              ? user.first_name
              : obj.data.first_name
            : "Admin" || "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? obj.email && obj.email
          : "Admin"
      );
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        // className="ms-sm-3 header-item topbar-user"
        className="ms-sm-3 header-item "
      >
        <DropdownToggle tag="button" type="button" className="btn">
          {/* <span className="d-flex align-items-center"> */}
          <span className="fs-8">
            <Avatar
              name={Type}
              maxInitials={1}
              color="#607458"
              fgColor="#1b1b29"
              className="rounded-circle " // Add a custom class name
              size="40px" // Adjust the size as needed
              
            
            ></Avatar>

          
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" style={{height:" 150px",width: "250px"}}>
          <CardHeader>
            <h6 className="ms-3">{Type}</h6>
            <h6 className="ms-3">{username}</h6>
          </CardHeader>
          <DropdownItem className="p-0">
            <Link to="/change-password" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Change Password</span>
            </Link>
          </DropdownItem>
          { (Type === 2 || Type === 3) &&
          <DropdownItem className="p-0">
            <Link to={Type === 2 ? `/agency/view/${Id}` : `/clinic/view/${Id}`} className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Edit Profile  </span>
            </Link>
          </DropdownItem>}
          {/* <DropdownItem className='p-0'>
                        <Link to="/apps-chat" className="dropdown-item">
                            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Messages</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to={"#"} className="dropdown-item">
                            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Taskboard</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/pages-faqs" className="dropdown-item">
                            <i
                                className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Help</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem className='p-0'>
                        <Link to="/pages-profile" className="dropdown-item">
                            <i
                                className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Balance : <b>$5971.67</b></span>
                        </Link>
                    </DropdownItem >
                    <DropdownItem className='p-0'>
                        <Link to="/pages-profile-settings" className="dropdown-item">
                            <span
                                className="badge bg-success-subtle text-success mt-1 float-end">New</span><i
                                    className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle">Settings</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/auth-lockscreen-basic" className="dropdown-item">
                            <i
                                className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span>
                        </Link>
                    </DropdownItem> */}
          <DropdownItem className="p-0">
            <Link to="/login" className="dropdown-item"  onClick={() => sessionStorage.clear()}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
