import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";

import DocumentsTab from "./RolesTab";
import TeamTab from "./TeamTab";
import Support from "./Support";
import Controls from "./WorkerTab";
import Controlss from "./Controlss";
import {
  getAgencyById,
  getAgencyPaymentCount,
  updateActiveDeactive,
} from "pages/Agency/_requests";
import { Link, useNavigate, useParams } from "react-router-dom";
import Department from "pages/Department";
import InvoicesTab from "./InvoicesTab";
import WorkersList from "pages/Workers";
import Role from "pages/Roles";
import WorkerTab from "./WorkerTab";
import RolesTab from "./RolesTab";
import DepartmentTab from "./DepartmentTab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "Components/Hooks/UserHooks";
import Avatar from "react-avatar";
import BreadCrumb from "Components/Common/BreadCrumb";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

const Section = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const [agency, setAgency] = useState<any>([]);
  const [count, setCount] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [AgenAccess, setAgenAccess] = useState<any>([]);

  document.title =
    Type === 1
      ? "Agency View | Alkhairi care"
      : "Agency Profile | Alkhairi care";
  useEffect(() => {
    let Data;
    if (Type === 1) {
      Data = 10;
    } else if (Type === 2) {
      Data = 36;
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setAgenAccess(res.Data[0]);
    });
  }, []);

  const editAgenProfile = (Data: any) => {
    setAgenAccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.edit === 1) {
        history("/agency/Edit/" + Data);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const UpdateStatus = async () => {
    if (agency?.suspenseStatus !== 2) {
      var status = 2;
    } else {
      var status = 1;
    }
    await updateActiveDeactive(id, status);
    navigate("/agency");
    await setTimeout(() => {
      toast.success("Status Updated Successfully", { autoClose: 3000 });
    }, 100);
  };
  const Data = Type === 1 ? id : Id;

  useEffect(() => {
    getAgencyById(Data).then((res: any) => {
      setAgency(res);
    });

    getAgencyPaymentCount(Data).then((res: any) => {
      setCount(res.data);
    });
  }, []);
  var date = agency.dob;
  var dates = new Date(date);
  var formattedDate = dates.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const history = useNavigate();
  function goBack() {
    history(-1);
  }

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <Row>
        <BreadCrumb title="Agency Overview" pageTitle="Agency" />
        {Type === 1 && (
          <Row>
            <Col>
              {" "}
              <button
                onClick={goBack}
                className="btn btn-primary px-2 text-center mb-3"
              >
                <i className="ri ri-reply-line fs-6 me-2"></i> Go Back
              </button>
            </Col>
          </Row>
        )}

        <Col lg={12}>
          <Card className="">
            <div className="bg-primary-subtle">
              <CardBody className={Type === 1 ? "pb-0 px-4" : "pb-4 px-4"}>
                <Row className="mb-3">
                  <div className="col-md">
                    <Row className="align-items-center g-3">
                      <div className="col-md">
                        <div>
                          <h4 className="fw-bold">{agency?.name}</h4>
                          <div className="badge bg-success mb-2">
                            {agency.agencyType}
                          </div>
                          <div className="hstack gap-3 flex-wrap ">
                            <div className="border border-success border-dashed rounded p-1">
                              Total Invoice Sent:
                              <span className="fw-medium">
                                {count?.totalInvoicesent}
                              </span>
                            </div>
                            <div className="vr"></div>
                            <div className="border border-success border-dashed rounded p-1 mb-2">
                              Total Payments Recieved:{" "}
                              <span className="fw-medium">
                                {count?.totalInvoiceReceive}
                              </span>
                            </div>
                            <div className="vr"></div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                  {Type === 1 && (
                    <div className="col-md-auto">
                      <div onClick={toggle}>
                        {agency.suspenseStatus !== 2 ? (
                          <div
                            className="badge bg-danger w-80 h-20"
                            style={{ fontSize: "1rem" }}
                          >
                            {" "}
                            Deactivate
                          </div>
                        ) : (
                          <div
                            className="badge bg-success w-80 h-20"
                            style={{ fontSize: "1rem" }}
                          >
                            {" "}
                            Activate
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Row>

                {Type === 1 && (
                  <Nav
                    className="nav-tabs-custom border-bottom-0"
                    role="tablist"
                  >
                    <NavItem >
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                        href="#"
                      >
                        Overview
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3");
                        }}
                        href="#"
                      >
                        Workers
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4");
                        }}
                        href="#"
                      >
                        Invoices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("5");
                        }}
                        href="#"
                      >
                        Roles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "6" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("6");
                        }}
                        href="#"
                      >
                        Support
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "7" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("7");
                        }}
                        href="#"
                      >
                        Department
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "8" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("8");
                        }}
                        href="#"
                      >
                        Controls
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row>
                <Col>
                  <Card>
                  <CardHeader>
  <Row className="align-items-center d-flex ">
    <Col xl={6} sm={6}>
      <h5 className="card-title">Agency Details</h5>
    </Col>
    <Col
      xl={6}
      sm={6}
      className="d-flex justify-content-end"
    >
      <button
        onClick={() => {
          editAgenProfile(Data);
        }}
        className="btn text-white"
        style={{
          backgroundColor: "#28a8df",
          borderColor: "#28a8df",
        }}
      >
        Edit Agency
      </button>
    </Col>
  </Row>
</CardHeader>


                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium col-lg-4 col-6">
                                Agency Contact
                              </td>
                              <td>{agency?.telephoneNumber}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Agency Email</td>
                              <td>{agency?.email}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Contact Person Name</td>
                              <td>{agency?.primaryContact}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">
                                Contact Person Email
                              </td>
                              <td>{agency?.primaryEmail}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">
                                Contact Person Phone Number
                              </td>
                              <td>{agency?.primaryTelephone}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Address</td>
                              <td id="c-date">{agency?.address}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium"> </td>
                              <td id="d-date">{agency?.city}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium"></td>
                              <td>
                                {agency?.state} - {agency?.pincode}
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Country</td>
                              <td>{agency?.country}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Website</td>
                              <td>{agency?.website}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Agency Fax</td>
                              <td>{agency?.fax}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Total Workers</td>
                              <td>{agency?.totalWorkers}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Non panel</td>
                              <td>
                                {agency?.non_panel_disable_status === 1 ? (
                                  <span
                                    className="badge bg-success"
                                    id="t-priority"
                                  >
                                    Non panel Enabled
                                  </span>
                                ) : (
                                  <span
                                    className="badge bg-danger"
                                    id="t-priority"
                                  >
                                    Non panel not Enabled
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            {Type === 1 && (
              <>
                <TabPane tabId="3">
                  <WorkerTab />
                </TabPane>
                <TabPane tabId="4">
                  <InvoicesTab />
                </TabPane>
                <TabPane tabId="5">
                  <RolesTab />
                </TabPane>
                <TabPane tabId="6">
                  <Support />
                </TabPane>
                <TabPane tabId="7">
                  <DepartmentTab />
                </TabPane>
                <TabPane tabId="8">
                  <Controlss data={agency} />
                </TabPane>
              </>
            )}
          </TabContent>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure want to{" "}
          {agency?.suspenseStatus !== 2 ? "Deactivate" : "Activate"} this agency
          ?<strong>{agency?.name}</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Not Now
          </Button>
          <Button color="danger" onClick={UpdateStatus}>
            Yes, {agency?.suspenseStatus !== 2 ? "Deactivate" : "Activate"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Section;
