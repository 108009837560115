import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Toast } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Widgets from "./Widgets";
import ClinicData from "./ClinicData";
import ClaimData from "./ClaimData";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClinicOverview = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [clinicaccess, setclinicaccess] = useState<any>([]);
  const [claimaccess, setclaimaccess] = useState<any>([]);

  document.title = "Clinic Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
  });


  useEffect(() => {
    getCRUD(userProfile?.role, 39).then((res: any) => {
      setclinicaccess(res.Data[0]);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 6).then((res: any) => {
      setclaimaccess(res.Data[0]);
    });
  }, []);

  const addClinic = () => {
    if (clinicaccess?.add === 1) {
      navigate("/clinics/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const listClinic = () => {
    if (clinicaccess?.list === 1) {
      navigate("/clinics");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const claimForm = () => {
    if (claimaccess?.list === 1) {
      navigate("/claim-form");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const addCategory = () => {
    if (claimaccess?.add === 1) {
      navigate("/category");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Clinic Overview</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <div
                      onClick={addClinic}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      Add Clinic
                    </div>

                    <div
                      onClick={claimForm}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      Claim Form
                    </div>

                    <div
                      onClick={addCategory}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      Add Category
                    </div>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody className="p-0">
                  <Row className="align-items-end">
                    <Col>
                      <div className="p-3 ">
                        <div className="mt-3">
                          <p className="fw-bold text-muted text-hover-primary fs-4">
                            Welcome to Clincs
                          </p>
                        </div>
                        <div className="fw-bold text-primary my-6">{time}</div>
                        <div className="mt-3 text-dark-75 fw-semibold fs-5 m-0">
                          <p className="fs-16 lh-base m-0">
                            Manage and configure clinics
                          </p>
                          <p className="fs-16 lh-base m-0">
                            based settings here
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="position-relative">
                    <div className="mt-3">
                      <p className="fw-bold  text-hover-primary fs-4">
                        Add or Check
                          <a
                            onClick={addClinic}
                            className="text-underline fw-bold fs-5 me-2 mx-2"
                          >
                            new Clinic
                          </a>
                        here
                      </p>
                    </div>
                    <p className="  text-dark-75 fw-bold fs-5">
                      This is the master clinic datas.
                    </p>
                    <div className="mt-1  fw-semibold fs-5">
                      <div
                        onClick={addClinic}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Add new Clinic
                      </div>
                      <div
                        onClick={listClinic}
                        className="btn text-white add-btn me-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        List of Clinic
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Widgets />
          </Row>
          <Row>
            <Col xl={12}>
              <ClaimData />
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <ClinicData />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicOverview;
