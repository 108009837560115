import React from 'react';
import { Card, CardBody, Col,Row, } from 'reactstrap';
import { updatecontrols } from '../_requests';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface data{
    data :any
}
const Controls = ({ data }: data)=> {
    const navigate=useNavigate()
    const sendEmail=()=>{

        var GetName: string | undefined = data?.name;
        var GetUserId: string | undefined = data?.id;
        var GetEmail: string | undefined = data?.email;
        var GetDob: string | undefined = data?.dob;
        var GetCompany: string | undefined = data?.agency?.name;
        var Data={  name:GetName,email:GetEmail,dob:GetDob,company:GetCompany  }
        
        
        updatecontrols(Data,GetUserId).then((ress)=>{
            navigate('/workers', {replace: true,});
            setTimeout(() => {
                toast.success("Email sent successfully");
            }, 100);
       
         })
      } 
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1">Update Credentials</h5>
                    </div>
                        <Col lg={12}>
                        <div className="d-flex align-items-center mb-4">
                        <p className="card-title flex-grow-1">Click below button to resend username & password to corresponding worker through email</p>
                       </div>
                       <div className="hstack gap-3 flex-wrap">
                       <div  className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={sendEmail}>
                           Send Email
                          </div>{" "}
                        </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Controls;