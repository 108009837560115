import React from "react";
import ClinicList from "./List/ClinicList";
// import SupportView from "./SupportView";

const MainComponent = () => {
  return (
    <div>
      <ClinicList />
      {/* <SupportView /> */}
    </div>
  );
};

export default MainComponent;