import axios from 'axios'
import getToken from 'helpers/jwt-token-access/auth-token-header'

const API_URL = process.env.REACT_APP_API_URL
const CHANGE_PASSWORD_URL = `${API_URL}/login/profile/updatePassword`


const ChangePasswordAPI =(userId:any,data:any)=> {
    return axios.put(`${CHANGE_PASSWORD_URL}/${userId}`,data)
    .then((response) => response)
};

export {ChangePasswordAPI}
