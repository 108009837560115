import React from 'react'

const convertTo12HourFormat = (time: string) => {
  // let input=time.toString();



  const [hour, minute] = time.split(':')
  const parsedHour = parseInt(hour, 10)

  if (parsedHour === 0) {
    return `12:${minute} AM`
  } else if (parsedHour < 12) {
    return `${parsedHour}:${minute} AM`
  } else if (parsedHour === 12) {
    return `${parsedHour}:${minute} PM`
  } else {
    return `${parsedHour - 12}:${minute} PM`
  }
}

const TimeConversionComponent = (time: any) => {
  const time1 = time.toString()
  const time2 = '22:00'

  const convertedTime1 = convertTo12HourFormat(time?.time?.open)
  const convertedTime2 = convertTo12HourFormat(time?.time?.close)

  return (
    <div>
      <span className='fs-6'>
        {' '}
        {convertedTime1} - {convertedTime2}
      </span>
    </div>
  )
}

export default TimeConversionComponent
