import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getCompany,
  getReportforCompany,
  getPlan,
  getReportforCompanyNotIn,
  getCliniCategoryByplan,
} from "./_requests";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Button, Card, Col, Container, Row } from "reactstrap";
import TableContainer from "./TableContainer";
import * as XLSX from "xlsx";
interface Column<T> {
  header: string;
  id?: string;
  accessorKey: keyof T | string;
  enableColumnFilter: boolean;
  cell?: (cellProps: any) => any;
  columnDef?: any; // Add this property
}

const AgencyReportContents = () => {
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);

  const tableRef = useRef<HTMLTableElement>(null);
  const [com, setCom] = useState<any>([]);
  const [Total, setTotal] = useState<any>("");
  const [OCcom, setOCcom] = useState("");
  const [OCDate, setOCDate] = useState("");
  const [OCplan, setOCplan] = useState("");
  const [SelectedClinicName, setSelectedClinicName] = useState("");
  const [clinicid, setclinicid] = useState(0);
  const [plan, setPlan] = useState<any>([]);
  const [planid, setplanid] = useState(0);
  const [report, setReport] = useState<any>([]);
  const [notreport, setNotReport] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [btnExcelDisable, setbtnExcelDisable] = useState(true);
  const [btnClearDisable, setbtnClearDisable] = useState(true);
  const [ClinicCategory, setClinicCategory] = useState<any>([]);

  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };

  const formatDataForExport = async () => {
    const exportData = [];

    // // First row with agency, plan, and date
    // const firstRow = {
    //     'Agency': OCcom,
    //     'Plan': OCplan,
    //     'Date': OCDate,
    // };
    // exportData.push(firstRow);

    // // Empty row
    // exportData.push({});

    // Data rows
    await Promise.all(
      report.map(async (row: any, i: any) => {
        const dataRow = {
          "S.No": i + 1,
          "Worker Name": row.workerName,
          NRIC: row.nricPassport,
          "Wbs Code": row?.wbsCode,
          Status: row.suspenseStatus === 2 ? "Suspended" : "Active",
          Plan: row.plan.planName,
          Category: row.cliniccategory?.name,
          "Valid Till": new Date(row.validityTo).toLocaleDateString("en-GB"),
          Limit: row.OrgplanLimit,
          Date: new Date(row.visitDate).toLocaleDateString("en-GB"),
          Clinic: row.clinic?.clinicName,
          Diagnosis: row.diagDetails
            ? row.diagDetails
                .map((item: any) => item?.diagnosis?.name)
                .join(", ")
            : "",
          "MC Days": row?.totalMcDays,
          Balance: row?.workerCurrentBalance,
          "Charge (RM)": row?.amount,
        };
        exportData.push(dataRow);
      })
    );

    // Last row with total
    const lastRow = {
      Balance: "Total",
      "Charge (RM)": Total,
    };
    exportData.push(lastRow);

    return exportData;
  };

  const exportToExcel = (data: any[]) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveExcelFile(excelBuffer);
  };

  var DatefileName = new Date();
  var DateFileName =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();

  const saveExcelFile = (buffer: any) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const fileName = `Agency_Report_${DateFileName}.xlsx`;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    getCompany().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setCom(optionsWithAll);
    });
  }, []);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Agency_Report_${DateFileName}`,
    sheet: "Report",
  });

  const [OCcomid, setOCcomid] = useState(0);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [planselectedOption, setplanselectedOption] = useState<any>(null);
  const [clinicselectedOption, setclinicselectedOption] = useState<any>(null);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    var text = selectedOption?.label;
    setOCcom(text);
    setOCcomid(selectedOption?.value);
    getPlan(selectedOption?.value).then((res: any) => {
      const mappedOptions = res.Data.map((plan: { id: any; name: any }) => ({
        value: plan.id,
        label: plan.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setPlan(optionsWithAll);
    });
    setplanselectedOption(null);
    setclinicselectedOption(null);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  };

  const planhandleSelectChange = (planselectedOption: any) => {
    setplanselectedOption(planselectedOption);
    var text = planselectedOption?.label;
    setOCplan(text);
    setplanid(planselectedOption?.value);
    setclinicselectedOption(null);
    getCliniCategoryByplan(planselectedOption?.value).then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "Select" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setClinicCategory(optionsWithAll);
      if (flatpickrRef.current) {
        flatpickrRef.current.flatpickr.clear();
      }
    });
  };

  const clinichandleSelectChange = (clinicselectedOption: any) => {
    setclinicselectedOption(clinicselectedOption);

    var text = clinicselectedOption?.label;
    setSelectedClinicName(text);
    setclinicid(clinicselectedOption?.value);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  };

  const companyReport = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate = selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange = startDate && endDate ? `${startDate} - ${endDate}` : "";

    setOCDate(Daterange);
    setLoading(true);
    getReportforCompany(planid, OCcomid, clinicid, Daterange).then(
      (resRep: any) => {
        setReport(resRep.Data);
        const total = resRep.Data.reduce((acc: number, res: any) => {
          return acc + parseInt(res.amount);
        }, 0);
        setTotal(total);
        setLoading(false);
        getReportforCompanyNotIn(planid, OCcomid).then((resRepNot: any) => {
          setNotReport(resRepNot.Data);
          if (resRep?.Data.length > 0 || resRepNot.Data.length > 0) {
            setbtnExcelDisable(false);
            setbtnClearDisable(false);
          } else {
            setbtnExcelDisable(true);
            setbtnClearDisable(true);
          }
        });
      }
    );
  };

  const clearReport = () => {
    setSelectedOption(null);
    setplanselectedOption(null);
    setclinicselectedOption(null);
    setReport([]);
    setNotReport([]);
    setPlan([]);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setOCDate("");
    setOCplan("");
    setOCcom("");
    setTotal("");
    setplanid(0);
    setOCcomid(0);
    getCompany().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setCom(optionsWithAll);
    });

    setbtnExcelDisable(true);
    setbtnClearDisable(true);
  };

  const renderCreatedAt = (cell: any, dateKey: any) => {
    const formattedDate = new Date(
      cell.row.original[dateKey]
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  const columns: Column<any>[] = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        accessorKey: "sno",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Worker Name",
        accessorKey: "workerName",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "NRIC",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Wbs Code",
        accessorKey: "wbsCode",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "status",
        accessorKey: "suspenseStatus",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.suspenseStatus === 2 ? "Suspended" : "Active"}
            </>
          );
        },
      },
      {
        header: "Plan",
        accessorKey: "plan.planName",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Category",
        accessorKey: "cliniccategory.name",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Valid Till",
        accessorKey: "validityTo",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
        cell: (cell: any) => renderCreatedAt(cell, "validityTo"),
      },
      {
        header: "Limit",
        accessorKey: "OrgplanLimit",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
        cell: (cell: any) => renderCreatedAt(cell, "visitDate"),
      },
      {
        header: "Clinic",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Diagnosis",
        accessorKey: "diagDetails.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {" "}
              {cell.row.original.diagDetails?.map((items: any, i: any) => {
                return <>{items?.diagnosis?.name}</>;
              })}
            </>
          );
        },
      },
      {
        header: "MC Days",
        accessorKey: "totalMcDays",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Balance",
        accessorKey: "workerCurrentBalance",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
      {
        header: "Charage (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
        columnDef: {}, // Add this property
      },
    ],
    []
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <div className="cursor-pointer">
            <Row>
              <div className="col-sm">
                <h5 className="card-title m-3">Agency Report</h5>
              </div>
              <div className="col-sm-auto m-3">
                <button
                 className="btn text-white me-1"
                 style={{
                   backgroundColor: "#28a8df",
                   borderColor: "#28a8df",
                 }}
                  disabled={btnExcelDisable}
                  onClick={handleExport}
                >
                  Export
                </button>
              </div>
            </Row>
          </div>
          <div className="card-body py-3">
            <div className="row mb-10">
              <div className="col-lg-3">
                <label className=" col-form-label required fw-bold fs-6">
                  Filter By Agency
                </label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={com}
                  isSearchable
                />
              </div>
              <div className="col-lg-3">
                <label className=" col-form-label fw-bold fs-6">
                  Filter By Plan
                </label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={planselectedOption}
                  onChange={planhandleSelectChange}
                  options={plan}
                  isSearchable
                />
              </div>
              <div className="col-lg-3">
                <label className=" col-form-label fw-bold fs-6">
                  Filter By Category
                </label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={clinicselectedOption}
                  onChange={clinichandleSelectChange}
                  options={ClinicCategory}
                  isSearchable
                />
              </div>
              <div className="col-lg-3">
                <label className=" col-form-label required fw-bold fs-6">
                  Filter By Date Range
                </label>
                <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
              </div>
              <Row>
                <Col lg={8} xl={8}></Col>
                <Col lg={2} xl={2} className="my-2">
                  <br></br>
                  <Button
                    onClick={companyReport}
                    className="btn text-white col-lg-12 me-1"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                  >
                    Search
                  </Button>
                </Col>
                <Col lg={2} xl={2} className="my-2">
                <br></br>
                  <Button
                    onClick={clearReport}
                     className="btn text-white me-1 col-lg-12"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                    disabled={btnClearDisable}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <TableContainer
                innerRef={tableRef}
                columns={columns as any[]}
                data={report || []}
                customPageSize={10}
                divClass="table-responsive"
                tableClass="align-middle table-nowrap mb-0"
                agency={OCcom}
                plan={OCplan}
                date={OCDate}
                total={Total}
              />
            </Row>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export { AgencyReportContents };
