import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

//import images
import avatar3 from "../../../../assets/images/users/avatar-3.jpg";
import DocumentsTab from "./DocumentsTab";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import {
  viewclinicSupport,
  getreplyList,
  getSupportStatus,
  updateclinicStatus,
  updateReply,
} from "../_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "Components/Common/BreadCrumb";
import Avatar from "react-avatar";

const Section = () => {
  //Tab
  const [modal, setModal] = useState<boolean>(false);
  const [commentModal, setCommentModal] = useState<boolean>(false); // New state for comment modal
  const toggle = () => {
    setModal(!modal);
  };

  const toggleCommentModal = () => {
    setCommentModal(!commentModal);
  };
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [support, setclinic] = useState<any>([]);
  const [support, setsup] = useState<any>([]);
  const [replist, setreplist] = useState<any>([]);
  const [statuslist, setstatuss] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShow] = useState(false);
  const [btnDisable, setbtnDisable] = useState(true);
  const [RbtnDisable, setRbtnDisable] = useState(true);
  const [statusId, setstatusId] = useState(null);
  const [reply, setreply] = useState(null);
  const [showCModal, setCShow] = useState(false);

  var date = support.dob;
  var dates = new Date(date);
  var formattedDate = dates.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [statusdesc, setstatusdesc] = useState(null);
  const handledescription = (e: any) => {
    const statusDesc = e.target.value;
    setstatusdesc(statusDesc);
  };

  const handleStatusChange = (e: any) => {
    const statusId = e.target.value;

    if (statusId != "") {
      setstatusId(statusId);
      setbtnDisable(false);
    } else {
      setstatusId(null);
      setbtnDisable(true);
    }
  };
  // const handledescription = (e: any) => {
  //   const statusDesc = e.target.value;
  //   setstatusdesc(statusDesc);
  // }
  const SubmitReply = () => {
    setLoading(true);
    const data = {
      supportId: id,
      toId: support?.fromId,
      replymessage: reply,
    };
    updateReply(data).then((res) => {
      navigate(`/supportview/${id}`, { replace: true });
      setCShow(false);
      setLoading(false);
      toggleCommentModal();
      toast.success("Reply added  Successfully", { autoClose: 3000 });
      getreplyList(id).then((res: any) => {
        var supcont = res.Data;
        setreplist(supcont);
      });
    });
  };

  const submitchangeStatus = () => {
    toggle();
    setLoading(true);
    updateclinicStatus(statusId, statusdesc, id).then((res) => {
      toast.success("Add  Successfully", { autoClose: 3000 });
      navigate(`/supportview/${id}`, {
        replace: true,
      });
    });
    setLoading(false);
    setShow(false);
  };

  const handledeReply = (e: any) => {
    if (e.target.value != "") {
      setreply(e.target.value);
      setRbtnDisable(false);
    } else {
      setreply(null);
      setRbtnDisable(true);
    }
  };

  useEffect(() => {
    viewclinicSupport(id).then((res: any) => {
      setsup(res);
    });
  }, []);
  useEffect(() => {
    getreplyList(id).then((res: any) => {
      var supcont = res;
      setreplist(supcont);
    });
  }, []);
  useEffect(() => {
    getSupportStatus().then((res: any) => {
      var supcont = res;
      setstatuss(supcont);
    });
  }, []);

  document.title = " Support view | Al khairi care ";
  const history = useNavigate();
  function goBack() {
    history(-1);
  }
  return (
    <React.Fragment>
      <BreadCrumb title=" Support view" pageTitle="Support" />
      <Row>
        <Col>
          {" "}
          <button
            onClick={goBack}
            className="btn btn-primary px-2 text-center mb-3"
          >
            <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
          </button>{" "}
        </Col>
      </Row>
      <Row>
        <ToastContainer closeButton={false} limit={1} />
        <Col lg={12}>
          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row>
                <Col xxl={3}>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xl={6}>
                          <h5 className="card-title mb-0">View Support</h5>
                        </Col>
                        {/* <Col xl={6} className="d-flex justify-content-end">
                            <button  onClick={()  => {toggle();}}className="btn btn-primary me-1">
                                Update status
                            </button>
                        </Col> */}
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <div className="table-responsive table-card">
                        <Table className="table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-medium">Date</td>
                              <td id="t-client">{support[0]?.createdAt}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Subject</td>
                              <td>{support[0]?.subject}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Priority</td>
                              <td>
                                {support[0]?.supportpriority?.priorityName}
                              </td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Message</td>
                              <td>{support[0]?.message}</td>
                            </tr>

                            <tr>
                              <td className="fw-medium">Status</td>
                              <td>{support[0]?.supportstatus?.statusName}</td>
                            </tr>
                          
                            {support[0]?.statusnotes && (
                              <tr>
                                <td className="fw-medium">
                                  Status Description
                                </td>
                                <td>{support[0]?.statusnotes}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xl={6}>
                          <h5 className="card-title mb-0">
                            Replies to your support request
                          </h5>
                        </Col>
                        {/* <Col xl={6} className="d-flex justify-content-end">
                              <button onClick={toggleCommentModal} className="btn btn-primary me-1">
                                Make Comment
                            </button>
                        </Col> */}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="col-md-12 ">
                        {replist?.map((item: any, i: any) => (
                          <div
                            key={i}
                            className="d-flex align-items-start mb-3"
                          >
                            <div className="me-3">
                              <Avatar
                                size="40"
                                round="20px"
                                name={item?.fromName}
                                maxInitials={2}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-1">{item?.fromName}</h5>
                              <p
                                className="fs-13 text-muted mb-0"
                                style={{ textAlign: "justify" }}
                              >
                                {item?.replymessage}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <DocumentsTab />
            </TabPane>
          </TabContent>
        </Col>

        <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            Change Status
          </ModalHeader>

          <ModalBody>
            <div className="mb-3">
              <Label for="choices-payment-status">Status</Label>
              <div className="input-light" onChange={handleStatusChange}>
                <Input
                  name="name"
                  type="select"
                  className="bg-light border-0"
                  id="choices-payment-status"
                >
                  <option>Select a Status</option>
                  {statuslist?.map((item: any, index: any) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter role name"
                name="statusnotes"
                onChange={handledescription}
                //value={validation.values.support?.supportstatus?.statusnotes || ""}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              color="success"
              onClick={submitchangeStatus}
              disabled={btnDisable}
            >
              <i className="ri-save-line align-bottom me-1"></i> Update Status
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          id="commentModal"
          isOpen={commentModal}
          toggle={toggleCommentModal}
          centered
        >
          <ModalHeader className="bg-light p-3" toggle={toggleCommentModal}>
            Reply to the support
          </ModalHeader>

          <ModalBody>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Reply
              </label>
              <textarea
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter reply message"
                name="name"
                onChange={handledeReply}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              color="success"
              onClick={SubmitReply}
              disabled={RbtnDisable}
            >
              <i className="ri-save-line align-bottom me-1"></i> Submit Comment
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default Section;
