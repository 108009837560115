import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`



const getCompany = async () => {
  const res = await axios.get(`${REPORT_URL}/getagencydropdown`,)
  return res;
}
const getClinic= async () => {
  const res = await axios.get(`${REPORT_URL}/getClinicdropdown`,)
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${REPORT_URL}/clientReport/getAssignedPlans/${id}`,)
  return res;
}
const getEmployee = async () => {
  const res = await axios.get(`${REPORT_URL}/getEmployeedropdown`,)
  return res;
}
const getTreatement = async () => {
  const res = await axios.get(`${CLAIM_URL}/getTreatment`,)
  return res;
}
const claimTreatements = async (id:any) => {
  const res = await axios.get(`${CLAIM_URL}/claimTreatements/${id}`,)
 // 
  return res;
}

const getReportforCompany = async (CID:any,COMID:any,date:any) => {
  const res = await axios.post(`${REPORT_URL}/getClaimReport/${CID}/${COMID}`,{date},)
  return res;
}
const getReportforCompanyNotIn = async (CID:any,COMID:any) => {
  const res = await axios.get(`${REPORT_URL}/getNonClaimEmployee/${CID}/${COMID}`,)
  return res;
}
const getCompanyReport= async (id:any)=>{
  const res = await axios.get(`${REPORT_URL}/comapany/individualReport/${id}`,)
  return res;
}
const datewisevisitGraph = async (data: any) => {
  const result = await axios.post(`${API_URL}/report/comapany/monthwiseReport`, data )
  //
  return result
}

const datewisecreditAgencyGraph = async (data: any) => {
  const result = await axios.post(`${API_URL}/report/comapany/creditwiseReport`, data, )
  //
  return result
}
export {
  getCompany,getEmployee,getClinic,getReportforCompany,claimTreatements,getPlan,getReportforCompanyNotIn,
  getTreatement,getCompanyReport,datewisevisitGraph,datewisecreditAgencyGraph
}
