import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MAIN_URL = `${API_URL}/clinicPayment`



const getClinicPayment = (clinicId:any,filters?:any,) => {
  return axios
    .post(`${MAIN_URL}/getdata/${clinicId}`,filters)
    .then((d: any) => {
     
      return d.Data
    })
}
const getClinicPaymentById = (id:any) => {
  return axios
    .get(`${MAIN_URL}/viewClinicPayment/${id}`) 
    .then((response) =>{
    
      return response;
    }
   )
}
const addClinicPayment = (data: FormData,clinicID:any) => {
 // 
 const config = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};
  return axios
  .post(`${MAIN_URL}/addClinicPayment/${clinicID}`, data,config)
  .then((response:any) => {
   //
    return response
   })
   
}
export {
  getClinicPayment,addClinicPayment,getClinicPaymentById
}
