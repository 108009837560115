import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "Components/Common/TableContainer";
import {
  cronJob,
  deleteWorkerById,
  getAgencyWorker,
  getWorker,
  updatebulkinsert,
} from "./_requests";
import Avatar from "react-avatar";
import { useProfile } from "Components/Hooks/UserHooks";
import WorkerFilter from "./Filter";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WorkersList = () => {
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const history = useNavigate();
  const [worker, setWorker] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bulkuploadModal, setBulkuploadModal] = useState(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState("");
  const [Filter, setFilter] = useState<boolean>(false);
  const [workeraccess, setworkercaccess] = useState<any>([]);
  const filter = {};
  const toggleFilter = () => {
    setFilter(!Filter);
  };
 
  


  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };
  const toggleBulkuploadModal = () => {
    setIsUploadButtonDisabled(true);
    setBulkuploadModal(!bulkuploadModal);
  };

  useEffect(() => {
    let Data;
    if (Type === 1) {
      Data = 11;
    } else if (Type === 2) {
      Data = 31;
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setworkercaccess(res.Data[0]);
    });
  }, []);

  const prepareDelete = (worker: any) => {
    setRoleToDeleteId(worker.primaryId);
    setRoleToDeleteName(worker.name);

    // Accessing the latest state directly within the function
    setworkercaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.remove === 1) {
        toggleDeleteModal();
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess; // Return the previous state to avoid unintended changes
    });
  };

  const confirmDelete = (departmentId: any) => {

    deleteWorkerById(departmentId).then(() => {
      history("/workers");
      toast.success("Worker deleted Successfully", { autoClose: 3000 });
      fetchWorker(filter);
      toggleDeleteModal();
    });
  };

  useEffect(() => {
    fetchWorker(filter);
  }, []);

  const [ResetButton,setResetButton]=useState<Boolean>(false)

const handleResetButton=()=>{
  fetchWorker();
  setResetButton(false)
}

  const handleFilterApply = (selectedFilters?: any ,Reset?:any) => {
    fetchWorker(selectedFilters);
    if(Reset){
      setResetButton(true);
    }
    else{
      setResetButton(false);
    }
  };

  const addWorkers = () => {
    setworkercaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.add === 1) {
        history("/workers/add");
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess; // Return the previous state to avoid unintended changes
    });
  };

  const viewWorkers = (cellProps: any) => {
    setworkercaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.view === 1) {
        history(
          `/workers/view/${cellProps.row.original?.primaryId}/${cellProps.row.original.workerId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess; // Return the previous state to avoid unintended changes
    });
  };

  const fetchWorker = (filters?: any) => {
    if (Type === 1) {
      getWorker(filters).then((response: any) => {
        setWorker(response);
      });
    } else {
      getAgencyWorker(filters, Id).then((response: any) => {
        setWorker(response);
      });
    }
  };

  const downloadFile = () => {
    const fileUrl = "/media/ExcelStatic/workerInfo.xlsx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "workerData.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const imageHandlerclick = () => {
    setworkercaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.add == 1) {
        toggleBulkuploadModal()
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess;
    });
  };
  const inputFileRef = React.createRef<HTMLInputElement>()
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);

  const handleFileChange = (e: any) => {
    if (e.target.files.length > 0) {
      setIsUploadButtonDisabled(false);
    } else {
      setIsUploadButtonDisabled(true);
    }
  };
  

  const imageHandler = (e: any) => {
    toggleBulkuploadModal()
    if (inputFileRef && inputFileRef.current && inputFileRef.current.files) {
      const formData = new FormData()
      formData.append('excel', inputFileRef.current.files[0])



      updatebulkinsert(formData)
        .then((res: any) => {
          if (res?.Code === "1") {
            cronJob()
            toast.success(res?.Message);
          }
          if (res?.Code === "0" || res?.Code === "2") {

            toast.error(res?.Message);
          }
          fetchWorker(filter);
        })
        .catch(() => {
          console.error("Error uploading file:");
          toast.error("Error uploading file");
        });
      }
    
  };

  const workerInfoCell = (cellProps: any) => {
    return (
      <div className="d-flex align-items-center">
        {/* begin:: Avatar */}
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          <div>
            {cellProps.row.original?.profileImage ? (
              <div className="symbol-label">
                <img
                  src={cellProps.row.original?.profileImage}
                  alt="hi"
                  /*clinic?.clinic_name*/ style={{
                    objectFit: "contain",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  className="w-20"
                />
              </div>
            ) : (
              <div
                className={clsx(
                  "symbol-label fs-3",
                  `bg-light-primary`,
                  `text-white`
                )}
              >
                <Avatar
                  size="40"
                  round="20px"
                  maxInitials={2}
                  name={cellProps.row.original?.name}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-800  mb-1">{/*clinic?.clinic_name*/}</div>
          <span>{cellProps.row.original?.name}</span>
        </div>
      </div>
    );
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Worker Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: workerInfoCell,
      },
      {
        header: "Department",
        accessorKey: "department.name",
        enableColumnFilter: false,
      },
      {
        header: "Worker Id",
        accessorKey: "workerId",
        enableColumnFilter: false,
      },
      {
        header: "Contact Number",
        accessorKey: "mobileNumber",
        enableColumnFilter: false,
      },
      {
        header: "Plan",
        accessorKey: "plan.name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "suspenseStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge border border-success text-success">
                  Active
                </span>
              );
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  Suspended
                </span>
              );
            default:
              return null; // or handle the default case as needed
          }
        },
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewWorkers(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const worker = cellProps.row.original;
                    prepareDelete(worker);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ];

    // Conditionally add the "Agency" column if Type === 1
    if (Type === 1) {
      baseColumns.splice(2, 0, {
        header: "Agency",
        accessorKey: "agency.name",
        enableColumnFilter: false,
      });
    }

    return baseColumns.filter((column) => column); // Remove any undefined columns
  }, [Type]);

  document.title = "Worker | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Workers" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="WorkresList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Worker List</h5>
                      </div>
                    </div>
                    <div className="col-auto">
  <div className="d-flex flex-wrap align-items-center">
    {ResetButton && (
      <button
        type="button"
        className="btn text-white add-btn me-1 mb-1"
        style={{
          backgroundColor: "#28a8df",
          borderColor: "#28a8df",
        }}
        onClick={handleResetButton}
      >
        <i className="ri-filter-3-line align-bottom me-1"></i>
        Reset
      </button>
    )}
    <button
      type="button"
      className="btn text-white add-btn me-1 mb-1"
      style={{
        backgroundColor: "#28a8df",
        borderColor: "#28a8df",
      }}
      onClick={toggleFilter}
    >
      <i className="ri-filter-3-line align-bottom m-1"></i> Filter
    </button>
    <button
      type="button"
      className="btn text-white add-btn me-1 mb-1"
      style={{
        backgroundColor: "#28a8df",
        borderColor: "#28a8df",
      }}
      onClick={addWorkers}
    >
      <i className="ri-add-line align-bottom m-1"></i> Add Worker
    </button>
    <button
      type="button"
      className="btn text-white add-btn me-1 mb-1"
      style={{
        backgroundColor: "#28a8df",
        borderColor: "#28a8df",
      }}
      onClick={imageHandlerclick}
    >
      <i className="ri-file-download-line align-bottom me-1"></i> Bulk Upload
    </button>
    <button
      type="button"
      className="btn text-white add-btn me-1 mb-1"
      style={{
        backgroundColor: "#28a8df",
        borderColor: "#28a8df",
      }}
      onClick={downloadFile}
    >
      <i className="ri-file-download-line align-bottom me-1"></i> Download Sample
    </button>
  </div>
</div>

                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={worker || []}
                      customPageSize={10}
                      isGlobalFilter={true}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search for worker..."
                    />
                  </div>
                </div>
              </Card>
              <ToastContainer closeButton={false} limit={1} />
            </Col>
          </Row>
          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the worker{" "}
              <strong>{RoleToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(RoleToDeleteId);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={bulkuploadModal} toggle={toggleBulkuploadModal} centered>
  <ModalHeader toggle={toggleBulkuploadModal}>
    Bulk upload
  </ModalHeader>
  <ModalBody>
  <div className='mb-3'>
              <label
                htmlFor='fileInput'
                className='col-form-label required fw-bold fs-6 form-label'
              >
                Upload File
              </label>
              <input type='file' className='form-control' id='fileInput' 
              ref={inputFileRef} 
              onChange={handleFileChange}
              />
            </div>
  </ModalBody>
  <ModalFooter>
    <Button color="secondary" onClick={toggleBulkuploadModal}>
      Cancel
    </Button>
    <Button
      color="success"
      disabled={isUploadButtonDisabled}
      onClick={(e) => { imageHandler(e) }}
    >
      Upload
    </Button>
  </ModalFooter>
</Modal>

        </Container>
      </div>
      <WorkerFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default WorkersList;
