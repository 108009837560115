import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getClientSupport,
  getpriority,
  createAgencySupport,
  updateclinicSupport,
  deleteclinicSupport,
} from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import Filter from "./Filter";

const SaAgencyList = (data: any) => {
  const history = useNavigate();
  const { userProfile } = useProfile();
  const Id = userProfile?.primaryID;
  const Type = userProfile?.userType;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Support, setSupport] = useState<any>([]);
  const [priority, setpriority] = useState<any>([]);
  const [id, setId] = useState<any>([]);
  const [agencysupportaccess, setagencysuportcaccess] = useState<any>([]);

  // Delete Support
  const [modal, setModal] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [SupportToDeleteId, setSupportToDeleteId] = useState<string>("");
  const [SupportToDeleteName, setSupportToDeleteName] = useState<string>("");

  useEffect(() => {
    let Data;
    if (Type === 2) {
      Data = 35;
    } else {
      Data = "";
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setagencysuportcaccess(res.Data[0]);
    });
  }, []);

  const addagencySupport = () => {
    if (agencysupportaccess?.add === 1) {
      toggle();
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const PortalAgencyOverview = (cellProps: any) => {
    setagencysuportcaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.view === 1) {
        history(
          `/PortalAgencyOverview/view/${cellProps.row.original.primaryId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess;
    });
  };

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    getpriority().then((res: any) => {
      setpriority(res.Data);
    });
  }, []);

  // Delete Data
  const onClickDelete = useCallback(
    (support: any) => {

      setSupportToDeleteName(support.name);
      setSupportToDeleteId(support.primaryId);

      setagencysuportcaccess((prevWorkerAccess: any) => {
        if (prevWorkerAccess?.remove === 1) {
          toggleDeleteModal();
        } else {
          toast.error("You don't have permission to access this page", {
            position: "top-right",
            style: {
              backgroundColor: "#9b2313",
              color: "#c7c7d7",
              fontSize: "14px",
            },
            closeButton: false,
          });
        }
        return prevWorkerAccess;
      });
    },
    [setSupportToDeleteName, setSupportToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      priority: (Support && Support.priority) || "",
      subject: (Support && Support.subject) || "",
      message: (Support && Support.message) || "",
    },
    validationSchema: Yup.object({
      priority: Yup.string().required('priority is required'),
      subject: Yup.string().required('subject is required'),
      message: Yup.string().required('message is required'),
    }),
    onSubmit: async(values: any) => {

      if (isEdit) {
        const updateSupport = {
          priority: values.priority,
          subject: values.subject,
          message: values.message,
        };
        //updateSupport(updateRole,values.id);

       await updateclinicSupport(updateSupport, id)
          toast.success("Agency Support Updated Successfully", {
            autoClose: 3000,
      
        });
       
      } else {
        const newSupport = {
          priority: values.priority,
          fromId: userProfile.primaryID,
          subject: values.subject,
          message: values.message,
        };
        await createAgencySupport(newSupport)
          toast.success("Agency Support created Successfully", {
            autoClose: 3000,
        });
      }
      toggle();
      validation.resetForm();
      fetchSupport();
    },
  });

  const confirmDelete = (primaryId: any) => {

    deleteclinicSupport(primaryId);

    toggleDeleteModal();
    toast.success("Support Agency deleted Successfully", { autoClose: 3000 });
  };

  // Update Data
  const handleSupportClick = useCallback(
    (arg: any) => {
      const Support = arg;
      setId(Support.primaryId);
      validation.setValues({
        subject: Support.subject,
        message: Support.message,
        priority: Support.supportpriorityId, // Make sure roleId is properly assigned
      });
      setIsEdit(true);

      setagencysuportcaccess((prevWorkerAccess: any) => {
        if (prevWorkerAccess?.edit === 1) {
          toggle();
        } else {
          toast.error("You don't have permission to access this page", {
            position: "top-right",
            style: {
              backgroundColor: "#9b2313",
              color: "#c7c7d7",
              fontSize: "14px",
            },
            closeButton: false,
          });
        }
        return prevWorkerAccess;
      });
    },
    [toggle, validation]
  );

  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false);

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const [ResetButton,setResetButton]=useState<Boolean>(false)
const handleResetButton=()=>{
  fetchSupport();
  setResetButton(false)
}
  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchSupport(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  useEffect(() => {
    fetchSupport(); // Fetch initial worker data
  }, []);

  const fetchSupport = async (filters?: any) => {
    try {
      const response = await getClientSupport(filters, Id);

      setSupport(response);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "SUBJECT",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "PRIORITY",
        accessorKey: "supportpriority.priorityName",
        enableColumnFilter: false,
      },
      {
        header: "STATUS",
        accessorKey: "supportstatus.statusName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const statusValue = cell.getValue();
          let badgeClass = "";
          switch (statusValue) {
            case "Open":
              badgeClass = "border border-danger text-danger";
              break;
            case "Inprogress":
              badgeClass = "border border-secondary text-secondary";
              break;
            case "Resolved":
              badgeClass = "border border-success-subtle text-success";
              break;
          }

          return (
            <span className={`badge text-uppercase ${badgeClass}`}>
              {statusValue}
            </span>
          );
        },
      },
      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const SupportData = cellProps.row.original;
                    handleSupportClick(SupportData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="View">
                <div
                  onClick={() => {
                    PortalAgencyOverview(cellProps);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </div>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const SupportData = cellProps.row.original;
                    onClickDelete(SupportData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleSupportClick, onClickDelete]
  );

  document.title = "Agency support | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Agency Support" pageTitle="Support" />
          <Row>
            <Col lg={12}>
              <Card id="employeeList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Agency Support</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}

                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleInfo}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>
                          Fliter
                        </button>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            addagencySupport();
                            validation.setValues({
                              subject: "",
                              message: "",
                              priority: "",
                            });
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Support
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Support || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search support"
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Support" : "Add Support"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div className="mb-3">
                          <Label
                            htmlFor="Supportname-field"
                            className="form-label"
                          >
                            Subject
                          </Label>
                          <Input
                            name="subject"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter Subject"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subject || ""}
                            invalid={
                              validation.touched.subject &&
                              validation.errors.subject
                                ? true
                                : false
                            }
                          />
                          {validation.touched.subject &&
                          validation.errors.subject ? (
                            <FormFeedback type="invalid">
                              {validation.errors.subject}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="status-field" className="form-label">
                            Type of Priority
                          </Label>

                          <Input
                            name="priority"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.priority || ""}
                            invalid={
                              validation.touched.priority &&
                              validation.errors.priority
                                ? true
                                : false
                            }
                          >
                            <option>Select a priority ...</option>
                            {priority?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div>
                          <Label htmlFor="phone-field" className="form-label">
                            Message
                          </Label>
                          <Input
                            name="message"
                            type="text"
                            id="phone-field"
                            placeholder="Enter Message"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ""}
                            invalid={
                              validation.touched.message &&
                              validation.errors.message
                                ? true
                                : false
                            }
                          />
                          {validation.touched.message &&
                          validation.errors.message ? (
                            <FormFeedback type="invalid">
                              {validation.errors.message}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button type="submit"       className="btn text-white add-btn me-1"
                                   style={{
                                     backgroundColor: "#28a8df",
                                     borderColor: "#28a8df",
                                   }}>
                            {" "}
                            {!!isEdit ? "Update" : "Add Support"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the User{" "}
              <strong>{SupportToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  confirmDelete(SupportToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
      <Filter
        show={isInfoDetails}
        onCloseClick={toggleInfo}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default SaAgencyList;
