import React, {  useState } from 'react';
import { Card,  Col, Container, Input, Label, Row, Button, Form, FormFeedback,} from 'reactstrap';
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthSlider from 'pages/AuthenticationInner/authCarousel';
import { getUserByToken, login } from './request';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo-light.png'


const Login = (props: any) => {
    const history = useNavigate();
  

    const [Error, setError] = useState<any>('');
    const [passwordShow, setPasswordShow] = useState<boolean>(false);




    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // username: 'test@gmail.com',
            // password: "12345",
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Email / User Id"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            try {
                // Make the login request
              const response= await login(values.username, values.password)

                const auth = response.data; // Assuming the token and other properties are in the data field     
                // Log the authentication data to the console to verify
             
                if (response?.Code == "0") {
                    setError(response.Message);
                }
                // Check if auth object is valid
                if (auth?.Code === '1') {
                    // Store authUser in sessionStorage
                    sessionStorage.setItem("authUser", JSON.stringify(auth));
                    // Optionally, store the token separately
                     await getUserByToken(auth.token);
                  if(auth.userType === 1){
                      history('/overview')
                  }
                  else if(auth.userType === 2){
                      history('/agency-portal')
                  }
                  else{
                      history('/clinic-portal')
                  }
                    // Redirect or do any other necessary actions after successful login
                } else {
                    // Handle invalid auth or authentication failure
                    console.error("Invalid authentication data:", auth);
                    // Optionally, display an error message to the user
                }
            
            } catch (error) {
                console.error("Error occurred during login:", error);
                // Optionally, display an error message to the user
            }
        }
        
        
    });




    //for facebook and google authentication
 



    document.title = "SignIn | Alkhairi Care";
    return (
        <React.Fragment>
          <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div className='text-center mb-2'>
                                                <img src={logo} alt="" height="35" width="120" />
                                                </div>
                                         {Error &&(       <div className=''>
    <p className='text-danger border border-danger rounded-3 p-2'>
        {Error}
    </p>
</div>)}


                                                <div className="mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#">

                                                        <div className="mb-3">
                                                            <Label htmlFor="username" className="form-label">Email / User Id</Label>
                                                            <Input type="text" className="form-control" id="username" placeholder="Enter Email / User Id"
                                                                name="username"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.username || ""}
                                                                invalid={
                                                                    validation.touched.username && validation.errors.username ? true : false
                                                                }
                                                            />
                                                            {validation.touched.username && validation.errors.username ? (
                                                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="float-end">
                                                                {/* <Link to="/auth-pass-reset-cover" className="text-muted">Forgot password?</Link> */}
                                                            </div>
                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type={passwordShow ? "text" : "password"} className="form-control pe-5 password-input" placeholder="Enter password" id="password-input"
                                                                    name="password"
                                                                    value={validation.values.password || ""}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>

                                                        {/* <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                        </div> */}

                                                        <div className="mt-4">
                                                            <Button color="success" className="w-100" type="submit">Sign In</Button>
                                                        </div>
                                            </Form>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} AlKhairi Care</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);