import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Row } from "reactstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { createBenefit } from "./_requests";
import BreadCrumb from "Components/Common/BreadCrumb";

const AddBenefit = () => {
  const history = useNavigate();
  const [benefit, setBenefit] = useState([{ name: "", description: "", status: "1" }]);

  const Formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      benefit: benefit,
    },
    validationSchema: Yup.object({
      benefits: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Name is required"),
          description: Yup.string().required("Description is required")
        })
      )
    }),
    onSubmit: async(values: any, { resetForm }) => {
        await createBenefit(values)
        resetForm();
        history("/benefit");
        setTimeout(()=>{    
          toast.success("Benefit Created Successfully", { autoClose: 3000 });
        },100)
      
    }
  });

  const addRow = () => {
    setBenefit([...benefit, { name: "", description: "", status: "1" }]);
  };

  const removeRow = (i: number) => {
    if (benefit.length === 1) return;
    const newBenefit = [...benefit];
    newBenefit.splice(i, 1);
    setBenefit(newBenefit);
  };
  document.title = " Benefit | Al khairi care ";
      function goBack() {
        history(-1);
      }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title=" create Benefit" pageTitle="Benefit" />
        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
       
          <Row>
            <Col xxl={9}>
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardHeader>
                  <h5>Benefits List</h5>
                  <p>Manage and configure Master Benefits List</p>
                </CardHeader>
                <CardBody className="p-4">
                  <Form onSubmit={Formik.handleSubmit}>
                  <Col
                      lg={12}
                      className="m-auto bg-light-warning rounded border-success border border-dashed" style={{ backgroundColor: "rgb(0 152 199 / 11%)" }}
                    >
                      <div className="notice d-flex m-1 mb-10 align-items-center">
                        <Col lg={1} className="ms-3">
                          <i
                            className="bx bx-message-square-check justify-self-center"
                            style={{ fontSize: "2rem" }}
                          ></i>
                        </Col>
                        <Col lg={11}>
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-bold">
                              <h4
                                className="text-gray-800 fw-bolder mt-2 me-5"
                                style={{ textAlign: "left" }}
                              >
                                Very Important
                              </h4>
                              <div className="fs-6 text-gray-600 pe-7 ms-2 mb-2 me-5">
                                Benefits list are been used to show the benefits to the Workers. So please provide accurate information about the benift name and its respective description
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Row className="my-3">
                      {benefit.map((item: any, i: number) => (
                        <div key={i} className="mb-3">
                          <Row>
                            <Col xs={5} xl={4}>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Benefit name"
                                value={item.name}
                                onChange={(e) => {
                                  const newBenefit = [...benefit];
                                  newBenefit[i].name = e.target.value;
                                  setBenefit(newBenefit);
                                }}
                              />
                            </Col>
                            <Col xs={5} xl={4}>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Description"
                                value={item.description}
                                onChange={(e) => {
                                  const newBenefit = [...benefit];
                                  newBenefit[i].description = e.target.value;
                                  setBenefit(newBenefit);
                                }}
                              />
                            </Col>
                            <Col xs={2} className="align-self-center">
                         
                                <i className="bx bxs-trash-alt" onClick={() => removeRow(i)}></i>
                           
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Row>
                    <div className="d-flex justify-content-start py-6 px-9">
                      <div onClick={addRow}   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                        Add New Field
                      </div>
                    </div>
                    <Row className="mt-3">
                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                         
                          <button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddBenefit;
