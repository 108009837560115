import React, {useEffect, useMemo, useRef, useState} from 'react'
import Flatpickr from "react-flatpickr";
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import { Button, Card, Container, Row } from 'reactstrap'
import TableContainer from './TableContainer';
import { getReportforClinic } from '../Worker Report/_requests';
import { useProfile } from 'Components/Hooks/UserHooks';
import { getClinicsById } from 'pages/Clinics/_requests';
import * as XLSX from 'xlsx';
const PortalClinicReportContent = () => {
  const { userProfile } = useProfile();
  const Id = userProfile?.primaryID;
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const tableRef = useRef(null)
  const [com, setCom] = useState<any>([])
  const [Total, setTotal] = useState<any>('')
  const [Clinic, setClinic] = useState<any>('')

  const [OCcom, setOCcom] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [OCplan, setOCplan] = useState('')
  const [clinicid, setclinicid] = useState(0)
  const [plan, setPlan] = useState<any>([])
  const [planid, setplanid] = useState(0)
  const [report, setReport] = useState<any>([])
  const [notreport, setNotReport] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);

  useEffect(() => {
    getClinicsById(Id).then((res: any) => {
      setClinic(res.name)
    })
  }, [])

  //


  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Clinic_Report_${fileName}`,
    sheet: 'Report',
  })

 

  const [OCcomid, setOCcomid] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const [planselectedOption, setplanselectedOption] = useState(null)
  const [clinicselectedOption, setclinicselectedOption] = useState(null)

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    var text = selectedOption?.label
    setOCcom(text)
    setclinicid(selectedOption?.value)
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  }
 
  const clinicReport = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate =selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange =(startDate && endDate) ? `${startDate} - ${endDate}` : "";


    setOCDate(Daterange);
    setLoading(true)
    getReportforClinic( clinicid, Daterange,1).then((resRep:any) => {
      //
      setReport(resRep.Data)
     const  total = resRep.Data.reduce((acc: number, res: any) => {
      return acc + parseInt(res.amount);
    }, 0);
     setTotal(total);
     
        if (resRep.Data.length > 0 ) {
          setbtnExcelDisable(false)
          setbtnClearDisable(false)
        } else {
          setbtnExcelDisable(true)
          setbtnClearDisable(true)
        }
      
    })
  }
  const clearReport = () => {
    setSelectedOption(null)
    setplanselectedOption(null)
    setclinicselectedOption(null)
    setTotal(null)
    setReport([])
    setNotReport([])
    setPlan([])
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setOCDate('')
    setOCplan('')
    setOCcom('')
    setplanid(0)
    setOCcomid(0)
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  

  const renderCreatedAt = (cell: any,dateKey :any) => {
    const formattedDate = new Date(
      cell.row.original[dateKey]
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "Name",
        enableColumnFilter: false,
      },
      {
        header: "NRIC",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agency.agencyName",
        enableColumnFilter: false,
      },
      {
        header: "Plan",
        accessorKey: "plan.planName",
        enableColumnFilter: false,
      },
      {
        header: "Clinic",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Visit Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell:any) => renderCreatedAt(cell, "visitDate"),
      },
      {
        header: "Diagnosis",
        accessorKey: "plan.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {" "}
              {cell.row.original.diagDetails?.map((items: any, i: any) => {
                return <>{items?.diagnosis?.name}</>;
              })}
            </>
          );
        },
      },
      {
        header: "MC Days",
        accessorKey: "totalMcDays",
        enableColumnFilter: false,
      },
      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      }
    ],
    []
  );

  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };

  const formatDataForExport = async () => {
    const exportData = [];
  
    await Promise.all(
      report.map(async (row:any, i:any) => {
        const diagnosisNames = row.diagDetails?.map((items:any) => items?.diagnosis?.name).join(', ');
  
        const dataRow = {
          sno: i + 1,
          Name: row.Name,
          nricPassport: row.nricPassport,
          agency: row.agency?.agencyName,
          plan: row.plan?.planName,
          clinic: row?.clinic?.clinicName,
          visitDate: new Date(row.visitDate).toLocaleDateString("en-GB"),
          diagnosis: diagnosisNames,
          totalMcDays: row.totalMcDays,
          amount: row.amount
        };
        exportData.push(dataRow);
      })
    );
  
    // Pushing total row
    const totalRow = {
      totalMcDays: "Total",
      amount: Total // Assuming Total is defined elsewhere
    };
    exportData.push(totalRow);
  
    return exportData;
  };
  

const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveExcelFile(excelBuffer);
};


  var Datefilename = new Date();
  var DateFileName  =
    Datefilename.getFullYear() +
    "-" +
    (Datefilename.getMonth() + 1) +
    "-" +
    Datefilename.getDate();
  
  const saveExcelFile = (buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `Clinic_Report${DateFileName}.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    
       <div className="page-content">
        <Container fluid>
          <Card>
        {/* begin::Header */}
        <div className='cursor-pointer'>
          <Row>
          <div className='col-sm'>
            <h5 className='card-title m-3'>Clinic Report</h5>
          </div>
          <div className='col-sm-auto m-3'>
            <button
         className="btn text-white add-btn me-1 col-lg-12"
         style={{
           backgroundColor: "#28a8df",
           borderColor: "#28a8df",
         }}
              disabled={btnExcelDisable}
              onClick={handleExport}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
           
        
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
            </div>

<div className='col-lg-2'>
  <br/>
                <Button onClick={clinicReport}     className="btn text-white add-btn me-1 mt-3 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }} >
                  Search
                </Button>
                </div>
                <div className='col-lg-2'>
                <br/>
                <Button
                  onClick={clearReport}
                  className="btn text-white add-btn me-1 mt-3 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  disabled={btnClearDisable}
                >
                  Clear
                </Button>
                </div>
          

          </div>
<Row className='mt-4'>
          <TableContainer       
                   
                      columns={columns}
                      data={report || []}
                      customPageSize={10}
                      divClass="table-responsive"
                      tableClass="align-middle table-nowrap mb-0"
                      clinic={Clinic}
                      date={OCDate}
                      total={Total}
                      onDownload={onDownload}
                    />
                    </Row>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        </Card>
        </Container>
      </div>
   
  )
}

export {PortalClinicReportContent}





