import axios from 'axios'
import getToken from 'helpers/jwt-token-access/auth-token-header'

const API_URL = process.env.REACT_APP_API_URL
const CLAIM_URL = `${API_URL}/claimform`
const C_IH_URL = `${API_URL}/worker/viewClaimedMember`
const GET_CLAIM_LIST = `${API_URL}/claimform/query`
const ADD_CLAIM_URL = `${API_URL}/claimform/addclaim`
const RA_URL = `${API_URL}/roleAccess`

type CategoryResponse = {
  id: number;
  name: string;
}[];
const listClaimForm = (filters:any) => {
  return axios.post(`${CLAIM_URL}/list`,filters,getToken() ).then((response) => {

    return response.data
  })
}
const listPortalClaimForm = (filters:any,Id:any) => {
  return axios.post(`${CLAIM_URL}/portalClaimlist/${Id}`,filters,getToken() ).then((response:any) => {
    return response.Data
  })
}

const getBalanceDetails = (id: any, catId: any) => {
  return axios
    .get(`${API_URL}claimform/balance/CurrentBalance/${id}/${catId}`,)
    .then((response) => {
      return response
    })
}
const deleteClaimFormById = (id: any,type:any) => {
  return axios.post(`${CLAIM_URL}/delete/hardAndSoft/${id}/${type}`).then((response) => {
  
    return response.data
  })
}
const getTreatementDetails = (id: any) => {
  return axios.get(`${CLAIM_URL}/claimTreatements/${id}`, ).then((response:any) => {
  
    return response.Data
  })
}
const getMCDetails = (id: any) => {
  return axios.get(`${CLAIM_URL}/claimMc/${id}`, ).then((response:any) => {
  
    return response.Data
  })
}
const getClaimform = (query: string)=> {
  return axios
    .get(`${GET_CLAIM_LIST}?${query}`,)
    .then((d:any ) => {
 
      return d.data
    })
}
const getClaimformPagination = (data: any) => {
 
  return axios
    .get(`${GET_CLAIM_LIST}?page=${data.page}&items_per_page=${data.items_per_page}`, )
    .then((d) => {
    
      return d.data
    })
}
const getMc = async () => {
  const res = await axios.get(`${CLAIM_URL}/getMC`,)

  return res
}
const getTreatement = async () => {
  const res = await axios.get(`${CLAIM_URL}/getTreatment`,)
  return res
}
const createClaimform = (Claim: any,clinicID:any) => {
  //debugger
  // Claim.mcdetails = Claim.mcdetails.filter(Boolean)
  // let treatmentdetails: any = []
  // Claim.treatmentdetails.forEach((element: any) => {
  //   if (element['cost'] || element['description']) {
  //     treatmentdetails.push(element)
  //   }
  // })
  // Claim.treatmentdetails = treatmentdetails
  return axios
    .post(`${ADD_CLAIM_URL}/${clinicID}`, Claim, )
    
    .then((response:any) => response)
}

const fetchData = async (id: string) => {

  const res = await axios.get(`${API_URL}/worker/search/${id}`)
  
  return res.data
}
const fetchDataVerify = async (id: any,radio:any) => {

  const res = await axios.get(`${API_URL}/worker/searchVerify/${id}/${radio}`)
  
  return res
}

const getCompanyById = (id: any)=> {
  return axios
    .get(`${CLAIM_URL}/${id}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}


const getCliniCategoryByplan =async (id: string): Promise<CategoryResponse> => 
  await axios.get(`${API_URL}/clinicCategory/getClinicCategoryByplan/${id}`).then((res:any)=>{ 
    return res.Data;
  })

const updateCompany = (clinic: any)=> {
  return axios
    .post(`${CLAIM_URL}/${clinic.id}`, clinic)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const deleteSelectedClaim = (selectedIds:any) => {

  return axios
  .post(`${CLAIM_URL}/deleteBulk`,{selectedIds},).then((response) => {return response})
}
const approveSelectedClaim = (selectedIds: any) => {
 
  return axios
  .post(`${CLAIM_URL}/bulkApproveClaimForm`,{selectedIds})
  .then((response) => {return response})
}
const rejectSelectedClaim = (selectedIds: any) => {
 
  return axios
  .post(`${CLAIM_URL}/bulkRejectClaimForm`,{selectedIds})
  .then((response) => {return response})
}
const getClaimformById = (id: any)=> {
  return axios
    .get(`${CLAIM_URL}/view/${id}`)
    .then((response) => {return response})
   
}
const getfamilymember = (id: any)=> {
  return axios
    .get(`${C_IH_URL}/${id}`)
    .then((response) => {return response})
   
}
const fetchapproveclaim = (id: any,workerId:any,billingAmount:any)=> {
  return axios
    .put(`${CLAIM_URL}/adminApproval/${id}`,{workerId,billingAmount})
    .then((response:any) => {
   
      return response
    })
   
}
const updateOtherCharges = (id: any,amt:any)=> {
  return axios
    .get(`${CLAIM_URL}/othercharges/AddForIndividual/${id}/${amt}`)
    .then((response:any) => {return response})
   
}

const getCRUD = (roleId: any,moduleId:any)=> {
  return axios
    .post(`${RA_URL}/getModuleWise/CRUD`,{roleId,moduleId})
    .then((response) => {return response})
   
}
const getClinicCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`)
    .then((response) => {return response})
   
}
const getCurretBlanceAmountById = (id: any,cid:any) => {
  return axios
    .get(`${API_URL}/claimform/balance/CurrentBalance/${id}/${cid}`)
    .then((response) => response.data)
    
}
export {
  listClaimForm,
  listPortalClaimForm,
  getCliniCategoryByplan,
  getClaimform,
  deleteClaimFormById,
  deleteSelectedClaim,
  getCompanyById,
  createClaimform,
  fetchDataVerify,
  updateCompany,getCRUD,
  fetchData,updateOtherCharges,
  getMc,getfamilymember,fetchapproveclaim,getClaimformPagination,
  getTreatement,getClaimformById,getTreatementDetails,getMCDetails,getClinicCategory,
  getCurretBlanceAmountById,approveSelectedClaim,getBalanceDetails,rejectSelectedClaim
}
