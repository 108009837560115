import { getClinicsById, updatecontrols } from 'pages/Clinics/_requests';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';

const Controls = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [clinic, setclinic] = useState<any>([]);
    useEffect(() => {
        getClinicsById(id).then((res: any) => {
            setclinic(res)
            
        })
      }, [])
    const sendEmail = () => {
        var Getname = clinic?.name
        var GetuserId = clinic?.userId
        var Getemail = clinic?.email
        var GetPincode = clinic?.pincode
        var data = {name: Getname, email: Getemail, pincode: GetPincode}
    
        updatecontrols(data, GetuserId).then((res) => {
         
          navigate('/clinics',);
          setTimeout(() => {
            toast.success("Email sent successfully");
        }, 100);
        })
      }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1">Reset Password</h5>
                    </div>
                        <Col lg={12}>
                        <div className="d-flex align-items-center mb-4">
                        <p className="card-title flex-grow-1">Click below button to resend username & password to corresponding clinic through email</p>
                       </div>
                       <div className="hstack gap-3 flex-wrap">
                       <Link onClick={sendEmail} className="btn btn-success add-btn me-1" to={''}>
                           Send Email
                          </Link>{" "}
                        </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Controls;