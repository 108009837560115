import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { GetBenefit, updateBenefit } from "./_requests";
import BreadCrumb from "Components/Common/BreadCrumb";

const EditBenefit = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [benefit, setBenefit] = useState<any>({ name: "", description: "" });

  useEffect(() => {
    GetBenefit(id).then((res: any) => {
      setBenefit(res);
    });
  }, [id]);

  const Formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: benefit.name,
      description: benefit.description,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          benefit: {
            name: values.name,
            description: values.description,
          }
        };
        
          await updateBenefit(payload, id)
          history("/benefit");
          setTimeout(()=>{   
            toast.success("Benefit Updated Successfully", { autoClose: 3000 });
          },100)
        
      } catch (error) {
        console.error("Error updating benefit:", error);
        toast.error("Failed to update benefit");
      }
    },
  });
  document.title = " Benefit | Al khairi care ";
      function goBack() {
        history(-1);
      }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title=" Edit Benefit" pageTitle="Benefit" />
        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
          
          <Row>
            <Col xxl={9}>
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardHeader>
                  <h5>Benefits List</h5>
                  <p>Manage and configure Master Benefits List</p>
                </CardHeader>
                <CardBody className="p-4">
                  <Form onSubmit={Formik.handleSubmit}>
                  <Col
                      lg={12}
                      className="m-auto bg-light-warning rounded border-success border border-dashed" style={{ backgroundColor: "rgb(0 152 199 / 11%)" }}
                    >
                      <div className="notice d-flex m-1 mb-10 align-items-center">
                        <Col lg={1} className="ms-3">
                          <i
                            className="bx bx-message-square-check justify-self-center"
                            style={{ fontSize: "2rem" }}
                          ></i>
                        </Col>
                        <Col lg={11}>
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-bold">
                              <h4
                                className="text-gray-800 fw-bolder mt-2 me-5"
                                style={{ textAlign: "left" }}
                              >
                                Very Important
                              </h4>
                              <div className="fs-6 text-gray-600 pe-7 mb-2  ms-2 me-5">
                                Benefits list are been used to show the benefits to the Workers. So please provide accurate information about the benift name and its respective description
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Row className="my-3">
                      <div className="mb-3">
                        <Row>
                          <Col xs={6} xl={4}>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter Benefit name"
                              {...Formik.getFieldProps("name")}
                            />
                            {Formik.touched.name && Formik.errors.name ? (
                              <div className="text-danger">{Formik.errors.name}</div>
                            ) : null}
                          </Col>
                          <Col xs={6} xl={4}>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter Description"
                              {...Formik.getFieldProps("description")}
                            />
                            {Formik.touched.description && Formik.errors.description ? (
                              <div className="text-danger">{Formik.errors.description}</div>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                         
                          <button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditBenefit;
