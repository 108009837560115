import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Card, CardBody, Col } from 'reactstrap';
import { widgetCount } from './_request';



const Widgets = () => {


    const [count, setCount] = useState<any>([]);

    useEffect(() => {
      widgetCount().then((res: any) => { 
             
      setCount(res.data)  
       
      });
    }, [])

    const DashboardWidgets = [
        {
            id: 1,
            title: "Total Workers",
            counter: count?.totalemployeeCount,
            arrowIcon: "ri-arrow-up-line",
            percentage: "17.32 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 2,
            title: "Total Agency",
            counter: count?.totalagencysCount,
            arrowIcon: "ri-arrow-down-line",
            percentage: "0.96 %",
            percentageClass: "danger",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 3,
            title: "Total Clinic",
            counter: count?.totalClinicsCount,
            arrowIcon: "ri-arrow-down-line",
            percentage: "3.87 %",
            percentageClass: "danger",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 4,
            title: "Total Claimform",
            counter: count?.totalClaimformCount,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 5,
            title: "Total Support",
            counter: count?.supportCount,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 6,
            title: "Total Payment Sent",
            counter: count?.paymentsent,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 7,
            title: "Total Invoice Sent",
            counter: count?.invoicesent,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "success",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
        {
            id: 8,
            title: "Total Payment Received",
            counter: count?.paymentReceive,
            arrowIcon: "ri-arrow-up-line",
            percentage: "1.09 %",
            percentageClass: "",
            icon: "ri-ticket-2-line",
            decimals: 0,
            prefix: "",
            suffix: "",
        },
    ];
    return (
        <React.Fragment>
            {(DashboardWidgets || []).map((item, key) => (
                <Col className='col-lg-3 col-sm-12 ' key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">{item.title}</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value" data-target="547">
                                            <CountUp
                                                start={0}
                                                end={item.counter}
                                                duration={3}
                                                suffix={item.suffix}
                                                prefix={item.prefix}
                                                decimals={item.decimals}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className={"badge bg-" + item.percentageClass + "-subtle text-" + item.percentageClass + " mb-0"}>
                                        {/* <i className={item.arrowIcon + " align-middle"}></i> {item.percentage} */}
                                    </span></p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                                            <i className={item.icon}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    );
};

export default Widgets;