import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { updatecontrolsAgency } from "../_requests";
import { toast } from "react-toastify";

const Controlss: React.FC<{ data: any }> = ({ data }) => {
  const navigate = useNavigate();
  const sendEmail = () => {
    var Emaildata = {
      name: data.name,
      email: data.email,
      pincode: data.pincode,
    };

    updatecontrolsAgency(Emaildata, data?.userId).then((res) => {
      navigate("/agency");
      setTimeout(() => {
        toast.success("Email sent successfully");
    }, 100);
    });
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1">Update Credentials</h5>
            </div>
            <Col lg={12}>
              <div className="d-flex align-items-center mb-4">
                <p className="card-title flex-grow-1">
                  Click below button to resend username & password to
                  corresponding company through email
                </p>
              </div>
              <div className="hstack gap-3 flex-wrap">
                <div
                  className="btn btn-success add-btn me-1"
                  onClick={sendEmail}
                >
                  Send Email
                </div>{" "}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Controlss;
