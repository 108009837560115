import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { getCompany } from './_requests';
import { Card, Container, Row } from 'reactstrap';
import React from 'react';

export const AgencyReportContent = () => {
  const tableRef = useRef(null);
  const [cli, setCli] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [employee, setEmployee] = useState<any>([]);
  const [report, setReport] = useState<any>([]);
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([null, null]);
  const [cliId, setCliId] = useState(0);
  const [OCcli, setOCcli] = useState('');
  const [OCDate, setOCDate] = useState('');

  const [loading, setLoading] = useState(false);
  const [btnExcelDisable, setBtnExcelDisable] = useState(true);
  const [btnClearDisable, setBtnClearDisable] = useState(true);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [selectedId, setSelectedId] = useState('');
 const [btndisable, setBtnDisable]=useState(true);
  const EmployeeChange = (event: any) => {
    const id = event.target.value;
    const selectedDataId = event.target.selectedOptions[0].getAttribute('data-id');
    setSelectedEmp(selectedDataId);
    setSelectedId(id);
  };

  const clinicChange = (event: any) => {
    var index = event.nativeEvent.target.selectedIndex;
    setOCcli(event.nativeEvent.target[index].text);
    setCliId(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  
  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setSelectedId(selectedOption?.value);
    setSelectedEmp(selectedOption?.emp_id);
    setBtnDisable(false)
  };

  useEffect(() => {
    getCompany().then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: 'All' };
      const optionsWithAll = [allOption, ...mappedOptions];
      setCompany(optionsWithAll);
    });
  }, []);

  const clinicReport = () => {
    const empDetails = { id: selectedId, empId: selectedEmp };
    navigate('/AgencyIndividualReport/NewReport/View/' + selectedId);

    var DatefileName = new Date();
    var fileName = DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate();
  };

  return (
    <>
       <React.Fragment>
    <div className="page-content">
      <Container fluid>
      <Row>
      <Card>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Agency Report</h3>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Agency</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={company}
                isSearchable
              />
            </div>
            <div className='col-lg-2 pt-12 mt-3'>
            <br></br>
              <button onClick={clinicReport}   className="btn text-white add-btn me-1 col-lg-12"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              disabled={btndisable}
                              
                              >
                Search
              </button>
            </div>
    
          </div>
          <div className='table-responsive'></div>
        </div>
        </Card>
      </Row>
      </Container>
      </div>
      </React.Fragment>
    </>
  );
};
