import { useProfile } from "Components/Hooks/UserHooks";
import { getrolewisesidemenu } from "pages/Roles/_requests-roleaccess";
import React, { useEffect, useState } from "react";

const Navdata = () => {
  const { userProfile } = useProfile();
  const currentRole = userProfile?.role;

  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [dynamicStates, setDynamicStates] = useState<{ [key: string]: boolean }>({});
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");
  const [groupedItems, setGroupedItems] = useState<{ [key: string]: any[] }>({});
  const [statesInitialized, setStatesInitialized] = useState(false);

  useEffect(() => {
    if (currentRole) {
      getrolewisesidemenu(currentRole)
        .then((response: any) => {
          const sidemenuitems = response?.Data || [];
  
          const groupedItems: { [key: string]: any[] } = {};
          sidemenuitems.forEach((item: any) => {
            const sbtitleName = item.module.sbtitle?.subtitileName || "Other";
            if (!groupedItems[sbtitleName]) {
              groupedItems[sbtitleName] = [];
            }
            groupedItems[sbtitleName].push({
              id: item.module.name,
              label: item.module.name,
              link: `/${item.module.link}`,
              parentId: sbtitleName,
            });
          });
  
          const initialDynamicStates: { [key: string]: boolean } = {};
          Object.keys(groupedItems).forEach((key) => {
            initialDynamicStates[key] = false;
          });

          setDynamicStates(initialDynamicStates);
          setGroupedItems(groupedItems);
          setStatesInitialized(true);
        })
        .catch((error) => {
          console.error("Error fetching sidemenu:", error);
        });
    }
  }, [currentRole]);

  useEffect(() => {
    if (statesInitialized) {
      const menuItems = Object.keys(groupedItems).map((key) => {
        return {
          id: key,
          label: key,
          icon: "",
          link: "/#",
          stateVariables: dynamicStates[key],
          click: generateItemClickHandler(key),
          subItems: groupedItems[key],
        };
      });
      setMenuItems(menuItems);
    }
  }, [statesInitialized, dynamicStates, groupedItems]);

  const generateItemClickHandler = (label: string) => (e: any) => {
    e.preventDefault();
    setDynamicStates((prevStates) => {
      const newState = !prevStates[label];
      const updatedStates = { ...prevStates, [label]: newState };
      setIsCurrentState(label);
      return updatedStates;
    });
    updateIconSidebar(e);
  };

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  return (
    <React.Fragment>
      {menuItems}
    </React.Fragment>
  );
};

export default Navdata;
