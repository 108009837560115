import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import { changeSidebarVisibility } from "slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

interface MenuItem {
  id: string;
  label: string;
  icon?: string;
  link?: string;
  stateVariables?: boolean;
  click?: () => void;
  subItems?: MenuItem[];
  isHeader?: boolean; // Add isHeader to MenuItem interface
}

const HorizontalLayout = (props: any) => {
  const [submenuStates, setSubmenuStates] = useState<{ [key: string]: boolean }>({});
  const [sidebarOpen, setSidebarOpen] = useState(false); // State variable to control sidebar visibility
  const navData = navdata().props.children;

  useEffect(() => {
    // Initialize menu
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
      const ul = document.getElementById("navbar-nav") as HTMLElement;
      const items: any = ul.getElementsByTagName("a");
      let itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      let matchingMenuItem = itemsArray.find((x) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.router.location.pathname, props.layoutType]);

  // Function to activate parent dropdown
  function activateParentDropdown(item: any) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
        var parentElementDiv =
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling;
        if (parentElementDiv)
          if (parentElementDiv.closest(".collapse"))
            parentElementDiv.closest(".collapse").classList.add("show");
        parentElementDiv.classList.add("active");
        var parentElementSibling =
          parentElementDiv.parentElement.parentElement.parentElement.previousElementSibling;
        if (parentElementSibling) {
          parentElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  // Function to remove activation
  const removeActivation = (items: any) => {
    items.forEach((item: any) => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (item.classList.contains("menu-link") || item.classList.contains("nav-link")) {
        item.setAttribute("aria-expanded", false);
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
    });
  };

  // Function to handle menu item click
  const handleMenuItemClick = (item: MenuItem) => {
    if (item.subItems && item.subItems.length > 0) {
      setSubmenuStates({ ...submenuStates, [item.id]: !submenuStates[item.id] });
      setSidebarOpen(!sidebarOpen); // Toggle sidebar state
    } else {
      removeActivation(document.querySelectorAll(".nav-item"));
    }
  };

  // Function to handle sub menu item click
  const handleSubMenuItemClick = () => {
    toogleMenuBtn()
    removeActivation(document.querySelectorAll(".nav-item"));
    setSidebarOpen(false); // Close sidebar
  };
  const dispatch : any = useDispatch();
  const selectDashboardData = createSelector(
    (state) => state.Layout,
    (sidebarVisibilitytype) => sidebarVisibilitytype.sidebarVisibilitytype
  );
// Inside your component
const sidebarVisibilitytype = useSelector(selectDashboardData);
  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    dispatch(changeSidebarVisibility("show"));

    if (windowSize > 767)
        humberIcon.classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === "horizontal") {
        document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
    }

    //For collapse vertical and semibox menu
    if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
        if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove('vertical-sidebar-enable');
            (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
        } else if (windowSize > 1025) {
            document.body.classList.remove('vertical-sidebar-enable');
            (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
        } else if (windowSize <= 767) {
            document.body.classList.add('vertical-sidebar-enable');
            document.documentElement.setAttribute('data-sidebar-size', 'lg');
        }
    }


    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
        document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
    }
};
  return (
    <React.Fragment>
      {(navData || []).map((item: MenuItem, key: number) => {
        const hasSubItems = item.subItems && item.subItems.length > 0;
        const isHeader = (item as any)["isHeader"]; // Explicitly type item to any and access isHeader
        const isActive = item.id in submenuStates && submenuStates[item.id];
        return (
          <React.Fragment key={key}>
            {!isHeader && hasSubItems ? (
              <li className="nav-item">
                <a
                  onClick={() => handleMenuItemClick(item)}
                  className="nav-link menu-link"
                  href="#"
                  data-bs-toggle="collapse"
                >
                  <i className={item.icon}></i>{" "}
                  <span data-key="t-apps">{props.t(item.label)}</span>
                </a>
                <Collapse
                  className="menu-dropdown"
                  isOpen={isActive}
                  id={`sidebar-${item.id}`}
                >
                  {item.subItems &&
                    item.subItems.map((subItem: MenuItem, subKey: number) => (
                      <ul key={subKey} className="nav nav-sm flex-column">
                        <li className="nav-item">
                          <Link
                            onClick={handleSubMenuItemClick}
                            to={subItem.link || "/#"}
                            className="nav-link"
                          >
                            {props.t(subItem.label)}
                          </Link>
                        </li>
                      </ul>
                    ))}
                </Collapse>
              </li>
            ) : (
              <li className="menu-title">
                <span data-key="t-menu">{props.t(item.label)}</span>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

HorizontalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(HorizontalLayout));
