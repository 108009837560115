import TableContainer from 'Components/Common/TableContainer';
import { getClinicPayment } from 'pages/Admin Invoices/Portal-Clinic -Payment/_requests';
import { getClaimlistClinic, getClinicsById, getSupportClinic } from 'pages/Clinics/_requests';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';


const PaymentsTab = () => {
    const { id } = useParams();
    const [worker, setWorker] = useState<any>([]);
    useEffect(() => {
     
        getClinicPayment(id).then((response: any) => { 
          setWorker(response);
          
        });
      }, []);
      const renderCreatedAt = (cell: any) => {
        const formattedDate = new Date(cell.row.original.createdAt).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      
        return <>{formattedDate}</>;
      };
      const columns = useMemo(
        () => [
            {
                header: "S.No",
                id: 'sno',
                enableColumnFilter: false,
                cell: (cellProps: any) => {
                  return cellProps.row.index + 1;
                },
              },
          {
            header: "Date",
            accessorKey: "paymentDate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <>
                  {cell.getValue() && (
                    <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
                  )}
                </>
              ),
          },
          {
              header: "Reference",
              accessorKey: "invoiceNumber",
              enableColumnFilter: false,
            },
            {
              header: "Invoice Description",
              accessorKey: "description",
              enableColumnFilter: false,
            },
          {
            header: "Amount ( RM )",
            accessorKey: "total",
            enableColumnFilter: false,
          },
      
          {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            cell: (cell:any) => {
              switch (cell.getValue()) {
                case 2:
                  return (
                    <span className="badge border border-danger text-danger">
                      Invoice Created
                    </span>
                  );
                case 1:
                  return (
                    <span className="badge border border-success text-success">
                      Payment Submitted
                    </span>
                  );
                default:
                  return (
                    <span className="badge border border-danger text-danger">
                      Invoice Created
                    </span>
                  );
              }
              },
          },
       
          {
            header: "Action",
            cell: (cellProps: any) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to={`/portal-clinic-payment/view/${cellProps.row.original.primaryId}`}
                      className="text-primary d-inline-block edit-item-btn"
                    >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
                </ul>
              );
            },
          },
        ],
        []
      );
      
    return (
        <React.Fragment>
          
                <Row>
            <Col lg={12}>
              <Card id="WorkresList">
              
          
                <div className="card-body pt-0">
                  <div>
                    
                    <TableContainer
                    columns={columns}
                    data={(worker || [])}
                    isGlobalFilter={true}
                    customPageSize={10}
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap mb-0"
                    SearchPlaceholder="Search payment"
                />
                
                   
                  </div>
                  <div>
               
                  </div>

                </div>
              </Card>
            </Col>
          </Row>
            
        </React.Fragment>
    );
};

export default PaymentsTab;