import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Widgets from "./Widgets";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useProfile } from "Components/Hooks/UserHooks";
import { getClinicsById, getCRUD } from "./_requests";
import ClaimData from "./ClaimData";

const ClinicDashboard = () => {
  const history = useNavigate();
  const [clinic, setClinic] = useState<any>([]);
  const { userProfile } = useProfile();
  const [claimaccess, setclaimaccess] = useState<any>([]);
  const [payaccess, setpayaccess] = useState<any>([]);

  //Current Date
  const current = new Date();
  const dateCurrent = new Date().toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  ////Current Date
  var clinicsID = userProfile?.primaryID;
  document.title = "Clinic Overview | Alkhairi care";
  const time = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    getClinicsById(clinicsID).then((res: any) => {
      setClinic(res);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 24).then((res: any) => {
      setclaimaccess(res.Data[0]);
    });
  }, []);

  useEffect(() => {
   
    getCRUD(userProfile?.role, 26).then((res: any) => {
      setpayaccess(res.Data[0]);
    });
  }, []);

  const addClaim = () => {
    if (claimaccess?.add === 1) {
      history("/claim-form/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const addPayment = () => {
    
    if (payaccess?.add === 1) {
      history("/portal-clinic-payment/add");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Clinic Dashboard</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody className="p-0">
                  <Row className="align-items-end">
                    <Col>
                      <div className="p-3 ">
                        <div className="mt-3">
                          <p className="fw-bold text-muted text-hover-primary fs-4">
                            {"Welcome to " + clinic?.name}
                          </p>
                        </div>
                        <div className="fw-bold text-primary my-6">
                          {"Date: " + dateCurrent}
                        </div>
                        <div className="mt-3 text-dark-75 fw-semibold fs-5 m-0">
                          <p className="fs-16 lh-base m-0">
                            Manage and configure Your Clinic's
                          </p>
                          <p className="fs-16 lh-base m-0">settings here</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="position-relative mb-1">
                    <div className="mt-3">
                      <p className="fw-bold  text-hover-primary fs-4 mx-3">
                        Add or Raise a
                        <a
                          onClick={addClaim}
                          className=" text-underline fw-bold fs-5 mx-2"
                        >
                          new Claim Form
                        </a>
                        here <p>for more support for Contact us</p>
                      </p>
                    </div>

                    <div className="mt-1  fw-semibold fs-5">
                      <div
                        onClick={addClaim}
                        className="btn text-white add-btn me-1 mb-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Raise Claim Form
                      </div>
                      <div
                        onClick={addPayment}
                        className="btn text-white add-btn me-1 mb-1"
                        style={{
                          backgroundColor: "#28a8df",
                          borderColor: "#28a8df",
                        }}
                      >
                        Raise Payment Request
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Widgets />
          </Row>

          <Row>
            <Col xl={12}>
              <ClaimData />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicDashboard;
