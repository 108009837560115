import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Button, Card, Col, Container, FormFeedback, Row } from "reactstrap";
import TableContainer from "./TableContainer";
import { getCompany } from "pages/Workers/_requests";
import { AddCharges, getClaimListToAddCharges } from "./export _requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddOtherCharges = () => {
  const tableRef = useRef(null);
  const [Agency, setAgency] = useState<any>(0);
  const [Valid, setValid] = useState("");
  const [Claim, setClaim] = useState<any[]>([]);
  const [btnDisable, setbtnDisable] = useState(true);
  const [btnClearDisable, setbtnClearDisable] = useState(true);
  useEffect(() => {
    getCompany().then((res: any) => {
      const mappedOptions = res.data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "Select Agency" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setAgency(optionsWithAll);
      setSelectedAgency(optionsWithAll[0] || null);
    });
  }, []);

  const clearReport = () => {
    setSelectedAgency(Agency[0] || null); // Set the first agency as selected
    setSelectedYear(yearsOptions[0] || null); // Set the first year as selected
    setSelectedMonth(monthsOptions[0] || null); // Set the first month as selected
    setClaim([]);
  };
  const Report = () => {
    if (selectedAgency.value !== 0) {
      getClaimListToAddCharges(
        selectedAgency.value,
        selectedYear.value,
        selectedMonth.value
      ).then((res: any) => {
        setClaim(res.Data);

        if (res?.Data.length > 0 ) {
          setbtnClearDisable(false);
        } else {
          setbtnClearDisable(true);
        }

      });




    } else {
      const Validation = "Please Select Agency";
      setValid(Validation);
    }
    callBtnProperty();
  };

  



  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const years = Array.from({ length: ( currentYear -2019)  + 5}, (_, i) => {
    const year = 2020 + i; // Start from 2020 and go up to the current year plus five years
    return { value: year, label: year.toString() };
  });
  const optionsWithYear = { value: 0, label: "Select Year" };
  const yearsOptions = [optionsWithYear, ...years];
  
  

  const months = Array.from({ length: 12 }, (_, i) => {
    const monthNumber = i + 1;
    return {
      value: monthNumber,
      label: new Date(2000, i, 1).toLocaleString("default", { month: "long" }),
    };
  });
  const optionsWithMonth = { value: 0, label: "Select Month" };
  const monthsOptions = [optionsWithMonth, ...months];


  const [selectedYear, setSelectedYear] = useState<any>({ value: currentYear, label: currentYear });
  const [selectedMonth, setSelectedMonth] = useState<any>(months.find((month: any) => month.value === currentMonth));
  const [selectedAgency, setSelectedAgency] = useState<any>(null);

  // Generate array of years from 2020 current year
 
  // Get current year and month

  // Set initial selected year to current year
  if (!selectedYear) {
    setSelectedYear({ value: currentYear, label: currentYear });
  }

  // Set initial selected month to current month
  if (!selectedMonth) {
    setSelectedMonth(months.find((month: any) => month.value === currentMonth));
  }


  const updateCharges = () => {
    AddCharges(Claim).then((res: any) => {
      setSelectedAgency(Agency[0] || null); 
      setSelectedYear({ value: currentYear, label: currentYear }); 
      setSelectedMonth(months.find((month: any) => month.value === currentMonth)); 
      setClaim([]);
      toast.success(res?.Message);
    });
  };

  // Event handler for year select change
  const handleYearSelectChange = (selectedOption: any) => {
    setSelectedYear(selectedOption);
  };

  // Event handler for month select change
  const handleMonthSelectChange = (selectedOption: any) => {
    setSelectedMonth(selectedOption);
  };

  const handleAgencyChange = (selectedOption: any) => {
    setSelectedAgency(selectedOption);
    setValid('');
  };

  const renderCreatedAt = (cell: any, dateKey: any) => {
    const formattedDate = new Date(
      cell.row.original[dateKey]
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };
  const handleAmountChange = (index: number, amount: string) => {
    if (index >= 0 ) {
      setClaim(prevClaims => {
        const newClaims = [...prevClaims]; 
        if (newClaims[index]) {
          newClaims[index] = {
            ...newClaims[index],
            otherCharges: amount
          };
        } else {
          newClaims.splice(index, 0, { otherCharges: amount }); // Insert at index
        }
        return newClaims; // Return the updated array
      });
    
    } else {
      console.error('Invalid index:', index);
    }
    callBtnProperty()
  };
  const callBtnProperty = () => {
    setClaim((prevClaims:any) => {
    const hasOtherCharges = prevClaims.some((item: any) => item.otherCharges !== null && item.otherCharges !== "");
    if (hasOtherCharges) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
    return prevClaims; // Return the updated array
  });
  };
  
  

  const OtherCharges= (cellProps :any) => {
    const [inputValue, setInputValue] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      handleAmountChange( cellProps.row.index, newValue);
    };

    return (
      <input
        type="number"
        value={inputValue}
        onWheel={(event) => {
          var blurEve = event.target as HTMLInputElement;
          blurEve.blur();
        }}
        onChange={handleChange}
        className="form-control"
      />
    );
  };
  

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Patient Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Worker Id",
        accessorKey: "workerId",
        enableColumnFilter: false,
      },

      {
        header: "Claim Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell: any) => renderCreatedAt(cell, "visitDate"),
      },
      {
        header: "Clinic Name",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Agency Name",
        accessorKey: "agency.agencyName",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },

      {
        header: "Other Charges",
        accessorKey: "othercharges",
        enableColumnFilter: false,
        cell: OtherCharges,
      },
    ],
    []
  );
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Agency_Report`,
    sheet: "Report",
  });
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          {/* begin::Header */}
          <div className="cursor-pointer">
            <Row>
              <div className="col-sm">
                <h5 className="card-title m-3">Agency Report</h5>
              </div>
            </Row>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="row mb-10">
              <div className="col-lg-4">
                <label className=" col-form-label required fw-bold fs-6">
                  Agency
                </label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={selectedAgency}
                  onChange={handleAgencyChange}
                  options={Agency}
                  isSearchable
                />
                 <p className="text-danger">{Valid}</p>
              </div>
              <div className="col-lg-4">
                <label className="col-form-label fw-bold fs-6">Year</label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={selectedYear}
                  onChange={handleYearSelectChange}
                  options={yearsOptions}
                  isSearchable
                />
               
              </div>
              <div className="col-lg-4">
                <label className="col-form-label fw-bold fs-6">Month</label>
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={selectedMonth}
                  onChange={handleMonthSelectChange}
                  options={monthsOptions}
                  isSearchable
                />
              </div>
         
              <Row className="my-2">
                <Col lg={2} xl={2} className="my-2">
                  <Button
                    onClick={Report}
                    className="btn text-white add-btn me-1 col-lg-12"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                  >
                    Search
                  </Button>
                </Col>
                <Col lg={2} xl={2} className="my-2">
                  <Button
                    onClick={clearReport}
                    className="btn text-white add-btn me-1 col-lg-12"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                    disabled={btnClearDisable}
                  >
                    Clear
                  </Button>
                </Col>
                <Col lg={2} xl={2} className="my-2">
                  <Button
                    onClick={updateCharges}
                    className="btn text-white add-btn me-1 col-lg-12"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                    disabled={btnDisable}
                  >
                    Update Charges
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <TableContainer
                columns={columns}
                data={Claim || []}
                customPageSize={10}
                divClass="table-responsive"
                tableClass="align-middle table-nowrap mb-0"
                agency=" "
                plan=" "
                date=" "
                total=" "
              />
            </Row>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </Card>
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </div>
  );
};

export { AddOtherCharges };
