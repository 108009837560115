import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL



const getReport = (date: any) => {
  return axios
    .post(`${API_URL}/report/exportClinic`,{date}, )
    .then((d) => {
     //
      return d
    })
}


export {
  getReport
}
