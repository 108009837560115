import { useEffect, useState } from "react";
import { getClientByPlanId } from "./_requests";



export const AgencyInfoCell = (plan :any) => {
    const [agencyData, setAgencyData] = useState([]);
  
    useEffect(() => {
        try {
          getClientByPlanId(plan.plan).then((res:any)=>{
            setAgencyData(res?.Data);
          })           
        } catch (error) {
          console.error("Error fetching agency data:", error);
        }
    }, []);
  
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
            {agencyData?.map((item: any, i: any) => (
              <div key={i}>
                <div className='badge bg-success text-white fw-bolder ms-2 fs-8 py-1 px-3 m-2'>
                  {item?.agency?.agencyname}
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
    
  };