import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  getReport,
} from './_requests'
import Flatpickr from "react-flatpickr";
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import { Button, Card, Container, Row } from 'reactstrap'
import TableContainer from './TableContainer';
import * as XLSX from 'xlsx';

interface ReportRow {
  [key: string]: string | number | undefined;
}
interface Column {
  header: string;
  accessorKey?: string;
}
const CliniExportContents = () => {
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const tableRef = useRef(null)
  const [Total, setTotal] = useState<any>('')
  const [OCcom, setOCcom] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [report, setReport] = useState<any>([])
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Telephone number",
        accessorKey: "telephoneNumber",
        enableColumnFilter: false,
      },
      {
        header: "Monday Timing",
        accessorKey: "monday_time",
        enableColumnFilter: false,
      },
      {
        header: "Tuesday Timing",
        accessorKey: "tuesday_time",
        enableColumnFilter: false,
      },
      {
        header: "WednesDay Timing",
        accessorKey: "wednesday_time",
        enableColumnFilter: false,
      },
      {
        header: "Thursday Timing",
        accessorKey: "thursday_time",
        enableColumnFilter: false,
      },
      {
        header: "Friday Timing",
        accessorKey: "friday_time",
        enableColumnFilter: false,
      },
      {
        header: "Saturday Timing",
        accessorKey: "saturday_time",
        enableColumnFilter: false,
      },
      {
        header: "Sunday Timing",
        accessorKey: "sunday_time",
        enableColumnFilter: false,
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
       
      },
      {
        header: "City",
        accessorKey: "city",
        enableColumnFilter: false,
     
      },
      {
        header: "State",
        accessorKey: "state",
        enableColumnFilter: false,
      },
      {
        header: "Country",
        accessorKey: "country",
        enableColumnFilter: false,
      },
      {
        header: "PinCode",
        accessorKey: "pincode",
        enableColumnFilter: false,
      },
      {
        header: "Fax",
        accessorKey: "fax",
        enableColumnFilter: false,
      },
      {
        header: "Website",
        accessorKey: "website",
        enableColumnFilter: false,
      },
      {
        header: "Location",
        accessorKey: "location",
        enableColumnFilter: false,
      },
    ],
    []
  );


  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Clinic_Report_${fileName}`,
    sheet: 'Report',
  })

 

 
  const clinicReport = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate =selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange =(startDate && endDate) ? `${startDate} - ${endDate}` : "";


    setOCDate(Daterange);
    getReport(  Daterange).then((resRep:any) => {
      setReport(resRep.Data)  
        
        if (resRep.Data.length > 0 ) {
          setbtnExcelDisable(false)
          setbtnClearDisable(false)
        } else {
          setbtnExcelDisable(true)
          setbtnClearDisable(true)
        }
      
    })
  }
  const clearReport = () => {
    setTotal(null)
    setReport([])
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setOCDate('')
    setOCcom('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  


  
  const formatDataForExport = async () => {
    const exportData = [];

    // // First row with agency, plan, and date
    // const firstRow = {
    //     'Agency': OCcom,
    //     'Plan': OCplan,
    //     'Date': OCDate,
    // };
    // exportData.push(firstRow);

    // // Empty row
    // exportData.push({});

    // Data rows
    await Promise.all(report.map(async (row:any, i:any) => {
        const dataRow = {
            'S.No': i + 1,
            'Worker Name': row.workerName,
            'NRIC': row.nricPassport,
            'Wbs Code': row?.wbsCode,
            'Status': row.suspenseStatus === 2 ? 'Suspended' : 'Active',
            'Plan': row.plan.planName,
            'Category': row.cliniccategory?.name,
            'Valid Till': new Date(row.validityTo).toLocaleDateString('en-GB'),
            'Limit': row.OrgplanLimit,
            'Date': new Date(row.visitDate).toLocaleDateString('en-GB'),
            'Clinic': row.clinic?.clinicName,
            'Diagnosis': row.diagDetails ? row.diagDetails.map((item:any) => item?.medicalcondition?.name).join(', ') : '',
            'MC Days': row?.totalMcDays,
            'Balance': row?.workerCurrentBalance,
            'Charge (RM)': row?.amount,
        };
        exportData.push(dataRow);
    }));

    // Last row with total
    const lastRow = {
        'Balance': 'Total',
        'Charge (RM)': Total,
    };
    exportData.push(lastRow);

    return exportData;
};
const handleExport = async () => {
  // Uncomment this line if you want to log the mapped report

  // Uncomment this line to export the mapped report to Excel
  exportToExcel(mappedReport);
};




const mappedReport: ReportRow[] = report.map((rowData: ReportRow, rowIndex: number) => {
  const mappedRow: ReportRow = {};

  // Set the serial number
  
  // Loop through each column (excluding S.No)
  columns.forEach((column: Column) => {
    const { accessorKey, header } = column;
    
    // Check if accessorKey is defined and not null
    if (typeof accessorKey === 'string' && rowData.hasOwnProperty(accessorKey)) {
      mappedRow["S.No"] = rowIndex + 1;
      // Log the accessorKey and its value for debugging

      // Assign value from rowData to mappedRow
      mappedRow[header] = rowData[accessorKey] !== undefined ? rowData[accessorKey] : '';
    } else {
      // If accessorKey is not defined or does not exist in rowData, assign empty string to mappedRow
      mappedRow[header] = '';
    }
  });


  return mappedRow;
});


const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveExcelFile(excelBuffer);
};


  var DatefileName = new Date();
  var DateFileName  =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();
  
  const saveExcelFile = (buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `Clinic_Report${DateFileName}.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  


 
  document.title = " Clinic Report  | Al khairi care ";
  return (
    
       <div className="page-content">
        <Container fluid>
          <Card>
        {/* begin::Header */}
        <div className='cursor-pointer'>
          <Row>
          <div className='col-sm'>
            <h5 className='card-title m-3'>Export Clinic Data</h5>
          </div>
          <div className='col-sm-auto m-3'>
            <button
   className="btn text-white add-btn me-1"
   style={{
     backgroundColor: "#28a8df",
     borderColor: "#28a8df",
   }}
              disabled={btnExcelDisable}
              onClick={handleExport}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
       
        
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Created Date</label>
              <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
            </div>

<div className='col-lg-2'>
  <br/>
                <Button onClick={clinicReport}     className="btn text-white mt-3 add-btn me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}>
                  Search
                </Button>
                </div>
                <div className='col-lg-2'>
                <br/>
                <Button
                  onClick={clearReport}
                  className="btn text-white mt-3 add-btn me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  disabled={btnClearDisable}
                >
                  Clear
                </Button>
                </div>
          

          </div>
<Row className='mt-4'>
          <TableContainer       
                   
                      columns={columns}
                      data={report || []}
                      customPageSize={10}
                      divClass="table-responsive"
                      tableClass="align-middle table-nowrap mb-0"
                      clinic={OCcom}
                      date={OCDate}
                      total={Total}
                      onDownload={onDownload}
                    />
                    </Row>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        </Card>
        </Container>
      </div>
   
  )
}

export {CliniExportContents}





