import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Widgets from './Widgets';
import { BasicLineCharts } from './chart';
import { ClaimApprovedGraph, PaymentReceiveGraphs, PaymentSentGraphs, vistGraph } from './_request';


const Dashboard = () => {

    const [data, setData] = useState<number[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const [ClaimApprovedata, setClaimApproveData] = useState<number[]>([])
    const [ClaimApprovecategories, setClaimApproveCategories] = useState<string[]>([])
    const [PaymentSentdata, setPaymentSentData] = useState<number[]>([])
    const [PaymentSentcategories, setPaymentSentCategories] = useState<string[]>([])
    const [PaymentReceivedata, setPaymentReceiveData] = useState<number[]>([])
    const [PaymentReceivecategories, setPaymentReceiveCategories] = useState<string[]>([])

    useEffect(() => {
        vistGraph().then((response) => {
            setData(response.data.data)
            setCategories(response.data.categories)

        })
      }, [])

      useEffect(() => {
          PaymentReceiveGraphs().then((response) => {
          setPaymentReceiveData(response.data.data);
          setPaymentReceiveCategories(response.data.categories);
        });
      }, []);

      useEffect(() => {
        ClaimApprovedGraph().then((response) => {
          setClaimApproveData(response.data.data);
          setClaimApproveCategories(response.data.categories);
        });
      }, []);

      useEffect(() => {
        PaymentSentGraphs().then((response) => {
          setPaymentSentData(response.data.data);
          setPaymentSentCategories(response.data.categories);
        });
      }, []);

    document.title="Dashboard | Alkhairi care";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                    
                    <BreadCrumb title="Dashboard" pageTitle="Dashboard" />
                    <Row>
                        <Widgets />
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Visit Graph Based on Month</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <BasicLineCharts dataColors='["--vz-primary"]' data={data}  categories={categories} name='Visit Graph' />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                <h4 className="card-title mb-0">Payment Sent Based on Month</h4>
                                </CardHeader>
                                <CardBody>
                                <div>
                                        <BasicLineCharts dataColors='["--vz-primary"]' data={PaymentSentdata}  categories={PaymentSentcategories} name='Payment Sent'/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Payment Received Based on Month</h4>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <BasicLineCharts dataColors='["--vz-primary"]' data={PaymentReceivedata}  categories={PaymentReceivecategories} name='Payment Received'/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                <h4 className="card-title mb-0">Approved Claimform Info Based on Month</h4>
                                </CardHeader>
                                <CardBody>
                                <div>
                                        <BasicLineCharts dataColors='["--vz-primary"]' data={ClaimApprovedata}  categories={ClaimApprovecategories} name='Approved Claims'/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;