import TableContainer from 'Components/Common/TableContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Card,
  } from "reactstrap";
import { WorkerSupport } from '../_requests';


const Support = () => {
    const {id}=useParams()
    const [Invoice,SetInvoice]=useState('')
       useEffect(()=>{
        WorkerSupport(id).then((res:any)=>{
               
               SetInvoice(res)
           })
       },[])
       const columns = useMemo(
           () => [
             {
               header: "S.No",
               id: "sno",
               enableColumnFilter: false,
               cell: (cellProps: any) => {
                 return cellProps.row.index + 1;
               },
             },
       
             {
               header: "Date",
               accessorKey: "createdAt",
               enableColumnFilter: false,
               cell: (cell: any) => (
                   <>
                     {cell.getValue() && (
                       <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
                     )}
                   </>
                 ),
             },
             {
               header: "Subject",
               accessorKey: "subject",
               enableColumnFilter: false,
             },
             {
               header: "Priority",
               accessorKey: "supportpriority.priorityName",
               enableColumnFilter: false,
             },
         
             {
                header: "Status",
                accessorKey: "supportstatus.statusName",
                enableColumnFilter: false,
                cell: (cell: any) => {
                  const statusValue = cell.getValue();
                  let badgeClass = "";
                  switch (statusValue) {
                    case "Open":
                      badgeClass = "border border-danger text-danger";
                      break;
                    case "Inprogress":
                      badgeClass = "border border-secondary text-secondary";
                      break;
                    case "Resolved":
                      badgeClass = "border border-success-subtle text-success";
                      break;
                  }
              
                  return <span className={`badge text-uppercase ${badgeClass}`}>{statusValue}</span>;
                }
              },
             {
               header: "ACTION",
               cell: (cellProps: any) => {
                 return (
                   <ul className="list-inline mb-0 ms-3">
                     <li className="list-inline-item edit" title="View">
                       <Link
                         to={`/agencyview/${cellProps.row.original.primaryId}`}
                         className="text-primary d-inline-block edit-item-btn"
                       >
                         <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                       </Link>
                     </li>
                   </ul>
                 );
               },
             },
           ],
           []
         );
    return (
        <React.Fragment>
         <Card >
           <div className="card-body pt-0 m-0">
             <div>
            
                 <TableContainer
                   columns={columns}
                   data={Invoice || []}
                   isGlobalFilter={true}
                   customPageSize={10}
                   divClass="table-responsive table-card mb-3"
                   tableClass="align-middle table-nowrap mb-0"
                   SearchPlaceholder="Search for support"
                 />
             
               {/* <ToastContainer closeButton={false} limit={1} /> */}
             </div>
        
           </div>
         </Card>
      
  
   </React.Fragment>
    );
};

export default Support;