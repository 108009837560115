import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { createClinics, getCountry } from "./_requests";
import {} from "./index";
import { useFormik } from "formik";
import { checkDuplicateEmail } from "pages/Users/_requests";
import BreadCrumb from "Components/Common/BreadCrumb";
interface FormValues {
  // Define your form fields here
  [key: string]: string | boolean;
}
const ClinicsAdd = () => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [apicountry, setapiCountry] = useState<any>([]);
  const [monday_status, setMondayStatus] = React.useState(0);
  const [tuesday_status, setTuesdayStatus] = React.useState(0);
  const [wednesday_status, setWednesdayStatus] = React.useState(0);
  const [thursday_status, setThursdayStatus] = React.useState(0);
  const [friday_status, setFridayStatus] = React.useState(0);
  const [Saturday_status, setSaturdayStatus] = React.useState(0);
  const [sunday_status, setSundayStatus] = React.useState(0);
  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const generateDayValidationSchema = (day: string) => {
    return {
      [`${day}_time`]: Yup.string().required(`${day} timing is required`),
      [`${day}_open`]: Yup.string().test(`${day}-open-required`, `${day} opening time is required`, function(value) {
        const isOpenSelected = this.parent[`${day}Status`] === true;
        return isOpenSelected ? !!value : true;
      }),
      [`${day}_close`]: Yup.string().test(`${day}-close-required`, `${day} closing time is required`, function(value) {
        const isOpenSelected = this.parent[`${day}Status`] === true;
        return isOpenSelected ? !!value : true;
      }),
    };
  };
  
  // Define the days of the week
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  
  const formik: any = useFormik<FormValues>({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      telephoneNumber: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      fax: "",
      website: "",
      location: "",
      Code: "",
      Message: "",
      account_number: "",
      accountholder_name: "",
      bankname: "",
      option: "",
      monday_open: "",
      monday_close: "",
      tuesday_open: "",
      tuesday_close: "",
      wednesday_open: "",
      wednesday_close: "",
      thursday_open: "",
      thursday_close: "",
      friday_open: "",
      friday_close: "",
      saturday_open: "",
      saturday_close: "",
      sunday_open: "",
      sunday_close: "",
      monday_time: "",
      tuesday_time: "",
      wednesday_time: "",
      thursday_time: "",
      friday_time: "",
      saturday_time: "",
      sunday_time: "",
      sundayStatus: "",
      mondayStatus: "",
      tuesdayStatus: "",
      wednesdayStatus: "",
      thursdayStatus: "",
      fridayStatus: "",
      saturdayStatus: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Clinic name is required"),
      email: Yup.string().required("Email is required"),
      telephoneNumber: Yup.string().required("Phone Number is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      pincode: Yup.string().required("Pincode is required"),
      country: Yup.string().required("Country is required"),
      website: Yup.string().required("website is required"),
      location: Yup.string().required("location Status is required"),
      bankname: Yup.string().required("bank name is required"),
      account_number: Yup.string().required("Account number is required"),
      accountholder_name: Yup.string().required(
        "Account holder name is required"
      ),
      fax: Yup.string().required("fax is required"),
      ...daysOfWeek.reduce((acc, day) => {
        return { ...acc, ...generateDayValidationSchema(day) };
      }, {})
    }), 
    onSubmit: async (values: any) => {
      
      const data = {
        name: values.name,
        userId: values.userId,
        email: values.email,
        telephoneNumber: values.telephoneNumber,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
        fax: values.fax,
        website: values.website,
        location: values.location,
        bankname: values.bankname,
        accountholder_name: values.accountholder_name,
        account_number: values.account_number,
        status: values.status,
        weekdaysopen: values.weekdaysopen,
        weekdaysclose: values.weekdaysclose,
        saturdayopen: values.saturdayopen,
        saturdayclose: values.saturdayclose,
        sundayopen: values.sundayopen,
        sundayclose: values.sundayclose,
        monday_time: values.monday_time,
        monday_open: values.monday_open,
        monday_close: values.monday_close,
        tuesday_open: values.tuesday_open,
        tuesday_close: values.tuesday_open,
        wednesday_open: values.wednesday_open,
        wednesday_close: values.wednesday_close,
        thursday_open: values.thursday_open,
        thursday_close: values.thursday_close,
        friday_open: values.friday_open,
        friday_close: values.friday_close,
        saturday_open: values.saturday_open,
        saturday_close: values.saturday_close,
        sunday_open: values.sunday_open,
        sunday_close: values.sunday_close,
        tuesday_time: values.tuesday_time,
        wednesday_time: values.wednesday_time,
        thursday_time: values.thursday_time,
        friday_time: values.friday_time,
        saturday_time: values.wednesday_time,
        sunday_time: values.sunday_time,
        sundayStatus: values.sundayStatus,
        mondayStatus: values.mondayStatus,
        tuesdayStatus: values.tuesdayStatus,
        wednesdayStatus: values.wednesdayStatus,
        thursdayStatus: values.thursdayStatus,
        fridayStatus: values.fridayStatus,
        saturdayStatus: values.saturdayStatus,
      };

      // save new product
      const response = await createClinics(data);
      const { Code, Message } = response;
      history("/clinics");
      await setTimeout(() => {
        if (Code == 1) {
          toast.success(Message);
        } else {
          toast.error(Message);
        }
      }, 100);
      formik.resetForm();
    },
  });

  useEffect(() => {
    getCountry().then((res: any) => {
      setapiCountry(res.data);
    });
  }, []);
  const radioHandler = (status: any) => {
    setMondayStatus(status);
    if (status === 3) {
      formik.setFieldValue("monday_open", "");
      formik.setFieldValue("monday-close", "");
      formik.setFieldValue("mondayStatus", true);
    }
  };
  const radioHandlerTuesday = (status: any) => {
    setTuesdayStatus(status);
    if (status === 3) {
      formik.setFieldValue("tuesday_open", "");
      formik.setFieldValue("tuesday_close", "");
      formik.setFieldValue("tuesdayStatus", true);
    }
  };

  const radioHandlerWednesday = (status: any) => {
    setWednesdayStatus(status);
    if (status === 3) {
      formik.setFieldValue("wednesday_open", "");
      formik.setFieldValue("wednesday_close", "");
      formik.setFieldValue("wednesdayStatus", true);
    }
  };

  const radioHandlerThursday = (status: any) => {
    setThursdayStatus(status);
    if (status === 3) {
      formik.setFieldValue("thursday_open", "");
      formik.setFieldValue("thursday_close", "");
      formik.setFieldValue("thursdayStatus", true);
    }
  };
  const radioHandlerFriday = (status: any) => {
    setFridayStatus(status);
    if (status === 3) {
      formik.setFieldValue("friday_open", "");
      formik.setFieldValue("friday_close", "");
      formik.setFieldValue("fridayStatus", true);
    }
  };
  const radioHandlerSaturday = (status: any) => {
    setSaturdayStatus(status);
    if (status === 3) {
      formik.setFieldValue("saturday_open", "");
      formik.setFieldValue("saturday_close", "");
      formik.setFieldValue("saturdayStatus", true);
    }
  };
  const radioHandlerSunday = (status: any) => {
    setSundayStatus(status);
    if (status === 3) {
      formik.setFieldValue("sunday_open", "");
      formik.setFieldValue("sunday_close", "");
      formik.setFieldValue("sundayStatus", true);
    }
  };
  const [Duplicate, setDuplicate] = useState<any>("");
  const DuplicateCheck = (val: any) => {
    try {

      checkDuplicateEmail(val).then((response: any) => {
        if (response?.isDuplicate === true) {
          setDuplicate("Email already exist");
        } else {
          setDuplicate("");
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };
  function goBack() {
    history(-1);
  }

  document.title = "Create Clinic  | Al khairi care ";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title=" Create Clinic " pageTitle="Clinic" />
          <Row>
            <Col>
              {" "}
              <button
                onClick={goBack}
                className="btn btn-primary px-2 text-center mb-3"
              >
                <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
              </button>{" "}
            </Col>
          </Row>
          <Row>
            <Col xxl={9}>
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Add Clinic
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Bank Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Clinic Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name || ""}
                                invalid={
                                  formik.touched.name && formik.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Clinic Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="email"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Email"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  DuplicateCheck(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.email || ""}
                                invalid={
                                  formik.touched.email && formik.errors.email
                                    ? true
                                    : false
                                }
                              />
                              <span style={{ color: "red" }}>
                                {Duplicate ? Duplicate : ""}
                              </span>

                              {formik.touched.email && formik.errors.email ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Clinic Contact <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="telephoneNumber"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Contact Number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telephoneNumber || ""}
                                invalid={
                                  formik.touched.telephoneNumber &&
                                  formik.errors.telephoneNumber
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.telephoneNumber &&
                              formik.errors.telephoneNumber ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.telephoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Country <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="country"
                                type="select"
                                className="form-select"
                                id="choices-publish-status-input"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country || ""}
                                invalid={
                                  formik.touched.country &&
                                  formik.errors.country
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a Country...</option>
                                {apicountry?.map((item: any, index: any) => (
                                  <option value={item.name} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </Input>
                              {formik.touched.country &&
                              formik.errors.country ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.country}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="cityInput" className="form-label">
                                Address <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address"
                                type="text"
                                className="form-control"
                                placeholder="Nationality"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address || ""}
                                invalid={
                                  formik.touched.address &&
                                  formik.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.address &&
                              formik.errors.address ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="countryInput"
                                className="form-label"
                              >
                                City <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="city"
                                type="text"
                                className="form-control"
                                placeholder="Enter City"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city || ""}
                                invalid={
                                  formik.touched.city && formik.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.city && formik.errors.city ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                State <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="state"
                                type="text"
                                className="form-control"
                                placeholder="Enter State"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state || ""}
                              />
                                 {formik.touched.state &&
                              formik.errors.state ? (
                                <span className="text-danger">
                                  {formik.errors.state}
                                </span>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Postal Code <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="pincode"
                                type="text"
                                className="form-control"
                                id="zipcodeInput"
                                placeholder="Enter pincode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pincode || ""}
                                invalid={
                                  formik.touched.pincode &&
                                  formik.errors.pincode
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.pincode &&
                              formik.errors.pincode ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.pincode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Clinic Fax <span >(Optional)</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="fax"
                                placeholder="Enter Fax"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fax || ""}    
                              />
                         
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Clinic Website <span >(Optional)</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="website"
                                placeholder="Enter Website"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.website || ""}
                               
                              />
                           
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Clinic Location <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="location"
                                placeholder="Enter Location"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location || ""}
                                invalid={
                                  formik.touched.location &&
                                  formik.errors.location
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.location &&
                              formik.errors.location ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.location}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6">
                                Clinic Timing <span className="text-danger">*</span>
                              </label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Monday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "monday_time"
                                          )}
                                          type="radio"
                                          name="monday_time"
                                          id="monday_1"
                                          checked={monday_status === 1}
                                          onClick={(e) => radioHandler(1)}
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "monday_time"
                                          )}
                                          type="radio"
                                          name="monday_time"
                                          id="monday_2"
                                          checked={monday_status === 2}
                                          onClick={(e) => radioHandler(2)}
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "monday_time"
                                          )}
                                          type="radio"
                                          name="monday_time"
                                          id="monday_3"
                                          checked={monday_status === 3}
                                          onClick={(e) => radioHandler(3)}
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>

                              {formik.touched.monday_time &&
                                formik.errors.monday_time && (
                                  <span className="text-danger">
                                      {formik.errors.monday_time}
                                      </span >
                                )}
                                </div>
                              {monday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "monday_open"
                                          )}
                                        />
                                        {formik.touched.monday_open &&
                                          formik.errors.monday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.monday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "monday_close"
                                          )}
                                        />
                                        {formik.touched.monday_close &&
                                          formik.errors.monday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.monday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>

                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Tuesday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "tuesday_time"
                                          )}
                                          type="radio"
                                          name="tuesday_time"
                                          id="tuesday1"
                                          checked={tuesday_status === 1}
                                          onClick={(e) =>
                                            radioHandlerTuesday(1)
                                          }
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "tuesday_time"
                                          )}
                                          type="radio"
                                          name="tuesday_time"
                                          id="tuesday2"
                                          checked={tuesday_status === 2}
                                          onClick={(e) =>
                                            radioHandlerTuesday(2)
                                          }
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "tuesday_time"
                                          )}
                                          type="radio"
                                          name="tuesday_time"
                                          id="tuesday3"
                                          checked={tuesday_status === 3}
                                          onClick={(e) =>
                                            radioHandlerTuesday(3)
                                          }
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.tuesday_time &&
                                  formik.errors.tuesday_time && (
                                    <span className="text-danger">
                                        {formik.errors.tuesday_time}
                                        </span >
                                  )}
                              </div>

                              {tuesday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "tuesday_open"
                                          )}
                                        />
                                        {formik.touched.tuesday_open &&
                                          formik.errors.tuesday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger text-danger">
                                                {formik.errors.tuesday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "tuesday_close"
                                          )}
                                        />
                                        {formik.touched.tuesday_close &&
                                          formik.errors.tuesday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.tuesday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Wednesday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "wednesday_time"
                                          )}
                                          type="radio"
                                          name="wednesday_time"
                                          id="wednesday1"
                                          checked={wednesday_status === 1}
                                          onClick={(e) =>
                                            radioHandlerWednesday(1)
                                          }
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "wednesday_time"
                                          )}
                                          type="radio"
                                          name="wednesday_time"
                                          id="wednesday2"
                                          checked={wednesday_status === 2}
                                          onClick={(e) =>
                                            radioHandlerWednesday(2)
                                          }
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "wednesday_time"
                                          )}
                                          type="radio"
                                          name="wednesday_time"
                                          id="wednesday3"
                                          checked={wednesday_status === 3}
                                          onClick={(e) =>
                                            radioHandlerWednesday(3)
                                          }
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.wednesday_time &&
                                  formik.errors.wednesday_time && (
                                    <span className="text-danger">
                                        {formik.errors.wednesday_time}
                                        </span >
                                  )}
                              </div>

                              {wednesday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "wednesday_open"
                                          )}
                                        />
                                        {formik.touched.wednesday_open &&
                                          formik.errors.wednesday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.wednesday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "wednesday_close"
                                          )}
                                        />
                                        {formik.touched.wednesday_close &&
                                          formik.errors.wednesday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.wednesday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Thursday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "thursday_time"
                                          )}
                                          type="radio"
                                          name="thursday_time"
                                          id="thursday1"
                                          checked={thursday_status === 1}
                                          onClick={(e) =>
                                            radioHandlerThursday(1)
                                          }
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "thursday_time"
                                          )}
                                          type="radio"
                                          name="thursday_time"
                                          id="thursday2"
                                          checked={thursday_status === 2}
                                          onClick={(e) =>
                                            radioHandlerThursday(2)
                                          }
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "thursday_time"
                                          )}
                                          type="radio"
                                          name="thursday_time"
                                          id="thursday3"
                                          checked={thursday_status === 3}
                                          onClick={(e) =>
                                            radioHandlerThursday(3)
                                          }
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.thursday_time &&
                                  formik.errors.thursday_time && (
                                    <span className="text-danger">
                                        {formik.errors.thursday_time}
                                        </span >
                                  )}
                              </div>

                              {thursday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "thursday_open"
                                          )}
                                        />
                                        {formik.touched.thursday_open &&
                                          formik.errors.thursday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.thursday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "thursday_close"
                                          )}
                                        />
                                        {formik.touched.thursday_close &&
                                          formik.errors.thursday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.thursday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Friday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "friday_time"
                                          )}
                                          type="radio"
                                          name="friday_time"
                                          id="friday1"
                                          checked={friday_status === 1}
                                          onClick={(e) => radioHandlerFriday(1)}
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "friday_time"
                                          )}
                                          type="radio"
                                          name="friday_time"
                                          id="friday2"
                                          checked={friday_status === 2}
                                          onClick={(e) => radioHandlerFriday(2)}
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "friday_time"
                                          )}
                                          type="radio"
                                          name="friday_time"
                                          id="friday3"
                                          checked={friday_status === 3}
                                          onClick={(e) => radioHandlerFriday(3)}
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.friday_time &&
                                  formik.errors.friday_time && (
                                    <span className="text-danger">
                                        {formik.errors.friday_time}
                                        </span >
                                  )}
                              </div>

                              {friday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "friday_open"
                                          )}
                                        />
                                        {formik.touched.friday_open &&
                                          formik.errors.friday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.friday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "friday_close"
                                          )}
                                        />
                                        {formik.touched.friday_close &&
                                          formik.errors.friday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.friday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Saturday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "saturday_time"
                                          )}
                                          type="radio"
                                          name="saturday_time"
                                          id="saturday1"
                                          checked={Saturday_status === 1}
                                          onClick={(e) =>
                                            radioHandlerSaturday(1)
                                          }
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "saturday_time"
                                          )}
                                          type="radio"
                                          name="saturday_time"
                                          id="saturday2"
                                          checked={Saturday_status === 2}
                                          onClick={(e) =>
                                            radioHandlerSaturday(2)
                                          }
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "saturday_time"
                                          )}
                                          type="radio"
                                          name="saturday_time"
                                          id="saturday3"
                                          checked={Saturday_status === 3}
                                          onClick={(e) =>
                                            radioHandlerSaturday(3)
                                          }
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.saturday_time &&
                                  formik.errors.saturday_time && (
                                    <span className="text-danger">
                                        {formik.errors.saturday_time}
                                        </span >
                                  )}
                              </div>

                              {Saturday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "saturday_open"
                                          )}
                                        />
                                        {formik.touched.saturday_open &&
                                          formik.errors.saturday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.saturday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "saturday_close"
                                          )}
                                        />
                                        {formik.touched.saturday_close &&
                                          formik.errors.saturday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.saturday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <div className="row mb-1">
                              <label className="col-lg-2 col-form-label required  fs-6"></label>
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label
                                        className="form-check-label"
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                        }}
                                      >
                                        Sunday
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "sunday_time"
                                          )}
                                          type="radio"
                                          name="sunday_time"
                                          id="sunday1"
                                          checked={sunday_status === 1}
                                          onClick={(e) => radioHandlerSunday(1)}
                                          value="1"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          24Hrs
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "sunday_time"
                                          )}
                                          type="radio"
                                          name="sunday_time"
                                          id="sunday2"
                                          checked={sunday_status === 2}
                                          onClick={(e) => radioHandlerSunday(2)}
                                          value="2"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Holiday
                                        </label>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 fv-row">
                                    <div className="form-check form-check-custom form-check-solid me-10">
                                      <label className="radio">
                                        <input
                                          {...formik.getFieldProps(
                                            "sunday_time"
                                          )}
                                          type="radio"
                                          name="sunday_time"
                                          id="sunday3"
                                          checked={sunday_status === 3}
                                          onClick={(e) => radioHandlerSunday(3)}
                                          value="3"
                                        />
                                        <label
                                          className="form-check-label"
                                          style={{
                                            margin: "5px",
                                            padding: "5px",
                                          }}
                                        >
                                          {" "}
                                          Select custom time
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {formik.touched.sunday_time &&
                                  formik.errors.sunday_time && (
                                    <span className="text-danger">
                                        {formik.errors.sunday_time}
                                        </span >
                                  )}
                              </div>

                              {sunday_status === 3 && (
                                <div className="row mb-6">
                                  <label className="col-lg-2 col-form-label  fw-bold fs-6"></label>
                                  <div className="col-lg-10">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <input
                                          type="time"
                                          className="form-control mb-1"
                                          {...formik.getFieldProps(
                                            "sunday_open"
                                          )}
                                        />
                                        {formik.touched.sunday_open &&
                                          formik.errors.sunday_open && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.sunday_open}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="col-lg-6 fv-row">
                                        <input
                                          type="time"
                                          className="form-control"
                                          {...formik.getFieldProps(
                                            "sunday_close"
                                          )}
                                        />
                                        {formik.touched.sunday_close &&
                                          formik.errors.sunday_close && (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block text-danger">
                                                {formik.errors.sunday_close}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Row>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn btn-primary"
                                onClick={() => {
                                  tabChange("2");
                                }}
                              >
                                Bank Details
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Account Number <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="account_number"
                                placeholder="Enter Account Numbere"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.account_number || ""}
                                invalid={
                                  formik.touched.account_number &&
                                  formik.errors.account_number
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.account_number &&
                              formik.errors.account_number ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.account_number}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Account Holder's Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="accountholder_name"
                                placeholder="Enter  Account Holder's Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accountholder_name || ""}
                                invalid={
                                  formik.touched.accountholder_name &&
                                  formik.errors.accountholder_name
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.accountholder_name &&
                              formik.errors.accountholder_name ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.accountholder_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Bank Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="bankname"
                                placeholder="Enter Bank Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bankname || ""}
                                invalid={
                                  formik.touched.bankname &&
                                  formik.errors.bankname
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.bankname &&
                              formik.errors.bankname ? (
                                <FormFeedback type="invalid">
                                  {formik.errors.bankname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                disabled={Duplicate}
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicsAdd;
