import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Agency_URL = `${API_URL}/agency`
const SOB_URL = `${API_URL}/plan/getplan`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
const GET_PLAN_LIST = `${API_URL}/plan`

//
const getAgency = (filters:any)=> {
  return axios
    .post(`${Agency_URL}/list`,filters)
    .then((d: any) => {
      return d.Data;
    })
}

const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${Agency_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      
      return response.data
     })
   
}
const getAgencyById = (id:any) => {
  return axios
    .get(`${Agency_URL}/view/${id}`) 
    .then((response: any) =>{
      
      return response.Data;
    }
   )
}
const updateAgencyById = (Agency:any,ID:any) => {
  //
  return axios
  .put(`${Agency_URL}/edit/${ID}`, Agency)
  .then((response:any) => {
  //  
    return response
   })
   
}
const updateFamilyAgency = (Agency:any,ID:any) => {
 // 
  return axios
  .put(`${Agency_URL}/family/${ID}`, Agency)
  .then((response) => {
  //  
    return response.data
   })
   
}
const getAgencyPaymentCount = async (id:any)=> {
  const res = await axios.get(`${Agency_URL}/viewAgency/transactionCount/${id}`,);
  //
  return res;
}
const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${Agency_URL}/admingetDepartment`);
  //
  return res;
}
const createAgency = (Agency: any) => {
  //
  return axios
  .post(`${Agency_URL}/add`, Agency)
  .then((response) => {
  //  
    return response.data
   })
   
}

const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${EXCEL_URL}`, excel)
    .then((response) => {

    //  
      return response.data;
    // .then((response: AxiosResponse<Response<Agency>>) => response.data)
    // .then((response: Response<Agency>) => response.data)
})}

const getSubSob = async () => {
  const res = await axios.get(`${SOB_URL}`);
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
  return res;
}
const getCountry = async () => {
  const res = await axios.get(`${COUNTRY_URL}`);
  //
  return res;
}

const deleteAgencyById = (id: any) => {
  
  return axios
    .post(`${Agency_URL}/delete/${id}`, {})
    .then((response) => {
  
  //  
    return response.data;

    }
    )
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${Agency_URL}/status/activateDeactivate/${id}/${status}`)
    .then((response) => {

  //  
    return response.data;

    }
    )
}



const updatecontrolsAgency = (data:any,userId:any)=> {
  return axios
    .post(`${Agency_URL}/updateMailSend/${userId}`, data,)
    .then((response) => {
      
      return response.data
     })
   
}

const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/${id}`);
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid});
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data);
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data);
  //
   return res.data;
 }
 const cronJob=async()=>{ 
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`);
  //
   return res.data;
 }

 const getCity = async () => {
  const response=await axios.get(`${Agency_URL}/list/city`)
  return response;

}
const getState = async (city:any) => {
  const response=await axios.get(`${Agency_URL}/state/${city}`)
  return response;
}
export {
  getState,getCity,
  getAgency,getAgencyPaymentCount,
  deleteAgencyById,updatecontrolsAgency,
  getSubSob,updatecontrols,getsobdetails,getReportMonthlyCount,
  createAgency,getAgencyById,updateFamilyAgency,
  getCountry,updatebulkinsert,updateAgencyById,
  admingetDepartment,updateActiveDeactive,getPlan,upgradePlan,renewalPlan,cronJob
}
