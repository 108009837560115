import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import { recentClient } from "pages/Admin Dashboard/Dashboard/_request";
import { Link, useNavigate } from "react-router-dom";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { ToastContainer, toast } from "react-toastify";

const AgencyData = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [clients, setClients] = useState<any>([]);
  const [agencyaccess, setagencyaccess] = useState<any>([]);

  useEffect(() => {
    recentClient().then((res: any) => {
      setClients(res.data);
    });
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 10).then((res: any) => {
      setagencyaccess(res.Data[0]);
    });
  }, []);

  const viewAgency = (cell: any) => {
    setagencyaccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.view === 1) {
        navigate(`/agency/view/${cell.row.original.id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const listAgency = () => {
    setagencyaccess((prevAgenAccess: any) => {
      if (agencyaccess?.list === 1) {
        navigate("/agency");
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  const Contact = (cell: any) => {
    return (
      <>
        <p className="fw-20 fw-300">{cell.row.original?.email}</p>
        <p>{cell.row.original?.telephoneNumber}</p>
      </>
    );
  };
  const PocContact = (cell: any) => {
    return (
      <>
        <p>{cell.row.original?.primaryEmail}</p>
        <p>{cell.row.original?.primaryTelephone}</p>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        header: "Agency Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Contact",
        accessorKey: "email",
        enableColumnFilter: false,
        cell: Contact,
      },
      {
        header: "POC Contact",
        accessorKey: "primaryEmail",
        enableColumnFilter: false,
        cell: PocContact,
      },
      {
        header: "Country",
        accessorKey: "country",
        enableColumnFilter: false,
      },
      {
        header: "State",
        accessorKey: "state",
        enableColumnFilter: false,
      },

      {
        header: "Actions",
        cell: (cell: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    viewAgency(cell);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Recent Agencys</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <div
                      onClick={listAgency}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="fa-solid fa-bars"></i> View All
                    </div>
                  </div>
                </div>
              </div>
              <p>List of recently created agencies</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={clients || []}
                customPageSize={8}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AgencyData;
