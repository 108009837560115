import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getCompany,
  getPlan,
  getReportforCompany,
  getReportforCompanyNotIn,
} from "./_requests";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Button, Card, Col, Container, Row } from "reactstrap";
import TableContainer from "./TableContainer";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCliniCategoryByplan } from "../Agency Report/_requests";
import * as XLSX from 'xlsx';
const PortalAgencyReportContent = () => {
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);
  const tableRef = useRef(null);
  const [com, setCom] = useState<any>([]);
  const [Total, setTotal] = useState<any>('')
  const [OCcom, setOCcom] = useState("");
  const [OCDate, setOCDate] = useState("");
  const [OCplan, setOCplan] = useState("");
  const [clinicid, setclinicid] = useState(0);
  const [plan, setPlan] = useState<any>([]);
  const [planid, setplanid] = useState(0);
  const [report, setReport] = useState<any>([]);
  const [btnExcelDisable, setbtnExcelDisable] = useState(true);
  const [btnClearDisable, setbtnClearDisable] = useState(true);
  const [ClinicCategory, setClinicCategory] = useState<any>([]);
  const { userProfile } = useProfile();
  const Id = userProfile?.primaryID;
  useEffect(() => {
    getPlan(Id).then((res: any) => {
      
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setPlan(optionsWithAll);
    });
  }, []);

  //
  useEffect(() => {}, []);

  var DatefileName = new Date();
  var fileName =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Agency_Report_${fileName}`,
    sheet: "Report",
  });

  const [OCcomid, setOCcomid] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [planselectedOption, setplanselectedOption] = useState(null);
  const [clinicselectedOption, setclinicselectedOption] = useState(null);

  
  const planhandleSelectChange = (planselectedOption: any) => {
    setplanselectedOption(planselectedOption);
    var text = planselectedOption?.label;
    setOCplan(text);
    setplanid(planselectedOption?.value);
    setclinicselectedOption(null);
    getCliniCategoryByplan(planselectedOption?.value).then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "Select" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setClinicCategory(optionsWithAll);
      if (flatpickrRef.current) {
        flatpickrRef.current.flatpickr.clear();
      }
    });
  };

  const clinichandleSelectChange = (clinicselectedOption: any) => {
    setclinicselectedOption(clinicselectedOption);

    setclinicid(clinicselectedOption?.value);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  };
  const companyReport = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate =selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange =(startDate && endDate) ? `${startDate} - ${endDate}` : "";

    setOCDate(Daterange);
    getReportforCompany(planid, Id, clinicid, Daterange).then(
      (resRep: any) => {
        //
        setReport(resRep.Data);
        const  total = resRep.Data.reduce((acc: number, res: any) => {
          return acc + parseInt(res.amount);
        }, 0);
         setTotal(total);
        getReportforCompanyNotIn(planid, OCcomid).then((resRepNot: any) => {
       
          if (resRep?.Data.length > 0 || resRepNot.Data.length > 0) {
            setbtnExcelDisable(false);
            setbtnClearDisable(false);
          } else {
            setbtnExcelDisable(true);
            setbtnClearDisable(true);
          }
        });
      }
    );
  };
  const clearReport = () => {
    setSelectedOption(null);
    setplanselectedOption(null);
    setclinicselectedOption(null);
    setReport([]);
    setPlan([]);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setTotal(null)
    setOCDate("");
    setOCplan("");
    setOCcom("");

    setplanid(0);
    setOCcomid(0);
    getPlan(Id).then((res: any) => {
      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];
      setCom(optionsWithAll);
    });

    setbtnExcelDisable(true);
    setbtnClearDisable(true);
  };

  const renderCreatedAt = (cell: any,dateKey:any) => {
    const formattedDate = new Date(
      cell.row.original[dateKey]
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };
 

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Worker Name",
        accessorKey: "workerName",
        enableColumnFilter: false,
        // cell:workerInfoCell
      },
      {
        header: "NRIC",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "Wbs Code",
        accessorKey: "wbsCode",
        enableColumnFilter: false,
      },
      {
        header: "status",
        accessorKey: "suspenseStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.suspenseStatus === 2 ? "Suspended" : "Active"}
            </>
          );
        },
      },
      {
        header: "Plan",
        accessorKey: "plan.planName",
        enableColumnFilter: false,
      },
      {
        header: "Category",
        accessorKey: "cliniccategory.name",
        enableColumnFilter: false,
      },
      {
        header: "Valid Till",
        accessorKey: "validityTo",
        enableColumnFilter: false,
        cell: (cell:any) => renderCreatedAt(cell, "validityTo"),
      },
      {
        header: "Limit",
        accessorKey: "OrgplanLimit",
        enableColumnFilter: false,
      },
      {
        header: "Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell:any) => renderCreatedAt(cell, "visitDate"),
      },
      {
        header: "Clinic",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Diagnosis",
        accessorKey: "diagDetails.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {" "}
              {cell.row.original.diagDetails?.map((items: any, i: any) => {
                return <>{items?.diagnosis?.name}</>;
              })}
            </>
          );
        },
      },
      {
        header: "MC Days",
        accessorKey: "totalMcDays",
        enableColumnFilter: false,
      },
      {
        header: "Balance",
        accessorKey: "workerCurrentBalance",
        enableColumnFilter: false,
      },
      {
        header: "Charage (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
    ],
    []
  );


  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };

  const formatDataForExport = async () => {
    const exportData = [];
  
    await Promise.all(
      report.map(async (row:any, i:any) => {
        const dataRow = {
          sno: i + 1,
          workerName: row.workerName,
          nricPassport: row.nricPassport,
          wbsCode: row.wbsCode, 
          suspenseStatus: row.suspenseStatus === 2 ? "Suspended" : "Active",
          plan: row.plan?.planName,
          cliniccategory: row.cliniccategory?.name,
          validityTo: row.validityTo,
          Limit: row.OrgplanLimit, 
          visitDate: new Date(row.visitDate).toLocaleDateString("en-GB"),
          clinic: row?.clinic?.clinicName,
          diagDetails: row.diagDetails?.map((items:any) => items?.diagnosis?.name).join(', '), // Assuming diagDetails is an array of objects
          totalMcDays: row.totalMcDays,
          Balance: row.workerCurrentBalance, // Assuming workerCurrentBalance is not available in the report data
          amount: row.amount
        };
        exportData.push(dataRow);
      })
    );
  
    // Pushing total row
    const totalRow = {
      Balance: "Total", // Assuming workerCurrentBalance is not available in the report data
      amount: Total // Assuming Total is defined elsewhere
    };
    exportData.push(totalRow);
  
    return exportData;
  };

const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveExcelFile(excelBuffer);
};


  var Datefilename = new Date();
  var DateFileName  =
    Datefilename.getFullYear() +
    "-" +
    (Datefilename.getMonth() + 1) +
    "-" +
    Datefilename.getDate();
  
  const saveExcelFile = (buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `Agency_Report${DateFileName}.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          {/* begin::Header */}
          <div className="cursor-pointer">
            <Row>
              <div className="col-sm">
                <h5 className="card-title m-3">Agency Report</h5>
              </div>
              <div className="col-sm-auto m-3">
                <button
                  className="btn text-white add-btn me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  disabled={btnExcelDisable}
                  onClick={handleExport}
                >
                  {" "}
                  Export{" "}
                </button>
              </div>
            </Row>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="row mb-10">
           
              <div className="col-lg-3">
                <label className=" col-form-label  fw-bold fs-6">
                  Filter By Plan
                </label>

                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={planselectedOption}
                  onChange={planhandleSelectChange}
                  options={plan}
                  isSearchable
                />
              </div>
              <div className="col-lg-3">
                <label className=" col-form-label  fw-bold fs-6">
                  Filter By Category
                </label>

                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={clinicselectedOption}
                  onChange={clinichandleSelectChange}
                  options={ClinicCategory}
                  isSearchable
                />
              </div>
              <div className="col-lg-3">
                <label className=" col-form-label required fw-bold fs-6">
                  Filter By Date Range
                </label>
                <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
              </div>
              <Row className="my-1">
                <Col lg={8} xl={8}></Col>
                <Col lg={2} xl={2} className="my-2">
                  <Button
                    onClick={companyReport}
                    className="btn text-white add-btn me-1 col-lg-12"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                  >
                    Search
                  </Button>
                </Col>
                <Col lg={2} xl={2} className="my-2">
                  <Button
                    onClick={clearReport}
                    className="btn text-white add-btn me-1 col-lg-12"
                                  style={{
                                    backgroundColor: "#28a8df",
                                    borderColor: "#28a8df",
                                  }}
                    disabled={btnClearDisable}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <TableContainer
                columns={columns}
                data={report || []}
                customPageSize={10}
                divClass="table-responsive"
                tableClass="align-middle table-nowrap mb-0"
                agency={OCcom}
                plan={OCplan}
                date={OCDate}
                total={Total}
                onDownload={onDownload}
              />
            </Row>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </Card>
      </Container>
    </div>
  );
};

export { PortalAgencyReportContent };
