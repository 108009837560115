import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { FetchNonclaimholders, FetchNonclaimholdersByid, approvenonpanelclaim, deleteClaimFormById } from "./_requests";
import fileDownload from 'js-file-download'
import axios from "axios";
import Avatar from "react-avatar";
const ViewNonPanelClaimForm = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [ApproveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  const [RejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [workers, setWorker] = useState<any>([]);
  const [claim, setClaim] = useState<any>([])
  useEffect(() => {
    FetchNonclaimholdersByid(id).then((res: any) => {
      //
      setClaim(res)
      var workerId = res?.workerId

      FetchNonclaimholders(workerId).then((result:any) => {
        setWorker(result?.Data)
      })
    })
  }, [])

  const toggleApproveModal = () => {
    setApproveModalOpen(!ApproveModalOpen);
  };
  const toggleRejectModal = () => {
    setRejectModalOpen(!RejectModalOpen);
  };



  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Worker | Alkhairi care";

  const approveClaim = () => {
    approvenonpanelclaim(claim?.id, claim?.workerId, claim?.amount).then((res:any) => {
    
      let message = ''
      let code = ''
      if (res.Data === 'unlimited') {
        code = '1'
        message = 'Claimform approved successfully'
      }
      if (res.Data === 'insufficient') {
        code = '0'
        message =
          'The claim form was not approved because the employee has an insufficient balance'
      }
      if (res.Data === 'sufficient') {
       code = '1'
       message = 'Claimform approved successfully'
      }
      if(code === '1'){
        toggleApproveModal()
        history('/nonpanel-claims-form', {replace: true})
        setTimeout(()=>{
          toast.success(message, { autoClose: 3000 });         
         },100)
      }
      else{
        toggleApproveModal()
        history('/nonpanel-claims-form', {replace: true})
        setTimeout(()=>{
          toast.error(message, { autoClose: 3000 });         
         },100)
      }

    })
  }
  const rejectClaim = () => {
    deleteClaimFormById(claim?.id, 3).then((res:any) => {
      history('/nonpanel-claims-form', {replace: true})
      setTimeout(()=>{
        toast.success(res?.Message, { autoClose: 3000 });         
       },100)
      toggleRejectModal()
    })
  }

  const downloadUrl = claim?.prescription

  const downloadFileAtUrl = (url: any, Inv: any) => {
    const fileName = url.split('/').pop()
    const fileExtension = fileName.split('.').pop()
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        const DownloadedfileName = Inv + '.' + fileExtension
        fileDownload(res.data, DownloadedfileName)
      })
  }


  const renderCreatedAt = (cell: any) => {
    
    const formattedDate = new Date(cell).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  
    return <>{formattedDate}</>;
  };
  function goBack() {
    history(-1);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title="NonPanel Claimform view" pageTitle="Nonpanel claimform" />
        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
          <Row>
          
          <Card>
  <CardHeader>
    <Row>
      <Col xl={6} xs={6}><h4>Worker Info</h4></Col>
      <Col xl={6} xs={6} className="d-flex justify-content-end">
        {claim?.approvalStatus === 1 ? (
          <div className="btn btn-danger me-2" onClick={() => {toggleRejectModal();}}>Reject Claim</div>
        ) : (
          <div className="btn btn-success me-2" onClick={() => {toggleApproveModal();}}>Approve Claim</div>
        )}
      </Col>
    </Row>
  </CardHeader>
  <CardBody className="pb-0 px-4">
    <Row className="mb-3">
      <Col md={2} xs={6} className="text-center mb-3 mb-md-0">
        {workers?.profileImage && !workers?.profileImage.includes("null") ? (
          <img
            src={workers?.profileImage}
            alt="worker-img"
            className="rounded avatar-xl"
          />
        ) : (
          <Avatar
            name={workers?.name}
            size="100"
            color="#607458"
            fgColor="#1b1b29"
            round={true}
          />
        )}
      </Col>
      <Col md={10} xs={12}>
        <Row>
          <Col xs={12}>
            <h4 className="fw-bold">
              {workers?.name} ({workers?.workerId})
            </h4>
          </Col>
          <Col xs={12} className="mb-3">
            <div className="fs-14 text-start">
              <i className="mdi mdi-account-outline fs-20 me-2"></i>
              {workers?.department?.name}
            </div>
          </Col>
          <Col xs={12} className="mb-3">
            <div className="fs-10 badge bg-success text-start p-1">
              <i className="mdi mdi-account-outline fs-12 me-2"></i>
              {workers?.agency?.name}
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <i className="ri-phone-fill fs-20 me-2"></i>
                <span className="fw-medium">{workers?.mobileNumber}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <i className="ri-mail-line fs-20 me-2"></i>
                <span className="fw-medium">{workers?.username}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <span className="fw-medium mt-1">RM : {claim?.amount}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </CardBody>
</Card>

          
          </Row>
          <Row>
            <Card>
              <CardHeader>Reason</CardHeader>
              <CardBody>
  <Row className="mb-3">
    <Col className="col-4">Visit Date</Col>
    {claim?.visitDate != null ? (
    <Col className="col-3"> {renderCreatedAt(claim?.visitDate) }</Col>):('')}
  </Row>
 
  <Row className="mb-3">
    <Col className="col-4">Reason</Col>
    <Col className="col-3">{claim?.visitReason}</Col>
  </Row>
</CardBody>

            </Card>
          </Row>
          <Row>
            <Card>
              <CardHeader> <Row>
      <Col><h4>Prescription</h4></Col>
      <Col className="text-end">
      <div className="btn btn-primary me-2" 
      onClick={() => downloadFileAtUrl(downloadUrl, claim?.uniqueId)}
      >Download File</div>
      </Col>
    </Row></CardHeader>
    <CardBody>
  <div className="d-flex justify-content-center">
    <img
      src={claim?.prescription}
      alt="prescription"
      className="rounded w-100 h-100"
      style={{ maxWidth: '500px', maxHeight: '500px' }}
    />
  </div>
</CardBody>

            </Card>
          </Row>



          <Modal isOpen={ApproveModalOpen} toggle={toggleApproveModal} centered style={{ marginTop: '10vh' }}>
        <ModalHeader toggle={toggleApproveModal}>Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to approve the claim RM <strong>{}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleApproveModal}>
            Not Now
          </Button>
          <Button color="danger" onClick={approveClaim}>
            Yes,Approve
          </Button>
        </ModalFooter>
      </Modal>

          <Modal isOpen={RejectModalOpen} toggle={toggleRejectModal} centered style={{ marginTop: '10vh' }}>
        <ModalHeader toggle={toggleRejectModal}>Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to reject the claim RM <strong>{}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleRejectModal}>
            Not Now
          </Button>
          <Button color="danger" onClick={rejectClaim}>
            Yes,Reject
          </Button>
        </ModalFooter>
      </Modal>

 



        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewNonPanelClaimForm;
