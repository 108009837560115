import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Flatpickr from "react-flatpickr";
//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import TableContainer from "Components/Common/TableContainer";
// import {  deletePlanById, getClientByPlanId, getPlan } from "../_requests";
// import { AgencyInfoCell } from "./AgencyInfoCell";
import { useProfile } from "Components/Hooks/UserHooks";
import Filter from "./Filter";
import { useDownloadExcel } from "react-export-table-to-excel";
import { creditReport, getClinicCategory } from "pages/Category/_requests";
import { getCompany } from "./_requests";
import Select from "react-select";
// Checked All

const CreditReportContent = () => {
  const { userProfile } = useProfile();
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);
  const [Report, setReport] = useState<any>([]);
  const [Agency, SetAgency] = useState<any>([]);
  const [DateRange, SetDateRange] = useState<any>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [RoleToDeleteId, setRoleToDeleteId] = useState<string>("");
  const [RoleToDeleteName, setRoleToDeleteName] = useState<string>("");

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };





  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false);

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };
  const tableRef = useRef(null);
  var DatefileName = new Date();
  var fileName =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Agency_Report_${fileName}`,
    sheet: "Agency",
  });

  const clearData = () => {
    SetDateRange("");
    SetAgency("");
    setReport("");
  };

  const fetchData = (
    OCcomid: any,
    Daterange: any,
    OCcatid: any,
    OCcom: any
  ) => {
    SetDateRange(Daterange);
    SetAgency(OCcom);
    creditReport(OCcomid, Daterange, OCcatid)
      .then((res: any) => {
        setReport(res.Data);
        if (res.Data.length) {
          setbtnExcelDisable(false);
          setbtnClearDisable(false);
        } else {
          setbtnExcelDisable(true);
          setbtnClearDisable(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching credit report:", error);
      });
    // setReport(data);
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },

      {
        header: " WORKER NAME",
        accessorKey: "employee.name",
        enableColumnFilter: false,
      },
      {
        header: " NRIC/PASS",
        accessorKey: "employee.nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "AGENCY",
        accessorKey: "employee.agency.name",
        enableColumnFilter: false,
      },
      {
        header: "PLAN",
        accessorKey: "employee.plan.name",
        enableColumnFilter: false,
      },
      {
        header: "WBS CODE ",
        accessorKey: "employee.wbsCode",
        enableColumnFilter: false,
      },
      {
        header: "STATUS",
        accessorKey: "suspenseStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.suspenseStatus === 2 ? "Suspended" : "Active"}
            </>
          );
        },
      },
      {
        header: "CATEGORY",
        accessorKey: "usage.cliniccategoryname",
        enableColumnFilter: false,
      },
      {
        header: "LIMIT",
        accessorKey: "usage.unlimited",
        enableColumnFilter: false,
      },
      {
        header: "MONTH",
        accessorKey: "usage.month",
        enableColumnFilter: false,
      },
      {
        header: "USAGE",
        accessorKey: "usage.totalAmount",
        enableColumnFilter: false,
      },
      {
        header: "PANEL USAGE",
        accessorKey: "usage.paneltotal",
        enableColumnFilter: false,
      },
      {
        header: "NONPANEL USAGE",
        accessorKey: "usage.nonpaneltotal",
        enableColumnFilter: false,
      },
      {
        header: "TOTAL",
        accessorKey: "employee.sum",
        enableColumnFilter: false,
      },
      {
        header: "BALANCE",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.usage.currentunlimit === 1
                ? "Unlimited"
                : cell.row.original.usage.currentbalance}
            </>
          );
        },
      },
      {
        header: "NO. OF PANEL FORMS",
        accessorKey: "usage.panelcount",
        enableColumnFilter: false,
      },
      {
        header: "NO. OF NON-PANEL FORMS",
        accessorKey: "usage.nonpanelcount",
        enableColumnFilter: false,
      },
      {
        header: "DATE PERIOD",
        accessorKey: "company",
        enableColumnFilter: false,
        cell: () => {
          return <p>{DateRange}</p>;
        },
      },
    ],
    [DateRange]
  );

  const filteredData = Report.filter(
    (item: any) => item.usage && Object.keys(item.usage).length > 0
  );

  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };

  const formatDataForExport = async () => {
    const exportData: { [key: string]: string | number }[] = [];

    await Promise.all(
      filteredData.map(async (row: any, i: number) => {
        const dataRow: { [key: string]: string | number } = {
          "S.No": i + 1,
          "WORKER NAME": row.employee.name,
          "NRIC/PASS": row.employee.nricPassport,
          AGENCY: row.employee.agency.name,
          PLAN: row.employee.plan.name,
          "WBS CODE": row.employee.wbsCode,
          STATUS: row.suspenseStatus === 2 ? "Suspended" : "Active",
          CATEGORY: row.usage.cliniccategoryname,
          LIMIT: row.usage.unlimited,
          MONTH: row.usage.month,
          USAGE: row.usage.totalAmount,
          "PANEL USAGE": row.usage.paneltotal,
          "NONPANEL USAGE": row.usage.nonpaneltotal,
          TOTAL: row.employee.sum,
          BALANCE:
            row.usage.currentunlimit === 1
              ? "Unlimited"
              : row.usage.currentbalance,
          "NO. OF PANEL FORMS": row.usage.panelcount,
          "NO. OF NON-PANEL FORMS": row.usage.nonpanelcount,
          "DATE PERIOD": DateRange, // Assuming DateRange is defined somewhere
        };
        exportData.push(dataRow);
      })
    );

    return exportData;
  };

  const exportToExcel = (data: any[]) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveExcelFile(excelBuffer);
  };

  var DatefileName = new Date();
  var DateFileName =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();

  const saveExcelFile = (buffer: any) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const fileName = `Credit_Report${DateFileName}.xlsx`;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  const [company, setCompany] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [OCDate, setOCDate] = useState("");
  const [OCcom, setOCcom] = useState("");
  const [validationError, setValidationError] = useState("");
  const [OCcat, setOCcat] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnSearchDisable, setbtnSearchDisable] = useState(true);
  const [OCcomid, setOCcomid] = useState("");
  const [OCcatid, setOCcatid] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [catselectedOption, setCatSelectedOption] = useState(null);
  const [btnExcelDisable, setbtnExcelDisable] = useState(true);
  const [btnClearDisable, setbtnClearDisable] = useState(true);
  useEffect(() => {
    getCompany().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];

      setCompany(optionsWithAll);
    });
  }, []);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setValidationError("");
    setOCcom(selectedOption?.label);
    setOCcomid(selectedOption?.value);

    getClinicCategory().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAlll = [allOption, ...mappedOptions];

      setCategory(optionsWithAlll);
    });
  };

  const handleCatSelectChange = (catselectedOption: any) => {
    setCatSelectedOption(catselectedOption);

    setOCcat(catselectedOption?.label);
    setOCcatid(catselectedOption?.value);
  };

  const companyReport = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate = selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange = startDate && endDate ? `${startDate} - ${endDate}` : "";
    SetDateRange(Daterange);
    if (OCcomid !== "" && Daterange !== "") {
      fetchData(OCcomid, Daterange, OCcatid, OCcom);
      setLoading(true);
      setOCDate(Daterange);
      setValidationError("");
    } else {
      setbtnSearchDisable(true);
      setValidationError("Please fill manditory fields");
    }
  };
  const clearReport = () => {
    setSelectedOption(null);
    setReport([]);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    SetAgency([])
    SetDateRange([])
    setOCDate("");
    setOCcom("");
    setCatSelectedOption(null);
    setbtnExcelDisable(true);
    setbtnClearDisable(true);
  };
  document.title = "Credit Report | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Credit Report" pageTitle="Credit Report" />
          <Row>
            <Col lg={12}>
              <Card id="PlansList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Credit Report</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white me-2"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          disabled={btnExcelDisable}
                          onClick={handleExport}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                  
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body py-3">
                  {/* begin::Table container */}
                  <div className="row mb-10">
                    <div className="col-lg-3">
                      <label className=" col-form-label  fw-bold fs-6">
                        Filter By Agency
                      </label>

                      <Select
                        className="mb-0"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={company}
                        isSearchable
                        id="country-select"
                      ></Select>
                    </div>
                    <div className="col-lg-3">
                      <label className=" col-form-label  fw-bold fs-6">
                        Filter By Category
                      </label>

                      <Select
                        className="mb-0"
                        value={catselectedOption}
                        onChange={handleCatSelectChange}
                        options={category}
                        isSearchable
                        id="country-select"
                      ></Select>
                    </div>
                    <div className="col-lg-3">
                      <label className=" col-form-label required fw-bold fs-6">
                        Filter By Date Range
                      </label>
                      <Flatpickr
                        className="form-control"
                        id="Daterange"
                        placeholder="Select a date"
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          mode: "range",
                          dateFormat: "d/m/Y",
                        }}
                        value={selectedDates}
                        onChange={(dates: Date[]) => setSelectedDates(dates)}
                        ref={flatpickrRef}
                      />
                    </div>
                    {validationError ? <span style={{color: 'red'}}>{OCDate && OCcomid ? '' : validationError}</span> : ''}
                    <Row className="my-1">
                      <Col lg={8} xl={8}></Col>
                      <Col lg={2} xl={2} className="my-2">
                        <Button
                          onClick={companyReport}
                          className="btn text-white me-2 col-lg-12"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                        >
                          Search
                        </Button>
                      </Col>
                      <Col lg={2} xl={2} className="my-2">
                        <Button
                          onClick={clearReport}
                          className="btn text-white me-2 col-lg-12"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          disabled={btnClearDisable}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className="my-3">
                      <Col className="col-4 fw-bold ">Agency : {Agency}</Col>
                      <Col className="col-4 fw-bold">Date : {DateRange}</Col>
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={filteredData || []}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                    />
                    <ToastContainer closeButton={false} limit={1} />
                  </div>
                  <div></div>
                </div>
              </Card>
            </Col>
          </Row>

       
        </Container>
      </div>
      <Filter
        show={isInfoDetails}
        onCloseClick={toggleInfo}
        fetchData={fetchData}
        clearData={clearData}
      />
    </React.Fragment>
  );
};

export default CreditReportContent;
