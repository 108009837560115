import React, { useEffect, useState } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Table,
  Container,
  Label,
  CardHeader,
  Button,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";

import logoDark from "../../../assets/images/logo-light.png";
import logoLight from "../../../assets/images/logo-dark.png";
import fileDownload from "js-file-download";
import axios from "axios";
import { useProfile } from "Components/Hooks/UserHooks";
import { toast } from "react-toastify";
import { getClinicPaymentById } from "./_requests";
import { updateAdminFile } from "pages/Admin Invoices/Admin-Clinic -Payment/_requests";
import { getClinicsById } from "pages/Clinics/_requests";

const PortalPaymentDetails = () => {
  const history = useNavigate();
  const printInvoice = () => {
    window.print();
  };

  const { userProfile } = useProfile();

  const { id } = useParams();
  const [cliPay, setClipay] = useState<any>([]);
  const [cli, setCli] = useState<any>([]);

  useEffect(() => {
    getClinicPaymentById(id).then((res: any) => {
      setClipay(res.Data);
      
      getClinicsById(res.Data?.clinicId).then((res: any) => {
        setCli(res);
      });
    });
  }, []);

  var msDiff = new Date(cliPay.invoiceDueDate).getTime() - new Date().getTime(); //Future date - current date
  var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
  if (daysTill > 0) {
    var duetilll = `Due in ${daysTill} days`;
  } else {
    var duetilll = `Due Date Expired`;
  }

  const downloadFileAtUrl = (url: any, Inv: any, type: any) => {
    let Itype: string;
  if (type === 1) {
    Itype = "Payment";
  } else {
    Itype = "Invoice";
  }
    const fileName = url.split("/").pop();
    const fileExtension = fileName.split(".").pop();
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res:any) => {
        const DownloadedfileName = Itype + "-" + Inv + "." + fileExtension;
        fileDownload(res, DownloadedfileName);
      });
  };

  const viewFileAtUrl = (url: any) => {

    
    const fileName = url.split("/").pop();

    const fileExtension = fileName.split(".").pop();
    if (fileExtension === "pdf") {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((res:any) => {
          const blob = new Blob([res], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank");
        });
    } else {
      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res:any) => {
          
          const blobUrl = URL.createObjectURL(res);
          window.open(blobUrl, "_blank");
        });
    }
  };
  


  const [data, setData] = useState<any>({});
  const [formImage, setFormImage] = useState<File | null>(null);
  const [btnDisable, setbtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const imageHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          addadminSentFile: reader.result,
        });
      }
    };
    const files = e.target.files;

    if (files) {
      setFormImage(files[0]);
      reader.readAsDataURL(files[0]);
    }
    if (data?.addadminSentFile !== null) {
      setbtnDisable(false);
    }
  };



  //Column

  document.title =
    "Invoice Details | Al khairi care ";
    function goBack() {
      history(-1);
    }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Invoice Details" pageTitle="Invoices" />
        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card id="demo">
              <Row>
                <Col lg={12}>
                  <CardBody className="border-bottom border-bottom-dashed p-4">
                    <Row>
                      <Col lg={4}>
                        <div className="profile-user mx-auto  mb-3">
                          <Label
                            for="profile-img-file-input"
                            className="d-block"
                          >
                            <span
                              className="overflow-hidden  d-flex align-items-center justify-content-center rounded"
                              style={{ height: "60px", width: "256px" }}
                            >
                              <img
                                src={logoDark}
                                className="card-logo card-logo-dark user-profile-image img-fluid"
                                alt="logo dark"
                                style={{ height: "55px" }}
                              />
                              <img
                                src={logoLight}
                                className="card-logo card-logo-light user-profile-image img-fluid"
                                alt="logo light"
                                style={{ height: "55px" }}
                              />
                            </span>
                          </Label>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="ms-auto">
  <div className="mb-2">
    <Label className="fw-bold text-gray-600 mb-2">
      <span className="col-6 col-md-12">Invoice #</span>
      <span className="col-6 col-md-12 ms-2">{cliPay?.invoiceNumber}</span>{" "}
    </Label>
  </div>
  <div className="mb-2 d-flex">
    <Label className="fw-bold text-gray-600 mb-2">
      <span className="col-6 col-md-12">Date</span>{" "}
      <span className="col-6 col-md-12">
        {new Date(cliPay?.paymentDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </span>
    </Label>
  </div>
  <div>
    <Label className="fw-bold text-gray-600 mb-2">
      <span className="col-6 col-md-12">Due Date</span>{" "}
      <span className="col-6 col-md-12">
        {new Date(cliPay?.invoiceDueDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
        {cliPay?.adminPaidDate == null ? (
          <span className="fs-7 text-danger d-flex align-items-center">
            <span className="bullet bullet-dot bg-danger me-2"></span>
            {duetilll}
          </span>
        ) : (
          <span className="fs-7 text-success d-flex align-items-center">
            <span className="bullet bullet-dot bg-success me-2"></span>
            Paid
          </span>
        )}
      </span>
    </Label>
  </div>
</Col>
                    </Row>
                  </CardBody>
                </Col>
                <Col lg={12}>
                  <CardBody className="p-4 border-top border-top-dashed">
                    <Row>
                      <Col lg={4} sm={6}>
                        <div>
                          <Label
                            for="billingName"
                            className="text-muted text-uppercase fw-semibold"
                          >
                            Issued By
                          </Label>
                        </div>
                        <div className="mb-2">
                          <Label className="fw-bold text-gray-800 mb-2">
                            Alkhairi Care
                          </Label>
                          <Col className="mb-1">
                            79, Jalan SS 21/1a, Damansara Utamae
                          </Col>
                          <Col className="mb-1">
                            {" "}
                            47400 Petaling Jaya, Selangor,
                          </Col>
                          <Col className="mb-1"> Malaysia.</Col>
                        </div>
                      </Col>
                      <Col sm={6} className="ms-auto">
                        <Row>
                          <Col lg={8}>
                            <div className="row">
                              <div className="col-12">
                                <Label
                                  for="shippingName"
                                  className="text-muted fw-semibold me-5"
                                >
                                  Issued For
                                </Label>
                              </div>
                            </div>

                            <div>
                              <Label className="fw-bold text-gray-800 mb-2">
                                {cli?.name}
                              </Label>

                              <Col className="mb-1">{cli?.address}</Col>
                              <Col className="mb-1"> {cli?.city}</Col>
                              <Col className="mb-1">
                                {cli?.state}-{cli?.pincode}.
                              </Col>
                            </div>
                          </Col>
                    
                        </Row>
                    
                      </Col>
                    </Row>
                  </CardBody>
                </Col>

                <Col lg={12}>
                  <CardBody className="p-4">
                    <div className="table-responsive">
                      <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                        <thead>
                          <tr className="table-active">
                            <th scope="col" style={{ width: "50px" }}>
                              #
                            </th>
                            <th scope="col">Description</th>
                            <th scope="col">Price ( RM )</th>
                            <th scope="col">Tax ( RM )</th>
                            <th scope="col" className="text-end">
                              Amount ( RM )
                            </th>
                          </tr>
                        </thead>
                        <tbody id="products-list">
                          <tr>
                            <th scope="row">#</th>
                            <td className="text-start">
                              <span className="fw-medium">
                                {cliPay?.description}
                              </span>
                            </td>
                            <td> {cliPay?.amount}</td>
                            <td>{cliPay?.tax}</td>
                            <td className="text-end">{cliPay?.total}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="border-top border-top-dashed mt-2">
                      <Table
                        className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                        style={{ width: "250px" }}
                      >
                        <tbody>
                          <tr>
                            <td>Sub Total</td>
                            <td className="text-end">RM {cliPay?.amount}</td>
                          </tr>
                          <tr>
                            <td>Tax </td>
                            <td className="text-end">{cliPay?.tax}</td>
                          </tr>
                          <tr className="border-top border-top-dashed fs-15">
                            <th scope="row">Total Amount</th>
                            <th className="text-end">RM {cliPay?.total}</th>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <Col lg={12}>
  <CardBody className="p-4 border-top border-top-dashed">
    <Row className="fw-bold text-gray-800 mb-2">
      <h4>Summary</h4>
    </Row>
    <Row>
      <Col xs={12} sm={6} className="mb-3">
        <h6 className="text-muted fw-semibold mb-3">INVOICE DETAILS :</h6>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Invoice # :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
            {cliPay?.invoiceNumber}
          </Col>
        </div>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Created On :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
            {" "}
            {new Date(cliPay?.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Col>
        </div>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Status :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
            {" "}
            {cliPay?.status === "2" ? (
              <div className="badge bg-danger-subtle fw-bolder text-danger fs-8 py-1">
                Invoice Created
              </div>
            ) : (
              <div className="badge bg-success-subtle text-success fw-bolder fs-8 py-1">
                Payment Submitted
              </div>
            )}
          </Col>
        </div>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Total :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
            RM {cliPay?.total}
          </Col>
        </div>
      </Col>
      <Col xs={12} sm={6} className="mb-3">
        <h6 className="text-muted  fw-semibold mb-3">BANK DETAILS :</h6>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Bank Name :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
          {cli.bankname}
          </Col>
        </div>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Account :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
          {cli.account_number}
          </Col>
        </div>
        <div className="row mb-3">
          <Col xs={4} sm={3} className="fw-semibold text-gray-600 fs-7">
            Account Name :
          </Col>
          <Col xs={8} sm={9} className="fw-bold text-gray-800 fs-6">
            {" "}
            {cli.accountholder_name}
          </Col>
        </div>
      </Col>
    </Row>
  </CardBody>
</Col>
                    <div className="flex-grow-1 mt-20">
                      <div className="table-responsive border-bottom mb-9">
                        <h4>Documents</h4>
                        <table className="table mb-10">
                          <thead>
                            <tr className="border-bottom fs-6 fw-bold text-muted">
                              <th className="min-w-5px   pb-2">#</th>
                              <th className="min-w-70px text-center pb-2">
                                Reference
                              </th>
                              <th className="min-w-80px text-center pb-2">
                                Document
                              </th>
                              <th className="min-w-100px text-center pb-2">
                                Date
                              </th>
                              <th className="min-w-100px text-center pb-2">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr className="text-center">
                              <td className="pt-2">
                                1
                              </td>
                              <td className="pt-2"> {cliPay?.invoiceNumber}</td>
                              <td className="pt-2">
                                <div className="btn btn-sm btn-light-success py-1 px-3">
                                  Payment Proof
                                </div>
                              </td>
                              <td className="pt-2 ">
                              <div className="d-flex justify-content-center">
                                {new Date(
                                  cliPay?.paymentDate
                                ).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                                </div>
                              </td>
                              <td className="pt-2">
                              {cliPay?.clinicSentFile !== "" ? (
                              <div className="d-flex justify-content-center">
                                  <button
                                     className="btn text-white add-btn me-1"
                                     style={{
                                       backgroundColor: "#28a8df",
                                       borderColor: "#28a8df",
                                     }}
                                    onClick={() =>
                                      downloadFileAtUrl(
                                        cliPay?.clinicSentFile,
                                        cliPay?.invoiceNumber,
                                        1
                                      )
                                    }
                                  >
                                    Download
                                  </button>
                              
                            
                                  <button
                                 className="btn text-white add-btn me-1"
                                 style={{
                                   backgroundColor: "#28a8df",
                                   borderColor: "#28a8df",
                                 }}
                                    onClick={() =>
                                      viewFileAtUrl(cliPay?.clinicSentFile)
                                    }
                                  >
                                    View
                                  </button>
                               </div>
                              ):("Not Uploaded")}
                              </td>
                            </tr>
                            
                            {cliPay?.adminPaidDate != null && (
                              <tr className="text-center">
                                <td className="pt-2">
                                  2
                                </td>
                                <td className="pt-2">
                                  {" "}
                                  {cliPay?.invoiceNumber}
                                </td>
                                <td className="pt-2">
                                  <a className="btn btn-light-success py-1 px-3">
                                    Invoice Proof
                                  </a>
                                </td>
                                <td className="pt-2">
                                  {new Date(
                                    cliPay?.adminPaidDate
                                  ).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}
                                </td>
                                <td className="pt-2">
                                {cliPay?.adminPaymentFile != '' ? (
                                  <>
                                  <button
                                  className="btn text-white add-btn me-1"
                                  style={{
                                    backgroundColor: "#28a8df",
                                    borderColor: "#28a8df",
                                  }}
                                    onClick={() =>
                                      downloadFileAtUrl(
                                        cliPay?.adminPaymentFile,
                                        cliPay?.invoiceNumber,
                                        2
                                      )
                                    }
                                  >
                                    Download
                                  </button>
                                  <button
                                className="btn text-white add-btn me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                    onClick={() =>
                                      viewFileAtUrl(cliPay?.adminPaymentFile)
                                    }
                                  >
                                    View
                                  </button>
                                  </>   ) : (
                          'Not Uploaded'
                        )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PortalPaymentDetails;
