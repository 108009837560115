import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { createPlan } from "./_requests";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { getBenefit, getClinicCategory } from "pages/Category/_requests";
import BreadCrumb from "Components/Common/BreadCrumb";
const PlanAdd = () => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Plan | Alkhairi care";
  const [plan, setPlan] = useState<any>([]);
  const [soblimit, setsoblimit] = useState([
    { cliniccategoryId: "", limit: "", unlimited: "0" },
  ]);
  const [cCategory, setcCategory] = useState<any>([]);
  useEffect(() => {
    getClinicCategory().then((res: any) => {

      //setTreatment(res.data.Data);
      setcCategory(res.Data);
    });
  }, []);

  useEffect(() => {
    getBenefit().then((res: any) => {
      setPlan(res.data);
    });
  }, []);

  const Formik: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      benefitId: [],
      limit: "",
      validity: "",
      benifits: "",
      planCovers: "",
      nonPlanCovers: "",
      benefitlimit: [
        {
          limit: "",
          cliniccategoryId: "",
          unlimited: "0",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Plan Name is required"),
      benifits: Yup.string().required("benefits  is required"),
      validity: Yup.string().required("Plan Validity  is required"),
      planCovers: Yup.string().required("Plan Covers address is required"),
      nonPlanCovers: Yup.string().required("Non Plan Covers is required"),
      benefitlimit: Yup.array().of(
        Yup.object().shape({
          cliniccategoryId: Yup.string().required("category is required"),
          limit: Yup.string().required("Limit is required"),
          unlimited: Yup.string(),
        })
      ),
    }),
    onSubmit: async (values: any) => {

      // save new product
      await createPlan(values);
      Formik.resetForm();
      history("/plan");
      setTimeout(() => {
        toast.success("Plan Created Successfully", { autoClose: 3000 });
      }, 100);
    },
  });

  const addRow = () => {
    const newRow = {
      cliniccategoryId: "", // Set default values here
      limit: "",
      unlimited: 0,
    };

    setsoblimit((soblimit: any) => [...soblimit, newRow]);

    // Update Formik field value along with local state update
    Formik.setFieldValue("benefitlimit", [
      ...Formik.values.benefitlimit,
      newRow,
    ]);
  };
  const addRemove = (i: number) => {
    const newForm = [...soblimit];
    //
    newForm.splice(i, 1);
    setsoblimit(newForm);
  };
  const handleCheckboxChangeunlimited = (index: number) => {
    const newSoblimit = [...Formik.values.benefitlimit];
    newSoblimit[index].unlimited =
      newSoblimit[index].unlimited === "1" ? "0" : "1";
    Formik.setFieldValue("benefitlimit", newSoblimit);
    Formik.setFieldTouched(`benefitlimit[${index}].unlimited`, true); // Ensure field is marked as touched
  };



  document.title = " Create Plan | Al khairi care ";
  function goBack() {
    history(-1);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title=" Create Plan " pageTitle="Plan" />
          <Row>
            <Col>
              {" "}
              <button
                onClick={goBack}
                className="btn btn-primary px-2 text-center mb-3"
              >
                <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
              </button>{" "}
            </Col>
          </Row>
          <Row>
            <Col xxl={9}>
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Create Plan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Add Plan Limit
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      Formik.handleSubmit();
                      return false;
                    }}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Row>
                            <Col
                              lg={12}
                              className="m-2 bg-light-warning rounded border-success border border-dashed p-2"
                              style={{
                                backgroundColor: "rgb(0 152 199 / 11%)",
                              }}
                            >
                              <div className="notice d-flex flex-wrap m-1 mb-3 align-items-center">
                                <Col
                                  xs={2}
                                  md={1}
                                  className="d-flex justify-content-center mb-3 mb-md-0"
                                >
                                  <i
                                    className="bx bx-message-square-check"
                                    style={{ fontSize: "2rem" }}
                                  ></i>
                                </Col>
                                <Col
                                  xs={10}
                                  md={8}
                                  className="d-flex align-items-center ms-sm-1 mb-3 mb-md-0"
                                >
                                  <div className="flex-grow-1">
                                    <h4
                                      className="text-gray-800 fw-bolder mt-2 me-5"
                                      style={{ textAlign: "left" }}
                                    >
                                      Plan
                                    </h4>
                                    <div className="fs-6 text-gray-600 pe-7 mb-2 me-5">
                                      Plans are provided to the company and its
                                      respective workers. These are curated
                                      using a list of benefits. You can manage
                                      the plan list by clicking on this button.
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  className="d-flex justify-content-center justify-content-md-end mt-2 mt-md-0"
                                >
                                  <Link
                                    to="/benefit"
                                    className="btn text-white"
                                    style={{
                                      backgroundColor: "#28a8df",
                                      borderColor: "#28a8df",
                                    }}
                                  >
                                    View Benefits List
                                  </Link>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label required"
                                >
                                  Plan's Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Plans name"
                                  onChange={Formik.handleChange}
                                  onBlur={Formik.handleBlur}
                                  value={Formik.values.name || ""}
                                  invalid={
                                    Formik.touched.name && Formik.errors.name
                                      ? true
                                      : false
                                  }
                                ></Input>
                                {Formik.touched.name && Formik.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {Formik.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label required"
                                >
                                  Validity <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="validity"
                                  type="date"
                                  className="form-control"
                                  id="choices-publish-status-input"
                                  onChange={Formik.handleChange}
                                  onBlur={Formik.handleBlur}
                                  value={Formik.values.validity || ""}
                                  invalid={
                                    Formik.touched.validity &&
                                    Formik.errors.validity
                                      ? true
                                      : false
                                  }
                                ></Input>
                                {Formik.touched.validity &&
                                Formik.errors.validity ? (
                                  <FormFeedback type="invalid">
                                    {Formik.errors.validity}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label required"
                                >
                                  Select Benefits <span className="text-danger">*</span>
                                </Label>

                                <div className="row">
                                  {plan?.map((item: any, index: number) => (
                                    <div
                                      className="col-lg-4 mb-2"
                                      key={item.id}
                                    >
                                      <Label className="col-lg-12 form-check form-check-inline form-check-solid">
                                        <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={Formik.handleChange}
                                          onBlur={Formik.handleBlur}
                                          {...Formik.getFieldProps(`benefitId`)}
                                          value={item.id}
                                        />
                                        <span className="ps-2 fs-6">
                                          {item.name}
                                        </span>
                                      </Label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label required"
                              >
                                Plan Benefits <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="benifits"
                                type="textarea"
                                placeholder="Enter list of all benefits of this Plan "
                                className="form-control"
                                id="choices-publish-status-input"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.benifits || ""}
                                invalid={
                                  Formik.touched.benifits &&
                                  Formik.errors.benifits
                                    ? true
                                    : false
                                }
                              ></Input>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn text-white add-btn me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                onClick={() => {
                                  tabChange("2");
                                }}
                              >
                                Plan Limit
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Col
                          lg={12}
                          className="m-auto bg-light-warning rounded border-success p-2 border border-dashed"
                          style={{ backgroundColor: "rgb(0 152 199 / 11%)" }}
                        >
                          <div className="notice d-flex m-1 mb-10 align-items-center">
                            <Col lg={1} className="ms-3">
                              <i
                                className="bx bx-message-square-check justify-self-center"
                                style={{ fontSize: "2rem" }}
                              ></i>
                            </Col>
                            <Col lg={11}>
                              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                <div className="mb-3 mb-md-0 fw-bold">
                                  <h4
                                    className="text-gray-800 fw-bolder mt-2 me-5"
                                    style={{ textAlign: "left" }}
                                  >
                                    Coverages
                                  </h4>
                                  <div className="fs-6 text-gray-600 pe-7 mb-2 ms-1 me-5">
                                    Please add the list of all coverages and non
                                    coverages for each panel and non panel
                                    clinic respectively.
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Col>
                        <Row className="my-3">
                          {soblimit.map((item, i) => (
                            <div key={i}>
                              <div className="mb-3 mb-">
                                <div className="row">
                                  <div className="col-lg-3 mb-2 fv-row">
                                    <Input
                                      type="select"
                                      className="form-select"
                                      placeholder="Category"
                                      {...Formik.getFieldProps(
                                        `benefitlimit[${i}].cliniccategoryId`
                                      )}
                                    >
                                      <option value="">
                                        Select a Category
                                      </option>
                                      {cCategory.map((item: any, j: any) => {
                                        return (
                                          <option key={j} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                    {Formik.touched.benefitlimit &&
                                    Formik.touched.benefitlimit[i]
                                    ?.cliniccategoryId &&
                                      Formik.errors.benefitlimit &&
                                      Formik.errors.benefitlimit[i]
                                        ?.cliniccategoryId && (
                                        <div className="text-danger">
                                          {
                                            Formik.errors.benefitlimit[i]
                                              ?.cliniccategoryId
                                          }
                                        </div>
                                      )}
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <Input
                                      type="number"
                                      className="form-control "
                                      placeholder="Enter limit"
                                      {...Formik.getFieldProps(
                                        `benefitlimit[${i}].limit`
                                      )}
                                    />
                                    {/* {Formik.touched.benefitlimit &&
                                      Formik.errors.benefitlimit &&
                                      Formik.errors.benefitlimit[i]?.limit && (
                                        <div className="text-danger">
                                          {Formik.errors.benefitlimit[i]?.limit}
                                        </div>
                                      )} */}
                                  </div>
                                  <div className="col-lg-3 fv-row">
                                    <div
                                      className=" form-check form-check-inline form-check-solid mb-3 mb-lg-0"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        {...Formik.getFieldProps(
                                          `benefitlimit[${i}].unlimited`
                                        )}
                                        checked={
                                          Formik.values.benefitlimit[i]
                                            .unlimited === "1"
                                        }
                                        onChange={() =>
                                          handleCheckboxChangeunlimited(i)
                                        }
                                      />
                                      <span className=" ps-2 fs-6">
                                        Unlimited
                                      </span>
                                    </div>
                                  </div>
                                  <Input
                                    type="hidden"
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                                    {...Formik.getFieldProps(
                                      `benefitlimit[${i}].status`
                                    )}
                                  />

                                  {i > soblimit.length ? (
                                    ""
                                  ) : (
                                    <div
                                      className="col-lg-2 align-self-center"
                                      
                                    >
                                      <i className="bx bxs-trash-alt" onClick={() => addRemove(i)}></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </Row>
                        <div className="d-flex justify-content-start py-6 px-9">
                          <Button
                            onClick={addRow}
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                          >
                            Add New Field
                          </Button>
                        </div>

                        <Row className="mt-3">
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label required"
                              >
                                Panel Clinic Coverage <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="planCovers"
                                type="textarea"
                                className="form-control"
                                placeholder="Enter list of all panel clinic coverages "
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.planCovers || ""}
                                invalid={
                                  Formik.touched.planCovers &&
                                  Formik.errors.planCovers
                                    ? true
                                    : false
                                }
                              ></Input>
                              {Formik.touched.planCovers &&
                              Formik.errors.planCovers ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.planCovers}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label required"
                              >
                                Non Panel Clinic Coverage <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="nonPlanCovers"
                                type="textarea"
                                className="form-control"
                                placeholder="Enter list of all non panel clinic coverages "
                                id="choices-publish-status-input"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.nonPlanCovers || ""}
                                invalid={
                                  Formik.touched.nonPlanCovers &&
                                  Formik.errors.nonPlanCovers
                                    ? true
                                    : false
                                }
                              ></Input>
                              {Formik.touched.nonPlanCovers &&
                              Formik.errors.nonPlanCovers ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn text-white add-btn me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanAdd;
