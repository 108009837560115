import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL


const ListCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/list`)
    .then((d: any) => d.data)
}

const getClinicCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`)
    .then((response) => {return response})
   
}
const getBenefit = ()=> {
  return axios
    .get(`${API_URL}/plan/getbenefit`)
    .then((response) => {return response})
   
}

const createCategory = (name: any) => {
  return axios
  .post(`${API_URL}/clinicCategory/add`,name)
  .then((response) => response.data)
}
const deleteCategoryById = (id: any) => {
  return axios
    .post(`${API_URL}/clinicCategory/delete/${id}`)
    .then((response) => {
   // 
    return response.data;

    }
    )
}

const updateCategory = (id:any,name:any) => {
  return axios
    .put(`${API_URL}/clinicCategory/edit/${id}`,name)
    .then((response) => {return response.data; }
    )
}
const creditReport = (id:any,Daterange:any,OCcatid:any) => {
  return axios
    .post(`${API_URL}/report/agency/creditReport`,{id,Daterange,OCcatid})
    .then((response) => {return response; }
    )
}

export {getBenefit,ListCategory, deleteCategoryById,createCategory,updateCategory,getClinicCategory,creditReport}
