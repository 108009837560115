import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Benefit_URL = `${API_URL}/benefit`




const createBenefit = (Benefit: any) => {
  return axios
    .post(`${Benefit_URL}/add`, Benefit)
    .then((response) => {
      return response.data
     })
   
}

const ListBenefit = () => {
  return axios
    .get(`${Benefit_URL}/list`)
    .then((response) => {
      //
      return response.data
     })
   
}
const updateBenefit = (Benefit:any,id:any) => {
  
  return axios
    .put(`${Benefit_URL}/edit/${id}`,Benefit)
    .then((response) => {
     // 
      return response.data
     })
   
}
const GetBenefit = (id:any) => {
  return axios
    .get(`${Benefit_URL}/view/${id}`)
    .then((response) => {
     // 
      return response.data
     })
   
}
const DeleteBenefit = (id:any) => {
  return axios
    .post(`${Benefit_URL}/delete/${id}`)
    .then((response) => {
     // 
      return response.data
     })
   
}
export {createBenefit, ListBenefit,DeleteBenefit,updateBenefit,GetBenefit}
