import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const MAIN_URL = `${API_URL}/clinicPayment`




const getClinicPayment = (filter:any) => {
  return axios
    .post(`${MAIN_URL}/list`,filter)
    .then((d: any) => {
  
      return d.Data
    })
}
const getClinicPaymentById = (id:any) => {
  return axios
    .get(`${MAIN_URL}/viewClinicPayment/${id}`) 
    .then((response) =>{
   
      return response;
    }
   )
}
const addClinicPayment = (data: FormData,clinicID:any) => {
 
  return axios
  .post(`${MAIN_URL}/addClinicPayment/${clinicID}`, data,)
  .then((response) => {
 
    return response.data
   })
   
}
const updateAdminFile=(data: FormData,id:any)=>{

  return axios
  .put(`${MAIN_URL}/UpdateAdminPaid/${id}`, data,{
    headers: {
      "Content-Type": "multipart/form-data",
    },})
  .then((response:any) => {
   
    return response
   })
}
export {
  getClinicPayment,addClinicPayment,getClinicPaymentById,updateAdminFile
}
