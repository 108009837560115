import React from 'react';
import {Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Widgets from './Widgets';
import AgencySupportData from './AgencySupportData';
import ClinicSupportData from './ClinicSupportData';
import WorkerSupportData from './WorkerSupportData';


const SupportOverview = () => {
    
    document.title="Support Overview | Alkhairi care";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                    
                    <BreadCrumb title="Support Overview" pageTitle="Support Overview" />
                    <Row>
                        <Widgets />
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <AgencySupportData />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <ClinicSupportData />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <WorkerSupportData />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SupportOverview;