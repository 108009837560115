import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const Diagnosis_URL = `${API_URL}/diagnosis`



const ListDiagnosis = () => {
  return axios
    .post(`${Diagnosis_URL}/list`)
    .then((response:any) => {

   // 
    return response.Data;

    }
    )
}
const deleteDiagnosisById = (id: any) => {
  return axios
    .post(`${Diagnosis_URL}/delete/${id}`, {})
    .then((response) => {

   // 
    return response.data;

    }
    )
}
const updateDiagnosisById = (name:any,id: any) => {
  
  return axios
    .put(`${Diagnosis_URL}/edit/${id}`, name)
    .then((response) => {
    return response.data;

    }
    )
}


const createDiagnosis = (Diagnosis: any) => {
  return axios
    .post(`${Diagnosis_URL}/add`, Diagnosis)
    .then((response) => response.data)
    
}



export {updateDiagnosisById,deleteDiagnosisById, createDiagnosis,ListDiagnosis}
