import React from "react";
import AgencyList from "./List/AgencyList";
// import SupportView from "./SupportView";

const MainComponent = () => {
  return (
    <div>
      <AgencyList />
    </div>
  );
};

export default MainComponent;