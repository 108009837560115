import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import progileBg from "../../../src/assets/images/profile-bg.jpg";
import {
  admingetDepartment,
  createAgency,
  getAgency,
  getCountry,
  getSubSob,
} from "./_requests";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { checkDuplicateEmail } from "pages/Users/_requests";
import BreadCrumb from "Components/Common/BreadCrumb";
const AgencyAdd = () => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Worker | Alkhairi care";
  const [selectedImage, setSelectedImage] = useState<any>();
  const [department, setDepartment] = useState<any>([]);
  const [country, setapiCountry] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [plan, setPlan] = useState<any>([]);
  const [imgStore, setImgStore] = useState<any>();

  const handleClick = (item: any) => {
    const newData = [...imgStore, item];
    setImgStore(newData);
    Formik.setFieldValue("img", newData);
  };

  useEffect(() => {
    const filter = {};
    getAgency(filter).then((res: any) => {
      setCompany(res.data);
    });
  }, []);
  useEffect(() => {
    getCountry().then((res: any) => {
      //
      setapiCountry(res.data);
    });
  }, []);
  useEffect(() => {
    getSubSob().then((res: any) => {
      setPlan(res.data);
    });
  }, []);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      Formik.setFieldValue("img", e.target.result);
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const add_Agency = async (values: any) => {
    const response = await createAgency(values);
    Formik.resetForm();
    history("/agency");
    await setTimeout(() => {
      toast.success("Agency Added Successfully", { autoClose: 3000 });
    }, 100);
  };
  const Formik: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      userId: "",
      agencyUniqueId: "",
      name: "",
      email: "",
      telephoneNumber: "",
      agencyType: "",
      primaryContact: "",
      primaryEmail: "",
      primaryTelephone: "",
      totalWorkers: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      fax: "",
      website: "",
      non_panel_disable_status: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z ]+$/, "Agency name should contain only alphabets")
        .required("Agency name is required"),
      agencyType: Yup.string().required("Agency Type is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      telephoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone number should contain exactly 10 numbers")
        .required("Phone Number is required"),
      primaryContact: Yup.string()
        .matches(
          /^[a-zA-Z ]+$/,
          "Contact Person Name should contain only alphabets"
        )
        .required("Contact Person Name is required"),
      primaryEmail: Yup.string()
        .email("Invalid email")
        .required("Contact Person Email is required"),
      primaryTelephone: Yup.string()
        .matches(
          /^\d{10}$/,
          "Primary Contact phone number should contain exactly 10 numbers"
        )
        .required("Primary Contact phone number is required"),
      totalWorkers: Yup.string().required("Total Workers is required"),
      address: Yup.string().required("Primary address is required"),
      country: Yup.string().required("Country is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      pincode: Yup.string().required("Post Code is required"),
      fax: Yup.string(),
      website: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      const newAgency = {
        userId: values.userId,
        agencyUniqueId: values.agencyUniqueId,
        name: values.name,
        email: values.email,
        primaryEmail: values.primaryEmail,
        telephoneNumber: values.telephoneNumber,
        mobileNumber: values.mobileNumber,
        agencyType: values.agencyType,
        primaryContact: values.primaryContact,
        primaryTelephone: values.primaryTelephone,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
        totalWorkers: values.totalWorkers,
        fax: values.fax,
        website: values.website,
        non_panel_disable_status: values.non_panel_disable_status,
      };
      await add_Agency(newAgency);
    },
  });

  useEffect(() => {
    if (Formik.values.agencyId) {
      admingetDepartment().then((res: any) => {
        setDepartment(res.data);
      });
    }
  }, [Formik.values.agencyId]);

  const [Duplicate, setDuplicate] = useState<any>("");
  const DuplicateCheck = (val: any) => {
    try {
      checkDuplicateEmail(val).then((response: any) => {
        if (response?.isDuplicate === true) {
          setDuplicate("Email already exist");
        } else {
          setDuplicate("");
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  document.title = "Create Agency | Al khairi care ";
  function goBack() {
    history(-1);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Create Agency" pageTitle="Agency" />
          <Row>
            <Col>
              {" "}
              <button
                onClick={goBack}
                className="btn btn-primary px-2 text-center mb-3"
              >
                <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
              </button>{" "}
            </Col>
          </Row>
          <Row>
            <Col xxl={9}>
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardBody className="p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      Formik.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label required"
                          >
                            Agency Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter your Name"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.name || ""}
                            invalid={
                              Formik.touched.name && Formik.errors.name
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.name && Formik.errors.name ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="agencyTypeInput"
                            className="form-label required"
                          >
                            Agency Type <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="agencyType"
                            type="select"
                            className="form-select"
                            id="choices-publish-status-input"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.agencyType || ""}
                            invalid={
                              Formik.touched.agencyType &&
                              Formik.errors.agencyType
                                ? true
                                : false
                            }
                          >
                            <option>Select a agencyType...</option>
                            <option value="Sole proprietorship">
                              Sole proprietorship
                            </option>
                            <option value="Partnership">Partnership</option>
                            <option value="Private limited company (Sdn. Bhd.)">
                              Private limited company (Sdn. Bhd.)
                            </option>
                            <option value="Public limited company (Berhad)">
                              Public limited company (Berhad)
                            </option>
                            <option value="Foreign company">
                              Foreign company
                            </option>
                            <option value="Limited liability partnership (LLP)">
                              Limited liability partnership (LLP)
                            </option>
                          </Input>
                          {Formik.touched.agencyType &&
                          Formik.errors.agencyType ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.agencyType}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            Agency Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter your Email"
                            onChange={(e) => {
                              Formik.handleChange(e);
                              DuplicateCheck(e.target.value);
                            }}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.email || ""}
                            invalid={
                              Formik.touched.email && Formik.errors.email
                                ? true
                                : false
                            }
                          />
                          <span style={{ color: "red" }}>
                            {Duplicate ? Duplicate : ""}
                          </span>
                          {Formik.touched.email && Formik.errors.email ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="phonenumberInput"
                            className="form-label"
                          >
                            Agency Phone Number <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="telephoneNumber"
                            type="number"
                            className="form-control"
                            placeholder="Enter your Phone Number"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.telephoneNumber || ""}
                            invalid={
                              Formik.touched.telephoneNumber &&
                              Formik.errors.telephoneNumber
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.telephoneNumber &&
                          Formik.errors.telephoneNumber ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.telephoneNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="designationInput"
                            className="form-label"
                          >
                            Contact Person Name{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="primaryContact"
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.primaryContact || ""}
                            invalid={
                              Formik.touched.primaryContact &&
                              Formik.errors.primaryContact
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.primaryContact &&
                          Formik.errors.primaryContact ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.primaryContact}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="websiteInput1" className="form-label">
                            Contact Person Email{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="primaryEmail"
                            type="email"
                            className="form-control"
                            placeholder="Enter Your Email"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.primaryEmail || ""}
                            invalid={
                              Formik.touched.primaryEmail &&
                              Formik.errors.primaryEmail
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.primaryEmail &&
                          Formik.errors.primaryEmail ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.primaryEmail}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="cityInput" className="form-label">
                            Contact Person Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="primaryTelephone"
                            type="text"
                            className="form-control"
                            placeholder="primaryTelephone"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.primaryTelephone || ""}
                            invalid={
                              Formik.touched.primaryTelephone &&
                              Formik.errors.primaryTelephone
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.primaryTelephone &&
                          Formik.errors.primaryTelephone ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.primaryTelephone}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="countryInput" className="form-label">
                            Agency Fax (Optional)
                          </Label>
                          <Input
                            name="fax"
                            type="text"
                            className="form-control"
                            placeholder="Fax"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.fax || ""}
                            invalid={
                              Formik.touched.fax && Formik.errors.fax
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.fax && Formik.errors.fax ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.fax}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            Agency Website (Optional)
                          </Label>
                          <Input
                            name="website"
                            type="text"
                            className="form-control"
                            placeholder="Enter website"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.website || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            Total Workers <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="totalWorkers"
                            type="text"
                            className="form-control"
                            id="zipcodeInput"
                            placeholder="Enter Worker"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.totalWorkers || ""}
                            invalid={
                              Formik.touched.totalWorkers &&
                              Formik.errors.totalWorkers
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.totalWorkers &&
                          Formik.errors.totalWorkers ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.totalWorkers}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="skillsInput" className="form-label">
                            Country <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="country"
                            type="select"
                            className="form-select"
                            id="choices-publish-status-input"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.country || ""}
                            invalid={
                              Formik.touched.country && Formik.errors.country
                                ? true
                                : false
                            }
                          >
                            <option>Select a Country...</option>
                            {country?.map((item: any, index: any) => (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {Formik.touched.country && Formik.errors.country ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.country}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            Address <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="address"
                            placeholder="Enter Address"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.address || ""}
                            invalid={
                              Formik.touched.address && Formik.errors.address
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.address && Formik.errors.address ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            City <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="Enter City"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.city || ""}
                            invalid={
                              Formik.touched.city && Formik.errors.city
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.city && Formik.errors.city ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            State <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter State"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.state || ""}
                            invalid={
                              Formik.touched.state && Formik.errors.state
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.state && Formik.errors.state ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.state}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="zipcodeInput" className="form-label">
                            Postal Code <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            name="pincode"
                            placeholder="Enter Postal Code"
                            onChange={Formik.handleChange}
                            onBlur={Formik.handleBlur}
                            value={Formik.values.pincode || ""}
                            invalid={
                              Formik.touched.pincode && Formik.errors.pincode
                                ? true
                                : false
                            }
                          />
                          {Formik.touched.pincode && Formik.errors.pincode ? (
                            <FormFeedback type="invalid">
                              {Formik.errors.pincode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h5 className="mt-3 mb-4">
                            Non panel claim form configuration
                          </h5>
                        </div>
                      </div>

                      <Col lg={12}>
                        <Card>
                          <CardBody className="bg-info-subtle">
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <FeatherIcon
                                  icon="check-square"
                                  className="text-info icon-dual-info"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-6">
                                  Configure non panel claim forms for Agency
                                </h6>
                                <p className="text-muted mb-0">
                                  Option to enable or disable the Non-Panel
                                  Claim Form is available. When this feature is
                                  enabled, Agencys and insurance holders will
                                  have the capability to submit Non-Panel
                                  claims. Conversely, if the feature is
                                  disabled, the Non-Panel Claim Form will be
                                  inaccessible on both the web portal and mobile
                                  application.
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col xs={12} md={6}>
  <div className="form-check form-switch form-switch-lg d-flex align-items-center"> {/* Utilize flexbox to align items */}
    <Input
      name="non_panel_disable_status"
      type="checkbox"
      className="form-check-input"
      id="customSwitchsizelg"
      onChange={(e) => {
        const newValue = e.target.checked ? 1 : 0;
        Formik.setFieldValue("non_panel_disable_status", newValue);
      }}
      onBlur={Formik.handleBlur}
      value={Formik.values.non_panel_disable_status || ""}
    />
    <Label className="form-check-label ms-2" for="customSwitchsizelg">Enable or disable non-panel claim forms</Label> {/* Add margin to label */}
  </div>
</Col>


                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyAdd;
