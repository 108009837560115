import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import { recentSupportWorker } from "pages/Admin Dashboard/Dashboard/_request";
import TableContainer from "Components/Common/TableContainer";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WorkerSupportData = () => {
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const [worker, setWorker] = useState<any>([]);
  const [workersupportaccess, setworkersupportaccess] = useState<any>([]);

  useEffect(() => {
    getCRUD(userProfile?.role, 41).then((res: any) => {
      setworkersupportaccess(res.Data[0]);
    });
  }, []);

  useEffect(() => {
    recentSupportWorker().then((res: any) => {
      setWorker(res.Data);
    });
  }, []);

  const listWorkerSupport = () => {
    if (workersupportaccess?.list === 1) {
      navigate("/admin/worker-support");
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  const renderCreatedAt = (cell: any) => {
    const formattedDate = new Date(
      cell.row.original.createdAt
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  const AgencyName = (cell: any) => {
    return <p>{cell.row.original?.fromName}</p>;
  };

  

  const columns = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: renderCreatedAt,
      },
      {
        header: "Support Rasised By",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: AgencyName,
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "Priority",
        accessorKey: "supportpriority.priorityName",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "supportstatus.statusName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const statusValue = cell.getValue();
          let badgeClass = "";
          switch (statusValue) {
            case "Open":
              badgeClass = "border border-danger text-danger";
              break;
            case "Inprogress":
              badgeClass = "border border-secondary text-secondary";
              break;
            case "Resolved":
              badgeClass = "border border-success-subtle text-success";
              break;
          }

          return (
            <span className={`badge text-uppercase ${badgeClass}`}>
              {statusValue}
            </span>
          );
        },
      },

      {
        header: "Actions",
        cell: (cell: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <Link
                  to={`/workerview/${cell.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">
                  Recent Supports for Workers
                </h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <div
                      onClick={listWorkerSupport}
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                    >
                      <i className="fa-solid fa-bars"></i> View All
                    </div>
                  </div>
                </div>
              </div>
              <p>List of recently created supports from Workers</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={worker || []}
                customPageSize={5}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
                SearchPlaceholder="Search for ticket details or something..."
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WorkerSupportData;
