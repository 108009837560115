import React, { useEffect, useState } from "react";
import {
  CardBody,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
  Row,
  Button, 
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import logoDark from "../../../assets/images/logo-light.png";
import logoLight from "../../../assets/images/logo-dark.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone'; // Import Dropzone
import { useProfile } from "Components/Hooks/UserHooks";
import { addClinicPayment } from "./_requests";
import { getClinicsById } from "pages/Clinics/_requests";
import { toast } from "react-toastify";
interface Invoice {
  id: number;
  price: number;
  tax: number;
}

interface SelectedFile {
  name: string;
  file: File; // Change Blob to File
  preview: string;
  formattedSize: string;
  // Add other properties to match File interface if needed
}

const PaymentAdd = () => {
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const [rows, setRows] = useState<Invoice[]>([
    { id: 1, price: 0, tax: 0 },
  ]);
  const [count, setCount] = useState<number>(0);
  const [count2, setCount2] = useState<any>()
  const [tot, setTot] = useState<any>(0)
  const [rate, setRate] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [charge, setCharge] = useState<number>(0);
 // const [invoice, setInvoice] = useState<any>([])
  const [invoice, setInv] = useState<any>()

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const handleAcceptedFiles = (acceptedFiles: File[]) => {
    const mappedFiles: SelectedFile[] = acceptedFiles.map(file => ({
      name: file.name,
      file:file,
      preview: '', // You can set the preview URL if available
      formattedSize: '', // You can format the size if needed
      // Add other properties as needed
    }));
    setSelectedFiles(mappedFiles);
    
  };
  const priceKeyup = (e: any) => {
    var priceVal = parseFloat(e.target.value);
    validation.handleChange(e);
    if (!/^(\d+)?(\.\d{0,2})?$/.test(priceVal.toString())) {
      // If not, truncate it to two decimal places
      priceVal = parseFloat(priceVal.toFixed(2)); // Convert to fixed decimal number
      validation.setFieldValue('price', priceVal.toString()); // Convert to string for setFieldValue
    }
  
    setCount(priceVal);
  
    let taxVal =
      (document.getElementById("tax") as HTMLInputElement)?.value || "0.00";
  
    totalCalculation(priceVal, parseFloat(taxVal));
  };
  

  const taxKeyup = (e: any) => {
    validation.handleChange(e);
    let taxVal = parseFloat(e.target.value);
  
    if (!/^(\d+)?(\.\d{0,2})?$/.test(taxVal.toString())) {
      // If not, truncate it to two decimal places
      taxVal = parseFloat(taxVal.toFixed(2)); // Convert to fixed decimal number
      validation.setFieldValue('tax', taxVal.toString()); // Convert to string for setFieldValue
    }
  
    setCount2(taxVal);
  
    let priceVal =
      (document.getElementById("price") as HTMLInputElement)?.value || "0.00";
  
    totalCalculation(parseFloat(priceVal), taxVal);
  };
  
  const totalCalculation = (priceVal: number, taxVal: number) => {
    
    var totVal = priceVal + taxVal;

    setTot(totVal.toFixed(2));
  }
  
  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
   // accept: ['/*'], // Specify the accepted file types as an array
    onDrop: handleAcceptedFiles, // Callback function for handling dropped files
  });
  const { userProfile } = useProfile();
  const [cli, setCli] = useState<any>([])
const clinicsID=userProfile?.primaryID;
  useEffect(() => {
    getClinicsById(clinicsID).then((res: any) => {
      setCli(res)
    });
    var invdate=Math.floor(Math.random() * 100000000);
    var invoiced="INV-"+invdate;
    setInv(invoiced);
  }, [])


  useEffect(() => {
    let tax = (0.125 + rate + count);
    if (rate && count && !isNaN(rate) && !isNaN(count)) {
      setCharge(65);
    } else {
      setCharge(0);
    }
    setTax(tax);
  }, [rate, count]);


  document.title = "Invoice";
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentDate: "",
      invoiceNumber: "",
      invoiceDueDate: "",
      description: "",
      price: "",
      tax: "",
      remarks: "",
      total:"",
    },
    validationSchema: Yup.object({
      paymentDate: Yup.string().required('Date is required'),
      invoiceDueDate: Yup.string().required('Due Date is required'),
      description: Yup.string().required('Description is required'),
      price: Yup.string().required('Price is required'),
      tax: Yup.string(),
      remarks: Yup.string(),
    }),
    onSubmit:async (values: any) => {
      const formData = new FormData(); 
      formData.append('tax', String(count2)); // Convert count2 to string
      formData.append('amount', String(count)); // Convert count to string
      formData.append('total', String(tot)); // Convert tot to string
      formData.append('invoiceNumber', invoice);
      formData.append('paymentDate', values.paymentDate);
      formData.append('invoiceDueDate', values.invoiceDueDate);
      formData.append('description', values.description);
      formData.append('remarks', values.remarks);
      selectedFiles.forEach((file: SelectedFile, index: number) => {
   
        
        formData.append(`addclinicSentFile`, file.file, file.name); // Append file with unique key
      });
 
      
       const data=await  addClinicPayment(formData,clinicsID)
      history("/portal-clinic-payment"); 
      setTimeout(() => {
        
        toast.success(data.Message)
      }, 100);
      validation.resetForm();
     
  }
  
    },
  );

  const initialRow = {
    id: 1,
    description: '',
    rate: 0,
    count: 0,
    tax: 0,
  };

  // const [rows, setRows] = useState([initialRow]);

  // const addNewItem = () => {
  //   const newRow = {
  //     id: rows.length + 1,
  //     description: '',
  //     rate: 0,
  //     count: 0,
  //     tax: 0,
  //   };
  //   setRows([...rows, newRow]);
  // };

  // const deleteItem = (id: any) => {
  //   const updatedRows = rows.filter((row) => row.id !== id);
  //   setRows(updatedRows);
  // };

  // const updateRow = (id: any, field: any, value: any) => {
  //   const updatedRows = rows.map((row) => (row.id === id ? { ...row, [field]: value } : row));
  //   setRows(updatedRows);
  // };

  function goBack() {
    history(-1);
  }
  document.title =
    "Invoice Create | Al khairi care ";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Create Invoice" pageTitle="Invoices" />
        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                className="needs-validation"
                id="invoice_form"
              >
                <CardBody className="border-bottom border-bottom-dashed p-4">
                  <Row>
                    <Col lg={4}>
                      <div className="profile-user mx-auto  mb-3">
                      
                        <Label for="profile-img-file-input" className="d-block">
                          <span
                            className="overflow-hidden  d-flex align-items-center justify-content-center rounded"
                            style={{ height: "60px", width: "256px" }}
                          >
                            <img
                              src={logoDark}
                              className="card-logo card-logo-dark user-profile-image img-fluid"
                              alt="logo dark" style={{ height: "55px", }}
                            />
                            <img
                              src={logoLight}
                              className="card-logo card-logo-light user-profile-image img-fluid"
                              alt="logo light" style={{ height: "55px", }}
                            />
                          </span>
                        </Label>
                      </div>
                    </Col>
                    <Col lg={4} className="ms-auto">
                      <div className="mb-2">
                        <Label for="invoicenoInput">Invoice No</Label>
                        <Input
                          type="text"
                          className="form-control bg-light border-0"
                          id="invoicenoInput"
                          name="invoiceNumber"
                          value={invoice}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          placeholder=""
                          invalid={validation.errors.invoiceNumber && validation.touched.invoiceNumber? true : false}
                        />
                    
                      </div>
                      <div className="mb-2">
                      <Label for="date-field">Date</Label>
                        <div className="input-group">
                        <Input
                        type="date"
                          className="form-control"
                          placeholder="Select Date-time"
                           {...validation.getFieldProps('paymentDate')}
                        />
                    
                       </div>
                        {validation.touched.paymentDate && validation.errors.paymentDate ? (
                          <p className="text-danger">{validation.errors.paymentDate}</p>
                        ) : null}

                      </div>
                      <div>
                        <Label for="date-field">Due Date</Label>
                        <div className="input-group">
                        <Input
                        type="date"
                          name="invoiceDueDate"
                          id="event-start-date"
                          className="form-control"
                          placeholder="Select Date-time"
                          {...validation.getFieldProps('invoiceDueDate')}
                        />
                    
                       </div>
                        {validation.touched.invoiceDueDate && validation.errors.invoiceDueDate ? (
                          <p className="text-danger">{validation.errors.invoiceDueDate}</p>
                        ) : null}

                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="p-4 border-top border-top-dashed">
                    <Row>
                      <Col lg={4} sm={6}>
                        <div>
                          <Label
                            for="billingName"
                            className="text-muted text-uppercase fw-semibold"
                          >
                            Issued By
                          </Label>
                        </div>
                        <div>
                              <Label className="fw-bold text-gray-800 mb-2">
                                {cli?.name}
                              </Label>

                              <Col className="mb-1">{cli?.address}</Col>
                              <Col className="mb-1"> {cli?.city}</Col>
                              <Col className="mb-1">
                                {cli?.state}-{cli?.pincode}.
                              </Col>
                            </div>
                    
                      </Col>
                      <Col sm={6} className="ms-auto">
                        <Row>
                          <Col lg={8}>
                            <div className="row">
                              <div className="col-12">
                                <Label
                                  for="shippingName"
                                  className="text-muted fw-semibold me-5"
                                >
                                  Issued For
                                </Label>
                              </div>
                            </div>
                            <div className="mb-2">
                          <Label className="fw-bold text-gray-800 mb-2">
                            Alkhairi Care
                          </Label>
                          <Col className="mb-1">
                            79, Jalan SS 21/1a, Damansara Utamae
                          </Col>
                          <Col className="mb-1">
                            {" "}
                            47400 Petaling Jaya, Selangor,
                          </Col>
                          <Col className="mb-1"> Malaysia.</Col>
                        </div>
                        
                          </Col>
                   
                        </Row>
                    
                      </Col>
                    </Row>
                  </CardBody>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                      <thead className="align-middle">
                        <tr className="table-active">
                         
                          <th scope="col">DESCRIPTION</th>
                          <th scope="col" style={{ width: "120px" }}>
                            <div className="d-flex currency-select input-light align-items-center">
                              PRICE
                            </div>
                          </th>
                          <th scope="col" style={{ width: "120px" }}>
                            <div className="d-flex currency-select input-light align-items-center">
                              Tax
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: "150px" }}
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: "105px" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody id="newlink">

                        <tr  className="product">
                        
                          <td className="text-start">
                            <Input
                              type="textarea"
                              name="description"
                              className="form-control bg-light border-0"
                            
                              rows={2}
                              {...validation.getFieldProps('description')}
                              placeholder="Description"
                            />
                          </td>
                          <td>
                            <Input
                              name="price"
                              id="price"
                              type="number"
                              className="form-control product-price bg-light border-0"
                              placeholder="0.00"
                              style={{ width: "120px" }}
                              {...validation.getFieldProps('price')}
                              onChange={priceKeyup}
                              value={count ? count : ''}
                             
                            />
                            <div className="invalid-feedback">Please enter a price</div>
                          </td>
                          <td>
                            <Input
                              type="number"
                              name="tax"
                              id="tax"
                              className="form-control product-price bg-light border-0"
                              placeholder="0.00"
                              style={{ width: "120px" }}
                              {...validation.getFieldProps('tax')}
                              onChange={taxKeyup}
                              value={count2 ? count2 : ''}
                            />
                            <div className="invalid-feedback">Please enter a tax</div>
                          </td>
                          <td className="text-end">
  <div className="p-relative left-100">
    RM {tot ? tot : '0.00'}
  </div>
</td>

                        </tr>
                      
                      </tbody>
                      <tbody>
                        <tr id="newForm" style={{ display: "none" }}><td className="d-none" colSpan={5}><p>Add New Form</p></td></tr>
                        {/* <tr>
                          <td colSpan={5}>
                            <Link
                              to="#"
                              className="btn btn-soft-secondary fw-medium"
                              id="add-item"
                              onClick={addNewItem}
                            >
                              <i className="ri-add-fill me-1 align-bottom"></i>
                              Add Item
                            </Link>
                          </td>
                        </tr> */}
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan={3}></td>
                          <td colSpan={2} className="p-0">
                            <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                              <tbody>

                                 <>
                                <tr>
                                  <th scope="row">Sub Total</th>
                                  <td style={{ width: "150px" }}>
                                    {/* <Input
                                      type="text"
                                       name="subtotal"
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      placeholder="RM0.00"
                                      readOnly
                                      value={"RM" + count ? count : 0.0}
                                    /> */}
                                    RM { count ? count : 0.0}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row"> Tax</th>
                                  <td>
                                    {/* <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-tax"
                                      placeholder="RM0.00"
                                      readOnly
                                      value={"RM" + (count2 ? count2.toFixed(2) : 0.0)}
                                   
                                    /> */}
                                    RM {count2 ? count2.toFixed(2) : 0.0}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    Total
                                  </th>
                                  <td>
                                    {/* <Input
                                      type="text"
                                      name="total"
                                      className="form-control bg-light border-0"
                                      id="cart-discount"
                                      placeholder="RM0.00"
                                      readOnly
                                      {...validation.getFieldProps('total')}
                                      value={"RM" + tot ? tot : 0.0}
                              
                                    /> */}
                                    RM { tot ? tot : 0.0}
                                  </td>
                                </tr>
                                </>
                                
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
      
                <CardBody>
                  <div>
                    <p className="text-muted">Add Attached files here.</p>

                    {/* Dropzone component */}
                    <div {...getRootProps()} className="dropzone dz-clickable">
                      <input {...getInputProps()} type="file" name="clinicSentFile"/>
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>

                    {/* List of selected files */}
                    <ul className="list-unstyled mb-0" id="dropzone-preview">
                      {selectedFiles.map((file, index) => (
                        <Card key={index + "-file"} className="mt-1 mb-0 shadow-none border">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={file.preview}
                                />
                              </Col>
                              <Col>
                                <Link to="#" className="text-muted font-weight-bold">
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{file.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      ))}
                    </ul>
                  </div>
                </CardBody>
  

                  <div className="mt-4">
                    <Label
                      for="exampleFormControlTextarea1"
                      className="form-label text-muted text-uppercase fw-semibold"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control bg-light border-0"
                      id="productDetails-1"
                      {...validation.getFieldProps('remarks')}
                      rows={2}
                      placeholder="Add remarks about this invoice here ..."
                      style={{ height: " 88px" }}
                    ></Input>
                  </div>



                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <Button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                      <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                      Send Invoice
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PaymentAdd;


