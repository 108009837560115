import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  getCompany,
  getClinic,
} from './_requests'
import Flatpickr from "react-flatpickr";
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import { Button, Card, Container, Row } from 'reactstrap'
import TableContainer from './TableContainer';
import { getReportforClinic } from '../Worker Report/_requests';
import { getNonPanelReport } from 'pages/NonPanelClaimForm/_requests';
import * as XLSX from 'xlsx';
const NonpanelClaimReport = () => {
  const flatpickrRef = useRef<Flatpickr>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const tableRef = useRef(null)
  const [com, setCom] = useState<any>([])
  const [Total, setTotal] = useState<any>('')

  const [OCcom, setOCcom] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [OCplan, setOCplan] = useState('')
  const [clinicid, setclinicid] = useState(0)
  const [plan, setPlan] = useState<any>([])
  const [planid, setplanid] = useState(0)
  const [report, setReport] = useState<any>([])
  const [notreport, setNotReport] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);

  useEffect(() => {
    getClinic().then((res: any) => {
      // setCom(res.data.Data)
      const mappedOptions = res.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setCom(optionsWithAll)
    })
  }, [])

  //


  var Datefilename = new Date()
  var fileName =
    Datefilename.getFullYear() + '-' + (Datefilename.getMonth() + 1) + '-' + Datefilename.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Clinic_Report_${fileName}`,
    sheet: 'Report',
  })

 

  const [OCcomid, setOCcomid] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const [planselectedOption, setplanselectedOption] = useState(null)
  const [clinicselectedOption, setclinicselectedOption] = useState(null)

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    var text = selectedOption?.label
    setOCcom(text)
    setclinicid(selectedOption?.value)
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  }
 
  const Report = () => {
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const startDate =selectedDates[0] ? formatDate(selectedDates[0]) : "";
    const endDate = selectedDates[1] ? formatDate(selectedDates[1]) : "";
    const Daterange =(startDate && endDate) ? `${startDate} - ${endDate}` : "";


    setOCDate(Daterange);
    getNonPanelReport( Daterange).then((resRep:any) => {
      //
      setReport(resRep.Data)
     const  total = resRep.Data.reduce((acc: number, res: any) => {
      return acc + parseInt(res.amount);
    }, 0);
     setTotal(total);
     
        if (resRep.Data.length > 0 ) {
          setbtnExcelDisable(false)
          setbtnClearDisable(false)
        } else {
          setbtnExcelDisable(true)
          setbtnClearDisable(true)
        }
      
    })
  }
  const clearReport = () => {
    setSelectedOption(null)
    setplanselectedOption(null)
    setclinicselectedOption(null)
    setReport([])
    setNotReport([])
    setPlan([])
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setOCDate('')
    setOCplan('')
    setOCcom('')

    setplanid(0)
    setOCcomid(0)
    getCompany().then((res: any) => {
      const mappedOptions = res.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setCom(optionsWithAll)
    })

    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  

  const renderCreatedAt = (cell: any,dateKey :any) => {
    const formattedDate = new Date(
      cell.row.original[dateKey]
    ).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return <>{formattedDate}</>;
  };

  const columns = useMemo(
    () => [
  
      {
        header: "S.No",
        id: 'sno',
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
  

      },
      {
        header: "Nric/Passport",
        accessorKey: "nricPassport",
        enableColumnFilter: false,
      },
      {
        header: "Wbs Code",
        accessorKey: "wbsCode",
        enableColumnFilter: false,
      },
      {
        header: "Clinic",
        accessorKey: "clinicName",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agencyName",
        enableColumnFilter: false,
      },
      {
        header: "Visit Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell:any) => renderCreatedAt(cell, "visitDate"),
      },
      {
        header: "Reason",
        accessorKey: "visitReason",
        enableColumnFilter: false,
      },
      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.approvalStatus === 1 ? 'Approved' : 'Pending'}
            </>
          );
        },
      },
      {
        header: "prescription",
        accessorKey: "prescription",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <a href={cell.row.original.prescription} target="_blank" rel="noopener">{cell.row.original.prescription}</a>
            </>
          );
        },

      },
      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
   
    ],
    []
  );

 
  const handleExport = async () => {
    const formattedData = await formatDataForExport();
    exportToExcel(formattedData);
  };

  const formatDataForExport = async () => {
    const exportData: { [key: string]: string | number }[] = [];

    await Promise.all(
      report.map(async (row:any, i:any) => {
        const dataRow = {
          sno: i + 1,
          name: row.name ?? "",
          nricPassport: row.nricPassport ?? "",
          wbsCode: row.wbsCode ?? "",
          clinicName: row.clinicName ?? "",
          agencyName: row.agencyName ?? "",
          visitDate: row.visitDate ? new Date(row.visitDate).toLocaleDateString("en-GB") : "",
          visitReason: row.visitReason ?? "",
          approvalStatus: row.approvalStatus === 1 ? "Approved" : (row.approvalStatus === 0 ? "Pending" : ""),
          prescription: row.prescription ?? "",
          amount: row.amount ?? ""
        };
        exportData.push(dataRow);
      })
    );
    const lastRow = {
      prescription: "Total",
      amount: Total,
    };
    exportData.push(lastRow);

    return exportData;
  };
  





const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveExcelFile(excelBuffer);
};


  var DatefileName = new Date();
  var DateFileName  =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();
  
  const saveExcelFile = (buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const fileName = `NonpanelClaim_Report${DateFileName}.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  

  document.title = "Nonpanel Claimform Report  | Al khairi care ";

  return (
    
       <div className="page-content">
        <Container fluid>
          <Card>
        {/* begin::Header */}
        <div className='cursor-pointer'>
          <Row>
          <div className='col-sm'>
            <h5 className='card-title m-3'>Nonpanel Claimform Report</h5>
          </div>
          <div className='col-sm-auto m-3'>
            <button
         className="btn text-white add-btn me-1 col-lg-12"
         style={{
           backgroundColor: "#28a8df",
           borderColor: "#28a8df",
         }}
              disabled={btnExcelDisable}
              onClick={handleExport}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
        
        
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <Flatpickr
                  className="form-control"
                  id="Daterange"
                  placeholder="Select a date"
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={selectedDates}
                  onChange={(dates: Date[]) => setSelectedDates(dates)}
                  ref={flatpickrRef}
                />
            </div>

<div className='col-lg-2'>
  <br/>
                <Button onClick={Report}     className="btn text-white mt-3 add-btn me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }} >
                  Search
                </Button>
                </div>
                <div className='col-lg-2'>
                <br/>
                <Button
                  onClick={clearReport}
                  className="btn text-white add-btn mt-3 me-1 col-lg-12"
                  style={{
                    backgroundColor: "#28a8df",
                    borderColor: "#28a8df",
                  }}
                  disabled={btnClearDisable}
                >
                  Clear
                </Button>
                </div>
          

          </div>
<Row className='mt-4'>
          <TableContainer       
                   
                      columns={columns}
                      data={report || []}
                      customPageSize={10}
                      divClass="table-responsive"
                      tableClass="align-middle table-nowrap mb-0"
                      clinic={OCcom}
                      date={OCDate}
                      total={Total}
                      onDownload={onDownload}
                    />
                    </Row>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        </Card>
        </Container>
      </div>
   
  )
}

export {NonpanelClaimReport}





