import React, { useEffect, useState, useMemo } from "react";
import { CardBody, Row, Col, Card, Container, CardHeader } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClinicPayment } from "./_requests";
import InvoiceFilter from "./Filter";

import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { useProfile } from "Components/Hooks/UserHooks";

const PaymentList = () => {
  const { userProfile } = useProfile();
  const history = useNavigate();

  const [invpaymentaccess, setInvPaymentAccess] = useState<any>([]);
  //const [invoice, setInvoice] = useState<any>([]);
  const [invoice, setInvoice] = useState<any>(null);
  const [Filter, setFilter] = useState<boolean>(false);
  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchInvoice();
    setResetButton(false)
  }
  const toggleFilter = () => {
    setFilter(!Filter);
  };
  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchInvoice(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };


  useEffect(() => {
    fetchInvoice();
  }, []);

  useEffect(() => {
    getCRUD(userProfile?.role, 5).then((res: any) => {
      setInvPaymentAccess(res.Data[0]);
    });
  }, []);

  const fetchInvoice = (filters?: any) => {
    getClinicPayment(filters).then((res: any) => {
      setInvoice(res);
    });
  };

  const viewInvPayment = (cellProps: any) => {
    setInvPaymentAccess((prevAgenAccess: any) => {
      if (prevAgenAccess?.view === 1) {
        history(`/clinic-payment/view/${cellProps.row.original.id}`);
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevAgenAccess;
    });
  };

  //Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "DATE",
        accessorKey: "invoiceDueDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "REFERENCE",
        accessorKey: "invoiceNumber",
        enableColumnFilter: false,
      },
      {
        header: "Clinic",
        accessorKey: "clinic.clinicName",
        enableColumnFilter: false,
      },
      {
        header: "AMOUNT ( RM )",
        accessorKey: "total",
        enableColumnFilter: false,
      },

      {
        header: "STATUS",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 2:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
            case 1:
              return (
                <span className="badge border border-success text-success">
                  Payment Submitted
                </span>
              );
            default:
              return (
                <span className="badge border border-danger text-danger">
                  Invoice Created
                </span>
              );
          }
        },
      },
      {
        header: "ACTION",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
              { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                <div
                  onClick={() => {
                    viewInvPayment(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
          
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Clinic Payment| Al khairi care ";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Clinic Payments" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Invoice List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <div className="flex-shrink-0">
                          <div className="d-flex gap-2 flex-wrap">
                          { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}

                            <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={toggleFilter}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Fliter
                            </button>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <TableContainer
                      columns={columns}
                      data={invoice || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      SearchPlaceholder="serach invoice"
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <InvoiceFilter
        show={Filter}
        onCloseClick={toggleFilter}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default PaymentList;
