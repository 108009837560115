import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

//redux
import { useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

import Loader from "../../../Components/Common/Loader";
import { createSelector } from "reselect";
import { ToastContainer, toast } from "react-toastify";
import Filter from "./Filter";
import { getEmployeeSupport } from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

const SaWorkerList = () => {
  const history = useNavigate();
  const { primaryId } = useParams();
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const [workersupportaccess, setworkersuportcaccess] = useState<any>([]);
  // Inside your component
 

  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false);

  useEffect(() => {
    let Data;
    if (Type === 2) {
      Data = 42;
    } else {
      Data = "";
    }
    getCRUD(userProfile?.role, Data).then((res: any) => {
      setworkersuportcaccess(res.Data[0]);
    });
  }, []);

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };
  const [ResetButton,setResetButton]=useState<Boolean>(false)
  const handleResetButton=()=>{
    fetchWorker();
    setResetButton(false)
  }

  const handleFilterApply = (selectedFilters: any , Reset?: any) => {
    fetchWorker(selectedFilters);
    if(Reset){
      setResetButton(true)
    }
    else{
      setResetButton(false)
    }
  };

  useEffect(() => {
    fetchWorker(); // Fetch initial worker data
  }, []);
  const [worker, setSupport] = useState<any>([]);
  const fetchWorker = async (filters?: any) => {
    try {
      const response = await getEmployeeSupport(filters, userProfile.primaryID);
      setSupport(response);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  };

  const viewworkerSupport = (cellProps: any) => {
    setworkersuportcaccess((prevWorkerAccess: any) => {
      if (prevWorkerAccess?.view === 1) {
        history(
          `/PortalWorkerOverview/view/${cellProps.row.original.primaryId}`
        );
      } else {
        toast.error("You don't have permission to access this page", {
          position: "top-right",
          style: {
            backgroundColor: "#9b2313",
            color: "#c7c7d7",
            fontSize: "14px",
          },
          closeButton: false,
        });
      }
      return prevWorkerAccess;
    });
  };


  // Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
    
      {
        header: "Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>{new Date(cell.getValue()).toLocaleDateString("en-GB")}</>
            )}
          </>
        ),
      },
      {
        header: "Subject",
        accessorKey: "subject",
        enableColumnFilter: false,
      },
      {
        header: "Priority",
        accessorKey: "supportpriority.priorityName",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "supportstatus.statusName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const statusValue = cell.getValue();
          let badgeClass = "";
          switch (statusValue) {
            case "Open":
              badgeClass = "border border-danger text-danger";
              break;
            case "Inprogress":
              badgeClass = "border border-secondary text-secondary";
              break;
            case "Resolved":
              badgeClass = "border border-success-subtle text-success";
              break;
          }

          return (
            <span className={`badge text-uppercase ${badgeClass}`}>
              {statusValue}
            </span>
          );
        },
      },
      {
        header: "Action",
        cell: (cellProps: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" title="View">
              <div
                onClick={() => {
                  viewworkerSupport(cellProps);
                }}
              >
                <i className="ri-eye-fill align-bottom text-muted"></i>
              </div>
            </li>
          </ul>
        ),
      },
    ],
    []
  );
  document.title = "Worker support | Alkhairi care";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Worker Support" pageTitle="Support" />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                      <h5 className="card-title mb-0">Worker Support</h5>
                    </Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                      { ResetButton && ( <button
                              type="button"
                              className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                              onClick={handleResetButton}
                            >
                              <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                              Reset 
                            </button>)}
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          onClick={toggleInfo}
                        >
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          Fliter
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={worker || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      SearchPlaceholder="Search support"
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        show={isInfoDetails}
        onCloseClick={toggleInfo}
        onFilterApply={handleFilterApply}
      />
    </React.Fragment>
  );
};

export default SaWorkerList;
