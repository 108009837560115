import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ListBilling } from "pages/Billing/_requests";
import { ListDiagnosis } from "pages/Diagnosis/_requests";
import {
  createClaimform,
  fetchData,
  fetchDataVerify,
  getCliniCategoryByplan,
} from "./_requests";
import { useProfile } from "Components/Hooks/UserHooks";
import BreadCrumb from "Components/Common/BreadCrumb";
type ErrorType = {
  [key: string]: string;
};

interface WorkerData {
  name: string;
  workerId: string;
  nricPassport: string;
  mobileNumber: string;
  department?: {
    name: string;
  };
  agency?: {
    name: string;
  };
  planId: string;
  // Add more properties as needed
}


const AddClaimForm = () => {
  const { userProfile } = useProfile();
  const Type = userProfile?.userType;
  const Id = userProfile?.primaryID;
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Worker | Alkhairi care";
  const [billing, setBilling] = useState<any>();
  const [diagnosis, setDiagnosis] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [errors, setErrors] = useState<ErrorType>({});

  const Formik: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: "",
      workerId: "",
      searchtype: "",
      agencyId: "",
      clinicId: "",
      departmentId: "",
      birthCertificateNumber: "",
      mobileNumber: "",
      departmentName: "",
      idcard: "",
      nricPassport: "",
      companyName: "",
      accidentVenue: "",
      accidentTime: "",
      accidentDate: "",
      accidentPolicyReport: "",
      accidentPolicyReportNumber: "",
      patientVerificationStatus: "",
      visitDate: "",
      visitTime: "",
      totalMcDays: "",
      primary_Id: "",
      name: "",
      amount: 0,
      relationship: "",
      clinic_category: "",
      agencyname: "",
      approvalStatus: 0,
      diagnosisdetails: [
        {
          diagnosId: '',
          status: 1,
        },
      ],
      billingdetails: [
        {
          billingId: "",
          cost: 0,
          description: "",
          status: 1,
        },
      ],
    },
    validationSchema: Yup.object().shape({
      searchtype: Yup.string().required("Search By Method is required"),
      visitDate: Yup.string().required("Visiting Date  is required"),
      visitTime: Yup.string().required("Visiting Time  is required"),
      totalMcDays: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Total MC Days should contain only numbers")
        .required("Total MC Days  is required"),
      clinic_category: Yup.string().required("Clinic Category is required"),
    }),
    onSubmit: async (values: any, { resetForm }: FormikHelpers<any>) => {
      values.amount = sum;

      try {
        const Data = Type === 3 ? Id : 0;
        // Call the asynchronous function to submit form data
       const res= await createClaimform(values, Data)
          if (res.Code === "0") {
            history("/claim-form");
            setTimeout(()=>{
              toast.error("Claimform not added due to insufficient balance", {
                autoClose: 3000,
              });
            },100)
          } else {
            history("/claim-form");
            setTimeout(()=>{
              toast.success("Claim added successfully", { autoClose: 3000 });
            },100)
          }
          resetForm();
        
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred while submitting the form", {
          autoClose: 3000,
        });
      }
    },
  });

  useEffect(() => {
    ListDiagnosis().then((res: any) => {
      setDiagnosis(res);
    });
  }, []);

  useEffect(() => {
    ListBilling().then((res: any) => {
      setBilling(res);
    });
  }, []);
const [valid , setValid]=useState('')

const SearchWorker = async (val: any, radio: any) => {
  try {
    if (!radio) {
      setValid("Search by method is required");
      return;
    }
    const res = await fetchDataVerify(val, radio); // Annotate res with WorkerData type
    if (!res ||  !res.data[0]) {
      history("/claim-form", { replace: true });
      setTimeout(() => {
        toast.error("Worker does not exist...");
      }, 100);
      return;
    }
    const worker: WorkerData = res.data[0]; // Access the first worker object in the array

    const categories = await getCliniCategoryByplan(worker.planId);
    if (!categories || categories.length === 0) {
      toast.error("No categories found");
      return;
    }
    setCategory(categories);

    if (!worker.name) {
      throw new Error("Name property not found in response");
    }

    Formik.setValues({
      ...Formik.values,
      name: worker.name,
      workerId: worker.workerId,
      nricPassport: worker.nricPassport,
      mobileNumber: worker.mobileNumber,
      departmentName: worker.department?.name || "",
      agencyname: worker.agency?.name || "",
    });
  } catch (error: any) {
    console.error("Error:", error.message);
    toast.error("An error occurred. Please try again.");
  }
};

  
  const [inputs, setInputs] = useState({});
  const [sum, setSum] = useState(0);

  const getSum = useCallback(() => {
    const values = Object.values(inputs);
    var sum = values.reduce((acc: number, c: any) => acc + c, 0);

    const sumString = sum.toFixed(2); // Ensure sum has two decimal places
    const regex = /^\d*\.?\d{0,2}$/;

    // Update the state or formik values if the input matches the regex pattern
    if (regex.test(sumString)) {
      setSum(Number(sumString));
    }
  }, [inputs, setSum]);

  useEffect(() => {
    getSum(); // Call getSum here if needed
  }, [getSum]);

  function handleInput(e: any, i: any, item: any) {
    if (
      Formik.values.clinic_category === "" ||
      typeof Formik.values.clinic_category === "undefined"
    ) {
      if (!toast.isActive("selectCategoryError")) {
        toast.error("Please select category and try again", {
          toastId: "selectCategoryError",
        });
      }
      return;
    }
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: +value });
    const regex = /^\d*\.?\d{0,2}$/;
    const inputValue = e.target.value;
    // Update the state or formik values if the input matches the regex pattern
    if (regex.test(inputValue)) {
      Formik.setFieldValue(`billingdetails[${i}].billingId`, item.id);
      Formik.setFieldValue(`billingdetails[${i}].status`, 1);
      Formik.setFieldValue(`billingdetails[${i}].cost`, inputValue);
    }

    const newErrors: ErrorType = { ...errors };

    if (inputValue !== "") {
      newErrors[i] = "Please enter description for this item";
    } else {
      delete newErrors[i]; // Remove the error message if the input is empty
    }
    setErrors(newErrors);
  }
  function handleDescription(e: any, i: any) {
    Formik.setFieldValue(`billingdetails[${i}].description`, e.target.value);

    const newErrors: ErrorType = { ...errors };
    if (`billingdetails[${i}].cost` === "") {
      newErrors[i] = "Please enter description for this item";
    } else {
      delete newErrors[i];
    }
    if (e.target.value === "") {
      newErrors[i] = "Please enter description for this item";
    }
    setErrors(newErrors);
  }


  function goBack() {
    history(-1);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title="Claim Form Add" pageTitle="Claim form" />

        <Row>
            <Col>  <button onClick={goBack}  className='btn btn-primary px-2 text-center mb-3'> 
           <i className="ri ri-reply-line fs-6 me-2"></i> Go Back </button> </Col>
            </Row>
          <ToastContainer closeButton={false} limit={1} />
          <Row>
            <Col >
              <Card className="mt-xxl-n5 card-bg-fill">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Patient Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Accident Information
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          tabChange("3");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Reason for Visit
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          tabChange("4");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Treatment Provided
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      Formik.handleSubmit();
                      return false;
                    }}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                        <Col lg={6}>
          <Label htmlFor="skillsInput" className="form-label me-5">
            Search By
          </Label>
          <div className="form-check form-check-inline">
            <Input
              name="searchtype"
              className="form-check-input me-3"
              type="checkbox"
              id="inlineCheckbox6"
              checked={Formik.values.searchtype === "2"}
              onChange={(e) => {
                const newValue = e.target.checked ? "2" : "";
                Formik.setFieldValue("searchtype", newValue);
              }}
              value={Formik.values.searchtype || ""}
            />
            <Label className="form-check-label me-2" htmlFor="inlineCheckbox6">
              Worker ID
            </Label>
          </div>
          <div className="form-check form-check-inline">
            <Input
              name="searchtype"
              className="form-check-input me-3"
              type="checkbox"
              id="inlineCheckbox7"
              checked={Formik.values.searchtype === "1"}
              onChange={(e) => {
                const newValue = e.target.checked ? "1" : "";
                Formik.setFieldValue("searchtype", newValue);
              }}
              value={Formik.values.searchtype || ""}
            />
            <Label className="form-check-label" htmlFor="inlineCheckbox7">
              NRIC/Passport
            </Label>
          </div>
          {Formik.touched.searchtype && Formik.errors.searchtype && (
            <FormFeedback type="invalid">
              {Formik.errors.searchtype}
            </FormFeedback>
          )}
          {valid && (<p className="text-danger">{valid}</p>)

          }
        </Col>

                          <Col lg={6}>
                            <div className="mb-3 d-flex align-items-end">
                              {" "}
                              {/* Added d-flex and align-items-end classes */}
                              <Col className="col-6">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Workers Id  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="workerId"
                                  type="text"
                                  className="form-control"
                                  placeholder={Formik.values.searchtype === "2" ? "Enter Worker ID ( as in ID card )" : "Enter  NRIC/Passport "}
                                  onChange={Formik.handleChange}
                                  onBlur={Formik.handleBlur}
                                  value={Formik.values.workerId || ""}
                                  invalid={
                                    Formik.touched.workerId &&
                                    Formik.errors.workerId
                                      ? true
                                      : false
                                  }
                                />

                                {Formik.touched.workerId &&
                                Formik.errors.workerId ? (
                                  <FormFeedback type="invalid">
                                    {Formik.errors.workerId}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col className="ms-3">
                                {" "}
                                {/* Added margin to separate button */}
                                <Button
                                  onClick={() => {
                                    SearchWorker(
                                      Formik.getFieldProps("workerId").value,
                                      Formik.values.searchtype
                                    );
                                    setValid('');
                                  }}
                                  className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}
                                >
                                  Search
                                </Button>
                              </Col>
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Name of Worker's <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter name of worker"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.name || ""}
                                invalid={
                                  Formik.touched.name && Formik.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.name && Formik.errors.name ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="websiteInput1"
                                className="form-label"
                              >
                                NRIC No <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="nricPassport"
                                type="text"
                                className="form-control"
                                placeholder="Enter nric no"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.nricPassport || ""}
                                invalid={
                                  Formik.touched.nricPassport &&
                                  Formik.errors.nricPassport
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.nricPassport &&
                              Formik.errors.nricPassport ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.nricPassport}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                      
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="countryInput"
                                className="form-label"
                              >
                                Contact Details <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="mobileNumber"
                                type="number"
                                className="form-control"
                                placeholder="Enter phone number"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.mobileNumber || ""}
                                invalid={
                                  Formik.touched.mobileNumber &&
                                  Formik.errors.mobileNumber
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.mobileNumber &&
                              Formik.errors.mobileNumber ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.mobileNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Agency Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="agencyname"
                                type="text"
                                className="form-control"
                                placeholder="Enter Agency name"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.agencyname || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Department <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="departmentName"
                                type="text"
                                className="form-control"
                                placeholder="Enter department name"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.departmentName || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="zipcodeInput"
                                className="form-label"
                              >
                                Clinic Category <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="clinic_category"
                                type="select"
                                className="form-select"
                                id="zipcodeInput"
                                placeholder="Enter Worker NRIC/Passport"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.clinic_category || ""}
                                invalid={
                                  Formik.touched.clinic_category &&
                                  Formik.errors.clinic_category
                                    ? true
                                    : false
                                }
                              >
                                <option>Select a Category...</option>
                                {category?.map((item: any, j: any) => {
                                  return (
                                    <option key={j} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              {Formik.touched.clinic_category &&
                              Formik.errors.clinic_category ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.clinic_category}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                  className="btn text-white add-btn me-1"
                                  style={{
                                    backgroundColor: "#28a8df",
                                    borderColor: "#28a8df",
                                  }}
                                onClick={() => {
                                  tabChange("2");
                                }}
                              >
                                Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Venue <span >(Optional)</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="accidentVenue"
                                placeholder="Enter accident venue"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.accidentVenue || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Date <span >(Optional)</span>
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                name="accidentDate"
                                placeholder="Enter accident date"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.accidentDate || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Time <span >(Optional)</span>
                              </Label>
                              <Input
                                type="time"
                                className="form-control"
                                name="accidentTime"
                                placeholder="Enter accident time"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.accidentTime || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              {" "}
                              {/* Added d-flex and align-items-end classes */}
                              <Col lg={6}>
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label me-5"
                                >
                                  Police Report <span >(Optional)</span>
                                </Label>
                              </Col>
                              <Col lg={6} className="ms-3">
                                <div className="form-check form-check-inline">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    name="accidentPolicyReport"
                                    value="1"
                                    checked={
                                      Formik.values.accidentPolicyReport === "1"
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.checked
                                        ? "1"
                                        : "";
                                      Formik.setFieldValue(
                                        "accidentPolicyReport",
                                        newValue
                                      );
                                    }}
                                  />
                                  <Label
                                    className="form-check-label me-2"
                                    htmlFor="inlineCheckbox6"
                                  >
                                    Yes
                                  </Label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox7"
                                    name="accidentPolicyReport"
                                    value="0"
                                    checked={
                                      Formik.values.accidentPolicyReport === "0"
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.checked
                                        ? "0"
                                        : "";
                                      Formik.setFieldValue(
                                        "accidentPolicyReport",
                                        newValue
                                      );
                                    }}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="inlineCheckbox7"
                                  >
                                    No
                                  </Label>
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {Formik.values.accidentPolicyReport === "1" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Report Number (If Yes) <span >(Optional)</span>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="accidentPolicyReportNumber"
                                  placeholder="Enter police report number"
                                  onChange={Formik.handleChange}
                                  onBlur={Formik.handleBlur}
                                  value={
                                    Formik.values.accidentPolicyReportNumber ||
                                    ""
                                  }
                                />
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                className="btn text-white add-btn me-1"
                                style={{
                                  backgroundColor: "#28a8df",
                                  borderColor: "#28a8df",
                                }}
                                onClick={() => {
                                  tabChange("3");
                                }}
                              >
                                Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Visit Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                name="visitDate"
                                placeholder="Enter date of visit"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.visitDate || ""}
                                invalid={
                                  Formik.touched.visitDate &&
                                  Formik.errors.visitDate
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.visitDate &&
                              Formik.errors.visitDate ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.visitDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Time <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="visitTime"
                                type="time"
                                className="form-control"
                                placeholder="Enter time of visit"
                                id="choices-publish-status-input"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.visitTime || ""}
                                invalid={
                                  Formik.touched.visitTime &&
                                  Formik.errors.visitTime
                                    ? true
                                    : false
                                }
                              ></Input>
                              {Formik.touched.visitTime &&
                              Formik.errors.visitTime ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.visitTime}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Total MC Days <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                name="totalMcDays"
                                placeholder="Enter total MC days"
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                value={Formik.values.totalMcDays || ""}
                                invalid={
                                  Formik.touched.totalMcDays &&
                                  Formik.errors.totalMcDays
                                    ? true
                                    : false
                                }
                              />
                              {Formik.touched.totalMcDays &&
                              Formik.errors.totalMcDays ? (
                                <FormFeedback type="invalid">
                                  {Formik.errors.totalMcDays}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <Row>
                              {diagnosis?.map((item: any, i: any) => {
                                return (
                                  <div className="col-lg-4 mb-4" key={i}>
                                    <label className="col-lg-12 form-check form-check-inline form-check-solid">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        {...Formik.getFieldProps(
                                          `diagnosisdetails[${i}].diagnosisId`
                                        )}
                                        value={item.id}
                                      />
                                      <span className=" ps-2 fs-6">
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </Row>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <div
                                 className="btn text-white add-btn me-1"
                                 style={{
                                   backgroundColor: "#28a8df",
                                   borderColor: "#28a8df",
                                 }}
                                onClick={() => {
                                  tabChange("4");
                                }}
                              >
                               Next
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col lg={12}>
                            {billing?.map((item: any, i: any) => (
                              <Row className="mb-2" key={i}>
                                <Col className="col-4">
                                  <Label className="form-label">
                                    {item.name}
                                  </Label>
                                </Col>
                                <Col className="col-3">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter cost (RM)"
                                    {...Formik.getFieldProps(
                                      `billingdetails[${i}].cost`
                                    )}
                                    onChange={(e) => handleInput(e, i, item)}
                                  />
                                </Col>
                                <Col className="col-4">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      item.name
                                        ?.toLowerCase()
                                        .includes("medication")
                                        ? "Please specify dosage & quantity of prescription, If no medication enter NA"
                                        : "Enter descriptions"
                                    }
                                    {...Formik.getFieldProps(
                                      `billingdetails[${i}].description`
                                    )}
                                    onChange={(e) => handleDescription(e, i)}
                                  />

                                  {errors[i] && (
                                    <div style={{ color: "red" }}>
                                      {errors[i]}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </Col>

                          <Col lg={12}>
                            <Row>
                              <Col className="col-4">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label"
                                >
                                  Total
                                </Label>
                              </Col>
                              <Col className="col-3">
                                <Input
                                  name="planId"
                                  type="text"
                                  className="form-control"
                                  id="choices-publish-status-input"
                                  placeholder="Enter total (RM)"
                                  onChange={Formik.handleChange}
                                  value={sum}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                            
                              <button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddClaimForm;
