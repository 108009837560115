import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { getCompany } from "./_requests";
import { creditReport, getClinicCategory } from "pages/Category/_requests";
import { log } from "util";

const CrmFilter = ({ show, onCloseClick, fetchData,clearData }: any) => {
  const [company, setCompany] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [OCDate, setOCDate] = useState("");
  const [OCcom, setOCcom] = useState("");
  const [validationError, setValidationError] = useState("");
  const [OCcat, setOCcat] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnSearchDisable, setbtnSearchDisable] = useState(true);
  const [OCcomid, setOCcomid] = useState("");
  const [OCcatid, setOCcatid] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [catselectedOption, setCatSelectedOption] = useState(null);
  useEffect(() => {
    getCompany().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAll = [allOption, ...mappedOptions];

      setCompany(optionsWithAll);
    });
  }, []);

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setValidationError("");
    setOCcom(selectedOption?.label);
    setOCcomid(selectedOption?.value);
    
    getClinicCategory().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.Data.map((com: { id: any; name: any }) => ({
        value: com.id,
        label: com.name,
      }));
      const allOption = { value: 0, label: "All" };
      const optionsWithAlll = [allOption, ...mappedOptions];

      setCategory(optionsWithAlll);
    });
  };

  const handleCatSelectChange = (catselectedOption: any) => {
    setCatSelectedOption(catselectedOption);

    setOCcat(catselectedOption?.label);
    setOCcatid(catselectedOption?.value);
  };
  const clearDatas: () => void = () => {
    clearData();
    onCloseClick();
  };
  

  const clickReport = () => {
    const selectedDatesString = (document.getElementById("Daterange") as HTMLInputElement).value;
    const [startDateString, endDateString] = selectedDatesString.split(" to ");
  
    // Convert start date string to Date object
    const startDateParts = startDateString.split("/");
    const startDate = new Date(
      parseInt(startDateParts[2]), // Year
      parseInt(startDateParts[1]) - 1, // Month (months are zero-indexed)
      parseInt(startDateParts[0]) // Day
    );
  
    // Convert end date string to Date object
    const endDateParts = endDateString.split("/");
    const endDate = new Date(
      parseInt(endDateParts[2]), // Year
      parseInt(endDateParts[1]) - 1, // Month (months are zero-indexed)
      parseInt(endDateParts[0]) // Day
    );
  
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    
    const Daterange = `${formattedStartDate} - ${formattedEndDate}`;
    if(OCcomid !== '' && Daterange!== ''){
      fetchData(OCcomid,Daterange,OCcatid,OCcom)
      setbtnSearchDisable(false);
      setLoading(true);
      setOCDate(Daterange);
      setValidationError('');
    }
    else{
      setbtnSearchDisable(true)
      setValidationError('Please fill manditory fields')
    }
    
  };
  

const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Credit Report
      </OffcanvasHeader>
      <form action="" className="d-flex flex-column justify-content-end h-100">
        <OffcanvasBody>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Agency
            </Label>

            <Select
              className="mb-0"
              value={selectedOption}
              onChange={handleSelectChange}
              options={company}
              isSearchable
              id="country-select"
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="country-select"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Category
            </Label>

            <Select
              className="mb-0"
              value={catselectedOption}
              onChange={handleCatSelectChange}
              options={category}
              isSearchable
              id="country-select"
            ></Select>
          </div>
          <div className="mb-4">
            <Label
              htmlFor="datepicker-range"
              className="form-label text-muted text-uppercase fw-semibold mb-3"
            >
              Date
            </Label>
            <Flatpickr
              className="form-control"
              id="Daterange"
              placeholder="Select a date"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                mode: "range",
                dateFormat: "d/m/Y",
              }}
            />
          </div>
          <span style={{ color: "red" }}>
                    {validationError}
                  </span>
        </OffcanvasBody>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button  type="button" className="btn btn-light w-100" onClick={clearDatas}>
            Clear Filter
          </button>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={clickReport}
          >
            Filters
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default CrmFilter;
