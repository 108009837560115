import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useProfile } from "Components/Hooks/UserHooks";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import TableContainer from "../../Components/Common/TableContainer";
import {
  checkDuplicateEmail,
  createEmployee,
  deleteEmployee,
  listEmployee,
  updateEmployee,
} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ListRole } from "pages/Roles/_requests";
import { getCRUD } from "pages/Clinic Dashboard/_requests";

const User = (data: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Employee, setEmployee] = useState<any>([]);
  const [Roles, setRoles] = useState<any>([]);
  const [useraccess, setrolecaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const TypeId = userProfile?.primaryID;
  const Type = userProfile?.userType;
  // Delete Employee
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [EmployeeToDeleteId, setEmployeeToDeleteId] = useState<string>("");
  const [EmployeeToDeleteName, setEmployeeToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 49;
    } else if (Type === 2) {
      Data = 47;
    } else if (Type === 3) {
      Data = 48;
    } else {
      Data = "";
    }

    getCRUD(userProfile?.role, Data).then((res: any) => {
      setrolecaccess(res.Data[0]);
    });
  }, []);

  


  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = () => {
    listEmployee(Type, TypeId).then((res: any) => {
      setEmployee(res.Data);
    });
  };

  useEffect(() => {
    ListRole(Type, TypeId).then((res: any) => {
      setRoles(res);
    });
  }, [modal, deleteModalOpen]);

  // Delete Data
  const onClickDelete = useCallback(
    (employee: any) => {
      setEmployeeToDeleteName(employee.name);
      setEmployeeToDeleteId(employee.id);

      setrolecaccess((prevDepAccess: any) => {
        if (prevDepAccess?.remove === 1) {
          toggleDeleteModal();
        } else {
          toast.error("You don't have permission to access this page", {
            position: "top-right",
            style: {
              backgroundColor: "#9b2313",
              color: "#c7c7d7",
              fontSize: "14px",
            },
            closeButton: false,
          });
        }
        return prevDepAccess;
      });
    },
    [setEmployeeToDeleteName, setEmployeeToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: (Employee && Employee.name) || "",
      email: (Employee && Employee.email) || "",
      roleId: (Employee && Employee.roleId) || "",
      password: (Employee && Employee.password) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("User Name is required"),
      roleId: Yup.string().required("User Role is required"),
      email: Yup.string().required("User Email is required"),
      password: isEdit
        ? Yup.string()
        : Yup.string().required("User Password is required"),
    }),
    onSubmit: (values: any) => {
      if (isEdit) {
        const updateRole = {
          name: values.name,
          email: values.email,
          roleId: values.roleId,
          password: values.password,
        };
        updateEmployee(updateRole, values.id).then(() => {
          toast.success("User updated Successfully", { autoClose: 3000 });
          fetchEmployee();
          validation.resetForm();
        });
      } else {
        const newUser = {
          name: values.name,
          email: values.email,
          roleId: values.roleId,
          password: values.password,
          type: Type,
          typeId: TypeId,
        };
        createEmployee(newUser).then(() => {
          toast.success("User created Successfully", { autoClose: 3000 });
          fetchEmployee();
          validation.resetForm();
        });
      }
      toggle();
    },
  });

  const confirmDelete = async(employeeId: any) => {
    await deleteEmployee(employeeId);
    toggleDeleteModal();
    toast.success("User deleted Successfully", { autoClose: 3000 });
    await fetchEmployee();
  };

  // Update Data
  const handleEmployeeClick = useCallback(
    (Employee: any) => {
      validation.setValues({
        id: Employee.id,
        name: Employee.name,
        email: Employee.email,
        roleId: Employee.roleId, // Make sure roleId is properly assigned
      });
      setEmail(Employee.email);
      setIsEdit(true);

      setrolecaccess((prevDepAccess: any) => {
        if (prevDepAccess?.edit === 1) {
          toggle();
        } else {
          toast.error("You don't have permission to access this page", {
            position: "top-right",
            style: {
              backgroundColor: "#9b2313",
              color: "#c7c7d7",
              fontSize: "14px",
            },
            closeButton: false,
          });
        }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const DuplicateCheck = (val: any) => {
    try {

      checkDuplicateEmail(val).then((response: any) => {
        if (isEdit) {
          if (response?.isDuplicate === true && Email !== val) {
            setDuplicate("Email already exists");
          } else {
            setDuplicate("");
          }
        } else {
          if (response?.isDuplicate === true) {
            setDuplicate("Email already exist");
          } else {
            setDuplicate("");
          }
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  // Employees Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "User Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "User ID",
        accessorKey: "userId",
        enableColumnFilter: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "User Role",
        accessorKey: "role.roleName",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const EmployeeData = cellProps.row.original;
                    handleEmployeeClick(EmployeeData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const EmployeeData = cellProps.row.original;
                    onClickDelete(EmployeeData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleEmployeeClick, onClickDelete]
  );

  const addUser = () => {
    if (useraccess?.add === 1) {
      setIsEdit(false);
      toggle();
      validation.setValues({ name: "", email: "", roleId: "", password: "" });
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
  };

  document.title = "Users | Al khairi care - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="employeeList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">User List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addUser}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          User
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Employee || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search User..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <Label htmlhtmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Employeename-field"
                            className="form-label"
                          >
                            User Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter User Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="email-field"
                            className="form-label"
                          >
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            type="email"
                            id="email-field"
                            placeholder="Enter User Email"
                            onChange={(e: any) => {
                              validation.handleChange(e);
                              DuplicateCheck(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          <span style={{ color: "red" }}>
                            {Duplicate ? Duplicate : ""}
                          </span>
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                            User Role <span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="roleId"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.roleId || ""}
                            invalid={
                              validation.touched.roleId &&
                              validation.errors.roleId
                                ? true
                                : false
                            }
                          >
                            <option>Select a role...</option>
                            {Roles?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.roleName}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.roleId &&
                          validation.errors.roleId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roleId}
                            </FormFeedback>
                          ) : null}
                        </div>

                        {isEdit ? (
                          ""
                        ) : (
                          <div>
                            <Label
                              htmlhtmlFor="phone-field"
                              className="form-label"
                            >
                              Password <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="password"
                              type="text"
                              id="phone-field"
                              placeholder="Enter User Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add User"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the User{" "}
              <strong>{EmployeeToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(EmployeeToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default User;
