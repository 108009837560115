import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL


const ListDepartment = (id:any)=> {
  return axios
    .get(`${API_URL}/department/list/${id}`)
    .then((d: any) => {
      return d.Data})
}

const getClinicDepartment = ()=> {
  return axios
    .get(`${API_URL}/clinicDepartment/getClinicDepartment`)
    .then((response) => {return response})
   
}

const createDepartment = (name: any) => {
  return axios
  .post(`${API_URL}/department/add`,name)
  .then((response) => response.data)
}
const deleteDepartmentById = (id: any) => {
  return axios
    .post(`${API_URL}/department/delete/${id}`)
    .then((response) => {
   // 
    return response.data;

    }
    )
}

const updateDepartment = (id:any,updateRole:any) => {
  return axios
    .put(`${API_URL}/department/edit/${id}`,updateRole)
    .then((response) => {return response.data; }
    )
}
const creditReport = (id:any,Daterange:any,OCcatid:any) => {
  return axios
    .post(`${API_URL}/report/company/creditReport`,{id,Daterange,OCcatid})
    .then((response) => {return response.data; }
    )
}

export {ListDepartment, deleteDepartmentById,createDepartment,updateDepartment,getClinicDepartment,creditReport}
