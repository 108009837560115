import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_IH_LIST = `${API_URL}/worker/searchVerify` 
const GET_PLAN_LIST = `${API_URL}/plan` 



 
const fetchDataVerify = async (EMPLOYEEID: string,radio:any)=> {
 // 
  const res = await axios.get(`${GET_IH_LIST}/${EMPLOYEEID}/${radio}`);
 // 
  return res;
}
const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/view/${id}`,);
  //
   return res;
 }

export {
  fetchDataVerify,getsobdetails
 }
