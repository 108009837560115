import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const INVOICE_URL = `${API_URL}/agencyInvoice`


//
const getInvoice = (filters ?:any)=> {
  return axios
    .post(`${API_URL}/agencyInvoice/list`,filters)
    .then((d: any) => {
      return d.Data})
}



const getagencydropdown = async () => {
  const res = await axios.get(`${API_URL}/report/getagencydropdown`)
  return res;
}

const addAgencyInvoice = (data: FormData,clinicID:any) => {
  
  return axios
  .post(`${INVOICE_URL}/addAgencyInvoice/${clinicID}`, data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then((response:any) => {
    return response
   })
   
}



const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${INVOICE_URL}/updateMailSend/${userId}`, data)
    .then((response) => {
      
      return response.data
     })
   
}
const getInvoiceById = (id:any) => {
  return axios
    .get(`${INVOICE_URL}/${id}`) 
    .then((response: any) =>{
      
      return response.Data;
    }
   )
}
const updateInvoiceById = (Agency:any,ID:any) => {
  //
  return axios
  .put(`${INVOICE_URL}/${ID}`, Agency)
  .then((response) => {
  //  
    return response.data
   })
}
const updateFamilyInvoice = (Agency:any,ID:any) => {
 // 
  return axios
  .put(`${INVOICE_URL}/family/${ID}`, Agency)
  .then((response) => {
  //  
    return response.data
   })
   
}

const admingetDepartment = async ()=> {
  //
  const res = await axios.get(`${INVOICE_URL}/admingetDepartment`);
  //
  return res;
}
const createInvoice = (Agency: any,clientID:any) => {
  //
  return axios
  .post(`${INVOICE_URL}/addAgencyInvoice/${clientID}`, Agency,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then((response) => {
  //  
    return response.data
   })
   
}



const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`)
  return res;
}


const deleteInvoiceById = (id: any) => {
  return axios
    .post(`${INVOICE_URL}/delete/invoice/${id}`,)
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const getAgencyInvoiceById = (id:any) => {
  return axios
    .get(`${INVOICE_URL}/viewAgencyInvoice/${id}`) 
    .then((response) =>{
   
      return response;
    }
   )
}

const getAgencyById = (id:any) => {
  return axios
    .get(`${API_URL}/agency/view/${id}`) 
    .then((response:any) =>{
    return response.Data;
    }
   )
    
}
export {
  getInvoice,
  getagencydropdown,
  addAgencyInvoice,
  deleteInvoiceById,
  updatecontrols,
  createInvoice,getInvoiceById,updateFamilyInvoice,
  updateInvoiceById,
  admingetDepartment,getPlan,getAgencyInvoiceById,
  getAgencyById
}
