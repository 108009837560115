import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TableContainer from "Components/Common/TableContainer";
import { useProfile } from "Components/Hooks/UserHooks";
import { getCRUD, recentClaims } from "./_requests";
import { log } from "console";

const ClaimData = () => {
  const history = useNavigate();
  const [Claim, setClaim] = useState<any>([]);
  const { userProfile } = useProfile();
  const [claimaccess, setclaimaccess] = useState<any>([]);

  useEffect(() => {
    const Type = userProfile?.userType;
    let Data;
    if (Type === 1) {
      Data = 6;
    } else if (Type === 3) {
      Data = 24;
    } else {
      Data = "";
    }
    getCRUD(userProfile?.role, 24).then((res: any) => {
      setclaimaccess(res.Data[0]);
    });
  }, []);

  const ViewClaimForm = (cellProps: any) => {
    setclaimaccess((prevDepAccess: any) => {
    if (prevDepAccess?.view === 1) {
      history(`/claim-form/view/${cellProps.row.original.id}`);
    } else {
      toast.error("You don't have permission to access this page", {
        position: "top-right",
        style: {
          backgroundColor: "#9b2313",
          color: "#c7c7d7",
          fontSize: "14px",
        },
        closeButton: false,
      });
    }
      return prevDepAccess;
    });
  };

  useEffect(() => {
    recentClaims(userProfile?.primaryID).then((res: any) => {
      setClaim(res.Data);
    });
  }, []);

  const name = (cell: any) => {
    return (
      <>
        <p>{cell.row.original?.fromName}</p>
        <p>{cell.row.original?.workerId}</p>
      </>
    );
  };
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Date",
        accessorKey: "visitDate",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {cell.getValue() && (
              <>
                {new Date(cell.getValue()).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </>
            )}
          </>
        ),
      },
      {
        header: "Worker Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: name,
      },
      {
        header: "Agency Name",
        accessorKey: "agency.agencyName",
        enableColumnFilter: false,
      },
      {
        header: "Amount (RM)",
        accessorKey: "amount",
        enableColumnFilter: false,
      },

      {
        header: "Approval Status",
        accessorKey: "approvalStatus",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  Approved
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-success">
                  {" "}
                  Rejected
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  {" "}
                  Pending{" "}
                </span>
              );
          }
        },
      },

      {
        header: "Actions",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  onClick={() => {
                    ViewClaimForm(cellProps);
                  }}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">
                  Recent Claim Form
                </h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <Link to="/claim-form"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
                      <i className="fa-solid fa-bars"></i> View All
                    </Link>
                  </div>
                </div>
              </div>
              <p>List of recently created claim form</p>
            </CardHeader>
            <CardBody className="pt-0">
              <TableContainer
                columns={columns}
                data={Claim || []}
                customPageSize={5}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClaimData;
