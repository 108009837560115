import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";
// import {
//   admingetDepartment,
//   getAgency,
//   getCountry,
//   getWorkerById,
//   updateWorkerById,
// } from "./_requests";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import {
  deleteClaimFormById,
  fetchData,
  fetchapproveclaim,
  getClaimformById,
  getMCDetails,
  getTreatementDetails,
  updateOtherCharges,
} from "./_requests";
import Avatar from "react-avatar";
import { getCRUD } from "pages/Clinic Dashboard/_requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "Components/Hooks/UserHooks";

const ViewClaimForm = () => {
  const { id } = useParams();
  const history = useNavigate();
  const { userProfile } = useProfile();
    const Type = userProfile?.userType;
  const [activeTab, setActiveTab] = useState("1");
  const [ApproveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  const [RejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [ChargesModalOpen, setChargesModalOpen] = useState<boolean>(false);
  const [ChargesEditModalOpen, setChargesEditModalOpen] = useState<boolean>(
    false
  );

  document.title = "Claim form | Alkhairi care";

  const [billing, setBilling] = useState<any>([]);
  const [diagnosis, setDiagnosis] = useState<any>([]);
  const [worker, setWorker] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [btnDisable, setbtnDisable] = useState(true);
  const [OCharges, setOCharges] = useState(null);
  const [EOCharges, setEOCharges] = useState("");
  const [EbtnDisable, setEbtnDisable] = useState(false);
  const [claimaccess, setclaimaccess] = useState<any>([]);


  useEffect(() => {
    getCRUD(userProfile?.role, 6).then((res: any) => {
      setclaimaccess(res.Data[0]);
    });
  }, []);


  const toggleChargesModal = () => {
    // if (claimaccess?.add === 1) {
      setChargesModalOpen(!ChargesModalOpen);
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  const toggleEditChargesModal = () => {
    setChargesEditModalOpen(!ChargesEditModalOpen);
  };

  const toggleApproveModal = () => {
    setApproveModalOpen(!ApproveModalOpen);
  };

  const toggleRejectModal = () => {
    setRejectModalOpen(!RejectModalOpen);
  };

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getClaimformById(id).then((res: any) => {


      setData(res.Data);
      setEOCharges(res.Data.otherChargers);
      var workerId = res.Data.workerId;
      fetchData(workerId).then((result) => {
        setWorker(result[0]);
      });
    });
  }, []);
  useEffect(() => {
    getTreatementDetails(id).then((res: any) => {
      setBilling(res);

    });
  }, []);
  useEffect(() => {
    getMCDetails(id).then((res: any) => {

      setDiagnosis(res);
    });
  }, []);

  const OtherChargesAdd = () => {
    updateOtherCharges(id, OCharges).then((res: any) => {
      
      if (res.Code === "1") {
        history("/claim-form", { replace: true });
        setTimeout(()=>{
          
          toast.success( "Othercharges Successfully Updated", { autoClose: 3000 });         
         },100)
      } else {
        setTimeout(()=>{
          toast.error(res?.Message, { autoClose: 3000 });         
         },100)
      }
    });
  };
  const OtherChargesEdit = () => {
    updateOtherCharges(id, EOCharges).then((res: any) => {
      if (res.Code === "1") {
        history("/claim-form", { replace: true });
        setTimeout(()=>{
          toast.success(res?.Message, { autoClose: 3000 });         
         },100)
      } else {
        setTimeout(()=>{
          toast.error(res?.Message, { autoClose: 3000 });         
         },100)
      }
    });
  };
  const approveClaim = () => {
    fetchapproveclaim(data?.id, data?.workerId, data?.amount).then(
      (res: any) => {
        var message: string;
        var code: string;
        if (res.Data === "unlimited") {
          code = "1";
          message = "Claimform approved successfully";
        }
        if (res.Data === "insufficient") {
          code = "0";
          message =
            "The claim form was not approved because the Worker has an insufficient balance";
        }
        if (res.Data === "sufficient") {
          code = "1";
          message = "Claimform approved successfully";
        }
        history("/claim-form/", { replace: true });
        if ((code = "1")) {
          setTimeout(()=>{
            toast.success(`${message}`, { autoClose: 3000 });         
           },100)
        } else {
          setTimeout(()=>{
            toast.error(`${message}`, { autoClose: 3000 });         
           },100)
        }

      }
    );
  };

  const rejectClaim = () => {
    deleteClaimFormById(data?.id, 3).then(() => {
      history("/claim-form", { replace: true });
      setTimeout(()=>{
        toast.success("Claimform rejected successfully", { autoClose: 3000 });         
       },100)
    });
  };

  const handledeEditOtherCharges = (event: any) => {
    const newValue = event.target.value;
    setEOCharges(newValue);
    if (event.target.value !== "") {
      setEbtnDisable(false);
    } else {
      setEbtnDisable(true);
    }
  };
  const handledeOtherCharges = (e: any) => {
    if (e.target.value !== "") {
      setOCharges(e.target.value);
      setbtnDisable(false);
    } else {
      setOCharges(null);
      setbtnDisable(true);
    }
  };

  function goBack() {
    history(-1);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Container fluid>
          <BreadCrumb title="Claimform View" pageTitle="Claimform" />
          <Row>
            <Row>
              <Col>
                {" "}
                <button
                  onClick={goBack}
                  className="btn btn-primary px-2 text-center mb-3"
                >
                  <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
                </button>{" "}
              </Col>
            </Row>

            <Card>
  <CardHeader>
    <Row>
      <Col xl={6}>
        <h4>Worker Info</h4>
      </Col >
      <Col className="d-flex justify-content-end" xl={6}>
        {Type === 1 && (
          <>
            {data?.approvalStatus === 1 ? (
              <button
                className="btn btn-danger me-2"
                onClick={toggleRejectModal}
              >
                Reject Claim
              </button>
            ) : (
              <button
                className="btn btn-success me-2"
                onClick={toggleApproveModal}
              >
                Approve Claim
              </button>
            )}
            {(EOCharges === null || EOCharges === "0") ? (
              <button
                className="btn btn-primary"
                onClick={toggleChargesModal}
              >
                Add other Charges
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={toggleEditChargesModal}
              >
                Edit other Charges
              </button>
            )}
          </>
        )}
      </Col>
    </Row>
  </CardHeader>
  <CardBody className="pb-0 px-4">
    <Row className="mb-3">
      <Col md={2} xs={6} className="text-center mb-3 mb-md-0">
        {worker?.profileImage && !worker?.profileImage.includes("null") ? (
          <img
            src={worker?.profileImage}
            alt="worker-img"
            className="rounded avatar-xl"
          />
        ) : (
          <Avatar
            name={worker?.name}
            size="100"
            color="#607458"
            fgColor="#1b1b29"
            round={true}
          />
        )}
      </Col>
      <Col md={10} xs={12}>
        <Row>
          <Col xs={12}>
            <h4 className="fw-bold">
              {worker?.name} ({worker?.workerId})
            </h4>
          </Col>
          <Col xs={12} className="mb-3">
            <div className="fs-14 text-start">
              <i className="mdi mdi-account-outline fs-20 me-2"></i>
              {worker?.department?.name}
            </div>
          </Col>
          <Col xs={12} className="mb-3">
            <div className="fs-10 badge bg-success text-start p-1">
              <i className="mdi mdi-account-outline fs-12 me-2"></i>
              {worker?.agency?.name}
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <i className="ri-phone-fill fs-20 me-2"></i>
                <span className="fw-medium">{worker?.mobileNumber}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <i className="ri-mail-line fs-20 me-2"></i>
                <span className="fw-medium">{worker?.username}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-3">
            <div className="border border-success border-dashed rounded py-1 px-2">
              <div className="d-flex align-items-center">
                <span className="fw-medium mt-1">RM : {data?.amount}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </CardBody>
</Card>


          </Row>
          {data.accidentVenue && (
            <Row>
              <Card>
                <CardHeader>Accident Details</CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col className="col-4">Venue</Col>
                    <Col className="col-3">{data.accidentVenue}</Col>
                  </Row>
                  {data?.accidentDate != null ? (
                    <Row className="mb-3">
                      <Col className="col-4">Date & Time</Col>
                      <Col className="col-3">
                        {" "}
                        {new Date(data?.accidentDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}{" "}
                        -{" "}
                        {new Date(
                          `01/01/2000 ${data?.accidentTime}`
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {data?.accidentPolicyReportNumber != "" ? (
                    <Row className="mb-3">
                      <Col className="col-4">Police Report Number</Col>
                      <Col className="col-3">
                        {data?.accidentPolicyReportNumber}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Row>
          )}
          <Row>
            <Card>
              <CardHeader>Reason</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Visit Date & Time</Col>
                  <Col className="col-8">
                    {" "}
                    {new Date(data?.visitDate).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}{" "}
                    -{" "}
                    {new Date(
                      `01/01/2000 ${data?.visitTime}`
                    ).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Total MC Days</Col>
                  <Col className="col-8">{data?.totalMcDays}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Reason for Visit</Col>
                  <Col className="col-8">
                    {diagnosis &&
                      diagnosis.length > 0 &&
                      diagnosis.map((item: any, i: any) => (
                        <span
                          key={i}
                          className="badge bg-success text-white mr-1 me-2"
                        >
                          {item.diagnosis}
                        </span>
                      ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardHeader>Billing Details</CardHeader>
              <CardBody>
                {billing?.map((item: any, i: any) => (
                  <Row className="mb-3" key={i}>
                    <Col className="col-4">{item?.billing?.name}</Col>
                    <Col className="col-4">{item?.cost}</Col>
                    <Col className="col-4">{item?.description}</Col>
                  </Row>
                ))}
                <Row className="mt-3">
                  <Label className="col-4">Total ( RM )</Label>
                  <Col className="col-4">{data?.amount}</Col>
                </Row>
              </CardBody>
            </Card>
          </Row>

          <Modal
            isOpen={ApproveModalOpen}
            toggle={toggleApproveModal}
            centered
            style={{ marginTop: "10vh" }}
          >
            <ModalHeader toggle={toggleApproveModal}>Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to approve the claim RM{" "}
              <strong>{data.amount}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleApproveModal}>
                Not Now
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  approveClaim();
                }}
              >
                Yes,Approve
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={RejectModalOpen}
            toggle={toggleRejectModal}
            centered
            style={{ marginTop: "10vh" }}
          >
            <ModalHeader toggle={toggleRejectModal}>Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to reject the claim RM{" "}
              <strong>{data.amount}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleRejectModal}>
                Not Now
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  rejectClaim();
                }}
              >
                Yes,Reject
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={ChargesModalOpen}
            toggle={toggleChargesModal}
            centered
            style={{ marginTop: "10vh" }}
          >
            <ModalHeader toggle={toggleChargesModal}>
              Add Other Charges Details
            </ModalHeader>
            <ModalBody>
              <form noValidate className="form">
                <div className="card-body   p-9">
                  <div className="row mb-6">
                    <Label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Other Charges{" "}
                    </Label>
                    <div className="col-lg-8">
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="Enter other charges"
                        name="editOther"
                        onKeyUp={handledeOtherCharges}
                      />
                      {OCharges === null && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Please provide other charges
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleChargesModal}>
                Not Now
              </Button>
              <Button
                color="danger"
                disabled={btnDisable}
                onClick={OtherChargesAdd}
              >
                Add Other Charges
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={ChargesEditModalOpen}
            toggle={toggleEditChargesModal}
            centered
            style={{ marginTop: "10vh" }}
          >
            <ModalHeader toggle={toggleEditChargesModal}>
              Edit Other Charges Details
            </ModalHeader>
            <ModalBody>
              <form noValidate className="form">
                <div className="card-body   p-9">
                  <div className="row mb-6">
                    <Label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Other Charges{" "}
                    </Label>
                    <div className="col-lg-8">
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="Enter other charges"
                        value={EOCharges}
                        onChange={handledeEditOtherCharges}
                      />
                      {EOCharges === null && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Please provide other charges
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleEditChargesModal}>
                Not Now
              </Button>
              <Button
                color="danger"
                disabled={EbtnDisable}
                onClick={OtherChargesEdit}
              >
                Edit Other Charges
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewClaimForm;
