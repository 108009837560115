import React, { useEffect, useState } from "react";

import {
  Container,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
  TabPane,
  TabContent,
  CardBody,
  Input,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useParams } from "react-router-dom";
import Avatar from "react-avatar";
import { getEmployeeDetails } from "../Worker Report/_requests";
import { datewisecreditAgencyGraph, datewisevisitGraph, getCompanyReport } from "./_requests";
import Flatpickr from "react-flatpickr";
import { BasicLineCharts, ColumnWithLable } from "./chart";
import { HistoryVisitPage } from "./HistoryVisitPage";
import { NonpanelVisitPage } from "./NonpanelVisitPage";
const { startOfYear, endOfYear } = require("date-fns");
const AgencyReportList = (props: any) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<any>("1");
  const [worker, setWorker] = useState<any>([]);
  const [Agency, setAgency] = useState<any>([]);
  const [isPersonalDetailsOpen, setIsPersonalDetailsOpen] = useState(false);
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(true);
  const [penel, setpenel] = useState<any>([]);
  const [nonpenel, setnonpenel] = useState<any>([]);

  useEffect(() => {
    getCompanyReport(id).then((res: any) => {
      setAgency(res.Data);

      setpenel(res?.Data?.panelvisits);
      setnonpenel(res?.Data?.nonpanelvisits);
    });
  }, []);
  const [data, setData] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [creditdata, setCreditData] = useState<number[]>([]);
  const [creditcategories, setCreditCategories] = useState<string[]>([]);
interface CreditData {
  MaxAmount: number;
  MinAmount: number;
  totalCredit: number;
}

const [CountData, setCountData] = useState<CreditData>({
  MaxAmount: 0,
  MinAmount: 0,
  totalCredit: 0,
});
  const endDates = new Date();
  const startDate = startOfYear(endDates);
  const endDate = endOfYear(endDates);
  const initialDateRange = [startDate, endDate];
  const [dateRange, setDateRange] = useState<Date[]>(initialDateRange);

  const fetchGraphData = (startDate: any, endDate: any) => {
    if (!startDate || !endDate) {
      return; // Exit if start or end date is not available
    }

    const StartDate = formatDate(startDate);
    const EndDate = formatDate(endDate);
    const requestData = {
      id: id,
      startDate: StartDate,
      endDate: EndDate,
    };


    datewisevisitGraph(requestData).then((response: any) => {
 
      
      const APIRES = response?.Data;
      const months = APIRES.map((item: { month: any }) => item.month);
      const count = APIRES.map((item: { count: any }) => item.count);

      setData(count);
      setCategories(months);
    });
  };
  const fetchCreditGraphData = (startDate: any, endDate: any) => {
    if (!startDate || !endDate) {
      return; // Exit if start or end date is not available
    }
   
    const StartDate = formatDate(startDate);
    const EndDate = formatDate(endDate);
    const requestData = {
      id: id,
      startDate: StartDate,
      endDate: EndDate,
    };

    datewisecreditAgencyGraph(requestData).then((response: any) => {
      const APIRES = response?.Data;
      const months = APIRES.map((item: { month: any }) => item.month);
      const count = APIRES.map((item: { count: any }) => item.count);



      setCreditData(count);
      setCreditCategories(months);
      setCountData(response)
    });
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };

  useEffect(() => {
    fetchGraphData(startDate, endDate);
    fetchCreditGraphData(startDate, endDate);

  }, []);

  document.title = "Agency Report | Alkhairi Care";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Agency Report" pageTitle="Report" />
        <Row>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className=" ri-account-circle-fill me-3"></i>{" "}
                  <span> {Agency?.totalWorkers}</span>
                </h5>
                <div className="ms-2">Total Workers</div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className="  ri-file-chart-line me-3"></i>{" "}
                  <span> {Agency?.totalInvoice}</span>
                </h5>
                <div className="ms-2">Total Invoices</div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className=" ri-account-circle-fill me-3"></i>{" "}
                  <span> {Agency?.totalSupport}</span>
                </h5>
                <div className="ms-2">Total Support</div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className=" ri-account-circle-fill me-3"></i>{" "}
                  <span> {Agency?.totalVisit}</span>
                </h5>
                <div className="ms-2">Total Visits</div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className=" ri-account-circle-fill me-3"></i>{" "}
                  <span> {Agency?.totalpanel}</span>
                </h5>
                <div className="ms-2">Total Panel Claims</div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={2} lg={2} sm={12}>
            <Card className="card card-height-100  shadow-none bg-opacity-10">
              <CardBody className="p-4 ">
                <h5 className="m-2 text start">
                  <i className=" ri-account-circle-fill me-3"></i>{" "}
                  <span> {Agency?.totalNonpanel}</span>
                </h5>
                <div className="ms-2">Total NonPanel Claims</div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={3} lg={4}>
            <Card>
              <CardHeader>
                <h4 className="ms-3">
                  MYR{" "}
                  <span className="ms-2">
                    {Agency.payReceived !== null ? Agency.payReceived : 0}
                  </span>
                </h4>
                <p className="ms-3 mt-3">Total Payment Received </p>
              </CardHeader>

              <CardHeader>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <div className="avatar-md">
                    <div className="avatar-title bg-white rounded-circle">
                      <Avatar
                        name={Agency?.name}
                        maxInitials={2}
                        round="50px"
                        color="#607458"
                        fgColor="#1b1b29"
                        className="custom-avatar" // Add a custom class name
                        style={{ fontSize: "1.25rem" }} // Adjust the font size using inline style
                      ></Avatar>
                    </div>
                  </div>
                </div>
                <h4 className="text-center">{Agency?.info?.name}</h4>
                <h4 className="text-center">{Agency?.info?.agencyType}</h4>
              </CardHeader>

              <div className="accordion accordion-flush">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button bg-transparent shadow-none ${
                        isPersonalDetailsOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      id="flush-headingRating"
                      onClick={() =>
                        setIsPersonalDetailsOpen(!isPersonalDetailsOpen)
                      }
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Details
                      </span>{" "}
                    </button>
                  </h2>

                  <UncontrolledCollapse toggler="#flush-headingRating">
                    <div
                      id="flush-collapseRating"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingRating"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2">
                          <label
                            className="-label"
                            htmlFor="productratingRadio4"
                          >
                            <span className="text-muted me-3">
                              Agency Contact :
                            </span>{" "}
                            <span> {Agency?.info?.telephoneNumber}</span>
                          </label>

                          <label
                            className="-label"
                            htmlFor="productratingRadio4"
                          >
                            <span className="text-muted me-3">
                              Agency Email :
                            </span>{" "}
                            <span>{Agency?.info?.email}</span>
                          </label>

                          <label
                            className="-label"
                            htmlFor="productratingRadio4"
                          >
                            <span className="text-muted me-3">Address :</span>{" "}
                            <span>
                              {Agency?.info?.address},{Agency?.info?.city},
                              {Agency?.info?.state}-{Agency?.info?.pincode}
                            </span>
                          </label>

                          <label
                            className="-label"
                            htmlFor="productratingRadio4"
                          >
                            <span className="text-muted me-3">Country :</span>{" "}
                            <span>{Agency?.info?.country}</span>
                          </label>
                          <label
                            className="-label"
                            htmlFor="productratingRadio4"
                          >
                            <span className="text-muted me-3">Website :</span>{" "}
                            <span>{Agency?.info?.website}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button bg-transparent shadow-none ${
                        isContactDetailsOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      id="flush-headingBrands"
                      onClick={() =>
                        setIsContactDetailsOpen(!isContactDetailsOpen)
                      }
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        POC Details
                      </span>{" "}
                    </button>
                  </h2>
                  <UncontrolledCollapse
                    toggler="#flush-headingBrands"
                    defaultOpen
                  >
                    <div
                      id="flush-contactdetails"
                      className="accordion-collapse collapse show collapsed"
                      aria-labelledby="flush-contactdetails"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2">
                          <div className="">
                            <span className="text-muted me-3">
                              Contact person :
                            </span>{" "}
                            <span>{Agency?.info?.primaryContact}</span>
                          </div>
                          <div className="">
                            <span className="text-muted me-3">
                              Contact number :
                            </span>{" "}
                            <span>{Agency?.info?.primaryTelephone}</span>
                          </div>
                          <div className="">
                            <span className="text-muted me-3">Email :</span>{" "}
                            <span>{Agency?.info?.primaryEmail}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div>
            </Card>
          </Col>

          <Col xl={9} lg={8}>
            <Row>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Total Visit by Workers based on each months
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex flex-wrap gap-2">
                        <Flatpickr
                          className="form-control"
                          id="Daterange"
                          placeholder="Filter by date range"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            mode: "range",
                            dateFormat: "d/m/Y",
                            defaultDate: [startDate, endDate], // Set initial date range
                            onChange: function (selectedDates) {
                              if (selectedDates.length === 2) {
                                fetchGraphData(
                                  selectedDates[0],
                                  selectedDates[1]
                                );
                              } else if (selectedDates.length === 0) {
                                // Handle reset here if needed
                                // You might want to refetch data with the default date range
                                fetchGraphData(startDate, endDate);
                              }
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p>It shows combined value of panel and non panel</p>
                </CardHeader>
                <CardBody>
                  <BasicLineCharts
                    dataColors='["--vz-primary"]'
                    data={data}
                    categories={categories}
                    name="Total visits"
                  />
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card className="mt-3">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Credit usage based on Month
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex flex-wrap gap-2">
                        <Flatpickr
                          className="form-control"
                          id="Daterange"
                          placeholder="Filter by date range"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            mode: "range",
                            dateFormat: "d/m/Y",
                            defaultDate: [startDate, endDate], // Set initial date range
                            onChange: function (selectedDates) {
                              if (selectedDates.length === 2) {
                                fetchCreditGraphData(
                                  selectedDates[0],
                                  selectedDates[1]
                                );
                              } else if (selectedDates.length === 0) {
                                // Handle reset here if needed
                                // You might want to refetch data with the default date range
                                fetchCreditGraphData(startDate, endDate);
                              }
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>

                  <Row className="d-flex flex-wrap flex-stack">
                    <Col className="d-flex flex-column flex-grow-1 pe-8">
                      <Row className="d-flex flex-wrap">
                        <Col className="border border-gray-300 border-dashed rounded min-w-125px py-2 ps-3 m-2">
                          <div className="d-flex align-items-center">
                            <div className="fw-bold fs-6 text-gray-400">
                              MYR
                            </div>
                            <div
                              className="fs-2 "
                              style={{ marginLeft: "10%" }}
                            >
                      {(CountData?.MaxAmount ?? 0).toString()}

                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Highest Credit Used
                          </div>
                        </Col>
                        <Col className="border border-gray-300 border-dashed rounded min-w-125px py-2 ps-3 m-2">
                          <div className="d-flex align-items-center">
                            <div className="fw-bold fs-6 text-gray-400">
                              MYR
                            </div>
                            <div
                              className="fs-2 "
                              style={{ marginLeft: "10%" }}
                            >
                              {(CountData?.MinAmount ?? 0).toString()}
                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Lowest Credit Used
                          </div>
                        </Col>
                        <Col className="border border-gray-300 border-dashed rounded min-w-125px py-2 ps-3 m-2">
                          <div className="d-flex align-items-center">
                            <div className="fw-bold fs-6 text-gray-400">
                              MYR
                            </div>
                            <div
                              className="fs-2 "
                              style={{ marginLeft: "10%" }}
                            >
                              {(CountData?.totalCredit ?? 0).toString()}
                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Total Credit
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ColumnWithLable
                    dataColors='["--vz-primary"]'
                    data={creditdata}
                    categories={creditcategories}
                    name='Credit used'
                  />
                </CardBody>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6}>
          {/* <HistoryVisitPage className='mb-5 mb-xxl-8 col-md-12' data={penel} /> */}

          </Col>
          <Col lg={6} xl={6}>
          {/* <NonpanelVisitPage className='mb-5 mb-xxl-8 col-md-12' data={nonpenel} /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AgencyReportList;
